import HelpIcon from "@components/common/HelpIcon";
import Placeholder from "@components/common/Placeholder";
import { financialToolTip } from "@constants/tooltip-data";
import generateLineChartOptions from "@utils/chart/line-chart";
import { formatNumberWithCommas, getTickAmount } from "@utils/helpers";
import { http } from "@utils/http";
import {
  generateFinancialGCIDataByYear,
  generateTicks,
} from "@utils/transform/gci";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function ForeCastedRevenue() {
  const desktopOptions: any = {
    chart: {
      id: "line-chart",
      toolbar: {
        show: false,
      },

      // dropShadow: {
      //   enabled: false,
      //   enabledOnSeries: undefined,
      //   top: 0,
      //   left: 0,
      //   blur: 3,
      //   color: '#000',
      //   opacity: 0.35,
      // },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#CF4AFE"],
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      style: {
        colors: "#CF4AFE",
      },
    },
    yaxis: {
      labels: {
        show: true,
        offsetX: -14,
        formatter(val: any) {
          return `$${val}k`;
        },
        style: {
          colors: "#CF4AFE",
          fontSize: "12px",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-label",
        },
      },
    },
    grid: {
      // borderColor: "#f1f1f1",
      padding: {
        left: -14,
      },
    },
  };
  const mobileOptions:any = {
    ...desktopOptions,
    xaxis: {
      ...desktopOptions.xaxis,
      labels: {
        rotate: -45,
        rotateAlways: true,
      },
    },
  };
  const [gci, setGci] = useState({} as any);
  const [gciApiData, setGciApiData] = useState({} as any);
  const [gciForecastedData, setGciForecastedData] = useState({} as any);
  const [theOptions, setTheOptions] = useState({} as any);
  const [isLoadingGci, setIsLoadingGci] = useState(false);
  const series = [
    {
      name: "series-1",
      data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5],
    },
  ];
  useEffect(() => {
    // if (officeId !== "") {
    getGCIData();
    // }
  }, []);

  function getGCIData() {
    setIsLoadingGci(true);
    http
      .get("/financial/forecast-report.json")
      .then(async (response: any) => {
        const apiData = response?.data?.forecast_report;
        setGciApiData(apiData);
        
        const forecastedData = await getGCIForecastedData();
        // const reverseData = response?.data?.forecast.reverse();
        const mergedData = [...forecastedData, ...apiData];

        const theData: any = generateFinancialGCIDataByYear(
          2023,
          mergedData,
        );
        setGci(theData);

        const chartOptions: any = generateLineChartOptions({
          theId: "line-chart",
          colors: ["#CF4AFE"],
          categories: theData?.categories,
          ticks: generateTicks(theData?.gci_data ?? []),
          tickAmount: getTickAmount(theData?.gci_data),
          ticksPreLast: ["$", 'k'],
          forecast: forecastedData?.length,
        });
        setTheOptions(chartOptions);
        setIsLoadingGci(false);
      }).catch((error:any) => console.log(error));
  }

  function getGCIForecastedData() {
    return http
      .get("/serverless/financial-gci-line-chart.json")
      .then((response: any) => {
        const apiData = response?.data?.forecast;
        setGciForecastedData(apiData);
        return apiData;
      }).catch((error:any) => console.log(error));
  }

  return (
    <>
      <div className="mb-4" style={{ backgroundColor: "#2A3042" }}>
        <div className="custom-modal-inner__padding">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row row-gap-2 row-gap-md-0">
                <div className="col-md-8">
                <div className="d-inline-block">
                  <span className="header-title pe-2 ">
                    3 Month Forecasted GCI
                  </span>
                  <div className="d-inline-block align-middle">
                    <HelpIcon
                      tooltip={`${financialToolTip.forecastedRevenue}`}
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
                </div>
                <div className="col-md-4">
                  {/* <div className="">
                    <SearchSelect
                      options={[
                        {
                          id: 1,
                          title: "office-1",
                        },
                        {
                          id: 2,
                          title: "office-2",
                        },
                      ]}
                      isClearable
                      getOptionValue={(option: any) => option.id}
                      getOptionLabel={(option: any) => `${option.title}`}
                      placeholders="Select Office"
                    />
                  </div> */}
                </div>
              </div>
              {isLoadingGci && <div className="loader-circle"></div>}
              <div id="line-chart" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: "GCI",
                        data: gci?.gci_data ?? [],
                      },
                    ]}
                    options={theOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="">
                <p className="mb-0 custom-text-fs">Last month&apos;s GCI</p>
                <p className="number-stats mb-0 text-break">
                $
                    <Placeholder
                      loading={isLoadingGci}
                      className="w-8 h-8"
                    ></Placeholder>
                    {!isLoadingGci && formatNumberWithCommas(Math.round(gciApiData[0]?.forecast_gci))}
                </p>
                {/* <div>
                  <span className="badge badge-soft-success p-1 custom-fs-10 me-1">
                    + 0.4%
                  </span>
                  <span className="custom-fs-10">From previous period</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForeCastedRevenue;
