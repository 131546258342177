/* eslint-disable camelcase */
/* eslint-disable no-multiple-empty-lines */
const generateToolsCmaPieChartData = (data:any) => {
  const result:any = {
    reportPercentageData: [],
    reportCountData: [],
    reportLabels: [],
  };
 
  // eslint-disable-next-line no-unused-expressions
  data?.length && data.forEach((item:any) => {
    result.reportLabels.push(item.report_type); // Add report_type to labels array
    result.reportCountData.push(item.report_count); // Add report_count to series array
    result.reportPercentageData.push(item.report_type_percentage); // Add report_count to series array
  });
  return result;
};

const generateToolsNeutrinoPieChartData = (data:any) => {
  const result:any = {
    reportPercentageData: [],
    reportCountData: [],
    reportLabels: [],
  };
 
  // eslint-disable-next-line no-unused-expressions
  data?.length && data.forEach((item:any) => {
    result.reportLabels.push(item.modified_type); // Add report_type to labels array
    result.reportCountData.push(item.type_count); // Add type_count to series array
    result.reportPercentageData.push(item.type_percentage); // Add report_count to series array
  });
  return result;
};

const generateToolsTopAgentLineChart = (data: any): Record<string, any> => {
  const result: Record<string, any> = {
    months: [],
    leadTypesData: {},
    categories: [],
  };

  // Create a set of all unique lead types
  const leadTypesSet = new Set(data.map((entry: any) => entry.lead_type));

  // Initialize leadTypesData with empty objects for each month
  const monthsSet = new Set(data.map((entry: any) => entry.month_short_name));
  monthsSet.forEach((month: any) => {
    result.leadTypesData[month] = {};
    leadTypesSet.forEach((leadType: any) => {
      result.leadTypesData[month][leadType] = 0;
    });
  });

  const filteredData = data
    .sort((a: any, b: any) => {
      // Compare by year
      if (parseFloat(a.year) !== parseFloat(b.year)) {
        return (parseFloat(a.year) - parseFloat(b.year));
      }
      // If years are the same, compare by month
      const monthOrder: any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_short_name] - monthOrder[b.month_short_name];
    }).slice(-15);

  filteredData.forEach((entry: any) => {
    const yearSplit = entry.year.slice(-2);
    result.months.push(entry.month_short_name);
    result.categories.push(`${entry.month_short_name} ${yearSplit}`);

    // Update the value for the corresponding month and lead type
    result.leadTypesData[entry.month_short_name][entry.lead_type] = (entry.last_12_months_count ?? 0);
  });

  return result;
};

const generateToolsCmaOverallReportTypeData = (data:any) => {
  const result: Record<string, any> = {
    months: [],
    cma_last_12_months_count: [],
    cma_previous_12_months_count: [],
    cma_percent_difference: [],
    flyer_last_12_months_count: [],
    flyer_previous_12_months_count: [],
    flyer_percent_difference: [],
    homebeat_last_12_months_count: [],
    homebeat_previous_12_months_count: [],
    homebeat_percent_difference: [],
    property_last_12_months_count: [],
    property_previous_12_months_count: [],
    property_percent_difference: [],
    tour_last_12_months_count: [],
    tour_previous_12_months_count: [],
    tour_percent_difference: [],
    categories: [],
  };

  const filteredData = data
    .sort((a:any, b:any) => {
      // Compare by year
      if (parseFloat(a.year) !== parseFloat(b.year)) {
        return (parseFloat(a.year) - parseFloat(b.year));
      }
      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_name] - monthOrder[b.month_name];
    }).slice(-12);

  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry.year).slice(-2);
    result.months.push(entry.month_name);
    
    result.cma_last_12_months_count.push(entry.cma_last_12_months_count ?? 0);
    result.cma_previous_12_months_count.push(entry.cma_previous_12_months_count ?? 0);
    result.cma_percent_difference.push(entry.cma_percent_difference ?? 0);
    
    result.flyer_last_12_months_count.push(entry.flyer_last_12_months_count ?? 0);
    result.flyer_previous_12_months_count.push(entry.flyer_previous_12_months_count ?? 0);
    result.flyer_percent_difference.push(entry.flyer_percent_difference ?? 0);

    result.homebeat_last_12_months_count.push(entry.homebeat_last_12_months_count ?? 0);
    result.homebeat_previous_12_months_count.push(entry.homebeat_previous_12_months_count ?? 0);
    result.homebeat_percent_difference.push(entry.homebeat_percent_difference ?? 0);

    result.property_last_12_months_count.push(entry.property_last_12_months_count ?? 0);
    result.property_previous_12_months_count.push(entry.property_previous_12_months_count ?? 0);
    result.property_percent_difference.push(entry.property_percent_difference ?? 0);

    result.tour_last_12_months_count.push(entry.tour_last_12_months_count ?? 0);
    result.tour_previous_12_months_count.push(entry.tour_previous_12_months_count ?? 0);
    result.tour_percent_difference.push(entry.tour_percent_difference ?? 0);

    result.categories.push(`${entry.month_name} ${yearSplit}`);
  });

  return result;
};

const generateToolsNeutrinoOverallReportTypeData = (data:any) => {
  const result: Record<string, any> = {
    months: [],
    categories: [],

    agent_contact_form_last_12_months_count: [],
    agent_contact_form_previous_12_months_count: [],
    agent_contact_form_percent_diff: [],

    area_inquiry_last_12_months_count: [],
    area_inquiry_previous_12_months_count: [],
    area_inquiry_percent_diff: [],

    office_contact_form_referral_last_12_months_count: [],
    office_contact_form_referral_previous_12_months_count: [],
    office_contact_form_referral_percent_diff: [],

    property_inquiry_last_12_months_count: [],
    property_inquiry_previous_12_months_count: [],
    property_inquiry_percent_diff: [],

    property_inquiry_referral_last_12_months_count: [],
    property_inquiry_referral_previous_12_months_count: [],
    property_inquiry_referral_percent_diff: [],

    request_con_sultation_form_last_12_months_count: [],
    requestconsultation_form_previous_12_months_count: [],
    request_consultation_form_percent_diff: [],

    request_consultation_form_referral_last_12_months_count: [],
    request_consultation_form_referral_previous_12_months_count: [],
    request_consultation_form_referral_percent_diff: [],

    sell_your_home_avm_form_last_12_months_count: [],
    sell_your_home_avm_form_previous_12_months_count: [],
    sell_your_home_avm_form_percent_diff: [],

    sell_your_home_avm_form_referral_last_12_months_count: [],
    sell_your_home_avm_form_referral_previous_12_months_count: [],
    sell_your_home_avm_form_referral_percent_diff: [],
  };

  const filteredData = data
    .sort((a:any, b:any) => {
      // Compare by year
      if (parseFloat(a.year) !== parseFloat(b.year)) {
        return (parseFloat(a.year) - parseFloat(b.year));
      }
      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_short_name] - monthOrder[b.month_short_name];
    }).slice(-12);

  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry.year).slice(-2);
    result.months.push(entry?.month_short_name);
    
    result.agent_contact_form_last_12_months_count.push(entry.agent_contact_form_last_12_months_count ?? 0);
    result.agent_contact_form_previous_12_months_count.push(entry.agent_contact_form_previous_12_months_count ?? 0);
    result.agent_contact_form_percent_diff.push(entry.agent_contact_form_percent_diff ?? 0);
    
    result.area_inquiry_last_12_months_count.push(entry.area_inquiry_last_12_months_count ?? 0);
    result.area_inquiry_previous_12_months_count.push(entry.area_inquiry_previous_12_months_count ?? 0);
    result.area_inquiry_percent_diff.push(entry.area_inquiry_percent_diff ?? 0);
    
    result.office_contact_form_referral_last_12_months_count.push(entry.office_contact_form_referral_last_12_months_count ?? 0);
    result.office_contact_form_referral_previous_12_months_count.push(entry.office_contact_form_referral_previous_12_months_count ?? 0);
    result.office_contact_form_referral_percent_diff.push(entry.office_contact_form_referral_percent_diff ?? 0);

    result.property_inquiry_last_12_months_count.push(entry.property_inquiry_last_12_months_count ?? 0);
    result.property_inquiry_previous_12_months_count.push(entry.property_inquiry_previous_12_months_count ?? 0);
    result.property_inquiry_percent_diff.push(entry.property_inquiry_percent_diff ?? 0);

    result.property_inquiry_referral_last_12_months_count.push(entry.property_inquiry_referral_last_12_months_count ?? 0);
    result.property_inquiry_referral_previous_12_months_count.push(entry.property_inquiry_referral_previous_12_months_count ?? 0);
    result.property_inquiry_referral_percent_diff.push(entry.property_inquiry_referral_percent_diff ?? 0);
    
    result.request_con_sultation_form_last_12_months_count.push(entry.request_con_sultation_form_last_12_months_count ?? 0);
    result.requestconsultation_form_previous_12_months_count.push(entry.requestconsultation_form_previous_12_months_count ?? 0);
    result.request_consultation_form_percent_diff.push(entry.request_consultation_form_percent_diff ?? 0);
    
    result.request_consultation_form_referral_last_12_months_count.push(entry.request_consultation_form_referral_last_12_months_count ?? 0);
    result.request_consultation_form_referral_previous_12_months_count.push(entry.request_consultation_form_referral_previous_12_months_count ?? 0);
    result.request_consultation_form_referral_percent_diff.push(entry.request_consultation_form_referral_percent_diff ?? 0);

    result.sell_your_home_avm_form_last_12_months_count.push(entry.sell_your_home_avm_form_last_12_months_count ?? 0);
    result.sell_your_home_avm_form_previous_12_months_count.push(entry.sell_your_home_avm_form_previous_12_months_count ?? 0);
    result.sell_your_home_avm_form_percent_diff.push(entry.sell_your_home_avm_form_percent_diff ?? 0);

    result.sell_your_home_avm_form_referral_last_12_months_count.push(entry.sell_your_home_avm_form_referral_last_12_months_count ?? 0);
    result.sell_your_home_avm_form_referral_previous_12_months_count.push(entry.sell_your_home_avm_form_referral_previous_12_months_count ?? 0);
    result.sell_your_home_avm_form_referral_percent_diff.push(entry.sell_your_home_avm_form_referral_percent_diff ?? 0);

    result.categories.push(`${entry.month_short_name} ${yearSplit}`);
  });
  return result;
};
export { generateToolsCmaOverallReportTypeData, generateToolsCmaPieChartData, generateToolsNeutrinoOverallReportTypeData, generateToolsNeutrinoPieChartData, generateToolsTopAgentLineChart };

