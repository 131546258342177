/* eslint-disable react/no-array-index-key */
import Pagination from "@components/common/Pagination/Pagination";
import TablePlaceHolder from "@components/common/TablePlaceHolder";
import TableSortIcon from "@components/common/TableSortIcon";
import { colSortingV2 } from "@utils/table-sort";
import { useEffect, useState } from "react";
// css import
import PerPageOptionSelector from "@components/common/PerPageOptionSelector";
import SearchSelect from "@components/common/SearchSelect";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { faker } from "@faker-js/faker";
import { http } from "@utils/http";
import "react-loading-skeleton/dist/skeleton.css";

function CmaOfficeOverview(props: any) {
  const LAST_12_MONTHS = "last_12_months";
  const YTD = "year_to_data";
  const [officeOverviewData, setOfficeOverviewData] = useState([] as any);
  const [officeOverviewApiData, setOfficeOverviewApiData] = useState([] as any);
  const [overviewOptions, setOverViewOptions] = useState();
  const [selectedOverviewOptions, setSelectedOverViewOptions] = useState({
    id: 1,
    title: "Last 12 Months",
    value: `${LAST_12_MONTHS}`,
  } as any);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [sortColumns, setSortColumns] = useState([] as any);
  const [sortAPIColumns, setSortAPIColumns] = useState("" as any);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0 as any);
  const [perPage, setPerPage] = useState(10);

  const isSelectedOptionYtd = selectedOverviewOptions?.value === YTD;

  const last12MonthOrYtd = isSelectedOptionYtd ? "reports_ytd" : "reports_last_12_months";
  const ppMonthOrYtd = isSelectedOptionYtd ? "reports_last_year_ytd" : "reports_previous_12_months";
  const percentDiff = isSelectedOptionYtd ? "percent_diff_ytd" : "percent_diff_last_12_months";
  
  const columns: any = [
    {
      name: "Office",
      slug: "office_name",
      sort: false,
    },
    {
      name: "Last 12 Months/YTD",
      // slug: isSelectedOptionYtd ? "ytd_total_sent" : "last_12_total_sent",
      slug: `${last12MonthOrYtd}`,
      classNames: "justify-content-center",
      sort: false,
    },
    {
      name: "Previous Period Months/YTD",
      // slug: isSelectedOptionYtd ? "last_year_ytd_total_sent" : "previous_12_total_sent",
      slug: `${ppMonthOrYtd}`,
      classNames: "justify-content-center",
      sort: false,
    },
    {
      name: "Percent Differentage",
      // slug: isSelectedOptionYtd ? "last_year_ytd_total_opens" : "previous_12_total_opens",
      slug: `${percentDiff}`,
      classNames: "justify-content-center",
      sort: false,
    },
  ];
  
  useEffect(() => {
    getOfficeOverview();
  }, [currentPage, perPage, sortAPIColumns]);

  function getOfficeOverview(currentPageData = currentPage) {
    setIsDataLoading(true);
    http
      .get(
        "/tools/cloudcma--total-cloudcma-reports-last-12-month-ytd-by-office.json",
      )
      .then((response: any) => {
        const { data }: any = response;
        let theData = data?.cloudcma_marketing;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            office_name: faker.company.name(),
          }));
        }
        setOfficeOverviewData(theData);
        setOfficeOverviewApiData(theData);
        setTotalPage(data?.last_page);
        setIsDataLoading(false);
      }).catch((error) => {
        setIsDataLoading(false);
        console.log(error);
      });
  }

  // Define a function to handle column sorting
  const handleSort = (column: any) => {
    const sortingOutput: any = colSortingV2(
      officeOverviewApiData,
      sortColumns,
      column,
    );
    setSortAPIColumns(sortingOutput?.apiColumn);
    setSortColumns(sortingOutput.columns);
  };

  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected + 1);
  };
  return (
    <div
      className="custom-modal-inner__padding h-100"
      style={{ background: "#2B3041" }}
    >
      <div className="row row-gap-2 row-gap-lg-0 mb-3 ">
        <div className="col-12 col-lg-5">
          <div className="d-inline-block">
            <span className="header-title-6 fw-bold text-white">Total CMA Reports</span>
            {/* <div className="d-inline-block align-text-top ps-1">
              <HelpIcon
                tooltip="Total CMA Reports"
                width={20}
                height={20}
              />
            </div> */}
          </div>
        </div>
        <div className="col-12 col-lg-7 d-flex justify-content-end">
          <div className="">
            <SearchSelect
              options={[
                {
                  id: 1,
                  title: "Last 12 Months",
                  value: `${LAST_12_MONTHS}`,
                },
                {
                  id: 2,
                  title: "Year to Date",
                  value: `${YTD}`,
                },
              ]}
              value={
                JSON.stringify(selectedOverviewOptions) !== "{}"
                  ? selectedOverviewOptions
                  : "Select..."
              }
              onChange={(option: any) => {
                setSelectedOverViewOptions(option);
              }}
              width="200px"
              // isClearable
              getOptionValue={(option: any) => option.value}
              getOptionLabel={(option: any) => `${option.title}`}
              placeholders="Select..."
            />
          </div>
        </div>
      </div>
      <div className="table-responsive scrollable-body-2">
        <table className="align-middle table-nowrap table-check table table-striped table-sm fixed-header fixed-column">
          <thead className="table-light" style={{ background: "#33394C" }}>
            <tr role="row">
              {columns.map((column: any, index:any) => (
                <th
                  key={column.slug}
                  onClick={() => {
                    if (column.sort) {
                      handleSort(column.slug);
                    }
                  }}
                  role="button"
                >
                  <div
                    className={`d-flex p-1 align-items-center ${column?.classNames}`}
                  >
                    <TableSortIcon
                      sortColumns={sortColumns}
                      column={column}
                    ></TableSortIcon>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <TablePlaceHolder
              items={officeOverviewData}
              loading={isDataLoading}
              rows={13}
              cols={8}
            />
            {!isDataLoading &&
              officeOverviewData?.map((office: any, id: any) => (
                <tr key={office.office_id}>
                  <td className="p-2 text-wrap">
                    {office?.office_name}
                  </td>
                  <td className="p-2 text-wrap text-center">
                    {office?.[last12MonthOrYtd]}
                  </td>
                  <td className="text-center">
                    {office?.[ppMonthOrYtd]}
                  </td>
                  <td className="text-center">{office?.[percentDiff]}%</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="row mt-3">
        <div className="col-12 col-md-4 d-flex gap-2 align-items-center">
          <p className="mb-0 custom-fs-12-to-14">Rows Per Page</p>
          <PerPageOptionSelector
            perPageEmitSet={(value: any) => setPerPage(value)}
            labelUnit=" "
          />
        </div>
        <div className="col-12 col-md-8 d-flex justify-content-center justify-content-md-start align-items-center">
          <Pagination
            pageCount={totalPage}
            perPage={perPage}
            onPageChange={handlePageChange}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default CmaOfficeOverview;
