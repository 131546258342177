/* eslint-disable no-multiple-empty-lines */
// import { split } from "lodash";

const generateEmailMarketingEngagementScore = (data: any): Record<string, any> => {
  const result: Record<string, any> = {
    months: [],
    current_year_score: [],
    previous_year_score: [],
    categories: [],
    latestMonthEngagementObj: {},
  };

  const filteredData = data
    .sort((a:any, b:any) => {
      // Compare by year
      if (parseFloat(a.year) !== parseFloat(b.year)) {
        return (parseFloat(a.year) - parseFloat(b.year));
      }
      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_name] - monthOrder[b.month_name];
    });
  
  result.latestMonthEngagementObj = filteredData[filteredData?.length - 1] ?? {};

  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry.year).slice(-2);
    const formattedCategories = `${entry.month_name} ${yearSplit}`;
    
    result.months.push(entry.month_name);
    result.current_year_score.push(entry.current_year_score);
    result.previous_year_score.push(entry.previous_year_score);
    
    result.categories.push(formattedCategories);
  });
  return result;
};

const generateEmailMarketingChartFormat = (data: any): Record<string, any> => {
  const result: Record<string, any> = {
    months: [],
    last_12_campaigns: [],
    previous_yr_campaigns: [],

    last_12_opens: [],
    previous_yr_opens: [],

    last_12_sent: [],
    previous_yr_sent: [],

    last_12_open_rate: [],
    previous_yr_open_rate: [],

    categories: [],
    latestMonthMarketingChartObj: {},
  };
  const filteredData = data
    .sort((a:any, b:any) => {
      const aYear = a.month.split('-')[0];
      const bYear = b.month.split('-')[0];
      // Compare by year
      if (aYear !== bYear) {
        return aYear - bYear;
      }

      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_name] - monthOrder[b.month_name];
    });
  
  result.latestMonthEngagementObj = filteredData[filteredData?.length - 1] ?? {};

  filteredData.forEach((entry: any) => {
    // const yearSplit = String(entry.year).slice(-2);
    // const formattedCategories = `${entry.month_name} ${yearSplit}`;

    const yearSplitFromMonth = String(entry.month).split("-")[0];
    const formattedYear = String(yearSplitFromMonth).slice(-2);
    const formattedCategories = `${entry.month_name} ${formattedYear}`;
    
    result.months.push(entry.month_name);
    result.last_12_campaigns.push(entry?.last_12_campaigns ?? 0);
    result.previous_yr_campaigns.push(entry?.previous_yr_campaigns ?? 0);

    result.last_12_sent.push(entry?.last_12_sent ?? 0);
    result.previous_yr_sent.push(entry?.previous_yr_sent ?? 0);

    result.last_12_open_rate.push(entry?.last_12_open_rate ?? 0);
    result.previous_yr_open_rate.push(entry?.previous_yr_open_rate ?? 0);
    
    // eslint-disable-next-line no-unused-expressions, no-prototype-builtins
    entry.hasOwnProperty('last_12_opens') && result.last_12_opens.push(entry?.last_12_opens ?? 0);
    result.previous_yr_opens.push(entry?.previous_yr_opens ?? 0);
    

    result.categories.push(formattedCategories);
  });
  return result;
};

const generateChartFormatOnTableHover = (data: any): Record<string, any> => {
  const result: Record<string, any> = {
    months: [],
    total_opens: [],
    total_sent: [],
    total_open_rate: [],
    categories: [],
  };
  const filteredData = data
    .sort((a:any, b:any) => {
      // Compare by year
      if (a.year !== b.year) {
        return a.year - b.year;
      }

      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.short_month_name] - monthOrder[b.short_month_name];
    });

  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry.year).slice(-2);
    const formattedCategories = `${entry.short_month_name} ${yearSplit}`;
    
    result.months.push(entry.short_month_name);
    result.total_opens.push((entry?.total_opens ?? 0));

    result.total_sent.push((entry?.total_sent ?? 0));

    result.total_open_rate.push((entry?.total_open_rate ?? 0));

    result.categories.push(formattedCategories);
  });
  return result;
};

export { generateChartFormatOnTableHover, generateEmailMarketingChartFormat, generateEmailMarketingEngagementScore };

