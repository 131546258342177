// import BuildIcon from '@mui/icons-material/Build';
// import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
// import HomeIcon from '@mui/icons-material/Home';
// import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
// import PersonIcon from '@mui/icons-material/Person';
// import WorkIcon from '@mui/icons-material/Work';
import { AgentsIcon, FinancialsIcon, HomeIcon, OfficeIcon, RecommendationIcon, ToolsIcon } from '@utils/icons';

const menus: any = [
  {
    name: 'Home',
    url: '/',
    path_name: '/',
    icon: HomeIcon,
  },
  {
    name: 'Agents',
    url: '/agents',
    path_name: '/agents',
    icon: AgentsIcon,
  },
  {
    name: 'Offices',
    url: '/offices',
    path_name: '/offices',
    // icon: WorkIcon,
    icon: OfficeIcon,
    
  },
  {
    name: 'Financials',
    url: '/financials',
    path_name: '/financials',
    icon: FinancialsIcon,
  },
  {
    name: 'Tools',
    url: '/tools',
    path_name: '/tools',
    // icon: BuildOutlinedIcon,
    icon: ToolsIcon,
  },
  {
    name: 'Recommendations',
    url: '/recommendations',
    path_name: '/recommendations',
    // icon: BuildOutlinedIcon,
    icon: RecommendationIcon,
  },
  // {
  //   name: 'Markets',
  //   url: 'markets',
  //   path_name: '/markets',
  //   // icon: MapsHomeWorkIcon,
  //   icon: MarketsIcon,
    
  // },
];

export default menus;
