/* eslint-disable no-multiple-empty-lines */
// HOME PAGE
const homePerms = {
  home_recommendation_lists: "home--recommendation-lists",
  home_recommendation_filter: "home--recommendation-filter",
  home_recommendation_ignore: "home--recommendation-ignore",
  home_recommendation_complete: "home--recommendation-complete",
  home_recommendation_draft_mail: "home--recommendation-draft-mail",
  home_recommendation_avg_days_market: "home--average-days-on-market",
  home_recommendation_avg_list_price: "home--average-list-price",
  home_recommendation_closed_gci_ytd: "home--closed-gci-year-to-date",
};

const agentPerms = {
  agent_lists: "agent--lists",

  agent_view_more: "agent--view-more",

  agent_modal_profile: "agent--modal--profile",

  agent_modal_sales_volume: "agent--modal--sales-volume",

  agent_modal_company_dollar: "agent--modal--company-dollar",

  agent_modal_list_vs_sell_sides: "agent--modal--sell-sides-vs-list-sides",

  agent_modal_gci: "agent--modal--gci",

  agent_modal_pending_gci: "agent--modal--pending-gci",

  agent_modal_years_brokerage: "agent--modal--years-at-brokerage",

  agent_modal_three_month_forecast: "agent--modal--3-month-forecast",

  agent_modal_recom_list: "agent--modal-recom-list",

  agent_modal_recom_filter: "agent--modal-recom-filter",

  agent_modal_recom_ignore: "agent--modal-recom-ignore",

  agent_modal_recom_complete: "agent--modal-recom-complete",

  agent_modal_recom_draft_mail: "agent--modal-recom-draft-mail",

  agent_modal_gci_company_dollar_chart: "agent--modal-gci-company-dollar-chart",

  agent_modal_list_sell_side_chart: "agent-modal--list-sides-sell-sides-chart",

  agent_modal_total_listings_sold_listings_chart:
    "agent-modal--total-listings-sold-listings-chart",

  agent_modal_avg_lists_solds_chart: "agent-modal--avg-lists-solds-chart",

  agent_modal_tech_engagement: "agent-modal--tech-engagement",

  agent_modal_monthly_engmnt_score: "agent-modal--monthly-engagement-score",

  agent_modal_cma_tech_stats: "agent-modal--cma-tech-stats",
};
const financialPerms = {
  financial_three_month_summary: "financial--3-month-summary",

  financial_list_vs_sell_side: "financial--list-sides-vs-sell-sides",

  financial_sales_price_trends: "financial--sales-price-trends",

  financial_total_listings: "financial--total-listings",

  financial_avg_list_price: "financial--average-list-price",

  financial_avg_sold_price: "financial--average-sold-price",

  financial_sold__to_list_ratio: "financial--sold-to-list-ratio",
};

const officePerms = {
  office_list_overview: "office--list-overview",

  office_list_view_more: "office--list-view-more",

  office_complete_recommendation: "office--complete-recomendation",

  office_new_agent_recruits: "office--new-agent-recruits",

  office_retention_overview: "office--retention-review",

  office_gci_breakdown: "office--gci-breakdown",

  office_recruitment_retention: "office--recruitment-retention",

  office_modal_info: "office--modal--info",

  office_modal_sales_volume: "office--modal--sales-volume",

  office_modal_company_dollar: "office--modal--company-dollar",

  office_modal_agent_retention: "office--modal--agent-retention",

  office_modal_sell_list_sides: "office--modal--sell-sides-list-sides",

  office_modal_gci: "office--modal--gci",

  office_modal_pending_gci: "office--modal--pending-gci",

  office_modal_three_month_forecast: "office--modal--3-month-forecast",

  office_modal_recom_list: "office--modal--recom-list",

  office_modal_recom_filter: "office--modal--recom-filter",

  office_modal_recom_ignore: "office--modal--recom-ignore",

  office_modal_recom_complete: "office--modal--recom-complete",

  office_modal_recom_draft_mail: "office--modal--recom-draft-mail",

  office_modal_gci_vs_company_dollar_chart: "office--modal--gci-vs-company-dollar-chart",

  office_modal_list_sell_sides_chart: "office--modal--list-sell-sides-chart",

  office_modal_total_listing_sold_listings_chart: "office--modal--total-listing-sold-listing-chart",

  office_modal_avg_list_sold_price_chart: "office--modal--avg-list-sold-price-chart",

  office_modal_tech_engagement: "office--modal--tech-engagement",

  office_modal_monthly_engmnt_score: "office--modal--monthly-engagement-score",

  office_modal_cma_tech_stats: "office--modal--cma-tech-stats",
};
export { agentPerms, financialPerms, homePerms, officePerms };

