import {
  ThunkAction,
  Action,
  PreloadedState, combineReducers, createStore, applyMiddleware, compose,
} from '@reduxjs/toolkit';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import ErrorMiddleware from './ErrorMiddleware';
import authReducer from './appSlice';
import { api } from './api';

export const history = createBrowserHistory();

export const reducer = combineReducers({
  [api.reducerPath]: api.reducer,
  app: authReducer,
  router: connectRouter(history),
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const middlewares = [ReduxThunk, api.middleware, ErrorMiddleware, routerMiddleware(history)];

export const createAppStore = (preloadedState?: PreloadedState<any>) => createStore(
  persistedReducer,
  preloadedState,
  composeEnhancers(applyMiddleware(...middlewares)),
);

export const store = createAppStore();
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
