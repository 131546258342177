import { Tooltip } from "bootstrap";
import { useEffect } from "react";

function ProgressbarTooltip({ item, quotationText = "Total", handleHover, handleLeave, backgroundColor }:any) {
  useEffect(() => {
    // Find the tooltip element
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));

    // Initialize tooltips
    const tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl, {
      placement: 'top', // Adjust placement as needed
      'customClass': 'custom-tooltip',
    }));

    // Cleanup when the component unmounts
    return () => {
      tooltipList.forEach((tooltipProps:any) => {
        tooltipProps.dispose();
      });
    };
  }, []);

  return (
    <div
      // eslint-disable-next-line react/no-unknown-property
      className="cursor-pointer flex-grow-1"
      data-bs-toggle="tooltip"
      data-placement="top"
      title={`${parseFloat(item?.percentage_of_total).toFixed(2)}% ${quotationText}`}
    >
      <div
        className="progress animated-progess progress-sm"
        onMouseMove={() => handleHover(item)}
        onMouseLeave={() => handleLeave()}
      >
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${item?.percentage_of_total}%`,
            backgroundColor: `${backgroundColor}`,
          }}
        ></div>
      </div>
    </div>
  );
}

export default ProgressbarTooltip;
