/* eslint-disable camelcase */
import HelpIcon from "@components/common/HelpIcon";
import SearchSelect from "@components/common/SearchSelect";
import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
import { officeModalTooltip } from "@constants/tooltip-data";
import generateLineChartOptions from "@utils/chart/line-chart";
import { getTickAmount } from "@utils/helpers";
import { http } from "@utils/http";
import { generateListingFinancialDataByYear, generateSellSideFinancialDataByYear } from "@utils/transform/officePage";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function OfficeAvgListVsSoldPriceChart({ officeIdProps }: any) {
  const [monthOptions, setMonthOptions] = useState([
    {
      id: 1,
      title: "Last 3 Months",
      value: 3,
    },
    {
      id: 2,
      title: "Last 6 Months",
      value: 6,
    },
    {
      id: 3,
      title: "Last 12 Months",
      value: 12,
    },
  ] as any);
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[2] as any);

  const [chartOptions, setChartOptions] = useState({} as any);
  const [avgListAndSoldPriceData, setAvgListAndSoldPriceData] = useState({} as any);

  const [isSellSideDataLoading, setIsSellSideDataLoading] = useState(true);
  const [isListSideDataLoading, setIsListSideDataLoading] = useState(true);

  useEffect(() => {
    if (officeIdProps !== "") {
      getSellSideFinancialComparisonReport();
      getListSideFinancialComparisonData();
    }
  }, [officeIdProps, selectedMonth]);

  function getSellSideFinancialComparisonReport() {
    setIsSellSideDataLoading(true);
    http
      .get("/office/sell-side-financial-comparison-last-12.json")
      .then((response: any) => {
        const theData: any = generateSellSideFinancialDataByYear(
          2023,
          response.data.last_12_sell_sides,
        );

        const { last_12_avg_closed_price, pp_avg_closed_price } = theData;
        setAvgListAndSoldPriceData((prev:any) => ({
          ...prev,
          last_12_avg_closed_price,
          pp_avg_closed_price,
        }));
        const chartFormattedOptions: any = generateLineChartOptions({
          theId: "line-chart-gci",
          colors: ["#21C0FC", "#21C0FC", "#A4D124", "#A4D124"],
          categories: theData?.categories,
          // ticks: generateTicks(theData?.last_12_avg_closed_price ?? []),
          legendPostion: "top",
          legendPostionOnMobile: 'top',
          dashArray: [0, 5, 0, 5],
          gridPL: 5,
          tickAmount: getTickAmount(theData?.last_12_avg_closed_price),
          ticksPreLast: ["$", "k"],
        });
        setChartOptions(chartFormattedOptions);
        setIsSellSideDataLoading(false);
      })
      .catch((error) => {
        setIsSellSideDataLoading(false);
        console.log(error);
      });
  }
  function getListSideFinancialComparisonData() {
    setIsListSideDataLoading(true);
    http
      .get("/office/listings-financial-comparison-last-12.json")
      .then((response: any) => {
        const theData: any = generateListingFinancialDataByYear(
          2023,
          response.data.last_12_listing,
        );
        const { last_12_average_list_price, previous_period_average_list_price } = theData;

        setAvgListAndSoldPriceData((prev:any) => ({
          ...prev,
          last_12_average_list_price,
          previous_period_average_list_price,
        }));

        setIsListSideDataLoading(false);
      })
      .catch((err: any) => {
        setIsListSideDataLoading(false);
        console.log(err);
      });
  }
  return (
    <>
      <div className="row mb-3">
        <div className="col-6 col-lg-7 d-flex align-items-center">
          <div className="d-inline-block">
            <span className="header-title-5 text-white mb-0 fw-medium pe-1">
              Average List Price Vs Average Sold Price
            </span>
            <div className="d-inline-block align-middle">
              <HelpIcon
                tooltip={`${officeModalTooltip.avg_list_vs_sold_price_chart}`}
                width={20}
                height={20}
              />
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-5 d-inline-flex justify-content-end">
          <SearchSelect
            options={monthOptions}
            value={
              JSON.stringify(selectedMonth) !== "{}"
                ? selectedMonth
                : "Select a Month"
            }
            onChange={(option: any) => setSelectedMonth(option)}
            // isClearable
            // width="190px"
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => `${option.title}`}
            placeholder="Select Months"
          />
        </div>
      </div>

      <div>
        {(isSellSideDataLoading && isListSideDataLoading) && <div className="loader-circle"></div>}
        {(isSellSideDataLoading || isListSideDataLoading) ? (
          <DefaultLineChartUI />
        ) : (
          <div id="line-chart-gci" dir="ltr">
            <ReactApexChart
              series={[
                {
                  name: `${selectedMonth?.title} Avg List Price`,
                  data: avgListAndSoldPriceData?.last_12_average_list_price,
                },
                {
                  name: "Previous Period Avg List Price",
                  data: avgListAndSoldPriceData?.previous_period_average_list_price,
                },
                {
                  name: `${selectedMonth?.title} Avg Sold Price`,
                  data: avgListAndSoldPriceData?.last_12_avg_closed_price,
                },
                {
                  name: "Previous Period Avg Sold Price",
                  data: avgListAndSoldPriceData?.pp_avg_closed_price,
                },
              ]}
              options={chartOptions}
              type="line"
              height={400}
              className="apex-charts custom-legend-bg"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default OfficeAvgListVsSoldPriceChart;
