import HelpIcon from "@components/common/HelpIcon";
import Placeholder from "@components/common/Placeholder";
import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
import { officeModalTooltip } from "@constants/tooltip-data";
import generateLineChartOptions from "@utils/chart/line-chart";
import { formatDecimalPlaces, formatNumberWithCommas, getTickAmount } from "@utils/helpers";
import { http } from "@utils/http";
import {
  generateOfficeGciDataByYear,
  generateTicks,
} from "@utils/transform/gci";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function ForeCastedRevenue({ officeId }: any) {
  const [gciData, setGciData] = useState({} as any);
  const [gciApiData, setGciApiData] = useState({} as any);
  const [theOptions, setTheOptions] = useState({} as any);
  const [isLoadingGci, setIsLoadingGci] = useState(true);
  const options: any = {
    chart: {
      id: "line-chart",
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: "#000",
        opacity: 0.35,
      },
    },
    forecastDataPoints: {
      count: 3,
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#CF4AFE"],
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  };

  useEffect(() => {
    if (officeId !== "") {
      getGCIForecast();
    }
  }, [officeId]);

  function getGCIForecast() {
    setIsLoadingGci(true);
    http
      .get("/office/last-48-gci.json")
      .then((response: any) => {
        const apiData = response.data.last_48_gci;
        getForcastedData(apiData);
      });
  }
  function getForcastedData(data: any) {
    return http
      .get("/serverless/office-gci-line-chart.json")
      .then((response: any) => {
        const forecastApiData = response?.data?.forecast;
        const reverseData = forecastApiData.reverse();
        const mergedData = [...reverseData, ...data];

        const theData: any = generateOfficeGciDataByYear(mergedData);

        setGciData(theData);
        setGciApiData(data);
        
        const chartOptions: any = generateLineChartOptions({
          theId: "line-chart",
          colors: ["#CF4AFE"],
          categories: theData?.categories,
          ticks: generateTicks(data?.last_12_gci ?? []),
          tickAmount: getTickAmount(theData?.gci),
          ticksPreLast: ["$", "k"],
          forecast: forecastApiData?.length ?? 0,
        });
        setTheOptions(chartOptions);
        setIsLoadingGci(false);

        return response?.data?.forecast || [];
      });
  }

  return (
    <>
      <div className="mb-4" style={{ backgroundColor: "#2A3042" }}>
        <div className="custom-modal-inner__padding">
          <div className="row bg-input">
            <div className="row">
              <div className="col-12 col-lg-8 pe-0">
                <div className="row">
                  <div className="col-9">
                    <div className="d-inline-block">
                      <span className="header-title pe-2 ">
                        3 Month Forecasted GCI
                      </span>
                      <div className="d-inline-block align-middle">
                        <HelpIcon
                          tooltip={`${officeModalTooltip.forecastedRevenue}`}
                          width={20}
                          height={20}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-3 pe-0">
                    <p className="mb-0 pt-1 text-center">All Time</p>
                  </div>
                </div>
                {isLoadingGci && <div className="loader-circle"></div>}
                {/* <div id="line-chart" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: "series-1",
                        data: [
                          30, 40, 35, 50, 49, 60, 70, 91, 125, 150, 125, 100,
                        ],
                      },
                    ]}
                    options={options}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div> */}
                {
                  isLoadingGci ? 
                  <DefaultLineChartUI />
                    : (
                <div id="line-chart" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: "GCI",
                        data: gciData?.gci ?? [],
                      },
                    ]}
                    options={theOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
                    )
}
              </div>
              <div className="col-12 col-lg-4">
                <div className="mt-3 mt-lg-0">
                  <p className="mb-1 custom-text-fs">Last month&apos;s GCI</p>
                  <p className="mb-0 number-stats">
                    $
                    <Placeholder
                      loading={isLoadingGci}
                      className="w-8 h-8"
                    ></Placeholder>
                    {!isLoadingGci &&
                      formatNumberWithCommas(
                        Math.round(gciApiData[0]?.gci),
                      )}
                  </p>
                  <div className="d-inline-flex gap-1 align-items-center">
                    <Placeholder
                      loading={isLoadingGci}
                      className="custom-w-12 custom-loader-h-6"
                    ></Placeholder>
                    {!isLoadingGci &&
                      (gciApiData[0]?.difference_percent > 0 ? (
                        <span className="badge badge-soft-success  me-1">
                          +{" "}
                          {formatDecimalPlaces(
                            gciApiData[0]?.difference_percent,
                          )}
                          %
                        </span>
                      ) : (
                        <span className="badge badge-soft-danger  me-1">
                          {formatDecimalPlaces(
                            gciApiData[0]?.difference_percent,
                          )}
                          %
                        </span>
                      ))}
                    <span className="custom-fs-10">From previous period</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForeCastedRevenue;
