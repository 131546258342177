// import HelpIcon from "@components/common/HelpIcon";
import BuySideLeftSide from "@components/financials/BuySideLeftSide";
// import EmergingMarketHeatmap from "@components/financials/EmergingMarketHeatmap";
import PermissionCheck from "@components/common/PermissionCheck";
import ForeCastedRevenue from "@components/financials/ForecastedRevenue";
import MultipleGraphStatsCard from "@components/financials/MultipleGraphStatsCard";
import RecommendedActions from "@components/financials/RecommendedActions";
import SalePriceTrends from "@components/financials/SalePriceTrends";
import { financialPerms } from "@constants/permission";
import { actionUserPermissions, selectUser } from "@state/appSlice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { http } from "@utils/http";
import { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

function Financials(props: any) {
  const dispatch = useAppDispatch();
  const selectedUser: any = useAppSelector(selectUser);
  const [isPermissionDataLoading, setIsPermissionDataLoading] = useState(true);

  useEffect(() => {
    if (selectedUser?.id) {
      setIsPermissionDataLoading(true);
      // TODO: here call user permission api
      http
        .get(
          `user-permission-site-data.json`,
        )
        .then((response: any) => {
          const { permission } = response?.data;
          const getUserPerms = response?.data?.permission[0]?.permissions;
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: getUserPerms ?? [] }));
          setIsPermissionDataLoading(false);
        })
        .catch((error) => {
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: [] }));
          setIsPermissionDataLoading(false);
          console.log(error);
        });
    }
  }, [selectedUser]);

  return (
    <>
      <MetaTags>
        <title>Financials - Kintaro 1</title>
      </MetaTags>

      <div className="container">
        <div>
          <div className="page-title">Financial Summary</div>
          <p>
            We have laid out the financial picture of the brokerage below based
            on a number of data inputs.
          </p>
        </div>
        <PermissionCheck
          permission={`${financialPerms.financial_three_month_summary}`}
        >
          {/* Forecasted Revenues */}
          <ForeCastedRevenue />
        </PermissionCheck>
        <PermissionCheck
          permission={`${financialPerms.financial_list_vs_sell_side}`}
        >
          {/* Buy Side vs Left Side */}
          <BuySideLeftSide />
        </PermissionCheck>
        {/* Recommended Actions */}
        <RecommendedActions/>
        <PermissionCheck
          permission={`${financialPerms.financial_sales_price_trends}`}
        >
          {/* Sales Price Trends */}
          <SalePriceTrends />
        </PermissionCheck>
        
          {/* Multiple Graph Stats */}
          <MultipleGraphStatsCard />

        {/* Emerging Market Heatmap */}
        {/* <div className="mt-4" style={{ backgroundColor: "#2B3041" }}>
          <div className="custom-modal-inner__padding">
          <div className="d-flex align-items-center gap-1">
              <div className="header-title">Activity Heatmap</div>
                <HelpIcon tooltip="Activity Heatmap" />
              </div>
          </div>
          <EmergingMarketHeatmap/>
        </div> */}
      </div>
    </>
  );
}
export default Financials;
