import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";

function DeleteAgentModal(props: any) {
  // All state.
  const [openModal, setOpenModal] = useState(false);
  const [modalName] = useState("deleteAgentModal");

  useEffect(() => {
    if (props.modalName === modalName) {
      setOpenModal(props.modalStatus);
    }
  }, [setOpenModal, props.modalStatus]);

  // Added an event listener to handle 'Escape' key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && openModal) {
        modalClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openModal]);

  function modalClose() {
    setOpenModal(false);
    setTimeout(() => {
      props.modalClose({
        modalName,
      });
    }, 1000);
  }

  return (
    <Modal size="md" isOpen={openModal} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={modalClose}
            className="btn-close position-absolute end-0 top-0 m-3"
          >
            
          </button>
          {/* <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-trash-can-outline"></i>
            </div>
          </div> */}
          <div className="header-title mb-4">
            Are you sure ?
          </div>
          <p className="font-size-16 mb-4">
            Are you sure you want to permanently erase the job.
          </p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => modalClose()}
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => ''}
            >
              Yes
            </button>
            
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default DeleteAgentModal;
