import React from 'react';
import { Input, Label } from 'reactstrap';
import { Control, Controller } from 'react-hook-form';
import { InputType } from 'reactstrap/es/Input';

interface FieldProps {
  className?: string
  control: Control<any>
  label?: string
  name: string
  placeholder?: string
  rules: any
  type?: InputType
}

const Field = ({
  className, control, label, name, placeholder, rules, type,
}: FieldProps) => (
  <>
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <div className={fieldState.error != null ? `${className} form-group text-danger` : `${className} form-group`}>
          {label !== '' && <Label for={name}>{label}</Label>}
          <Input
            {...field}
            className={fieldState.error != null ? 'is-invalid' : ''}
            name={name}
            id={name}
            placeholder={placeholder}
            type={type}
          />
          {fieldState.error != null
            && (
              <div className="invalid-feedback">
                {fieldState.error.message}
              </div>
            )}
        </div>
      )}
    />
  </>
);
Field.defaultProps = {
  className: '',
  label: '',
  placeholder: '',
  type: 'text',
};

export default Field;
