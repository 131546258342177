import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface TablePlaceHolderProps {
  items: any,
  loading: any,
  rows: number;
  cols: number;
}

function TablePlaceHolder({ items, loading, rows, cols }: TablePlaceHolderProps) {
  const renderSkeletonRow = (numColumns: number) => (
    // <tr key={numColumns}>
    //   {Array.from({ length: numColumns }, (_, index) => (
    //     <td key={index}>
    //       <Skeleton count={1} />
    //     </td>
    //   ))}
    // </tr>
    <tr>
      <td colSpan={numColumns}>
        <Skeleton count={1} />
      </td>
    </tr>
  );

  const renderSkeletonRows = (numRows: number, numColumns: number) => (
    Array.from({ length: numRows }, (_, index) => renderSkeletonRow(numColumns))
  );

  return (
    <>
      {
        loading && (
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            {renderSkeletonRows(rows, cols)}
          </SkeletonTheme>
        )
      }
    </>
  );
}

export default TablePlaceHolder;
