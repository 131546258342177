import { formatNumberWithCommas } from "@utils/helpers";

interface ChartContext {
  theId: any;
  colors: any;
  categories: any;
  ticks?: any;
  ticksPreLast?: any;
  rotateXOnMobile?: any;
  isChartStacked?: any;
  columnWidthProps?: any;
  isXlabelShow?:any;
}

function generateColumnChartOptions(ctx: ChartContext) {
  const {
    theId = "column-chart",
    colors = ["#F5822F", "#CF4AFE"],
    categories = [],
    ticks,
    ticksPreLast = 0,
    rotateXOnMobile = -55,
    isChartStacked = false,
    columnWidthProps = "45%",
    isXlabelShow,
  } = ctx;

  const options: any = {
    chart: {
      id: theId,
      height: 350,
      stacked: isChartStacked,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: `${columnWidthProps}`,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors,
    xaxis: {
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories,
    },
    yaxis: {
      ticks,
      labels: {
        offsetX: -14,
        align: "left", // Set the alignment to 'left'
        axisTicks: {
          show: false, // Hide ticks on the right side of the y-axis
        },
      },
    },
    grid: {
      padding: {
        left: -10,
      },
    },
    legend: {
      horizontalAlign: "center", // Center legend for desktop
    },
  };

  if (ticksPreLast !== 0) {
    options.yaxis.labels = {
      ...options.yaxis.labels,
      // formatter: (value: any) => `${ticksPreLast[0] ?? ""}${value.toFixed(0)}${ticksPreLast[1] ?? ""}`,
      formatter: (value: any) => {
        const valueFormatted = parseFloat(value).toFixed(1); 
        return `${ticksPreLast[0] ?? ""}${formatNumberWithCommas(parseFloat(valueFormatted))}${ticksPreLast[1] ?? ""}`;
      },
    };
  }
  if (isXlabelShow) {
    options.xaxis.labels = {
      ...options.xaxis.labels,
      show: true,
    };
  }

  if (window.innerWidth <= 768) {
    const updatedOptions = {
      ...options,
      xaxis: {
        ...options.xaxis,
        labels: {
          rotate: rotateXOnMobile,
          rotateAlways: true,
        },
      },
      legend: {
        horizontalAlign: "left", // Center legend for desktop
        offsetX: 10,
      },
    };
    return updatedOptions;
  } else {
    return options;
  }
}

export default generateColumnChartOptions;
