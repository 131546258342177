import React, { useState, useEffect, ReactNode, createContext, useContext } from "react";
import { useHistory } from "react-router-dom";
import cookies from "@utils/cookies";

interface VersionedAppProps {
  children?: ReactNode;
}

interface VersionContextProps {
  version: string;
  setVersion: React.Dispatch<React.SetStateAction<string>>;
}

const VersionContext = createContext<VersionContextProps | undefined>(undefined);

const VersionedApp: React.FC<VersionedAppProps> = ({ children }) => {
  const history = useHistory();
  const [version, setVersion] = useState(cookies.get("previousVersion") as any);

  useEffect(() => {
    checkVersionAndReload();
    updateMetaTag();
    setVersion(process.env?.REACT_APP_CURRENT_VERSION ?? '');
  }, [history]);

  useEffect(() => {
    checkVersionAndReload();
  }, [version]);

  const checkVersionAndReload = () => {
    const currentVersion: any = process.env.REACT_APP_CURRENT_VERSION;
    const previousVersion = cookies.get("previousVersion");

    if (currentVersion !== previousVersion) {
      cookies.set("previousVersion", currentVersion, { path: '/' });
      setVersion(currentVersion);
      console.log(currentVersion);
      window.location.reload();
    }
  };

  const updateMetaTag = () => {
    const currentVersion: any = process.env.REACT_APP_CURRENT_VERSION;

    const existingMetaTag = document.head.querySelector("meta[name='app_version']");
    if (existingMetaTag) {
      existingMetaTag.remove();
    }

    const metaTag = document.createElement("meta");
    metaTag.name = "app_version";
    metaTag.content = currentVersion;
    document.head.appendChild(metaTag);
  };

  return (
    <VersionContext.Provider value={{ version, setVersion }}>
      <div>
        {children}
      </div>
    </VersionContext.Provider>
  );
};

// Custom hook to access version context
const useVersion = () => {
  const context = useContext(VersionContext);
  if (!context) {
    throw new Error("useVersion must be used within a VersionedAppProvider");
  }
  return context;
};

export { VersionedApp, useVersion };
