import { NextArrowIcon, PreviousArrowIcon } from "@utils/icons";
import ReactPaginate from "react-paginate";
import './Pagination.css';

interface PaginationProps {
  pageCount: number;
  perPage: number;
  onPageChange: (selectedPage: { selected: number }) => void;
  currentPage: number;
}

function Pagination({ pageCount, perPage, currentPage, onPageChange }: PaginationProps) {
  const breakLabel:any = window.innerWidth > 768 ? '...' : '..';
  return (
    <ReactPaginate
      previousLabel={<PreviousArrowIcon/>}
      nextLabel={<NextArrowIcon/>}
      breakLabel={breakLabel}
      breakClassName='break-me'
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      onPageChange={onPageChange}
      containerClassName='pagination'
      activeClassName='active'
      forcePage={currentPage - 1}
    />
  );
}

export default Pagination;
