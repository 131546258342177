import Placeholder from "@components/common/Placeholder";
import { faker } from "@faker-js/faker";
import { getFakerPhoneNumber } from "@utils/fakers-data";
import { useState } from "react";

function OfficeProfileInfo(props: any) {
  const { officeInfo, isDataLoading } = props;
  const [fakeOfficeProfileData] = useState({
    fakeOfficeName: faker.company.name(),
    fakePhoneNumber: faker.phone.number('(###) ###-####'),
    fakeEmail: faker.internet.email(),
    fakeManagerName: faker.person.fullName(),
    fakeAddress: `${faker.location.city()}, ${faker.location.state()}`,
  });
  return (
    <div className="h-100" style={{ backgroundColor: "#2B3041" }}>
      <div className="custom-modal-inner__padding custom-fs-12">
        <h4 className="header-title-5 text-white mb-2 mb-lg-3">
          <Placeholder
            loading={isDataLoading}
            className="custom-loader-w-20 custom-loader-h-6"
          ></Placeholder>
          {!isDataLoading && (officeInfo?.office_name ?? "N/A")}
        </h4>
        <div className="d-lg-flex gap-3 mb-2">
          <p className="mb-1">Phone</p>
          <p className="mb-0 text-white fw-bold">
            <Placeholder
              loading={isDataLoading}
              className="custom-loader-w-20 custom-loader-h-6"
            ></Placeholder>
            {/* {!isDataLoading && (officeInfo?.office_phone?.length > 1 ? formatMultiplePhoneNumbers(officeInfo?.office_phone) : "N/A")} */}
            {!isDataLoading && (officeInfo?.office_phone?.length > 1 ? getFakerPhoneNumber(officeInfo?.office_phone) : "N/A")}
          </p>
        </div>
        <div className="d-lg-flex gap-3 mb-2">
          <p className="mb-1">Address</p>
          <p className="mb-0 text-white fw-bold" style={{ color: "#FFFFFF" }}>
            <Placeholder
              loading={isDataLoading}
              className="custom-loader-w-20 custom-loader-h-6"
            ></Placeholder>
            {!isDataLoading && (officeInfo?.office_address ?? "N/A")}
            {/* {!isDataLoading && getFakeOrRealData(officeInfo?.office_address ?? "N/A", fakeOfficeProfileData.fakeAddress)} */}
          </p>
        </div>
        <div className="d-lg-flex gap-3 mb-2">
          <p className="mb-1">Manager</p>
          <p className="mb-0 text-white fw-bold" style={{ color: "#FFFFFF" }}>
            <Placeholder
              loading={isDataLoading}
              className="custom-loader-w-20 custom-loader-h-6"
            ></Placeholder>
            {!isDataLoading && (officeInfo?.office_manager ?? "N/A")}
          </p>
        </div>
        <div className="d-lg-flex gap-3">
          <p className="mb-1">Email</p>
          <p
            className="mb-0 text-break text-white fw-bold"
            style={{ color: "#FFFFFF" }}
          >
            <Placeholder
              loading={isDataLoading}
              className="custom-loader-w-20 custom-loader-h-6"
            ></Placeholder>
            {!isDataLoading && (officeInfo?.manager_email ?? "N/A")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default OfficeProfileInfo;
