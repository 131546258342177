import HelpIcon from "@components/common/HelpIcon";
import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
import { officeModalTooltip } from "@constants/tooltip-data";
import generateLineChartOptions from "@utils/chart/line-chart";
import { http } from "@utils/http";
import { generateEngagementDataByOffice } from "@utils/transform/officePage";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function OfficeEngagementScore({ officeIdProps }: any) {
  const [chartOptions, setChartOptions] = useState({} as any);
  const [chartData, setChartData] = useState({} as any);

  const [isChartDataLoading, setIsChartDataLoading] = useState(false);

  useEffect(() => {
    if (officeIdProps !== "") {
      getMonthlyEngagementScoreData();
    }
  }, [officeIdProps]);

  function getMonthlyEngagementScoreData() {
    setIsChartDataLoading(true);
    http
      .get("/office/monthly-tech-engagement.json")
      .then((response: any) => {
        const theData: any = generateEngagementDataByOffice(
          response?.data?.engagement_score,
        );
        setChartData(theData);
        const chartFormattedOptions: any = generateLineChartOptions({
          theId: "line-chart-engagement",
          colors: ["#F5822F", "#6BF2F2", "#F2CA6B"],
          categories: theData?.categories,
          legendPostion: "top",
          legendPostionOnMobile: "top",
          dashArray: [0],
          gridPL: 10,
          tickAmount: 6,
          ticksPreLast: ["", ""],
        });
        setChartOptions(chartFormattedOptions);
        setIsChartDataLoading(false);
      })
      .catch((error) => {
        setIsChartDataLoading(false);
        console.log(error);
      });
  }

  return (
    <>
      <div className="row mb-3">
        <div className="d-inline-block">
          <span className="header-title-5 text-white mb-0 fw-medium pe-1">
            Monthly Engagement Score
          </span>
          <div className="d-inline-block align-middle">
            <HelpIcon
              tooltip={officeModalTooltip.officeMonthlyEngmntScore}
              width={20}
              height={20}
            />
          </div>
        </div>
        <p className="custom-fs-12">Last 12 months</p>
      </div>

      <div>
        {isChartDataLoading && <div className="loader-circle"></div>}
        {isChartDataLoading ? (
          <DefaultLineChartUI />
        ) : (
          <div id="line-chart-gci" dir="ltr">
            <ReactApexChart
              series={[
                {
                  name: "CMA",
                  data: chartData?.avgcloudcmascore,
                },
                {
                  name: "Email",
                  data: chartData?.avgemailmarketingscore,
                },
                {
                  name: `Website`,
                  data: chartData?.avgwebsitescore,
                },
               
              ]}
              options={chartOptions}
              type="line"
              height={400}
              className="apex-charts custom-legend-bg"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default OfficeEngagementScore;
