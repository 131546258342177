import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  error, error33Opacity, success, success33Opacity,
} from '../../../assets/colors';

const styles = createUseStyles({
  IncreaseDecreaseIndicator: {
    display: 'flex',
    height: '1.5em',
  },
  IncreaseDecreaseIndicator_detail: {
    'border-radius': '1em',
    'padding': '0 .5em',
    'margin-right': '.5em',
  },
  IncreaseDecreaseIndicator_detail__success: {
    'background-color': success33Opacity,
    color: success,
  },
  IncreaseDecreaseIndicator_detail__error: {
    'background-color': error33Opacity,
    color: error,
  },
});

export interface IncreaseDecreaseIndicatorProps {
  change: string
  increasing: boolean
  text?: string
}

const IncreaseDecreaseIndicator = (
  { change, increasing, text }: IncreaseDecreaseIndicatorProps,
) => {
  const classes = styles();
  const indicatorDetailClass = increasing
    ? `${classes.IncreaseDecreaseIndicator_detail} ${classes.IncreaseDecreaseIndicator_detail__success}`
    : `${classes.IncreaseDecreaseIndicator_detail} ${classes.IncreaseDecreaseIndicator_detail__error}`;

  return (
    <>
      <div className={classes.IncreaseDecreaseIndicator}>
        <div className={indicatorDetailClass}>
          { increasing ? '+' : '' }
          &nbsp;
          { change }
          %
        </div>
        {text}
      </div>
    </>
  );
};

export default IncreaseDecreaseIndicator;
