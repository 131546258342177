import HelpIcon from "@components/common/HelpIcon";
import SearchSelect from "@components/common/SearchSelect";
import EmergingMarketAlert from "@components/markets/EmergingMarketAlert";
import EmergingMarketHeatmap from "@components/markets/EmergingMarketHeatmap";
import EmergingMarketShare from "@components/markets/EmergingMarketShare";
import { useState } from "react";

function Markets() {
  const marketOptions = [
    {
      id: 1,
      title: "Round Rocks",
    },
    {
      id: 2,
      title: "South Austin",
    },
    {
      id: 3,
      title: "White Plain",
    },
  ];
  const [emerginMarketOptions, setEmergingMarketOptions] =
    useState(marketOptions);
  const [selectedEmergingMarket, setSelectedEmergingMarket] = useState({
    id: 1,
    title: "Round Rocks",
  });
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <p className="header-title"> Emerging Market </p>
          <SearchSelect
            options={emerginMarketOptions ?? []}
            value={
              JSON.stringify(selectedEmergingMarket) !== "{}"
                ? selectedEmergingMarket
                : "Select Emerging Market"
            }
            onChange={(data: any) => setSelectedEmergingMarket(data)}
            isClearable
            getOptionValue={(option: any) => option.id}
            getOptionLabel={(option: any) => `${option.title}`}
            placeholder="Select Emerging Market"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center gap-1 mt-3">
            <div className="header-title">Activity Heatmap</div>
            <HelpIcon tooltip="Activity Heatmap" />
          </div>
        </div>
      </div>
      <div className="row row-gap-4 row-gap-lg-0 mt-4">
        <div className="col-lg-7">
          <EmergingMarketHeatmap />
        </div>
        <div className="col-lg-5">
          <EmergingMarketShare />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <EmergingMarketAlert />
        </div>
      </div>
    </div>
  );
}

export default Markets;
