import CustomCircleSpinner from "@components/common/CustomCircleSpinner";
import { RA_COMPLETED, RA_IGNORE } from "@constants/variables";
import { isDemo } from "@utils/fakers-data";
import { http } from "@utils/http";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Modal, ModalBody } from "reactstrap";

function HomepageNotificationModal(props: any) {
  // All state.
  const [openModal, setOpenModal] = useState(false);
  const [modalName] = useState("homepageNotificationModal");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { status, recommendationData } = props;

  useEffect(() => {
    if (props.modalName === modalName) {
      setOpenModal(props.modalStatus);
    }
  }, [setOpenModal, props.modalStatus]);

  // Added an event listener to handle 'Escape' key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && openModal) {
        modalClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openModal]);

  function modalClose() {
    setOpenModal(false);
    setTimeout(() => {
      props.modalClose({
        modalName,
      });
    }, 100);
  }

  function submitRecommendAction() {
    if (isDemo()) {
      setIsDataLoading(true);
      let toastMsg = "";
      if (status === RA_IGNORE) {
        toastMsg = "Recommendation Ignored";
      } else if (status === RA_COMPLETED) {
        toastMsg = "Recommendation Completed";
      }
      
      setTimeout(() => {
        setIsDataLoading(false);
        toast.success(toastMsg, { duration: 3000 });
        modalClose();
        props.refetchData();
      }, 500);

      return;
    }
    const submitData = {
      recommendation_id: recommendationData?.id,
      action_type: status,
    };
    setIsDataLoading(true);
    http
      .post("/recommendation/recommendation-action", submitData)
      .then((response: any) => {
        toast.success(response?.data?.message, { duration: 3000 });
        setIsDataLoading(false);
        modalClose();
        props.refetchData();
      })
      .catch((error: any) => {
        console.log(error);
        toast.error(error?.message, { duration: 3000 });
        setIsDataLoading(false);
      });
  }
  return (
    <Modal size="md" isOpen={openModal}>
      <div className="modal-content">
        <div className="container">
          <p className="custom-modal-title mt-2"></p>
          <button
            type="button"
            onClick={modalClose}
            className="btn-close position-absolute custom-right-2 custom-top-1 mb-4"
          ></button>
        </div>
        <ModalBody className="">
          <div className="row mb-3">
            <div className="header-title-2 text-center text-capitalize mb-3">
              {status} Notification ?
            </div>
            <p className="text-center">
              Are you sure that you want to mark this notification as {status}?
            </p>
            <div className="d-flex align-items-center justify-content-center gap-2">
              <button
                type="button"
                className="btn btn-outline-secondary bg-secondary text-black btn-sm fw-bold"
                onClick={modalClose}
              >
                Cancel
              </button>
              {/* <MailTo email="foo@bar.baz" subject="Hello & Welcome" body="Hello world!">
                Mail me!
              </MailTo> */}
              <div>
                <button
                  type="button"
                  disabled={isDataLoading}
                  className="btn btn-primary btn-sm text-capitalize fw-bold"
                  onClick={submitRecommendAction}
                  style={{ minWidth: "70px" }}
                >
                  <CustomCircleSpinner loading={isDataLoading}/>
                  {!isDataLoading && status}
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default HomepageNotificationModal;
