import Placeholder from '@components/common/Placeholder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useState } from 'react';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import { selectAuth, selectLoggedInUser, setAuth } from '../../../state/appSlice';
import { CookieType, cookies } from '../../../state/cookies';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import AvatarFromInitials from './AvatarFromInitials';
import ViewAsUserDialog from './ViewAsUserDialog';

const ProfileMenu = () => {
  const auth:any = useAppSelector(selectAuth);
  const loggedInUser = useAppSelector(selectLoggedInUser);
  // const userData = useRetrieveUserQuery({ id: auth!.user_id });
  const userData = {
    "id": "f21fdcbd-9bd1-4f35-a23c-3b2101667bae",
    "user_offices": [],
    "avialible_data": {
      "brands": {
        "3": "Company1",
        "4": "Brand2",
        "5": "Brand3",
        "6": "Brand Smith",
        "7": "Brand Carrey",
        "8": "some org",
        "9": "New Admin M00000490 Top Organization",
        "11": "AlfaTestData",
        "19": "Test Brand",
        "22": "sdfds",
        "23": "sadsa",
        "24": "test brand",
        "25": "zcx",
        "27": "xzc",
        "29": "Test Brand4",
        "30": "Brand1",
        "31": "Brand1",
        "32": "test-m-brand",
        "33": "test-m2-brand",
      },
      "companies": {
        "3": "Company1",
        "4": "Company2",
        "5": "Company3",
        "6": "Brand Smith Company 1",
        "7": "Brand Smith Company 2",
        "8": "Brand Carrey Company 1",
        "9": "Brand Carrey Company 2",
        "10": "some group",
        "11": "New Admin M00000490 Top Group",
        "13": "AlfaTestData",
        "17": "Test Company updated",
        "19": "test-m-company",
        "20": "test-m2-company ",
        "21": "test-m3-company",
        "22": "test-m4-company",
      },
      "branches": {
        "3": "Branch1",
        "4": "Branch2",
        "5": "Branch3",
        "7": "Smith Company 1 a",
        "8": "Smith Company 1 b",
        "9": "Smith Company 1 c",
        "10": "Smith Company 2 a",
        "11": "Smith Company 2 b",
        "12": "Smith Company 2 c",
        "13": "Carrey Company 1 a",
        "14": "Carrey Company 1 b",
        "15": "Carrey Company 1 c",
        "16": "Carrey Company 2 a",
        "17": "Carrey Company 2 b",
        "18": "Carrey Company 2 c",
        "21": "Kintaro North Austin",
        "22": "fa08ffc4-3072-41ad-94d5-583b41b175e8",
        "23": "New Admin M00000490 Top Office",
        "25": "AlfaTestData",
        "30": "Test Branch 2",
        "32": "AN Test Office",
        "34": "Test",
        "35": "deleting",
      },
    },
    "brands": [],
    "companies": [],
    "branches": [],
    "password": "pbkdf2_sha256$260000$QtHhE8eiEAUrauPSzllPrR$WWJnsG2EO9wICYGO0Wuq1EZtB9D8bzZ4ACMZmm69VNE=",
    "last_login": "2024-09-26T17:54:25.792077Z",
    "is_active": true,
    "email": "meherab@kintaro.io",
    "username": "meherab",
    "avatar_url": null,
    "first_name": "Meherab",
    "last_name": "Hosain",
    "street": null,
    "city": null,
    "state": null,
    "zip": null,
    "birthday": null,
    "start_date": null,
    "work_phone": "878787875",
    "mobile_phone": null,
    "home_phone": null,
    "user_level": 10,
    "current_status": null,
    "publish": false,
    "bio": "",
    "facebook_url": null,
    "linkedin_url": null,
    "twitter_url": null,
    "instagram_url": null,
    "title": null,
    "the_user_level": "0",
    "the_role_id": null,
  };
  const [menuOpen, setMenuOpen] = useState(false);
  const [viewAsUserOpen, setViewAsUserOpen] = useState(false);
  const dispatch = useAppDispatch();

  if (!userData.first_name) {
    return <Placeholder loading={!userData.first_name} className="custom-loader__profile-menu custom-h-2"/>;
    // return <LoadingIcon loading={!userData.isSuccess} />;
  }

  const viewAsSelf = () => {
    cookies.set(CookieType.AUTH.valueOf(), loggedInUser, { path: '/' });
    dispatch(setAuth(loggedInUser));
    setTimeout(() => window.location.reload());
  };

  const superuserItems = [];
  if (loggedInUser) {
    if (auth!.user_id === loggedInUser!.user_id) {
      superuserItems.push(
        <>
          <DropdownItem key="manage-emerging-markets" tag="a" href="/manage-emerging-markets">
            Emerging Markets
          </DropdownItem>
          <DropdownItem key="settings" tag="a" href="/settings">
            Settings
          </DropdownItem>
        </>,
      );
    }
    if (auth!.user_id !== loggedInUser!.user_id) {
      superuserItems.push(
        <DropdownItem key="view-as-self" onClick={viewAsSelf} tag="button">
          View as Self
        </DropdownItem>,
      );
    }
    superuserItems.push(<div key="dropdown-divider" className="dropdown-divider" />);
  }

  return (
    <>
      <ViewAsUserDialog open={viewAsUserOpen} setOpen={setViewAsUserOpen} />
      <Dropdown
        isOpen={menuOpen}
        toggle={() => setMenuOpen(!menuOpen)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
          data-testid="toggle"
        >
          <div className="header-profile-user rounded-circle">
            <AvatarFromInitials
              diameter={30}
              name={`${userData.first_name!} ${userData.last_name!}`}
            />
          </div>
          &nbsp;
          <span className="d-none d-xl-inline-block ms-1">
            { userData.first_name }
            &nbsp;
            { userData.last_name }
          </span>
          <KeyboardArrowDownIcon />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {superuserItems}
          <DropdownItem className="d-flex" tag="a" href="/logout">
            <div className="me-2 text-danger">
              <PowerSettingsNewIcon fontSize="small" />
            </div>
            Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default ProfileMenu;
