import { Oval } from "react-loader-spinner";

function OvalLoader({ isDataLoading, width = "20", height = "20", wrapperClasses = "", primaryColor = "#FFF5DE", secondaryColor = "#fffbf2" }: any) {
  return (
    <Oval
      visible={isDataLoading}
      height={height}
      width={width}
      color={primaryColor}
      secondaryColor={secondaryColor}
      ariaLabel="oval-loading"
      wrapperStyle={{}}
      wrapperClass={wrapperClasses}
    />
  );
}

export default OvalLoader;
