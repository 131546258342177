import AgentEngagementByQuartile from "@components/tools/AgentEngagementByQuartile";
import MultipleCategoryCards from "@components/tools/MultipleCategoryCards";
import OfficeEngagementTable from "@components/tools/OfficeEngagementTable";
import OfficeToolEngagementTable from "@components/tools/OfficeToolEngagementTable";
import RecommendedActions from "@components/tools/RecommendedActions";
import MetaTags from "react-meta-tags";

function Tools(props: any) {
  return (
    <>
      <MetaTags>
        <title>Tools - Kintaro 1</title>
      </MetaTags>

      <div className="container">
        {/* Multiple Category Cards */}
        <MultipleCategoryCards/>
        {/* Office Engagement Table */}
        <OfficeEngagementTable/>
        {/* Office Tool Access Engagement Table */}
        <OfficeToolEngagementTable/>
        {/* Recommended Actions */}
        <RecommendedActions/>
        {/* Agent Engagement */}
        <AgentEngagementByQuartile/>
      </div>
    </>
  );
}
export default Tools;
