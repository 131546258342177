import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface IconButtonProps {
  children: ReactNode
  destination: string
}

const styles = createUseStyles({
  IconButton: {
    'border-radius': '50%',
    'box-shadow': '0 -2px 10px rgba(0, 0, 0, .5)',
    'padding': '.5em',
  },
});

const IconButton = ({ children, destination }: IconButtonProps) => {
  const classes = styles();

  return (
    <>
      <a
        className={`${classes.IconButton} btn btn-primary`}
        href={destination}
      >
        {children}
      </a>
    </>
  );
};

export default IconButton;
