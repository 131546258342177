import { ProfileIcon } from "@utils/icons";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import AgentOpportunities from "./details/AgentOpportunities";
import AvgOpenRate from "./details/AvgOpenRate";
import BottomUsersTable from "./details/BottomUsersTable";
import CumulativeSends from "./details/CumulativeSends";
import EngagementOverTime from "./details/EngagementOverTime";
import TopUsersTable from "./details/TopUsersTable";

function CategoryCardsDetailsModal(props: any) {
  // All state.
  const [openModal, setOpenModal] = useState(false);
  const [modalName] = useState("cardsDetailsModal");
  const [selectedPersonInfo, setSelecedPersonInfo] = useState({} as any);
  
  const DUMMY_DATA: any = [
    {
      id: 1,
      title: "Transaction Management",
      stats: "100",
    },
    {
      id: 2,
      title: "CMA",
      stats: "55",
    },
    {
      id: 3,
      title: "Print Marketing",
      stats: "72",
    },
    {
      id: 4,
      title: "Email Marketing",
      stats: "54",
    },
    {
      id: 5,
      title: "CRM",
      stats: "37",
    },
  ];
  const personalInfo:any = [
    {
      id: 1,
      title: "CloudCMA",
      name: "Bonnie Stein",
      email: "bonnie.stein@juliabfee.com",
    },
    {
      id: 2,
      title: "Neutrino",
      name: "Sara Reel",
      email: "sara.reel@juliabfee.com",
    },
  ];

  useEffect(() => {
    if (props?.cardIdData) {
      const filteredData = personalInfo?.find((cardId:any) => cardId?.id === props?.cardIdData);
      setSelecedPersonInfo(filteredData);
    }
  }, [props?.cardIdData]);

  useEffect(() => {
    if (props.modalName === modalName) {
      setOpenModal(props.modalStatus);
    }
  }, [setOpenModal, props.modalStatus]);

  function modalClose() {
    setOpenModal(false);
    setTimeout(() => {
      props.modalClose({
        modalName,
      });
    }, 1000);
  }

  function getRandomColor(stats: any) {
    let color: any = "";
    switch (true) {
      case stats >= 40 && stats <= 50:
        color = "#F2CA6B";
        break;
      case stats > 50 && stats <= 70:
        color = "#F2CA6B";
        break;
      case stats > 70:
        color = "#CF4AFE";
        break;
      default:
        color = "#F5822F";
        break;
    }

    return color;
  }
  return (
    <Modal
      size="xl"
      isOpen={openModal}
      centered={true}
      
    >
      <div className="modal-content" style={{ background: "#222736" }}>
        <div className="container mb-4">
          <p className="custom-modal-title mt-2"></p>
          <button
            type="button"
            onClick={modalClose}
            className="btn-close position-absolute custom-top-2 custom-right-4 mb-4"
          ></button>
        </div>
        <ModalBody className="custom-modal-outer__padding">
          <div className="row mb-3">
            <div className="d-flex justify-content-between">
              {/* <div className="header-title">Email</div> */}
            </div>
          </div>
          {/* Active Pipe Card */}
          <div className="row mb-4">
            <div className="col-lg-5">
              <div className="mb-1" style={{ backgroundColor: "#2B3041" }}>
                <div className="custom-modal-inner__padding">
                  <p className="header-subtitle text-white">{selectedPersonInfo?.title}</p>
                  <div className="row">
                    <div className="col-7 col-lg-12">
                      <div className="row">
                        <div className="col-5 col-lg-12">
                          <div className="d-flex justify-content-center">
                            <ProfileIcon height="60"/>
                          </div>
                        </div>
                        <div className="col-7 col-lg-12 px-0">
                          <p className="text-left text-lg-center text-white header-subtitle-2 mb-1">
                          {selectedPersonInfo?.name}
                          </p>
                          {/* <p className="text-left text-lg-center custom-fs-10">
                            Customer Since 2010
                          </p> */}
                          {/* <p className="mb-0 d-flex d-lg-none page-subtitle-3">Engagement Score</p>
                          <p className="number-stats d-flex d-lg-none text-white mb-0">0</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-5 col-lg-12">
                      <div className="d-lg-flex gap-3 text-break">
                        <p className="mb-1 custom-fs-12">Email</p>
                        <p className="text-white custom-fs-12">{selectedPersonInfo?.email}</p>
                      </div>
                    </div>
                    {/* <div className="col-6">
                      <div className="d-none d-lg-flex header-subtitle-2 flex-column flex-lg-row gap-2 flex-lg-row-reverse justify-content-lg-end">
                        <p className="mb-0 page-subtitle-3">Engagement Score</p>
                        <p className="number-stats text-white mb-0">0</p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EngagementOverTime cardId={props?.cardIdData}/>
          <CumulativeSends cardId={props?.cardIdData}/>
          {
            props?.cardIdData === 1 ?
            <AvgOpenRate cardId={props?.cardIdData}/> : ""
          }
          
          {/* <AvgCtr /> */}
          <AgentOpportunities cardId={props?.cardIdData}/>
          <div className="row mb-4">
            <div className="col-12 col-lg-6">
              <BottomUsersTable cardId={props?.cardIdData}/>
            </div>
            <div className="col-12 col-lg-6">
              <TopUsersTable cardId={props?.cardIdData}/>
            </div>
          </div>
          {/* <RecomendedAction /> */}
        </ModalBody>
      </div>
    </Modal>
  );
}

export default CategoryCardsDetailsModal;
