/* eslint-disable no-unreachable */
/* eslint-disable camelcase */
import PermissionCheck from "@components/common/PermissionCheck";
import Placeholder from "@components/common/Placeholder";
import KPICard from "@components/home/KPICard";
import RecommendedActions from "@components/home/RecommendedActions";
import { homePerms } from "@constants/permission";
import { homepageToolTip } from "@constants/tooltip-data";
import { STATUS_UP } from "@constants/variables";
import { actionUserPermissions, selectUser } from "@state/appSlice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { http } from "@utils/http";
import { useEffect, useState } from "react";

function Home() {
  const dispatch = useAppDispatch();
  const selectedUser: any = useAppSelector(selectUser);
  const [isPermissionDataLoading, setIsPermissionDataLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(4 as any);
  const [perPage, setPerPage] = useState(10);
  const [avgMkt, setAvgMkt] = useState({} as any);
  const [avgLP, setAvgLp] = useState({} as any);
  const [closedGCI, setClosedGCI] = useState({} as any);
  const [isDataLoading, setIsDataLoading] = useState({
    avgMktLoading: true,
    avgLPLoading: true,
    closedGCIoading: true,
  });

  useEffect(() => {
    if (selectedUser?.id) {
      setIsPermissionDataLoading(true);
      http
        .get(
          `user-permission-site-data.json`,
        )
        .then((response: any) => {
          const { permission } = response?.data;
          const getUserPerms = response?.data?.permission[0]?.permissions;
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: getUserPerms ?? [] }));
          setIsPermissionDataLoading(false);
        })
        .catch((error) => {
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: [] }));
          setIsPermissionDataLoading(false);
          console.log(error);
        });
    }
  }, [selectedUser]);

  useEffect(() => {
    getAvgMkt();
    getavgLP();
    getclosedGCI();
  }, []);

  function getAvgMkt() {
    // setIsDataLoading((prev) => ({
    //   ...prev,
    //   avgMktLoading: true,
    // }));
    // setTimeout(() => {
    //   const apiData = average_market_ytd;
    //   setAvgMkt(apiData);
    //   setIsDataLoading((prev) => ({
    //     ...prev,
    //     avgMktLoading: false,
    //   }));
    // }, 500);
    // return;
    http
      .get("/homepage/average-market-ytd.json")
      .then((response: any) => {
        const apiData = response?.data?.average_market_ytd;
        setAvgMkt(apiData);
        setIsDataLoading((prev) => ({
          ...prev,
          avgMktLoading: false,
        }));
      })
      .catch((error: any) => {
        console.log(error);
        setIsDataLoading((prev) => ({
          ...prev,
          avgMktLoading: false,
        }));
      });
  }
  function getavgLP() {
    // setIsDataLoading((prev) => ({
    //   ...prev,
    //   avgLPLoading: true,
    // }));
    // setTimeout(() => {
    //   const apiData = average_list_price;
    //   setAvgLp(apiData);
    //   setIsDataLoading((prev) => ({
    //     ...prev,
    //     avgLPLoading: false,
    //   }));
    // }, 500);
    // return;
    http
      .get("/homepage/average-list-price.json")
      .then((response: any) => {
        const apiData = response?.data?.average_list_price;
        setAvgLp(apiData);
        setIsDataLoading((prev) => ({
          ...prev,
          avgLPLoading: false,
        }));
      })
      .catch((error: any) => {
        console.log(error);
        setIsDataLoading((prev) => ({
          ...prev,
          avgLPLoading: false,
        }));
      });
  }
  function getclosedGCI() {
    // setIsDataLoading((prev) => ({
    //   ...prev,
    //   closedGCIoading: true,
    // }));
    // setTimeout(() => {
    //   const apiData = closed_gci;
    //   setClosedGCI(apiData);
    //   setIsDataLoading((prev) => ({
    //     ...prev,
    //     closedGCIoading: false,
    //   }));
    // }, 500);
    // return;
    http
      .get("/homepage/closed-gci.json")
      .then((response: any) => {
        const apiData = response?.data?.closed_gci;
        setClosedGCI(apiData);
        setIsDataLoading((prev) => ({
          ...prev,
          closedGCIoading: false,
        }));
      })
      .catch((error: any) => {
        console.log(error);
        setIsDataLoading((prev) => ({
          ...prev,
          closedGCIoading: false,
        }));
      });
  }

  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected + 1);
  };
  function getLoader(loading: any) {
    return (
      <Placeholder
        loading={loading}
        className="custom-w-2 custom-h-2"
      ></Placeholder>
    );
  }
  return (
    <>
      <div className="segment-outer">
        <div className="row row-gap-3 row-gap-lg-0">
          <PermissionCheck
            permission={`${homePerms.home_recommendation_avg_days_market}`}
          >
            <div className="col-12 col-md-6 col-lg-4 pe-1 pe-lg-3">
              <KPICard
                title="Average Days on Market"
                loading={isDataLoading?.avgMktLoading}
                value={avgMkt?.average_days_on_market}
                indicatorStatus={avgMkt?.up_down}
                toolTipText={homepageToolTip?.avgMkt}
                text={`Average Days on Market has ${
                  avgMkt?.up_down === STATUS_UP ? "increased" : "decreased"
                }  by ${
                  !isDataLoading?.avgMktLoading ? avgMkt?.percent_diff ?? "" : ""
                }% compared to the previous year.`}
              />
            </div>
          </PermissionCheck>
          <PermissionCheck
            permission={`${homePerms.home_recommendation_avg_list_price}`}
          >
            <div className="col-12 col-md-6 col-lg-4 pe-1 pe-lg-3">
              <KPICard
                title="Average List Price"
                loaderClassName="custom-w-24"
                loading={isDataLoading?.avgLPLoading}
                value={avgLP?.asverage_list_price}
                indicatorStatus={avgLP?.up_down}
                toolTipText={homepageToolTip?.avgLP}
                text={`Average List Price has ${
                  avgLP?.up_down === STATUS_UP ? "increased" : "decreased"
                } by ${
                  !isDataLoading?.avgLPLoading ? avgLP?.percent_diff ?? "" : ""
                }% compared to the previous year.`}
              />
            </div>
          </PermissionCheck>
          <PermissionCheck
            permission={`${homePerms.home_recommendation_closed_gci_ytd}`}
          >
            <div className="col-12 col-md-6 col-lg-4 pe-1 pe-lg-3">
              <KPICard
                title="Closed GCI Year to Date"
                loaderClassName="custom-w-24"
                loading={isDataLoading?.closedGCIoading}
                value={closedGCI?.closed_gci}
                indicatorStatus={closedGCI?.up_down}
                toolTipText={homepageToolTip?.closedGCI}
                text={`Closed GCI Year to Date has ${
                  closedGCI?.up_down === STATUS_UP ? "increased" : "decreased"
                } by ${
                  !isDataLoading?.closedGCIoading ? closedGCI?.percent_diff ?? "" : ""
                }% compared to the previous year.`}
              />
            </div>
          </PermissionCheck>
        </div>
      </div>
      <div className="container">
        <RecommendedActions />
      </div>
    </>
  );
}

export default Home;
