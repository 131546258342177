const defaultLineChartOptions: any = {
  chart: {
    id: 'line-chart',
    animations: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    dropShadow: {
      enabled: false,
      enabledOnSeries: undefined,
      top: 0,
      left: 0,
      blur: 3,
      color: "#000",
      opacity: 0.35,
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: "",
  stroke: {
    curve: "smooth",
    width: 3,
  },
  xaxis: {
    tooltip: {
      enabled: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    categories: [],
  },
  yaxis: {
    labels: {
      show: false,
      offsetX: -14,
      align: "left", // Set the alignment to 'left'
      axisTicks: {
        show: false, // Hide ticks on the right side of the y-axis
      },
    },
  },

  legend: {
    horizontalAlign: "center", // Center legend for desktop
    markers: {
      radius: 0,
    },
  },
};
export default defaultLineChartOptions;
