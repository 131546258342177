import HelpIcon from "@components/common/HelpIcon";
import Placeholder from "@components/common/Placeholder";

function SingleCategoryCard(props: any) {
  const { isDataLoadingProps } = props;
  const {
    id,
    title,
    stats,
    text,
    textPercentage,
    color,
    toolTipText,
    cta,
  } = props?.cardItem;
  return (
    <div className="col-md-6 col-lg-4 col-xl-3 position-relative ">
      <div className="mb-2 h-100" style={{ backgroundColor: "#2A3042" }}>
        <div className="custom-modal-inner__padding">
          <div className="d-inline-block min-h-90">
            <span className="header-title-4 fw-bold pe-1 text-white">
              <Placeholder
                loading={isDataLoadingProps}
                className="custom-loader-w-20 custom-loader-h-6"
              ></Placeholder>
              {!isDataLoadingProps && title}
            </span>
            {!isDataLoadingProps && (
              <div className="d-inline-block align-middle">
                <HelpIcon tooltip={`${toolTipText}`} width={20} height={20} />
              </div>
            )}
          </div>
          <div className="text-center">
            <p className="number-stats-2" style={{ color }}>
              <Placeholder
                loading={isDataLoadingProps}
                className="custom-loader-w-20 custom-loader-h-6"
              ></Placeholder>
              {!isDataLoadingProps && stats}
            </p>
            {/* <p className="text-white page-subtitle-2 fw-bolder">
              {textPercentage}% {text}
            </p> */}
          </div>
          {!isDataLoadingProps && (
            <div>
              {cta ? (
                <button
                  type="button"
                  className="btn w-100 fw-bold pe-auto border-white text-white"
                  onClick={() => props?.cardDetailsModalEmit(id)}
                >
                  View More
                </button>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SingleCategoryCard;
