import TheAgents from '@pages/Agents';
import TheFinancials from '@pages/Financials';
import TheHome from '@pages/Home';
import Login from '@pages/Login/Login';
import Logout from '@pages/Logout/Logout';
import TheMarket from "@pages/Markets";
import TheOffices from '@pages/Offices';
import PreviewRecommendation from '@pages/PreviewRecommendation';
import Recommendations from '@pages/Recommendations';
import TheTools from '@pages/Tools';
import EditEmergingMarket from './pages/EditEmergingMarket/EditEmergingMarket';
import ManageEmergingMarkets from './pages/ManageEmergingMarkets/ManageEmergingMarkets';
import Markets from './pages/Markets/Markets';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import Recruiting from './pages/Recruiting/Recruiting';
import RequestPasswordReset from './pages/RequestPasswordReset/RequestPasswordReset';
import Settings from './pages/Settings/Settings';

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/request-password-reset', component: RequestPasswordReset },
  { path: '/password-reset/:id', component: PasswordReset },
];

const privateRoutes = [
  { path: '/', component: TheHome },
  { path: '/preview-recommendation/:id', component: PreviewRecommendation },
  { path: '/logout', component: Logout },
  { path: '/market/:id', component: Markets },
  { path: '/recruiting', component: Recruiting },
  { path: '/settings', component: Settings },
  { path: '/agents', component: TheAgents },
  { path: '/offices', component: TheOffices },
  { path: '/financials', component: TheFinancials },
  { path: '/tools', component: TheTools },
  { path: '/recommendations', component: Recommendations },
  { path: '/markets', component: TheMarket },
];

const superuserRoutes = [
  { path: '/edit-emerging-market', component: EditEmergingMarket },
  { path: '/edit-emerging-market/:id', component: EditEmergingMarket },
  { path: '/manage-emerging-markets', component: ManageEmergingMarkets },
];

export { privateRoutes, publicRoutes, superuserRoutes };
