/* eslint-disable camelcase */
import SearchSelect from "@components/common/SearchSelect";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { faker } from "@faker-js/faker";
import generateColumnChartOptions from "@utils/chart/bar-chart";
import defaultChartOptions from "@utils/chart/default-chart-options";
import { http } from "@utils/http";
import { generateNinjaAgentSalesVolumeActivityData } from "@utils/transform/officePage";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function NinjaAgentSalesVolumeActivity() {
  const [agentQuartitleData, setAgentQuartileData] = useState([] as any);
  const [agentQuartitleAPIData, setAgentQuartileAPIData] = useState([] as any);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [theOptions, setTheOptions] = useState();
  const [agentQuartileOptions, setAgentQuartileOptions] = useState([] as any);
  const [isAgentQuartileDataLoading, setIsAgentQuartileDataLoading] =
    useState(true);
  const [selectedAgentQuartileOption, setSelectedAgentQuartileOptions] =
    useState({} as any);
  const categories = [
    "Quartile 1",
    "Quartile 2",
    "Quartile 3",
    "Quartile 4",
  ];
  // const categories = [
  //   "1st Quartile",
  //   "2nd Quartile",
  //   "3rd Quartile",
  //   "4th Quartile",
  // ];

  useEffect(() => {
    getAgentQuartileData();
  }, [selectedAgentQuartileOption]);

  useEffect(() => {
    getAllOfficeInfo();
  }, []);

  function getAllOfficeInfo() {
    setIsAgentQuartileDataLoading(true);
    http
      .get("/office/office-info.json")
      .then((response: any) => {
        const officeAllData = response.data?.office_info;
        let theData = officeAllData;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            office_name: faker.company.name(),
          }));
        }
        // const formattedObj = [
        //   {
        //     office_id: "",
        //     office_name: "All",
        //   },
        //   ...officeAllData,
        // ];
        setAgentQuartileOptions(theData);
        setIsAgentQuartileDataLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setIsAgentQuartileDataLoading(false);
      });
  }

  function getAgentQuartileData() {
    setIsDataLoading(true);
    http
      .get("/office/ninja-quartile-agent-by-sales-volume-activity.json")
      .then((response: any) => {
        const apiData = response?.data?.ninja_quartile;
        // const apiData = ninja_quartile_activity;
        setAgentQuartileAPIData(apiData);
        const theData = generateNinjaAgentSalesVolumeActivityData(apiData);
        setAgentQuartileData(theData);

        const chartOptions: any = generateColumnChartOptions({
          theId: "line-chart",
          colors: ["#F2CA6B", "#CF4AFE", "#F5822F", "#6BF2F2", "#FFF5DE"],
          categories,
          isXlabelShow: true,
          // ticks: generateSVsLTicks(theData ?? []),
          columnWidthProps: "70%",
          ticksPreLast: ["$", ''],
          // forecast: 3,
        });
        setTheOptions(chartOptions);
        setIsDataLoading(false);
      })
      .catch((error: any) => {
        setIsDataLoading(false);
        console.log(error);
      });
  }
  const series = [
    {
      name: "Neutrino",
      data: [26, 15, 27, 50],
    },
    {
      name: "Maxa",
      data: [50, 83, 42, 35],
    },
    {
      name: "CloudCMA",
      data: [74, 70, 62, 40],
    },
    {
      name: "Gmail",
      data: [64, 75, 32, 70],
    },
    {
      name: "Activepipe",
      data: [34, 55, 22, 60],
    },
  ];
  return (
    <>
      <div className="mb-4">
        <div
          className="custom-modal-inner__padding"
          style={{ backgroundColor: "#2B3041" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="row row-gap-2 row-gap-md-0">
                <div className="col-12 col-md-7">
                  <div className="d-inline-block">
                    <span className="header-title pe-2 ">
                      Agents By Sales Volume Quartile
                    </span>
                    {/* <div className="d-inline-block align-middle">
                      <HelpIcon
                        tooltip={`${toolsPageToolTip.toolsAgentEngmntByQuartile}`}
                        width={20}
                        height={20}
                      />
                    </div> */}
                  </div>
                  {/* <div className="d-flex align-items-center gap-1">
                    <div className="header-title">
                      Engagement by Agent Quartile
                    </div>
                  </div> */}
                </div>
                <div className="col-12 col-md-5 d-inline-flex justify-content-end">
                  <div className="">
                    <SearchSelect
                      options={agentQuartileOptions}
                      width="180px"
                      value={
                        JSON.stringify(selectedAgentQuartileOption) === "{}"
                          ? "Select Office"
                          : selectedAgentQuartileOption
                      }
                      onChange={(option: any) => {
                        setSelectedAgentQuartileOptions(option);
                      }}
                      isClearable
                      isLoading={isAgentQuartileDataLoading}
                      getOptionValue={(option: any) => option.office_id}
                      getOptionLabel={(option: any) => `${option.office_name}`}
                      placeholder={
                        (JSON.stringify(selectedAgentQuartileOption) === "{}" ||
                          JSON.stringify(selectedAgentQuartileOption) ===
                            "null") &&
                        "All"
                      }
                    />
                  </div>
                </div>
              </div>
              {/* <div id="line-chart" dir="ltr">
                <LineColumnChart series={series} colors={["#F2CA6B", "#CF4AFE", "#F5822F", "#6BF2F2", "#FFF5DE"]} categories={categories} columnWidthProps="70%" strokeWidth={6}/>
              </div> */}
              {isDataLoading && <div className="loader-circle"></div>}
              {isDataLoading ? (
                <div id="bar-column-chart" dir="ltr">
                  <ReactApexChart
                    options={defaultChartOptions}
                    series={[]}
                    type="bar"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              ) : (
                <div id="bar-column-chart" dir="ltr">
                  <ReactApexChart
                    options={theOptions}
                    series={[
                      {
                        name: "Ninja",
                        data: agentQuartitleData?.ninja,
                      },
                      {
                        name: "non-Ninja",
                        data: agentQuartitleData?.non_Ninja,
                      },
                    ]}
                    type="bar"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NinjaAgentSalesVolumeActivity;
