import TablePlaceHolder from "@components/common/TablePlaceHolder";
import HomepageNotificationModal from "@components/home/HomepageNotificationModal";
import PreviewRecomDraftEmailModal from "@components/recommendation/PreviewRecomDraftEmailModal";
import { hasRecomDraftEmailAccess, isObjectEmpty } from "@utils/helpers";
import { http } from "@utils/http";
import { CheckMarkIcon, EmailIcon, EyeCrossIcon } from "@utils/icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function PreviewRecommendation() {
  const { id }: any = useParams();
  const [recommendationData, setRecommendationData] = useState([] as any);
  const [selectedRecommendationData, setSelectedRecommendationData] = useState(
    [] as any,
  );
  const [homepageNotificationStatus, setHomepageNotificationStatus] = useState(
    "" as any,
  );
  const [modals, setModals] = useState({
    draftEmailModal: false,
    homepageNotificationModal: false,
  });
  const [isDataLoading, setIsDataLoading] = useState({});

  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPage, setTotalPage] = useState(0 as any);
  // const [perPage, setPerPage] = useState(5);

  useEffect(() => {
    getHomePageRecommendationData();
  }, []);

  useEffect(() => {
    // Extract query parameters from the URL
    const searchParams = new URLSearchParams(window.location.search);
    // Check if 'draft_mail' parameter exists and has value of '1'
    if (searchParams.get("draft_mail") === "1") {
      setModals((prevState) => ({
        ...prevState,
        draftEmailModal: true,
      }));
    }
  }, []);

  /**
   * Modal close emit
   * Common for all typeof modal.
   *
   * @returns {void}
   */
  function modalCloseEmit(data: any) {
    setModals((prevState) => ({
      ...prevState,
      [data.modalName]: false,
    }));
  }

  function draftEmailModalOpen(data: any) {
    setSelectedRecommendationData(data);
    setModals((prevState) => ({
      ...prevState,
      draftEmailModal: true,
    }));
  }
  function homepageNotificationModalOpen(data: any, status: string) {
    setHomepageNotificationStatus(status);
    setSelectedRecommendationData(data);
    setModals((prevState) => ({
      ...prevState,
      homepageNotificationModal: true,
    }));
  }
  function getHomePageRecommendationData() {
    setIsDataLoading(true);
    http
      .get("/recommendation/fetch-recommendations/", {
        params: {
          recom_id: id,
        },
      })
      .then((response: any) => {
        const { data }: any = response;
        setRecommendationData(data?.recommendations);
        // setTotalPage(data?.last_page);
        setIsDataLoading(false);
      });
  }

  // const handlePageChange = (selectedPage: { selected: number }) => {
  //   setCurrentPage(selectedPage.selected + 1);
  // };
  return (
    <>
      {modals.draftEmailModal && (
        <PreviewRecomDraftEmailModal
          modalStatus={modals.draftEmailModal}
          modalName="draftEmailModal"
          recommendationData={selectedRecommendationData}
          refetchData={() => {
            setRecommendationData([]);
            getHomePageRecommendationData();
          }}
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
      {modals.homepageNotificationModal && (
        <HomepageNotificationModal
          modalStatus={modals.homepageNotificationModal}
          modalName="homepageNotificationModal"
          status={homepageNotificationStatus}
          recommendationData={selectedRecommendationData}
          refetchData={() => {
            setRecommendationData([]);
            getHomePageRecommendationData();
          }}
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
      <div className="container">
        <div className="mb-4">
          <div
            className="custom-modal-outer__padding custom-recommendation-bg"
            // style={{ backgroundColor: "#2A3042" }}
          >
            <div className="row">
              <div className="d-flex justify-content-between flex-wrap px-lg-0 mb-2 ">
                <p className="header-title mb-2 mb-lg-0">Recomended Action</p>
              </div>

              {isDataLoading && (
                <div className="px-2 px-lg-0">
                  <table className="w-100">
                    <TablePlaceHolder
                      items={recommendationData}
                      loading={true}
                      rows={13}
                      cols={8}
                    />
                  </table>
                </div>
              )}

              {/* {!isDataLoading && recommendationData?.map((recommendation: any, index: number) => (
               
              ))} */}
              {(!isDataLoading && !isObjectEmpty(recommendationData)) && (
                <div
                  key={recommendationData?.id}
                  className="card"
                  style={{
                    backgroundColor: "#262B3C",
                    // marginTop: "-24px",
                  }}
                >
                  <div className="custom-modal-inner__padding">
                    <div className="row">
                      <div className="col-12 col-lg-6 order-1 order-lg-1 px-0">
                        <p className="mb-0 text-white fw-semibold">
                          {recommendationData?.title}{" "}
                          {/* <span className="text-secondary opacity-25">
                            #{recommendation?.id}
                          </span> */}
                        </p>
                      </div>
                      <div className="col-12 col-lg-6 order-3 order-lg-2 px-0">
                        <div className="d-flex gap-2 justify-content-start justify-content-lg-end">
                          <button
                            type="button"
                            onClick={() => homepageNotificationModalOpen(
                              recommendationData,
                              "ignore",
                            )}
                            className="btn btn-sm w-sm pe-auto border-white text-white"
                          >
                            <EyeCrossIcon className="d-none d-lg-inline-block" />
                            <span className="ms-1 fw-bold">Ignore</span>
                          </button>
                          <button
                            type="button"
                            onClick={() => homepageNotificationModalOpen(
                              recommendationData,
                              "complete",
                            )}
                            className="btn button-ash w-sm text-black btn-sm fw-bold"
                          >
                            <CheckMarkIcon className="d-none d-lg-inline-block" />
                            Complete
                          </button>
                          {hasRecomDraftEmailAccess(
                            recommendationData?.slug,
                          ) ? (
                            <button
                              type="button"
                              className="btn btn-primary btn-sm w-sm fw-bold"
                              onClick={() => draftEmailModalOpen(recommendationData)}
                            >
                              <EmailIcon
                                width={20}
                                height={20}
                                className="d-none d-lg-inline-block"
                              />
                              Draft Email
                            </button>
                            ) : (
                              ""
                            )}
                        </div>
                      </div>
                      <div className="d-flex order-2 order-lg-3 px-0">
                        <p className="mt-3 custom-text-fs">
                          {recommendationData?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* {(!isDataLoading && recommendationData?.length && (
              <div className="row mt-1">
                <div className="col-12 col-md-4 ps-lg-0 d-flex gap-2 align-items-center">
                  <p className="mb-0 custom-fs-12-to-14">Rows Per Page</p>
                  <PerPageOptionSelectorV2
                    defaultPerPageValue={perPage}
                    perPageEmitSet={(value: any) => setPerPage(value)}
                    labelUnit=" "
                  />
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-center justify-content-md-start align-items-center">
                  <Pagination
                    pageCount={totalPage}
                    perPage={perPage}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            )) ||
              ""} */}
          </div>
        </div>
      </div>
    </>
  );
}

export default PreviewRecommendation;
