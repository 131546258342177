import HelpIcon from "@components/common/HelpIcon";
import { toolsPageToolTip } from "@constants/tooltip-data";
import { faker } from "@faker-js/faker";
import { http } from "@utils/http";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import NeutrinoAgentOpportunities from "./NeutrinoAgentOpportunities";
import NeutrinoBottomUsersTable from "./NeutrinoBottomUsersTable";
import NeutrinoEngagementOverTime from "./NeutrinoEngagementOverTime";
import NeutrinoOfficeOverview from "./NeutrinoOfficeOverview";
import NeutrinoProgressBarReportType from "./NeutrinoProgressBarReportType";
import NeutrinoTopProfileCard from "./NeutrinoTopProfileCard";
import NeutrinoTopUsersTable from "./NeutrinoTopUsersTable";
import NeutrinoWebLeadType from "./NeutrinoWebLeadType";

function NeutrinoCardDetailsModal(props:any) {
  // All state.
  const [openModal, setOpenModal] = useState(false);
  const [modalName] = useState("neutrinoModal");
  const [isNtEngagementDataLoading, setIsNtEngagementDataLoading] = useState(true);
  const [neutrinoEngagementScore, setNeutrinoEngagementScore] = useState({} as any);
  const [selectedPersonInfo, setSelecedPersonInfo] = useState({} as any);

  useEffect(() => {
    if (props.modalName === modalName) {
      setOpenModal(props.modalStatus);
    }
  }, [setOpenModal, props.modalStatus]);

  // useEffect(() => {
  //   if (openModal) {
  //     setTimeout(() => {
  //       setIsPieReportComponentLoaded(true);
  //     }, 1000);
  //   }
  // }, [openModal]);

  // Added an event listener to handle 'Escape' key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && openModal) {
        modalClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openModal]);

  useEffect(() => {
    getTopProfileEngagementScore();
  }, []);

  function modalClose() {
    setOpenModal(false);
    setTimeout(() => {
      props.modalClose({
        modalName,
      });
    }, 1000);
  }

  function getTopProfileEngagementScore() {
    setIsNtEngagementDataLoading(true);
    http
      .get("/tools/neutrino--overall-website-engagement-score.json")
      .then((response: any) => {
        const neutrinoEngagementScoreObj = response?.data?.website_engagement;
        let theData = neutrinoEngagementScoreObj;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          const fakerFirstName = faker.person.firstName();
          theData = {
            ...theData,
            first_name: fakerFirstName,
            email: faker.internet.email({ firstName: fakerFirstName, lastName: "" }),
          };
        }
        setNeutrinoEngagementScore(theData);
        setIsNtEngagementDataLoading(false);
      })
      .catch((error: any) => {
        setIsNtEngagementDataLoading(false);
        console.log(error);
      });
  }

  function getRandomColor(stats: any) {
    let color: any = "";
    switch (true) {
      case stats >= 40 && stats <= 50:
        color = "#F2CA6B";
        break;
      case stats > 50 && stats <= 70:
        color = "#F2CA6B";
        break;
      case stats > 70:
        color = "#CF4AFE";
        break;
      default:
        color = "#F5822F";
        break;
    }

    return color;
  }
  
  return (
    <Modal
      size="xl"
      isOpen={openModal}
      centered={true}
      
    >
      <div className="modal-content" style={{ background: "#222736" }}>
        <div className="container mb-4">
          <p className="custom-modal-title mt-2"></p>
          <button
            type="button"
            onClick={modalClose}
            className="btn-close position-absolute custom-top-2 custom-right-4 mb-4"
          ></button>
        </div>
        <ModalBody className="custom-modal-outer__padding">
          <div className="row mb-3">
            <div className="d-flex justify-content-between">
              {/* <div className="header-title">Email</div> */}
            </div>
          </div>
          {/* Cloud CMA Card */}
          <div className="row mb-2">
            <div className="col-12">
              <div className="" style={{ backgroundColor: "#2B3041" }}>
                <div className="custom-modal-inner__padding">
                  {/* <p className="header-subtitle text-white">Neutrino</p> */}
                  <div className="d-inline-block">
                    <span className="header-title-6 fw-bold text-white pe-1">
                      Neutrino
                    </span>
                    <div className="d-inline-block align-middle">
                      <HelpIcon
                        tooltip={`${toolsPageToolTip.toolsModalNeutrino}`}
                        width={20}
                        height={20}
                      />
                    </div>
                  </div>
                  <NeutrinoTopProfileCard
                    isDataLoading={isNtEngagementDataLoading}
                    topProfileInfo={neutrinoEngagementScore} 
                  />
                  {/* <div className="row">
                    <div className="col-7 col-lg-12">
                      <div className="row">
                        <div className="col-5 col-lg-12">
                          <div className="d-flex justify-content-center">
                            <ProfileIcon height="60"/>
                          </div>
                        </div>
                        <div className="col-7 col-lg-12 px-0">
                          <p className="text-left text-lg-center text-white header-subtitle-2 mb-1">
                          <Placeholder
                            loading={isNtEngagementDataLoading}
                            className="custom-loader-w-20 custom-loader-h-6"
                          ></Placeholder>
                            { !isNtEngagementDataLoading && (neutrinoEngagementScore?.first_name ?? "")}
                          </p>
                          <p className="mb-0 d-flex d-lg-none page-subtitle-3">Engagement Score</p>
                          <p className="number-stats d-flex d-lg-none text-white mb-0">
                          <Placeholder
                            loading={isNtEngagementDataLoading}
                            className="custom-loader-w-20 custom-loader-h-6"
                          ></Placeholder>
                            { !isNtEngagementDataLoading && (neutrinoEngagementScore?.overall_engagement_score ?? 0)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-5 col-lg-12">
                      <div className="d-lg-flex align-items-center gap-3 text-break">
                        <p className="mb-0 custom-fs-12">Email</p>
                        <p className="text-white mb-0 custom-fs-12">
                        <Placeholder
                          loading={isNtEngagementDataLoading}
                          className="custom-loader-w-20 custom-loader-h-6"
                        ></Placeholder>
                          { !isNtEngagementDataLoading && (neutrinoEngagementScore?.email ?? "N/A")}
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-none d-lg-flex align-items-center header-subtitle-2 flex-column flex-lg-row gap-2 flex-lg-row-reverse justify-content-lg-end">
                        <p className="mb-0 page-subtitle-3">Engagement Score</p>
                        <p className="number-stats text-white mb-0">
                        <Placeholder
                          loading={isNtEngagementDataLoading}
                          className="custom-loader-w-20 custom-loader-h-6"
                        ></Placeholder>
                          { !isNtEngagementDataLoading && (neutrinoEngagementScore?.overall_engagement_score ?? 0)}
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row custom-parent--row h-100">
            <div className="col-12 col-lg-6 mb-2 custom-column">
              <NeutrinoEngagementOverTime/>
            </div>
            <div className="col-12 col-lg-6 mb-2 custom-column">
              <NeutrinoWebLeadType/>
            </div>
          </div>
          
          {/* {
            openModal && <NeutrinoPieReport/>
          } */}
          {
            openModal && <NeutrinoProgressBarReportType/>
          }
          <div className="row row-gap-2 custom-mb-2 custom-parent--row">
            <div className="col-12 col-lg-6 custom-column">
              <NeutrinoAgentOpportunities cardId={props?.cardIdData}/>
            </div>
            <div className="col-12 col-lg-6 custom-column">
              <NeutrinoOfficeOverview cardId={props?.cardIdData}/>
            </div>
          </div>
          <div className="row row-gap-2 custom-mb-2 custom-parent--row">
            <div className="col-12 col-lg-6 custom-column">
              <NeutrinoTopUsersTable cardId={props?.cardIdData}/>
            </div>
            <div className="col-12 col-lg-6 custom-column">
              <NeutrinoBottomUsersTable cardId={props?.cardIdData}/>
            </div>
          </div>
          {/* <RecomendedAction /> */}
        </ModalBody>
      </div>
    </Modal>
  );
}

export default NeutrinoCardDetailsModal;
