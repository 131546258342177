import React, { useCallback } from 'react';
import { Modal } from 'reactstrap';

interface ModalProps {
  acceptText: string
  body: string
  open: boolean
  rejectText: string
  setOpen: (open: boolean) => void
  submitOnClick: () => Promise<any>
  title: string
}

const ConfirmationDialog = ({
  acceptText, body, open, rejectText, setOpen, submitOnClick, title,
}: ModalProps) => {
  const submit = useCallback(() => {
    setOpen(false);
    return submitOnClick();
  }, [setOpen, submitOnClick]);
  return (
    <>
      <Modal
        animation="false"
        isOpen={open}
        toggle={() => setOpen(!open)}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {title}
          </h5>
          <button
            type="button"
            onClick={() => setOpen(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div>
            {body}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => setOpen(false)}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {rejectText}
          </button>
          <button
            className="btn btn-primary"
            data-testid={`${acceptText}ModalButton`}
            onClick={submit}
            type="button"
          >
            {acceptText}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmationDialog;
