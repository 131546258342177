import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  EmergingMarket,
  useLazyByMlsEmergingMarketQuery,
} from '../../../../state/api';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';
import { useAppSelector } from '../../../../state/hooks';
import { selectMlsIdList } from '../../../../state/appSlice';
import { MarketsParams } from '../Markets';

export interface EmergingMarketSelectorProps {
  setEmergingMarket: (emergingMarket: EmergingMarket) => void
}

const EmergingMarketSelector = ({ setEmergingMarket }: EmergingMarketSelectorProps) => {
  const toggleInitialValue = 'Select Emerging Market';
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownValue, setDropdownValue] = React.useState(toggleInitialValue);
  const mlsList = useAppSelector(selectMlsIdList);
  const [trigger, emergingMarkets] = useLazyByMlsEmergingMarketQuery();
  const history = useHistory();
  const { id } = useParams<MarketsParams>();
  const handleDropdownSelect = useCallback(() => (emergingMarket: EmergingMarket) => {
    setDropdownValue(emergingMarket.name!);
    setEmergingMarket(emergingMarket);
    history.push(`/market/${emergingMarket.id}`);
  }, [history, setDropdownValue, setEmergingMarket]);

  useEffect(() => {
    if (mlsList.length > 0) {
      trigger({ mls: mlsList });
    }
  }, [mlsList, trigger]);

  useEffect(() => {
    if (emergingMarkets.isSuccess && emergingMarkets.data.length > 0) {
      let selected = emergingMarkets.data[0];
      if (id == null) {
        handleDropdownSelect()(emergingMarkets.data[0]);
      } else {
        const filtered = emergingMarkets.data.filter((it) => it.id === id);
        if (filtered.length > 0) {
          [selected] = filtered;
        }
      }
      handleDropdownSelect()(selected);
    }
  }, [id, emergingMarkets, handleDropdownSelect]);

  let dropdownElement;
  let noEmergingMarkets;
  let noMlsSubscriptions;
  if (mlsList.length === 0) {
    noMlsSubscriptions = (
      <div className="mt-2 card-title">
        To view emerging markets you must have at least one MLS subscription.
      </div>
    );
  }
  if (emergingMarkets.isSuccess) {
    if (emergingMarkets.data.length === 0) {
      noEmergingMarkets = (
        <div className="mt-2 card-title">
          It looks like there aren&apos;t any emerging markets in your area.
        </div>
      );
    }
    dropdownElement = (
      <div>
        <Dropdown
          isOpen={isOpen}
          size="sm"
          toggle={() => setIsOpen(!isOpen)}
        >
          <DropdownToggle disabled={emergingMarkets.data.length === 0}>
            {isOpen ? toggleInitialValue : dropdownValue}
            &nbsp;
            <KeyboardArrowDownIcon fontSize="small" />
          </DropdownToggle>
          <DropdownMenu>
            {emergingMarkets.data.map((it) => (
              <DropdownItem key={it.id} onClick={() => handleDropdownSelect()(it)}>
                {it.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  } else {
    dropdownElement = <LoadingIcon loading={emergingMarkets.isLoading} />;
  }
  let emergingMarketSelector;
  if (noMlsSubscriptions == null && noEmergingMarkets == null) {
    emergingMarketSelector = (
      <div className="d-flex">
        <div className="h4 mt-1 me-2 text-uppercase">
          Select Emerging Market:
        </div>
        {dropdownElement}
      </div>
    );
  }
  return (
    <>
      {emergingMarketSelector}
      {noMlsSubscriptions}
      {noEmergingMarkets}
    </>
  );
};

export default EmergingMarketSelector;
