import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { Input, Label } from 'reactstrap';
import Field from '../../common/Field';
import 'react-toastify/dist/ReactToastify.css';
import { usePasswordResetLoginMutation, usePartialUpdateUserMutation, useLogoutMutation } from '../../../state/api';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';
import AuthLayout from '../../layout/AuthLayout';
import { passwordMismatchMessage, passwordPattern, requiredFieldMessage } from '../../common/validation';
import { useAppSelector } from '../../../state/hooks';
import { selectAuth, selectTrackingUserId } from '../../../state/appSlice';
import tracking, { getTrackingData, TrackingActions } from '../../../state/tracking';

interface PasswordResetParams {
  id: string
}

export interface UpdatePasswordForm {
  password: string
}

export const passwordResetExpiredMessage = 'This password reset link is expired. Please reset your password again.';
export const passwordResetSuccessMessage = 'Successfully reset password.';
export const passwordResetApiFailureMessage = 'Error resetting password.';

const PasswordReset = () => {
  const { id } = useParams<PasswordResetParams>();
  const history = useHistory();
  const { control, handleSubmit } = useForm();
  const [passwordResetLogin, passwordResetLoginData] = usePasswordResetLoginMutation();
  const [partialUpdateUser, partialUpdateUserData] = usePartialUpdateUserMutation();
  const auth = useAppSelector(selectAuth);
  const [displayPassword, setDisplayPassword] = useState(false);
  const [logout] = useLogoutMutation();
  const userId = useAppSelector(selectTrackingUserId);

  // Try to login with the query param 'key' as the bearer token. Redirect on failure
  useEffect(() => {
    if (id != null) {
      const resetPassword = () => passwordResetLogin({
        tokenLogin: {
          token: id,
        },
      }).unwrap()
        .catch(() => {
          toast.dark(passwordResetExpiredMessage, { type: 'error' });
          history.push('/request-password-reset');
        });
      if (auth != null) {
        logout().unwrap().then(resetPassword);
      } else {
        resetPassword();
      }
    } else {
      history.push('/request-password-reset');
    }
    // Only do this once on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePartialUpdateUser = handleSubmit((form: UpdatePasswordForm) => {
    partialUpdateUser({ id: auth !== undefined ? auth.user_id : '', user: form }).unwrap()
      .then(() => {
        toast.dark(passwordResetSuccessMessage, { type: 'success' });
        history.push('/');
        tracking.track(TrackingActions.PASSWORD_RESET_SUCCESS.valueOf(), getTrackingData(userId!));
      }).catch(() => toast.dark(passwordResetApiFailureMessage, { type: 'error' }));
  });

  return (
    <AuthLayout modalTitle="Reset Password - Kintaro" pageTitle="Password Reset">
      <form
        className="form-horizontal"
        onSubmit={handlePartialUpdateUser}
      >
        <div className="mb-3">
          <Field
            control={control}
            label="Password"
            name="password"
            placeholder="Enter password"
            rules={{
              required: requiredFieldMessage('Password'),
              pattern: { value: passwordPattern, message: passwordMismatchMessage },
            }}
            type={displayPassword ? 'text' : 'password'}
          />
        </div>
        <div className="mb-3">
          <Label for="displayPassword">Display Password</Label>
          <Input
            className="ms-2"
            id="displayPassword"
            name="displayPassword"
            onClick={() => setDisplayPassword(!displayPassword)}
            type="checkbox"
          />
        </div>
        <div className="d-grid">
          <LoadingIcon className="mt-1 mb-4" loading={partialUpdateUserData.isLoading} />
          <button
            className="btn btn-primary btn-block"
            disabled={!passwordResetLoginData.isSuccess || partialUpdateUserData.isLoading}
            type="submit"
          >
            Reset Password
          </button>
        </div>
      </form>
    </AuthLayout>
  );
};

export default PasswordReset;
