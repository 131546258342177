import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { createUseStyles } from 'react-jss';

export interface LoadingIconProps {
  className?: string
  loading?: boolean
}

const useStyles = createUseStyles({
  LoadingIcon: {
    display: 'flex',
    'flex-wrap': 'nowrap',
    height: '2.75rem',
    overflow: 'hidden',
    'justify-content': 'center',
    transition: 'height .5s ease',
    'transition-delay': '.5s',
    'margin-bottom': '1em',
  },
  LoadingIcon__hidden: {
    height: '0',
    'margin-bottom': '0',
  },
});

export const LoadingIcon = ({ className, loading }: LoadingIconProps) => {
  // Don't display immediately, use CSS transitions
  const [loadingState, setLoadingState] = useState(false);
  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        setLoadingState(loading!);
      }
    });
    return () => { unmounted = true; };
  }, [loading]);

  const classes = useStyles();
  const classArray = [classes.LoadingIcon, className];
  if (!loadingState) {
    classArray.push(classes.LoadingIcon__hidden);
  }
  return (
    <div className={classArray.join(' ')}>
      <ClipLoader color="white" />
    </div>
  );
};

LoadingIcon.defaultProps = {
  loading: true,
  className: '',
};

export default LoadingIcon;
