import menus from '@constants/sidebar-menu';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Popover } from '@mui/material';
import { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useLocation } from 'react-router-dom';
import { gray700 } from '../../assets/colors';

const styles = createUseStyles({
  Sidebar_link: {
    '&:focus': {
      'color': 'inherit',
    },
  },
  Sidebar_link__selected: {
    'color': `${gray700} !important`,
    'backgroundColor': '#1e2330',
  },
});

const SimplePopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = useRef(null);
  const classes = styles();
  const selectedLinkClasses = `${classes.Sidebar_link} ${classes.Sidebar_link__selected} hide-list-background-color`;
  const unselectedLinkClasses = classes.Sidebar_link;
  const location = useLocation();
  function handleClick() {
    setAnchorEl(divRef.current);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div ref={divRef}>
      <Button
        variant="contained"
        onClick={() => handleClick()}
        className="btn bg-transparent btn-sm px-3 font-size-16 header-item"

      >
        <MenuIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <>
          <div className="burger-menu">
            <div className="h-100">
              <div id="sidebar-menu">
                <ul className="metismenu list-unstyled">
                {
                menus?.map((menu: any) => (
                  <li key={menu.path_name} className='mb-2 hide-list-background-color'>
                    <Link to={menu.url} className={`${location.pathname === menu.path_name ? selectedLinkClasses : unselectedLinkClasses} mx-2 rounded`}>
                     <div className='d-flex gap-3 align-items-end'>
                     {menu.icon && <span><menu.icon/></span>}
                      <span>{menu.name}</span>
                     </div>
                    </Link>
                  </li>
                ))
              }
                </ul>
              </div>
            </div>
          </div>
        </>
      </Popover>
    </div>
  );
};

export default SimplePopover;
