import React, { ReactElement } from 'react';
import { Pagination as PaginationContainer, PaginationLink, PaginationItem } from 'reactstrap';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { createUseStyles } from 'react-jss';

const styles = createUseStyles({
  Pagination_link__disabled: {
    '&:hover': {
      color: 'inherit',
    },
  },
  Pagination_link__selected: {
    '&:hover': {
      color: 'inherit',
    },
  },
});

interface PaginationProps {
  count: number
  elementsPerPage: number
  page: number
  setPage: (page: number) => void
}

const Pagination = ({
  count, elementsPerPage, page, setPage,
}: PaginationProps) => {
  const classes = styles();
  const totalPages = Math.ceil(count / elementsPerPage);
  const paginationItems: Array<ReactElement> = [];
  if (totalPages > 0) {
    const disabled = page === 1;
    paginationItems.push(
      <PaginationItem key="previous">
        <PaginationLink
          className={disabled ? classes.Pagination_link__disabled : ''}
          disabled={disabled}
          onClick={(e) => {
            setPage(page - 1);
            e.currentTarget.blur();
          }}
        >
          <ChevronLeftIcon />
        </PaginationLink>
      </PaginationItem>,
    );
  }
  for (let i = 1; i < totalPages + 1; i += 1) {
    if (i === 1 || i === totalPages || Math.abs(page - i) < 3) {
      paginationItems.push(
        <PaginationItem key={i}>
          <PaginationLink
            className={i === page ? `bg-primary ${classes.Pagination_link__selected}` : ''}
            onClick={(e) => {
              setPage(i);
              e.currentTarget.blur();
            }}
          >
            {i}
          </PaginationLink>
        </PaginationItem>,
      );
    }
    if (Math.abs(page - i) === 3) {
      paginationItems.push(
        <PaginationItem key={i} disabled>
          <PaginationLink className={i === page ? 'bg-primary' : ''} disabled>
            ...
          </PaginationLink>
        </PaginationItem>,
      );
    }
  }
  if (totalPages > 0) {
    const disabled = page === totalPages;
    paginationItems.push(
      <PaginationItem key="last">
        <PaginationLink
          className={disabled ? classes.Pagination_link__disabled : ''}
          disabled={disabled}
          onClick={(e) => {
            setPage(page + 1);
            e.currentTarget.blur();
          }}
        >
          <ChevronRightIcon />
        </PaginationLink>
      </PaginationItem>,
    );
  }
  return (
    <>
      <PaginationContainer className="pagination pagination-rounded justify-content-center">
        {paginationItems}
      </PaginationContainer>
    </>
  );
};

export default Pagination;
