import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Modal } from 'reactstrap';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';
import { useLazyListUsersQuery, useLoginAsUserMutation } from '../../../state/api';
import { useAppSelector } from '../../../state/hooks';
import { selectAuth } from '../../../state/appSlice';
import { SelectFieldOption } from '../../common/SelectField';
import AsyncSelectField from '../../common/AsyncSelectField';
import { requiredFieldMessage } from '../../common/validation';

interface ViewAsUserDialogForm {
  user: SelectFieldOption
}

interface ViewAsUserDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const ViewAsUserDialog = ({ open, setOpen }: ViewAsUserDialogProps) => {
  const { control, handleSubmit } = useForm();
  const [listUsersQuery, listUsersData] = useLazyListUsersQuery();
  const [loginAsUser, loginAsUserData] = useLoginAsUserMutation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [callback, setCallback] = useState(() => (options: SelectFieldOption[]) => {});
  const auth = useAppSelector(selectAuth);

  const loadUser = useCallback((
    inputValue: string,
    callbackFunction: (options: SelectFieldOption[]) => void,
  ) => {
    setCallback(() => callbackFunction);
    listUsersQuery({ search: inputValue });
  }, [listUsersQuery]);

  const debounceLoadUsers = useMemo(() => debounce(loadUser, 500), [loadUser]);

  useEffect(() => {
    if (listUsersData.isSuccess) {
      callback(listUsersData.data.results!.map((it) => ({ label: it.email!, value: it.id! })));
    } else if (listUsersData.isError) {
      toast.dark('Error searching for users.', { type: 'error' });
    }
  }, [callback, listUsersData]);

  const submit = handleSubmit((form: ViewAsUserDialogForm) => {
    loginAsUser({ tokenLogin: { token: auth!.token }, userId: form.user.value! })
      .unwrap()
      .then(() => {
        setOpen(false);
      })
      .catch(() => toast.dark(`Unable to log into application as ${form.user.label}`, { type: 'error' }));
  });

  return (
    <>
      <Modal
        isOpen={open}
        toggle={() => setOpen(!open)}
      >
        <form>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              View Application as Another User
            </h5>
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <AsyncSelectField
                aria-label="User"
                loadOptions={debounceLoadUsers}
                control={control}
                placeholder="Select user..."
                rules={{ required: requiredFieldMessage('User') }}
                name="user"
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              disabled={loginAsUserData.isLoading}
              onClick={submit}
              type="submit"
            >
              View as User
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ViewAsUserDialog;
