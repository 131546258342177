import React from 'react';
import { createUseStyles } from 'react-jss';

export interface AvatarFromInitialsProps {
  name: string
  diameter: number
}

const colors = [
  '#1abc9c', '#2ecc71', '#3498db', '#9b59b6',
  '#34495e', '#16a085', '#27ae60', '#2980b9',
  '#8e44ad', '#2c3e50', '#f1c40f', '#e67e22',
  '#e74c3c', '#95a5a6', '#f39c12', '#d35400',
  '#c0392b', '#bdc3c7', '#7f8c8d',
];

const AvatarFromInitials = ({ name, diameter }: AvatarFromInitialsProps) => {
  const nameParts = name.split(' ');
  const firstInitial = name.length > 1 ? nameParts[0].charAt(0).toUpperCase() : '?';
  const lastInitial = name.length > 0 ? nameParts[nameParts.length - 1].charAt(0).toUpperCase() : '?';
  const initials = firstInitial + lastInitial;
  const charIndex = initials.charCodeAt(0) - 65;
  const colorIndex = charIndex % 19;

  const classes = createUseStyles({
    AvatarFromInitials: {
      'background-color': colors[colorIndex],
      'border-radius': '50%',
      color: '#FFF',
      height: diameter,
      'line-height': `${diameter}px`,
      'text-align': 'center',
      width: diameter,
    },
  })();
  return (
    <div className={classes.AvatarFromInitials}>
      {initials}
    </div>
  );
};

export default AvatarFromInitials;
