import {
  RA_COMPLETED,
  RA_DRAFT_EMAIL,
  RA_IGNORE,
  SQL_NOT_EXIST_MESSAGE,
} from "@constants/variables";
import { http } from "@utils/http";
import { CheckMarkIcon, EyeCrossIcon, MailIcon } from "@utils/icons";
import CirclesWithBarLoader from "@utils/loaders/CirclesWithBarLoader";
import OvalLoader from "@utils/loaders/OvalLoader";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import RecommendedActionCtaModal from "./RecommendedActionCtaModal";

interface CustomArrowProps {
  [key: string]: any;
}

const CustomNextArrow: React.FC<CustomArrowProps> = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      style={{
        width: "100%",
        textAlign: "end",
        top: "12%",
        right: "2%",
      }}
      className={`arrow ${className}`}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") onClick?.();
      }}
      role="button"
      tabIndex={0}
      aria-label="Next slide"
    >
      &#9654; {/* Right arrow symbol */}
    </div>
  );
};

const CustomPrevArrow: React.FC<CustomArrowProps> = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`arrow ${className} custom-recom-slide-prev-arrow`}
      style={{
        width: "100%",
        textAlign: "end",
        top: "12%",
        right: "0",
        zIndex: 1,
      }}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") onClick?.();
      }}
      role="button"
      tabIndex={0}
      aria-label="Previous slide"
    >
      &#9664; {/* Left arrow symbol */}
    </div>
  );
};

function RecomSlider() {
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const [recommendationData, setRecommendationData] = useState([] as any);
  const [selectedRecommendationData, setSelectedRecommendationData] = useState(
    [] as any,
  );
  const [isDataLoading, setIsDataLoading] = useState({
    isRecomDataLoading: true,
    isRecomStatusCountDataLoading: false,
  });
  const [recommendedActionCtaStatus, setRecommendedActionCtaStatus] = useState(
    "" as any,
  );
  const [recomCountStatus, setRecomCountStatus] = useState({} as any);
  let sliderRef: any = useRef(null as any);
  const totalSlides = recommendationData?.length;
  const [modals, setModals] = useState({
    recommendedActionCtaModal: false,
  });
  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlide - 1,
    className: "slides",
    // arrows: false,
    nextArrow: <CustomNextArrow to="next" />,
    prevArrow: <CustomPrevArrow to="prev" />,
    afterChange: (current: number) => setCurrentSlide(current + 1),
  };

  useEffect(() => {
    getHomePageRecommendationData();
    getTotalStatusCount();
  }, []);

  function getHomePageRecommendationData() {
    setDataLoadingState("isRecomDataLoading", true);
    http
      .get("/recommendation/fetch-recommendations.json")
      .then((response: any) => {
        const { data }: any = response;
        let theData = data?.recommendations;
        if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
          theData = [];
        }
        setRecommendationData(theData);
        setDataLoadingState("isRecomDataLoading", false);
      });
  }

  function setDataLoadingState(fieldName: string, value: boolean) {
    setIsDataLoading((prev: any) => ({
      ...prev,
      [fieldName]: value,
    }));
  }
  /**
   * Modal close emit
   * Common for all typeof modal.
   *
   * @returns {void}
   */
  function modalCloseEmit(data: any) {
    setModals((prevState) => ({
      ...prevState,
      [data.modalName]: false,
    }));
  }

  function recommendedActionCtaModal(data: any, status: string) {
    setRecommendedActionCtaStatus(status);
    setSelectedRecommendationData(data);
    setModals((prevState) => ({
      ...prevState,
      recommendedActionCtaModal: true,
    }));
  }

  function getTotalStatusCount() {
    setDataLoadingState("isRecomStatusCountDataLoading", true);
    http
      .get("/recommendation/get-total-action-lists.json")
      .then((response: any) => {
        const totalCount = response?.data;
        setRecomCountStatus(totalCount);
        setDataLoadingState("isRecomStatusCountDataLoading", false);
      })
      .catch((error: any) => {
        console.log(error);
        // toast.error(error?.message, { duration: 3000 });
        setDataLoadingState("isRecomStatusCountDataLoading", false);
      });
  }
  if (
    isDataLoading?.isRecomDataLoading ||
    isDataLoading?.isRecomStatusCountDataLoading
  ) {
    return (
      <div
        className="d-flex align-items-center justify-content-center rounded-4"
        style={{
          padding: "20px 10px 10px 10px",
          minHeight: "304px",
          background:
            "linear-gradient(166deg, rgba(22, 64, 214, 0.6) -57%, rgb(34, 39, 54) 70%, rgba(237, 90, 179, 1) 318%)",
          // "linear-gradient(145deg, rgba(22,64,214,0.5984768907563025) -100%, rgba(34,39,54,1) 93%, rgba(237,90,179,0.1) 120%)",
        }}
      >
        <CirclesWithBarLoader />
      </div>
    );
  }
  return (
    <>
      {modals.recommendedActionCtaModal && (
        <RecommendedActionCtaModal
          modalStatus={modals.recommendedActionCtaModal}
          modalName="recommendedActionCtaModal"
          status={recommendedActionCtaStatus}
          recommendationData={selectedRecommendationData}
          refetchData={() => {
            getHomePageRecommendationData();
            getTotalStatusCount();
          }}
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
      <div className="custom-slider-css" style={{ maxWidth: "100%" }}>
        <div className="carousel-container" style={{ position: "relative" }}>
          {recommendationData?.length ? (
            <>
              <Slider
                ref={(slider) => {
                  sliderRef = slider;
                }}
                {...settings}
              >
                {/* Add your slide content here */}
                {recommendationData?.length
                  ? recommendationData?.map((recom: any) => (
                      <div key={recom?.id}>
                        <div
                          className="rounded-4"
                          style={{
                            padding: "20px 10px 10px 10px",
                            minHeight: "304px",
                            background:
                              "linear-gradient(166deg, rgba(22, 64, 214, 0.6) -57%, rgb(34, 39, 54) 70%, rgba(237, 90, 179, 1) 318%)",
                            // "linear-gradient(145deg, rgba(22,64,214,0.5984768907563025) -100%, rgba(34,39,54,1) 93%, rgba(237,90,179,0.1) 120%)",
                          }}
                        >
                          <h3
                            className="fw-bold text-white mt-5 mt-lg-0"
                            style={{ fontSize: "18px" }}
                          >
                            {recom?.title}
                          </h3>
                          <p
                            className="text-break mt-3"
                            style={{ minHeight: "132px" }}
                          >
                            {recom.description}
                          </p>
                          <div className="d-flex mt-5 gap-1 gap-md-2 gap-lg-4 align-items-center">
                            <button
                              type="button"
                              className="btn d-flex flex-grow-1 flex-lg-grow-0 align-items-center justify-content-center gap-2 p-2 text-white"
                              style={{ background: "#E32E71" }}
                              onClick={() => recommendedActionCtaModal(recom, RA_IGNORE)}
                            >
                              <span className="d-none d-md-inline-block">
                                <EyeCrossIcon />
                              </span>

                              <span>Ignore</span>
                              {recommendedActionCtaStatus === RA_IGNORE &&
                              isDataLoading?.isRecomStatusCountDataLoading ? (
                                <OvalLoader isDataLoading={true} />
                                ) : (
                                <span
                                  className="d-flex align-items-center justify-content-center bg-white rounded-circle fw-bolder"
                                  style={{
                                    padding: "2px",
                                    minWidth: "20px",
                                    minHeight: "20px",
                                    color: "#ED5AB3",
                                    fontSize: "12px",
                                  }}
                                >
                                  {recomCountStatus?.ignore}
                                </span>
                                )}
                            </button>
                            <button
                              type="button"
                              className="btn d-flex flex-grow-1 flex-lg-grow-0 align-items-center justify-content-center gap-2 p-2 text-white"
                              style={{ background: "#019955" }}
                              onClick={() => recommendedActionCtaModal(recom, RA_COMPLETED)}
                            >
                              <span className="d-none d-md-inline-block">
                                <CheckMarkIcon
                                  fillColor="#fff"
                                  strokeColor="#fff"
                                />
                              </span>

                              <span>Complete</span>
                              {recommendedActionCtaStatus === RA_COMPLETED &&
                              isDataLoading?.isRecomStatusCountDataLoading ? (
                                <OvalLoader isDataLoading={true} />
                                ) : (
                                  ""
                                )}
                            </button>
                            <button
                              type="button"
                              className="btn d-flex flex-grow-1 flex-lg-grow-0 align-items-center justify-content-center gap-2 p-2 text-white"
                              style={{ background: "#246BFD" }}
                              onClick={() => recommendedActionCtaModal(recom, RA_DRAFT_EMAIL)}
                            >
                              <span className="d-none d-md-inline-block">
                                <MailIcon />
                              </span>

                              <span>Send email</span>
                              {recommendedActionCtaStatus === RA_DRAFT_EMAIL &&
                              isDataLoading?.isRecomStatusCountDataLoading ? (
                                <OvalLoader isDataLoading={true} />
                                ) : (
                                <span
                                  className="d-flex align-items-center justify-content-center bg-white rounded-circle fw-bolder"
                                  style={{
                                    padding: "2px",
                                    minWidth: "20px",
                                    minHeight: "20px",
                                    color: "#1890FF",
                                    fontSize: "12px",
                                  }}
                                >
                                  {recomCountStatus?.draft_mail}
                                </span>
                                )}
                            </button>
                          </div>
                        </div>
                      </div>
                  ))
                  : ""}
              </Slider>

              <div className="position-absolute text-white fw-bold custom-recom-slide-number">
                {currentSlide}/{totalSlides}
              </div>
            </>
          ) : (
            <div
              className="rounded-4 d-flex align-items-center justify-content-center text-white fs-4"
              style={{
                padding: "20px 10px 10px 10px",
                minHeight: "304px",
                background:
                  "linear-gradient(166deg, rgba(22, 64, 214, 0.6) -57%, rgb(34, 39, 54) 70%, rgba(237, 90, 179, 1) 318%)",
                // "linear-gradient(145deg, rgba(22,64,214,0.5984768907563025) -100%, rgba(34,39,54,1) 93%, rgba(237,90,179,0.1) 120%)",
              }}
            >
              No Recommendation Data Available
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default RecomSlider;
