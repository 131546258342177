import HelpIcon from "@components/common/HelpIcon";
import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
import { toolsPageToolTip } from "@constants/tooltip-data";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import generateLineChartOptions from "@utils/chart/line-chart";
import { http } from "@utils/http";
import { generateToolsNeutrinoOverallReportTypeData } from "@utils/transform/toolsDetails";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function NeutrinoWebLeadType() {
  const [chartOptions, setChartOptions] = useState({} as any);

  const [chartData, setChartData] = useState({} as any);

  const [isChartDataLoading, setIsChartDataLoading] = useState(false);

  useEffect(() => {
    getOverallNeutrinoReportData();
  }, []);

  function getOverallNeutrinoReportData() {
    setIsChartDataLoading(true);
    http
      .get("/tools/neutrino--overall-by-lead-type-last-12-months.json")
      .then((response: any) => {
        let apiData = response?.data?.website_engagement;
        if (apiData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
          apiData = [];
        }
        const theData: any = generateToolsNeutrinoOverallReportTypeData(
          apiData,
        );
        setChartData(theData);
        const chartFormattedOptions: any = generateLineChartOptions({
          theId: "line-chart-neutrino",
          colors: ["#F795A8", "#00FF7F", "#CCCCFF", "#FF7F50", "#21C0FC", "#A4D124", "#FFF0F5", "#99FFFF", "#DCA2DC"],
          categories: theData?.categories ?? [],
          legendHorizontalAlignment: "left",
          legendPostion: "bottom",
          legendPostionOnMobile: "bottom",
          dashArray: [0],
          // tickAmount: 6,
          ticksPreLast: ["", ""],
        });
        setChartOptions(chartFormattedOptions);
        setIsChartDataLoading(false);
      })
      .catch((error) => {
        setIsChartDataLoading(false);
        console.log(error);
      });
  }

  return (
    <>
      <div
        className="custom-modal-inner__padding h-100"
        style={{ backgroundColor: "#2A3042" }}
      >
        <div className="row mb-3">
          <div className="d-lg-flex align-items-center justify-content-between">
            <div className="d-inline-block">
              <span className="header-title-6 text-white mb-0 fw-bold pe-1">
                Neutrino Web Lead Types
              </span>
              <div className="d-inline-block align-middle">
                <HelpIcon
                  tooltip={toolsPageToolTip.toolsModalNeutrinoWebLeads}
                  width={20}
                  height={20}
                />
              </div>
            </div>
            <p className="custom-fs-12 mb-0 mt-2 mt-lg-0">Last 12 months</p>
          </div>
          <div>
            {isChartDataLoading && <div className="loader-circle"></div>}
            {isChartDataLoading ? (
              <DefaultLineChartUI />
            ) : (
              <div id="line-chart-gci" dir="ltr">
                <ReactApexChart
                  series={[
                    {
                      name: "Agent Contact Form",
                      data: chartData?.agent_contact_form_last_12_months_count ?? [],
                    },
                    {
                      name: "Area Inquiry",
                      data: chartData?.area_inquiry_last_12_months_count ?? [],
                    },
                    {
                      name: `Office Contact Form + Referral`,
                      data: chartData?.office_contact_form_referral_last_12_months_count ?? [],
                    },
                    {
                      name: `Property Inquiry`,
                      data: chartData?.property_inquiry_last_12_months_count ?? [],
                    },
                    {
                      name: `Property Inquiry + Referral`,
                      data: chartData?.property_inquiry_referral_last_12_months_count ?? [],
                    },
                    {
                      name: `Request Consultation`,
                      data: chartData?.request_con_sultation_form_last_12_months_count ?? [],
                    },
                    {
                      name: `Request Consultation + Referral`,
                      data: chartData?.request_consultation_form_referral_last_12_months_count ?? [],
                    },
                    {
                      name: `Sell Your Home (AVM)`,
                      data: chartData?.sell_your_home_avm_form_last_12_months_count ?? [],
                    },
                    {
                      name: `Sell Your Home (AVM) + Referral`,
                      data: chartData?.sell_your_home_avm_form_previous_12_months_count ?? [],
                    },
                  ]}
                  options={chartOptions}
                  type="line"
                  height={400}
                  className="apex-charts custom-legend-bg"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NeutrinoWebLeadType;
