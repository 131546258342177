import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import profile from '../../assets/images/profile-img.png';
import lightLogo from '../../assets/images/kintaro_square_black.jpg';
import { VersionedApp } from '@components/VersionedApp';

export interface AuthLayoutProps {
  children: React.ReactElement
  modalTitle: string
  pageTitle: string
}

const AuthLayout = ({ children, modalTitle, pageTitle }: AuthLayoutProps) => (
  <VersionedApp>
    <MetaTags>
      <title>
        {pageTitle}
      </title>
    </MetaTags>
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-primary bg-soft">
                <Row>
                  <Col className="col-7">
                    <div className="p-4">
                      <h5>Welcome Back!</h5>
                      <p>{modalTitle}</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img
                      src={profile}
                      alt=""
                      className="img-fluid"
                    />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div className="auth-logo">
                  <Link to="/" className="auth-logo-light">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src={lightLogo}
                          alt=""
                          className="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="p-2">
                  {children}
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                ©
                &nbsp;
                {new Date().getFullYear()}
                &nbsp;
                Kintaro
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </VersionedApp>
);

export default AuthLayout;
