import React from 'react';
import { selectTrackingUserId } from '../../../../state/appSlice';
import { useRetrieveUserQuery } from '../../../../state/api';
import { useAppSelector } from '../../../../state/hooks';

const Account = () => {
  const userId = useAppSelector(selectTrackingUserId);
  const currentUser = useRetrieveUserQuery({ id: `${userId}` });
  const currentUserData = currentUser?.data;
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="fs-3 mx-2 text-white mb-4 px-5">Settings</div>
      </div>
      <div className="mx-2">
        <div className="d-flex px-5">
          <div className="py-2 fs-5 w-25">Profile Info</div>
          <div className="d-flex flex-column p-3 w-75 test-color">
            <div className="d-flex flex-column w-100 me-5">
              <label className="d-flex flex-column gap-2 text-white" htmlFor="username">Username
                <input
                  id="username"
                  className="account"
                  type="text"
                  value={currentUserData?.first_name}
                  required
                />
              </label>
            </div>
            <div className="d-flex flex-column w-100 me-5">
              <div className="d-flex justify-content-between gap-4">
                <label className="d-flex flex-column gap-2 text-white w-50" htmlFor="first-name">First Name
                  <input
                    id="first-name"
                    className="account"
                    type="text"
                    value={currentUserData?.first_name}
                    required
                  />
                </label>
                <label className="d-flex flex-column gap-2 text-white w-50" htmlFor="last-name">Last Name
                  <input
                    id="last-name"
                    className="account"
                    type="text"
                    value={currentUserData?.last_name}
                    required
                  />
                </label>
              </div>
              <div className="d-flex justify-content-between gap-4">
                <label className="d-flex flex-column gap-2 text-white w-50" htmlFor="branch">Branch
                  <input
                    id="branch"
                    className="account"
                    type="text"
                    value="Branch"
                    required
                  />
                </label>
                <label className="d-flex flex-column gap-2 text-white w-50" htmlFor="title">Title
                  <input
                    id="title"
                    className="account"
                    type="text"
                    value={currentUserData?.title === 'null' ? '' : currentUserData?.title}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-2">
        <div className="d-flex px-5">
          <div className="py-2 fs-5 w-25">Contact Info</div>
          <div className="d-flex flex-column w-75 ">
            <div className="border-top w-100 my-4" />
            <div className="d-flex flex-column p-3 test-color">
              <div className="d-flex flex-column w-100 me-5">
                <label className="d-flex flex-column gap-2 text-white" htmlFor="street">Street
                  <input
                    id="street"
                    className="account"
                    type="text"
                    value={currentUserData?.street === 'null' ? '' : currentUserData?.street}
                    required
                  />
                </label>
              </div>
              <div className="d-flex flex-column w-100 me-5">
                <div className="d-flex justify-content-between gap-4">
                  <label className="d-flex flex-column gap-2 text-white w-50" htmlFor="city">City
                    <input
                      id="city"
                      className="account"
                      type="text"
                      value={currentUserData?.city === 'null' ? '' : currentUserData?.city}
                    />
                  </label>
                  <label className="d-flex flex-column gap-2 text-white w-50" htmlFor="state">State
                    <input
                      id="state"
                      className="account"
                      type="text"
                      value={currentUserData?.state === 'null' ? '' : currentUserData?.state}
                      required
                    />
                  </label>
                </div>
                <div className="d-flex justify-content-between gap-4">
                  <label className="d-flex flex-column gap-2 text-white w-50" htmlFor="zip">Zip
                    <input
                      id="zip"
                      className="account"
                      type="text"
                      value={currentUserData?.zip === 'null' ? '' : currentUserData?.zip}
                      required
                    />
                  </label>
                  <label className="d-flex flex-column gap-2 text-white w-50" htmlFor="work-phone">Work Phone
                    <input
                      id="work-phone"
                      className="account"
                      type="text"
                      value={currentUserData?.work_phone}
                    />
                  </label>
                </div>
              </div>
              <div className="d-flex flex-column w-100 me-5">
                <label className="d-flex flex-column gap-2 text-white" htmlFor="email">Email
                  <input
                    id="email"
                    className="account"
                    type="text"
                    value={currentUserData?.email}
                    required
                  />
                </label>
              </div>
              <div className="d-flex flex-column w-100 me-5">
                <label className="d-flex flex-column gap-2 text-white" htmlFor="bio">Bio
                  <input
                    id="bio"
                    className="account"
                    type="text"
                    value={currentUserData?.bio === 'null' ? '' : currentUserData?.bio}
                    required
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-2">
        <div className="d-flex px-5">
          <div className="py-2 fs-5 w-25">Other Info</div>
          <div className="d-flex flex-column w-75 ">
            <div className="border-top w-100 my-4" />
            <div className="d-flex flex-column p-3 test-color">
              <div className="d-flex flex-column w-100 me-5">
                <label className="d-flex flex-column gap-2 text-white" htmlFor="user-level">User Level
                  <input
                    id="user-level"
                    className="account"
                    type="text"
                    value={currentUserData?.user_level}
                  />
                </label>
              </div>
              <div className="d-flex flex-column w-100 me-5">
                <div className="d-flex justify-content-between gap-4">
                  <label className="d-flex flex-column gap-2 text-white w-50" htmlFor="current-status">Current Status
                    <input
                      id="current-status"
                      className="account"
                      type="text"
                      value={currentUserData?.current_status === 'null' ? '' : currentUserData?.current_status}
                    />
                  </label>
                  <label className="d-flex flex-column gap-2 text-white w-50" htmlFor="twitter-url">Twitter URL
                    <input
                      id="twitter-url"
                      className="account"
                      type="text"
                      value={currentUserData?.twitter_url === 'null' ? '' : currentUserData?.twitter_url}
                      required
                    />
                  </label>
                </div>
                <div className="d-flex justify-content-between gap-4">
                  <label className="d-flex flex-column gap-2 text-white w-50" htmlFor="facebook-url">Facebook URL
                    <input
                      id="facebook-url"
                      className="account"
                      type="text"
                      value={currentUserData?.facebook_url === 'null' ? '' : currentUserData?.facebook_url}
                    />
                  </label>
                  <label className="d-flex flex-column gap-2 text-white w-50" htmlFor="instagram-url">Instagram URL
                    <input
                      id="instagram-url"
                      className="account"
                      type="text"
                      value={currentUserData?.instagram_url === 'null' ? '' : currentUserData?.instagram_url}
                    />
                  </label>
                </div>
              </div>
              <div className="d-flex flex-column w-100 me-5">
                <label className="d-flex flex-column gap-2 text-white" htmlFor="linkedin-url">Linkedin URL
                  <input
                    id="linkedin-url"
                    className="account"
                    type="text"
                    value={currentUserData?.linkedin_url === 'null' ? '' : currentUserData?.linkedin_url}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-2 mb-5">
        <div className="d-flex px-5">
          <div className="py-2 fs-5 w-25">Notification Prefences</div>
          <div className="d-flex flex-column w-75 ">
            <div className="border-top w-100 my-4" />
            <div className="d-flex flex-column p-3 test-color">
              <div className="d-flex w-100 me-5 py-2">
                <table className="w-100">
                  <tr>
                    <th className="fs-3 text-white">Preferences</th>
                    <th className="fs-5 text-white text-center">Weekly Emails</th>
                    <th className="fs-5 text-white text-center">Real Time SMS</th>
                    <th className="fs-5 text-white text-center">None</th>
                  </tr>
                  <tr>
                    <td className="py-2 w-50">
                      <div className="text-white fs-5">Churn Indicators</div>
                      <div>Predictive churn indicators to reduce agent turnover</div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 w-50">
                      <div className="text-white fs-5">Training</div>
                      <div>Office, agent and tool engagement opportunities</div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 w-50">
                      <div className="text-white fs-5">Deal Activity</div>
                      <div>Gets notified when an agent has a new listing or sale</div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 w-50">
                      <div className="text-white fs-5">Technology Engagement</div>
                      <div>
                        Engagement opportunities for agents to increase engagement with technology
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 w-50">
                      <div className="text-white fs-5">Emerging Markets</div>
                      <div>Actions when new emerging markets arise in your area</div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 w-50">
                      <div className="text-white fs-5">Emerging Markets</div>
                      <div>Actions when new emerging markets arise in your area</div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="text-white fs-5">Recruitment</div>
                      <div>Emerging recruiting opportunities with agents in your area</div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div>
                        <input className="setting-notification" type="checkbox" id="front" name="scales" />
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 mb-5">
        <div className="d-flex gap-2 justify-content-end px-2">
          <button
            className="btn btn-secondary"
            type="button"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            type="button"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default Account;
