import HelpIcon from "@components/common/HelpIcon";
import TableSortIcon from "@components/common/TableSortIcon";
import { EmailIcon } from "@utils/icons";
import { colSorting } from "@utils/table-sort";
import { useEffect, useState } from "react";

function BottomUsersTable(props:any) {
  const data1: any = [
    {
      id: 1,
      agent: "Elena Schloss",
      gci: "$78,083",
      engagement_score: "0",
    },
    {
      id: 2,
      agent: "Danielle Bonner",
      gci: "$132,506",
      engagement_score: "0",
    },
    {
      id: 3,
      agent: "Siobhan Dolce",
      gci: "$586,203",
      engagement_score: "0",
    },
    {
      id: 4,
      agent: "Sonia Forchetti",
      gci: "$37,665",
      engagement_score: "0",
    },
    {
      id: 5,
      agent: "Stuart Alper",
      gci: "$99,400",
      engagement_score: "0",
    },
  ];
  const columns: any = [
    {
      name: "#",
      slug: "",
      classNames: "",
      sort: false,
    },
    {
      name: "Agent",
      slug: "agent",
      classNames: "",
      sort: true,
    },
    {
      name: "GCI",
      slug: "gci",
      classNames: "",
      sort: true,
    },
    {
      name: "Engagement Score",
      slug: "engagement_score",
      classNames: "",
      sort: true,
    },
    // {
    //   name: "",
    //   classNames: "d-none d-md-inline-block",
    //   sort: false,
    // },
  ];
  const data2: any = [
    {
      id: 1,
      agent: "Fran Klingenstein",
      gci: "$412,100",
      engagement_score: "0",
    },
    {
      id: 2,
      agent: "Fran O'Toole",
      gci: "$122,556",
      engagement_score: "0",
    },
    {
      id: 3,
      agent: "Gail Feeney",
      gci: "$236,770",
      engagement_score: "0",
    },
    {
      id: 4,
      agent: "Gayle Bronstein",
      gci: "$197,363",
      engagement_score: "0",
    },
    {
      id: 5,
      agent: "Gina Buttacavoli",
      gci: "$18,575",
      engagement_score: "0",
    },
  ];
  const columns2: any = [
    {
      name: "#",
      slug: "",
      classNames: "",
      sort: false,
    },
    {
      name: "Agent",
      slug: "agent",
      classNames: "",
      sort: true,
    },
    {
      name: "GCI",
      slug: "gci",
      classNames: "",
      sort: true,
    },
    {
      name: "Engagement Score",
      slug: "engagement_score",
      classNames: "",
      sort: true,
    },
    // {
    //   name: "",
    //   classNames: "d-none d-md-inline-block",
    //   sort: false,
    // },
  ];
  const [offices, setOffices] = useState(data1 && data1 as any);
  const [sortColumns, setSortColumns] = useState([] as any);

  useEffect(() => {
    if (props?.cardId === 1) {
      setOffices(data1);
    } else {
      setOffices(data2);
    }
  }, [props?.cardId]);
  
  // Define a function to handle column sorting
  const handleSort = (column: any) => {
    const sortingOutput: any = colSorting(offices, sortColumns, column);
    setSortColumns(sortingOutput.columns);
    setOffices(sortingOutput.data);
  };

  const renderTableActionButton = (data: any) => (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm d-flex align-items-center justify-content-center gap-1"
      >
        <EmailIcon/>
        <span className="ps-1 d-none d-md-block">Email</span>
      </button>
    </>
  );

  return (
    <div className="">
      <div className="custom-modal-inner__padding" style={{ background: "#2B3041" }}>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex align-items-center gap-1">
            <div className="header-title">Bottom Users</div>
            <HelpIcon
              tooltip="Top Users"
            />
          </div>
          <p className="mb-0">Last Month</p>
        </div>
        <div className="table-responsive">
          <table className="align-middle table-nowrap table-check table table-striped table-sm">
            <thead className="table-light">
              <tr role="row">
                {columns.map((column: any) => (
                  <th
                    key={column.slug}
                    onClick={() => {
                      if (column.sort) {
                        handleSort(column.slug);
                      }
                    }}
                    role="button"
                  >
                    <div className="d-flex">
                      <TableSortIcon
                        sortColumns={sortColumns}
                        column={column}
                      ></TableSortIcon>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {offices?.map((office: any, id: any) => (
                <tr key={office.id}>
                  <td>{office.id}</td>
                  <td>
                    <div className="d-flex justify-content-between gap-1">
                      <div className="d-flex gap-1 align-items-center">
                        {/* <img src={profileImg} alt="profile"/> */}
                        <span>{office.agent}</span>
                      </div>
                      {/* <span className="d-flex d-md-none justify-content-end">
                        {renderTableActionButton(office)}
                      </span> */}
                    </div>
                  </td>
                  <td className="text-start">{office.gci}</td>
                  <td className="text-start">{office.engagement_score}</td>
                  {/* <td className="d-none d-md-block">
                    {renderTableActionButton(office)}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default BottomUsersTable;
