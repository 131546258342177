import React, { useEffect, useState } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import '../assets/scss/theme.scss';
import { Switch } from 'react-router-dom';
import { privateRoutes, publicRoutes, superuserRoutes } from './routes';
import AppRoute from './AppRoute';
import PublicLayout from './layout/PublicLayout';
import Layout from './layout/Layout';
import {
  selectAuth,
  setAuth,
  setLoggedInAuth,
  selectError,
} from '../state/appSlice';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { cookies, CookieType } from '../state/cookies';
import { history } from '../state/store';
import Error500 from './pages/Error500/Error500';
import tracking, { getTrackingData, TrackingActions } from '../state/tracking';

function App() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const authCookie = cookies.get(CookieType.AUTH.valueOf());
  const loggedInAuthCookie = cookies.get(CookieType.LOGGED_IN_AUTH.valueOf());
  const error = useAppSelector(selectError);
  const [authLoaded, setAuthLoaded] = useState(false);

  useEffect(() => {
    if (auth === undefined && authCookie !== undefined && loggedInAuthCookie !== undefined) {
      dispatch(setAuth(authCookie));
      dispatch(setLoggedInAuth(loggedInAuthCookie));
      tracking.track(
        TrackingActions.AUTHENTICATED.valueOf(),
        getTrackingData(loggedInAuthCookie.user_id),
      );
    }
    setAuthLoaded(true);
    // Run this once on page load.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!authLoaded) {
    return <div />;
  }

  if (error) {
    return <Error500 />;
  }

  return (
    <ConnectedRouter history={history}>
      <Switch>
        {publicRoutes.map((route) => (
          <AppRoute
            path={route.path}
            Layout={PublicLayout}
            Component={route.component}
            key={route.path}
            isAuthProtected={false}
            isSuperuserProtected={false}
            exact
          />
        ))}

        {privateRoutes.map((route) => (
          <AppRoute
            path={route.path}
            Layout={Layout}
            Component={route.component}
            key={route.path}
            isAuthProtected
            isSuperuserProtected={false}
            exact
          />
        ))}

        {superuserRoutes.map((route) => (
          <AppRoute
            path={route.path}
            Layout={Layout}
            Component={route.component}
            key={route.path}
            isAuthProtected
            isSuperuserProtected
            exact
          />
        ))}
      </Switch>
    </ConnectedRouter>
  );
}

export default App;
