import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

interface CardLoaderProps {
  count: number;
  loading: boolean;
}
function CardLoader({ count = 3, loading }: CardLoaderProps) {
  return (
    (loading && (
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <p>
          <Skeleton count={count} />
        </p>
      </SkeletonTheme>
    )) ||
    ""
  );
}

export default CardLoader;
