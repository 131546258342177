import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Card, CardBody, Table } from 'reactstrap';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import defaultAvatarSvg from '../../../../assets/images/default-avatar.svg';
import starTransparentSvg from '../../../../assets/images/starTransparent.svg';
import Tooltip from '../../../common/Tooltip';
import { useAppSelector } from '../../../../state/hooks';
import { selectTrackingUserId } from '../../../../state/appSlice';
import tracking, { getTrackingData, TrackingActions } from '../../../../state/tracking';
import { gray300 } from '../../../../assets/colors';
import HelpIcon from '../../../common/HelpIcon';

const styles = createUseStyles({
  ListingDataTable_clickableTableHeader_mobile: {
    cursor: 'pointer',
    '&:hover': {
      'color': 'rgb(239, 242, 247)',
    },
    'paddingTop': '20px',
  },
  ListingDataTable_clickableTableHeader: {
    cursor: 'pointer',
    '&:hover': {
      'color': 'rgb(239, 242, 247)',
    },
  },
  ListingDataTable_row: {
    'transition': '.25s',
    '&:hover': {
      'color': 'white',
      'background-color': gray300,
    },
  },
});

enum OrderingBy {
  AVERAGE_LISTING = 'average_listing',
  BUY_SIDES = 'buy_sides',
  DAYS_ON_MARKET = 'days_on_market',
  GCI = 'gci',
  NAME = 'name',
  SELL_SIDES = 'sell_sides',
  UNDER_CONTRACT = 'under_contract',
  TOTAL_ACTIVE = 'total_active',
}

const FavoritedAgents = () => {
  const classes = styles();
  const isMobile = window.screen.width <= 998;
  const [ordering, setOrdering] = useState(OrderingBy.GCI.valueOf());
  const [orderingAscending, setOrderingAscending] = useState(false);
  const userId = useAppSelector(selectTrackingUserId);

  let orderIcon;
  let orderByNameElement;
  if (orderingAscending) {
    orderIcon = <KeyboardArrowUpIcon />;
  } else {
    orderIcon = <KeyboardArrowDownIcon />;
  }
  if (ordering === OrderingBy.NAME.valueOf()) {
    orderByNameElement = orderIcon;
  }
  const handleOrderByGeneric = (orderingBy: OrderingBy) => () => {
    tracking.track(
      TrackingActions.OFFICES_LISTING_TABLE_ORDERED.valueOf(),
      getTrackingData(userId!, {
        order_by: orderingBy.valueOf(),
        order_ascending: orderingAscending.valueOf(),
      }),
    );
    if (ordering === orderingBy.valueOf()) {
      setOrderingAscending(!orderingAscending);
    } else {
      setOrdering(orderingBy.valueOf());
      setOrderingAscending(false);
    }
  };

  const handleOrderByGci = handleOrderByGeneric(OrderingBy.GCI);
  const handleOrderByName = handleOrderByGeneric(OrderingBy.NAME);

  const agentBreakdown = [
    {
      id: 1,
      agent: 'David McHenry',
      contacts: '500',
      loginPerMo: '0',
      sendsPerMo: '0',
      openRate: '0%',
      CTR: <img src={starTransparentSvg} alt="" />,
    },
    {
      id: 2,
      agent: 'Darlene Robertson',
      contacts: '300',
      loginPerMo: '2',
      sendsPerMo: '2',
      openRate: '2%',
      CTR: <img src={starTransparentSvg} alt="" />,
    },
    {
      id: 3,
      agent: 'Cody Fisher',
      contacts: '20000',
      loginPerMo: '10',
      sendsPerMo: '10',
      openRate: '10%',
      CTR: <img src={starTransparentSvg} alt="" />,
    },
    {
      id: 4,
      agent: 'Ralph Edwards',
      contacts: '100',
      loginPerMo: '100',
      sendsPerMo: '100',
      openRate: '100%',
      CTR: <img src={starTransparentSvg} alt="" />,
    },
    {
      id: 5,
      agent: 'Jacob Jones',
      contacts: '0',
      loginPerMo: '55',
      sendsPerMo: '55',
      openRate: '55%',
      CTR: <img src={starTransparentSvg} alt="" />,
    },
  ];
  const rows = agentBreakdown.map((it) => (
    <tr key={it.id} className={classes.ListingDataTable_row}>
      <td className="text-dark font-weight-semibold d-flex gap-2">
        <img className="rounded-circle mt-1" src={defaultAvatarSvg} alt="" height="32" />
        <div>{it.agent}</div>
      </td>
      <td className="pt-4">{it.contacts}</td>
      <td className="pt-4">{it.loginPerMo}</td>
      <td>
        <div className="d-flex gap-4 justify-content-between mt-2">
          <div className="pt-1">{it.openRate}</div>
          <button type="button" className="btn btn-primary btn-sm">Email</button>
        </div>
      </td>
    </tr>
  ));
  const rowsMobile1 = (
    <>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6 pt-4">Agent</td>
        <td className="text-muted h6">
          <div className="text-dark font-weight-semibold d-flex gap-2 align-items-center">
            <img className="rounded-circle mt-1" src={defaultAvatarSvg} alt="" height="32" />
            <div className="text-muted fs-6 pt-1">{agentBreakdown[0].agent}</div>
          </div>
        </td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Brokerage</td>
        <td className="text-muted h6">{agentBreakdown[0].contacts}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Date</td>
        <td className="text-muted h6">{agentBreakdown[0].loginPerMo}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Event</td>
        <td className="text-muted h6">{agentBreakdown[0].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">{' '}</td>
        <td className="text-muted h6">
          <button type="button" className="btn btn-primary btn-sm">Email</button>
        </td>
      </tr>
    </>
  );
  const rowsMobile2 = (
    <>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6 pt-4">Agent</td>
        <td className="text-muted h6">
          <div className="text-dark font-weight-semibold d-flex gap-2 align-items-center">
            <img className="rounded-circle mt-1" src={defaultAvatarSvg} alt="" height="32" />
            <div className="text-muted fs-6 pt-1">{agentBreakdown[1].agent}</div>
          </div>
        </td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Brokerage</td>
        <td className="text-muted h6">{agentBreakdown[1].contacts}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Date</td>
        <td className="text-muted h6">{agentBreakdown[1].loginPerMo}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Event</td>
        <td className="text-muted h6">{agentBreakdown[1].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">{' '}</td>
        <td className="text-muted h6">
          <button type="button" className="btn btn-primary btn-sm">Email</button>
        </td>
      </tr>
    </>
  );
  const rowsMobile3 = (
    <>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6 pt-4">Agent</td>
        <td className="text-muted h6">
          <div className="text-dark font-weight-semibold d-flex gap-2 align-items-center">
            <img className="rounded-circle mt-1" src={defaultAvatarSvg} alt="" height="32" />
            <div className="text-muted fs-6 pt-1">{agentBreakdown[2].agent}</div>
          </div>
        </td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Brokerage</td>
        <td className="text-muted h6">{agentBreakdown[2].contacts}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Date</td>
        <td className="text-muted h6">{agentBreakdown[2].loginPerMo}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Event</td>
        <td className="text-muted h6">{agentBreakdown[2].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">{' '}</td>
        <td className="text-muted h6">
          <button type="button" className="btn btn-primary btn-sm">Email</button>
        </td>
      </tr>
    </>
  );
  const rowsMobile4 = (
    <>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6 pt-4">Agent</td>
        <td className="text-muted h6">
          <div className="text-dark font-weight-semibold d-flex gap-2 align-items-center">
            <img className="rounded-circle mt-1" src={defaultAvatarSvg} alt="" height="32" />
            <div className="text-muted fs-6 pt-1">{agentBreakdown[3].agent}</div>
          </div>
        </td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Brokerage</td>
        <td className="text-muted h6">{agentBreakdown[3].contacts}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Date</td>
        <td className="text-muted h6">{agentBreakdown[3].loginPerMo}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Event</td>
        <td className="text-muted h6">{agentBreakdown[3].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">{' '}</td>
        <td className="text-muted h6">
          <button type="button" className="btn btn-primary btn-sm">Email</button>
        </td>
      </tr>
    </>
  );
  const rowsMobile5 = (
    <>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6 pt-4">Agent</td>
        <td className="text-muted h6">
          <div className="text-dark font-weight-semibold d-flex gap-2 align-items-center">
            <img className="rounded-circle mt-1" src={defaultAvatarSvg} alt="" height="32" />
            <div className="text-muted fs-6 pt-1">{agentBreakdown[4].agent}</div>
          </div>
        </td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Brokerage</td>
        <td className="text-muted h6">{agentBreakdown[4].contacts}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Date</td>
        <td className="text-muted h6">{agentBreakdown[4].loginPerMo}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Event</td>
        <td className="text-muted h6">{agentBreakdown[4].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">{' '}</td>
        <td className="text-muted h6">
          <button type="button" className="btn btn-primary btn-sm">Email</button>
        </td>
      </tr>
    </>
  );
  return (
    <>
      <Tooltip />
      <Card className="table-responsive">
        <CardBody className="p-0">
          <div className="d-flex mb-4 justify-content-between my-2">
            <div className="h4 fs-2 card-title lh-lg">Favorited Agents
              <HelpIcon tooltip="Based on the table above, the favorited agents table helps the
                brokerage staff identify and reach out to agents based on their recent transaction
                activity. "
              />
            </div>
          </div>
          {isMobile ? (
            <div className={classes.ListingDataTable_clickableTableHeader_mobile}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex justify-content-between w-75 align-items-center">
                    <Typography>{agentBreakdown[0].agent}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Table className="bg-transparent">
                      <thead />
                      <tbody>
                        {rowsMobile1}
                      </tbody>
                    </Table>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex justify-content-between w-75 align-items-center">
                    <Typography>{agentBreakdown[1].agent}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Table className="bg-transparent">
                      <thead />
                      <tbody>
                        {rowsMobile2}
                      </tbody>
                    </Table>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex justify-content-between w-75 align-items-center">
                    <Typography>{agentBreakdown[2].agent}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Table className="bg-transparent">
                      <thead />
                      <tbody>
                        {rowsMobile3}
                      </tbody>
                    </Table>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex justify-content-between w-75 align-items-center">
                    <Typography>{agentBreakdown[3].agent}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Table className="bg-transparent">
                      <thead />
                      <tbody>
                        {rowsMobile4}
                      </tbody>
                    </Table>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex justify-content-between w-75 align-items-center">
                    <Typography>{agentBreakdown[4].agent}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Table className="bg-transparent">
                      <thead />
                      <tbody>
                        {rowsMobile5}
                      </tbody>
                    </Table>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          ) : (
            <Table>
              <thead>
                <tr className="font-weight-semibold bg-light">
                  <th
                    className={classes.ListingDataTable_clickableTableHeader}
                    onClick={handleOrderByName}
                    role="button"
                  >
                    {orderByNameElement} Agent
                  </th>
                  <th
                    className={classes.ListingDataTable_clickableTableHeader}
                    onClick={handleOrderByGci}
                    role="button"
                  >
                    {orderByNameElement} Brokerage
                  </th>
                  <th
                    className={classes.ListingDataTable_clickableTableHeader}
                    onClick={handleOrderByGci}
                    role="button"
                  >
                    {orderByNameElement} Date
                  </th>
                  <th
                    className={classes.ListingDataTable_clickableTableHeader}
                    onClick={handleOrderByGci}
                    role="button"
                  >
                    {orderByNameElement} Event
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default FavoritedAgents;
