import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { createUseStyles } from 'react-jss';

export interface HelpIconProps {
  tooltip: string
}

const styles = createUseStyles({
  HelpIcon: {
    'font-size': '16px',
    'margin': '-.5em 0 0 .25em',
  },
});

const HelpIcon = ({ tooltip }: HelpIconProps) => {
  const classes = styles();
  return (
    <>
      <HelpOutlineIcon className={classes.HelpIcon} data-tip={tooltip} />
    </>
  );
};

export default HelpIcon;
