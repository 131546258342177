function CustomCircleSpinner({ loading }: any) {
  return (
    loading && (
      <div
        className="spinner-border text-white"
        style={{ height: "15px", width: "15px" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    )
  );
}

export default CustomCircleSpinner;
