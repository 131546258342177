import PermissionCheck from "@components/common/PermissionCheck";
import SearchSelect from "@components/common/SearchSelect";
import ComparativeFinancial from "@components/office/details/ComparativeFinancial";
import ForeCastedRevenue from "@components/office/details/ForecastedRevenue";
import { officePerms } from "@constants/permission";
import { officeModalTooltip } from "@constants/tooltip-data";
import { faker } from "@faker-js/faker";
import { actionUserPermissions, selectUser } from "@state/appSlice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { isDemo } from "@utils/fakers-data";
import { http } from "@utils/http";
import { ProfileIcon } from "@utils/icons";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import OfficeAgentRetentionInfo from "./details/OfficeAgentRetentionInfo";
import OfficeEngagementScore from "./details/OfficeEngagementScore";
import OfficeProfileInfo from "./details/OfficeProfileInfo";
import OfficeToolTechStats from "./details/OfficeToolTechStats";
import OfficeYearlyTechEngagement from "./details/OfficeYearlyTechEngagement";
import RecomendedAction from "./details/RecomendedAction";
import YtdOfficeCard from "./details/YtdOfficeCard";
import YtdOfficeListVsSellSide from "./details/YtdOfficeListVsSellSide";

function DetailsOfficeModal(props: any) {
  const dispatch = useAppDispatch();
  const selectedUser: any = useAppSelector(selectUser);
  const [isPermissionDataLoading, setIsPermissionDataLoading] = useState(true);
  // All state.
  const [openModal, setOpenModal] = useState(false);
  const [modalName] = useState("detailsOfficeModal");
  const [officeId, setOfficeId] = useState("" as any);
  const [office, setOffice] = useState({} as any);
  const [allOfficeData, setAllOfficeData] = useState([] as any);
  const [selectedOfficeData, setSelectedOfficeData] = useState({} as any);
  const [officeYTDReport, setOfficeYTDReport] = useState({} as any);
  const [officeAgentGainedData, setOfficeAgentGainedData] = useState({} as any);
  const [officeAgentLostData, setOfficeAgentLostData] = useState({} as any);
  const [isLoadingOffice, setIsLoadingOffice] = useState(true);
  const [isAllOfficeDataLoading, setIsAllOfficeDataLoading] = useState(true);
  const [isLoadingOfficeYTDReport, setIsLoadingOfficeYTDReport] =
    useState(true);
  const [isDataLoading, setIsDataLoading] = useState({
    isAgentGainedDataLoading: true,
    isAgentLostDataLoading: true,
  } as any);
  const DUMMY_DATA: any = [
    {
      id: 1,
      title: "Transaction Management",
      stats: "100",
    },
    {
      id: 2,
      title: "CMA",
      stats: "55",
    },
    {
      id: 3,
      title: "Print Marketing",
      stats: "72",
    },
    {
      id: 4,
      title: "Email Marketing",
      stats: "54",
    },
    {
      id: 5,
      title: "CRM",
      stats: "37",
    },
  ];

  useEffect(() => {
    if (selectedUser?.id) {
      setIsPermissionDataLoading(true);
      // TODO: here call user permission api
      http
        .get(
          `user-permission-site-data.json`,
        )
        .then((response: any) => {
          const { permission } = response?.data;
          const getUserPerms = response?.data?.permission[0]?.permissions;
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: getUserPerms ?? [] }));
          setIsPermissionDataLoading(false);
        })
        .catch((error) => {
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: [] }));
          setIsPermissionDataLoading(false);
          console.log(error);
        });
    }
  }, [selectedUser]);

  useEffect(() => {
    if (props.modalName === modalName) {
      setOfficeId(props.officeId);
      setOpenModal(props.modalStatus);
    }
  }, [setOpenModal, props.modalStatus]);

  // Added an event listener to handle 'Escape' key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && openModal) {
        modalClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openModal]);

  useEffect(() => {
    getAllOfficeInfo();
    if (officeId !== "") {
      getOfficeInfo();
      getOfficeYtdReport();
      getOffficeAgentGained();
      getOffficeAgentLost();
    }
  }, [officeId]);

  function modalClose() {
    setOpenModal(false);
    setTimeout(() => {
      props.modalClose({
        modalName,
      });
    }, 1000);
  }

  function getOffficeAgentGained() {
    setIsDataLoading((prev: any) => ({
      ...prev,
      isAgentGainedDataLoading: true,
    }));
    http
      .get("/office/agent-gained-percentage-diff.json")
      .then((response: any) => {
        setOfficeAgentGainedData(response.data?.gained_diff);
        setIsDataLoading((prev: any) => ({
          ...prev,
          isAgentGainedDataLoading: false,
        }));
      });
  }

  function getOffficeAgentLost() {
    setIsDataLoading((prev: any) => ({
      ...prev,
      isAgentLostDataLoading: true,
    }));
    http
      .get("/office/agent-lost-percentage-diff.json")
      .then((response: any) => {
        setOfficeAgentLostData(response.data?.lost_diff);
        setIsDataLoading((prev: any) => ({
          ...prev,
          isAgentLostDataLoading: false,
        }));
      });
  }

  function getRandomColor(stats: any) {
    let color: any = "";
    switch (true) {
      case stats >= 40 && stats <= 50:
        color = "#F2CA6B";
        break;
      case stats > 50 && stats <= 70:
        color = "#F2CA6B";
        break;
      case stats > 70:
        color = "#CF4AFE";
        break;
      default:
        color = "#F5822F";
        break;
    }

    return color;
  }

  function getOfficeInfo() {
    setIsLoadingOffice(true);
    http
      .get("/office/single-office-info.json")
      .then((response: any) => {
        let theData = response.data?.office_info;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          const managerFakeName = faker.person.fullName();
          theData = {
            ...theData,
            office_name: faker.company.name(),
            office_phone: faker.phone.number('(###) ###-####'),
            office_address: `${faker.location.city()}, ${faker.location.state()}`,
            office_manager: managerFakeName,
            manager_email: faker.internet.email({ firstName: managerFakeName, lastName: "" }),
          };
        }
        setOffice(theData);
        setIsLoadingOffice(false);
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoadingOffice(false);
      });
  }

  function getAllOfficeInfo() {
    setIsAllOfficeDataLoading(true);
    http
      .get("/office/office-info.json")
      .then((response: any) => {
        const officeAllData = response.data?.office_info;
        setAllOfficeData(officeAllData);
        if (officeId) {
          setSelectedOfficeData(
            officeAllData?.find((data: any) => data?.office_id === officeId),
          );
        }
        setIsAllOfficeDataLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setIsAllOfficeDataLoading(false);
      });
  }

  function getOfficeYtdReport() {
    setIsLoadingOfficeYTDReport(true);
    http
      .get("/office/ytd.json")
      .then((response: any) => {
        setOfficeYTDReport(response.data?.ytd);
        setIsLoadingOfficeYTDReport(false);
      });
  }

  function getResponsiveProfileIcon() {
    if (window.innerWidth <= 768) {
      return <ProfileIcon width="40" height="40" />;
    } else {
      return <ProfileIcon />;
    }
  }

  return (
    <Modal
      size="xl"
      isOpen={openModal}
      centered={true}
      
    >
      <div className="modal-content" style={{ background: "#222736" }}>
        <div className="container mb-4">
          {/* <p className="custom-modal-title mt-2"></p> */}
          <button
            type="button"
            onClick={modalClose}
            className="btn-close position-absolute custom-top-2 custom-right-4 mb-4"
          ></button>
        </div>
        <ModalBody className="custom-modal-outer__padding">
          <div className="row mb-3">
            <div className="col-6">
              <div className="header-title">Office</div>
            </div>
            {
              isDemo() ? "" : (
              <div className="col-6 d-flex justify-content-end">
                <SearchSelect
                  options={allOfficeData ?? []}
                  value={
                    JSON.stringify(allOfficeData) === "{}"
                      ? "Select Office"
                      : selectedOfficeData
                  }
                  onChange={(option: any) => {
                    setSelectedOfficeData(option);
                    setOfficeId(option?.office_id);
                  }}
                  width={200}
                  getOptionValue={(option: any) => option.office_id}
                  getOptionLabel={(option: any) => `${option.office_name ?? "Select Office"}`}
                  placeholder="Select Office"
                />
              </div>
              )
            }
          </div>

          <div className="row mb-3 mb-lg-4">
            <PermissionCheck permission={`${officePerms.office_modal_info}`}>
              <div className="col-6 col-lg-4 mb-1 mb-lg-2 order-1 pe-0 pe-lg-1">
                {/* <PermissionCheck permission={`${officePerms.office_modal_info}`}> */}
                <OfficeProfileInfo
                  officeInfo={office}
                  isDataLoading={isLoadingOffice}
                />
                {/* </PermissionCheck> */}
              </div>
            </PermissionCheck>
            <PermissionCheck
              permission={`${officePerms.office_modal_sales_volume}`}
            >
              <div className="col-12 col-lg-4 mb-1 mb-lg-2 order-3 ps-lg-1 pe-lg-1 order-lg-2">
                <div
                  className="h-100 d-grid align-items-center"
                  style={{ backgroundColor: "#2A3042" }}
                >
                  <div className="custom-modal-inner__padding">
                    <YtdOfficeCard
                      cardTitle="Sales Volume"
                      isDataLoading={isLoadingOfficeYTDReport}
                      tooltipText={officeModalTooltip.ytdSalesVolume}
                      cardSubtitile="YTD"
                      isAmountDollar={true}
                      amount={officeYTDReport?.ytd_sales_volume}
                      percentText="From Previous Period"
                      percentValue={officeYTDReport?.percent_ytd_sales_volume}
                    />
                    {/* <div className="d-inline-block">
                    <span className="header-title-4 text-white mb-0 pe-1">
                      Sales Volume
                    </span>
                    <div className="d-inline-block align-middle">
                      <HelpIcon tooltip={officeModalTooltip.ytdSalesVolume} width={20} height={20} />
                    </div>
                  </div>
                  <p className="custom-fs-12-to-14 mb-0">Year to Date</p>
                  <p className="text-center number-stats-4 text-white mb-0 ">
                    $
                    <Placeholder
                      loading={isLoadingOfficeYTDReport}
                      className="w-8 h-8"
                    ></Placeholder>
                    {!isLoadingOfficeYTDReport &&
                      formatNumberWithCommas(officeYTDReport?.ytd_sales_volume)}
                  </p>
                  <p className="text-center custom-fs-12-to-14 ">
                  <Placeholder
                    loading={isLoadingOfficeYTDReport}
                    className="custom-w-12 custom-loader-h-6"
                  ></Placeholder>
                    {
                      !isLoadingOfficeYTDReport && (
                        officeYTDReport?.percent_ytd_sales_volume > 0 ? (
                          <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                            +{" "}
                            {formatDecimalPlaces(
                              officeYTDReport?.percent_ytd_sales_volume,
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                            {formatDecimalPlaces(
                              officeYTDReport?.percent_ytd_sales_volume,
                            )}
                            %
                          </span>
                        )
                      )
                    }
                    <span>From Last Year</span>
                  </p> */}
                  </div>
                </div>
              </div>
            </PermissionCheck>
            <PermissionCheck
              permission={`${officePerms.office_modal_company_dollar}`}
            >
              <div className="col-12 col-lg-4 mb-1 mb-lg-2 order-4 ps-lg-1 order-lg-3">
                <div
                  className="h-100 d-grid align-items-center"
                  style={{ backgroundColor: "#2A3042" }}
                >
                  <div className="custom-modal-inner__padding">
                    <YtdOfficeCard
                      cardTitle="Company Dollar"
                      isDataLoading={isLoadingOfficeYTDReport}
                      tooltipText={officeModalTooltip.ytdCompanyDollar}
                      cardSubtitile="YTD"
                      isAmountDollar={true}
                      amount={officeYTDReport?.ytd_company_dollar}
                      percentText="From Previous Period"
                      percentValue={officeYTDReport?.percent_ytd_company_dollar}
                    />
                    {/* <div className="d-inline-block">
                    <span className="header-title-4 text-white mb-0 pe-1">
                      Company Dollar
                    </span>
                    <div className="d-inline-block align-middle">
                      <HelpIcon
                        tooltip={officeModalTooltip.ytdCompanyDollar}
                        width={20}
                        height={20}
                      />
                    </div>
                  </div>
                  <p className="custom-fs-12-to-14 mb-0">Year to Date</p>
                  <p className="text-center number-stats-4 text-white mb-0 ">
                    $
                    <Placeholder
                      loading={isLoadingOfficeYTDReport}
                      className="w-8 h-8"
                    ></Placeholder>
                    {!isLoadingOfficeYTDReport &&
                      formatNumberWithCommas(
                        officeYTDReport?.ytd_company_dollar,
                      )}
                  </p>
                  <p className="text-center custom-fs-12-to-14 ">
                  <Placeholder
                    loading={isLoadingOfficeYTDReport}
                    className="custom-w-12 custom-loader-h-6"
                  ></Placeholder>
                    {
                      !isLoadingOfficeYTDReport && (
                        officeYTDReport?.percent_ytd_company_dollar > 0 ? (
                          <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                            +{" "}
                            {formatDecimalPlaces(
                              officeYTDReport?.percent_ytd_company_dollar,
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                            {formatDecimalPlaces(
                              officeYTDReport?.percent_ytd_company_dollar,
                            )}
                            %
                          </span>
                        )
                      )
                    }
                    <span>From Last Year</span>
                  </p> */}
                  </div>
                </div>
              </div>
            </PermissionCheck>
            <PermissionCheck
              permission={`${officePerms.office_modal_agent_retention}`}
            >
              <div className="col-6 col-lg-4 mb-1 mb-lg-2 order-2 order-lg-4 custom-ps-1-to-3 pe-lg-1">
                <OfficeAgentRetentionInfo
                  officeAgentgainedInfo={officeAgentGainedData}
                  officeAgentLostInfo={officeAgentLostData}
                  isDataLoading={isDataLoading}
                />
              </div>
            </PermissionCheck>
            <PermissionCheck
              permission={`${officePerms.office_modal_sell_list_sides}`}
            >
              <div className="col-12 col-lg-4 mb-1 mb-lg-2 order-5 ps-lg-1 pe-lg-1">
                <div className="" style={{ backgroundColor: "#2A3042" }}>
                  <div className="custom-modal-inner__padding">
                    <YtdOfficeListVsSellSide
                      isDataLoading={isLoadingOfficeYTDReport}
                      officeYTDReportData={officeYTDReport}
                    />
                  </div>
                  {/* <div className="custom-modal-inner__padding">
                  <div className="d-inline-block">
                    <span className="header-title-4 text-white mb-0 pe-1">
                      Sell Sides
                    </span>
                    <div className="d-inline-block align-middle">
                      <HelpIcon tooltip={officeModalTooltip.ytdSellSides} width={20} height={20} />
                    </div>
                  </div>
                  <p className="mb-0 custom-fs-12-to-14">Year to Date</p>
                  <p className="text-center number-stats-4 text-white mb-1">
                    <Placeholder
                      loading={isLoadingOfficeYTDReport}
                      className="w-8 h-8"
                    ></Placeholder>
                    {!isLoadingOfficeYTDReport
                      ? officeYTDReport?.ytd_sell_sides ?? 0
                      : ""}
                  </p>
                  <p className="text-center custom-fs-12-to-14">
                  <Placeholder
                    loading={isLoadingOfficeYTDReport}
                    className="custom-w-12 custom-loader-h-6"
                  ></Placeholder>
                    {
                      !isLoadingOfficeYTDReport && (
                        officeYTDReport?.percent_sell_sides > 0 ? (
                          <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                            +{" "}
                            {formatDecimalPlaces(
                              officeYTDReport?.percent_sell_sides,
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                            {formatDecimalPlaces(
                              officeYTDReport?.percent_sell_sides,
                            )}
                            %
                          </span>
                        )
                      )
                    }
                    <span>From Last Year</span>
                  </p>
                </div>

                <div className="custom-modal-inner__padding">
                  <div className="d-inline-block">
                    <span className="header-title-4 text-white mb-0 pe-1">
                      List Sides
                    </span>
                    <div className="d-inline-block align-middle">
                      <HelpIcon tooltip={officeModalTooltip.ytdListSides} width={20} height={20} />
                    </div>
                  </div>
                  <p className="mb-0 custom-fs-12-to-14">Year to Date</p>
                  <p className="text-center number-stats-4 text-white mb-1">
                    <Placeholder
                      loading={isLoadingOfficeYTDReport}
                      className="w-8 h-8"
                    ></Placeholder>
                    {!isLoadingOfficeYTDReport
                      ? officeYTDReport?.ytd_list_sides ?? 0
                      : ""}
                  </p>
                  <p className="text-center custom-fs-12-to-14 ">
                  <Placeholder
                    loading={isLoadingOfficeYTDReport}
                    className="custom-w-12 custom-loader-h-6"
                  ></Placeholder>
                    {
                      !isLoadingOfficeYTDReport && (
                        officeYTDReport?.percent_list_sides > 0 ? (
                          <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                            +{" "}
                            {formatDecimalPlaces(
                              officeYTDReport?.percent_list_sides,
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                            {formatDecimalPlaces(
                              officeYTDReport?.percent_list_sides,
                            )}
                            %
                          </span>
                        )
                      )
                    }
                    <span>From Last Year</span>
                  </p>
                </div> */}
                </div>
              </div>
            </PermissionCheck>

            <div className="col-12 col-lg-4 mb-lg-2 custom-order-6 ps-lg-1">
              <div className="row h-100">
                <PermissionCheck permission={`${officePerms.office_modal_gci}`}>
                  <div className="col-12 d-grid align-items-center mb-1 mb-lg-2 ">
                    <div
                      className="h-100 d-grid align-items-center"
                      style={{ backgroundColor: "#2A3042" }}
                    >
                      <div className="custom-modal-inner__padding">
                        <YtdOfficeCard
                          cardTitle="GCI"
                          isDataLoading={isLoadingOfficeYTDReport}
                          tooltipText={officeModalTooltip.ytdGci}
                          cardSubtitile="YTD"
                          isAmountDollar={true}
                          amount={officeYTDReport?.ytd_gci}
                          percentText="From Previous Period"
                          percentValue={officeYTDReport?.precent_gci}
                        />
                      </div>
                    </div>
                  </div>
                </PermissionCheck>
                <PermissionCheck
                  permission={`${officePerms.office_modal_pending_gci}`}
                >
                  <div className="col-12 d-grid align-items-center">
                    <div
                      className="h-100 d-grid align-items-center"
                      style={{ backgroundColor: "#2A3042" }}
                    >
                      <div className="custom-modal-inner__padding">
                        <YtdOfficeCard
                          cardTitle="Pending GCI"
                          isDataLoading={isLoadingOfficeYTDReport}
                          tooltipText={officeModalTooltip.ytdPendingGci}
                          cardSubtitile="YTD"
                          isAmountDollar={true}
                          amount={officeYTDReport?.ytd_gci_under_contract}
                          percentText="From Previous Period"
                          percentValue={
                            officeYTDReport?.precent_gci_under_contract
                          }
                        />
                      </div>
                    </div>
                  </div>
                </PermissionCheck>
              </div>
              {/* <div className="" style={{ backgroundColor: "#2A3042" }}>
                <div className="custom-modal-inner__padding">
                  <div className="d-inline-block">
                    <span className="header-title-4 text-white mb-0 pe-1">
                      GCI
                    </span>
                    <div className="d-inline-block align-middle">
                      <HelpIcon tooltip={officeModalTooltip.ytdGci} width={20} height={20} />
                    </div>
                  </div>
                  <p className="mb-0 custom-fs-12-to-14">Year to Date</p>
                  <p className="text-center number-stats-4 text-white mb-1">
                    $
                    <Placeholder
                      loading={isLoadingOfficeYTDReport}
                      className="w-8 h-8"
                    ></Placeholder>
                    {!isLoadingOfficeYTDReport &&
                      formatNumberWithCommas(officeYTDReport?.ytd_gci)}
                  </p>
                  <p className="text-center custom-fs-12-to-14">
                  <Placeholder
                    loading={isLoadingOfficeYTDReport}
                    className="custom-w-12 custom-loader-h-6"
                  ></Placeholder>
                    {
                      !isLoadingOfficeYTDReport && (
                        officeYTDReport?.precent_gci > 0 ? (
                          <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                            + {formatDecimalPlaces(officeYTDReport?.precent_gci)}%
                          </span>
                        ) : (
                          <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                            {formatDecimalPlaces(officeYTDReport?.precent_gci)}%
                          </span>
                        )
                      )
                    }
                    <span>From Last Year</span>
                  </p>
                </div>

                <div className="custom-modal-inner__padding">
                  <div className="d-inline-block">
                    <span className="header-title-4 text-white mb-0 pe-1">
                      Pending GCI
                    </span>
                    <div className="d-inline-block align-middle">
                      <HelpIcon tooltip={officeModalTooltip.ytdPendingGci} width={20} height={20} />
                    </div>
                  </div>
                  <p className="mb-0 custom-fs-12-to-14">Year to Date</p>
                  <p className="text-center number-stats-4 text-white mb-1">
                    $
                    <Placeholder
                      loading={isLoadingOfficeYTDReport}
                      className="w-8 h-8"
                    ></Placeholder>
                    {!isLoadingOfficeYTDReport &&
                      formatNumberWithCommas(
                        officeYTDReport?.ytd_gci_under_contract,
                      )}
                  </p>
                  <p className="text-center custom-fs-12-to-14">
                  <Placeholder
                    loading={isLoadingOfficeYTDReport}
                    className="custom-w-12 custom-loader-h-6"
                  ></Placeholder>
                    {
                      !isLoadingOfficeYTDReport && (
                        officeYTDReport?.precent_gci_under_contract > 0 ? (
                          <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                            + {formatDecimalPlaces(officeYTDReport?.precent_gci_under_contract)}%
                          </span>
                        ) : (
                          <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                            {formatDecimalPlaces(officeYTDReport?.precent_gci_under_contract)}%
                          </span>
                        )
                      )
                    }
                    <span>From Last Year</span>
                  </p>
                  <p className="text-center custom-fs-12-to-14">
                    <span className="me-1">.</span>
                    <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                      + 0.4%
                    </span>
                    <span>From Last Year</span>
                  </p>
                </div>
              </div> */}
            </div>
          </div>
          <PermissionCheck
            permission={`${officePerms.office_modal_three_month_forecast}`}
          >
            <ForeCastedRevenue officeId={officeId}></ForeCastedRevenue>
          </PermissionCheck>
          <PermissionCheck
            permission={`${officePerms.office_modal_recom_list}`}
          >
            <RecomendedAction officeId={officeId}></RecomendedAction>
          </PermissionCheck>
          <ComparativeFinancial officeId={officeId}></ComparativeFinancial>

          {/* profile info start */}

          {/* <div className="custom-max-width-545 mx-auto mb-4">
            <div className="" style={{ backgroundColor: "#2A3042" }}>
              <div className="custom-modal-inner__padding">
                <div className="row mb-3">
                  <div className="col-9 pe-0">
                    <div className="d-inline-block">
                      <span className="header-title pe-1 pe-lg-2">
                        Office Engagement
                      </span>
                      <div className="d-inline-block align-middle">
                        <HelpIcon
                          tooltip="This chart helps break down the total number of recommendations we
                    provided, and how many were executed on an office by office basis."
                          width={20}
                          height={20}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-3 pe-0">
                    <p className="mb-0 pt-1">All Time</p>
                  </div>
                </div>
                <div className="justify-content-between text-white">
                  <div className="row align-content-start">
                    {DUMMY_DATA?.map((item: any) => (
                      <Fragment key={item?.id}>
                        <div className="col-md-5 fw-medium custom-text-fs">
                          {item?.title}
                        </div>
                        <div className="col-md-7">
                          <div className="d-flex flex-row-reverse flex-lg-row align-items-baseline gap-2">
                            <p>{item?.stats}%</p>
                            <div className="progress animated-progess progress-sm flex-grow-1">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${item?.stats}%`,
                                  backgroundColor: `${getRandomColor(
                                    item?.stats,
                                  )}`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                  </div>
                  <div className="d-flex gap-1 align-items-baseline ">
                    <h4 className="w-34 fw-bold page-subtitle">Total</h4>
                    <p className="number-stats-6">72%</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <PermissionCheck
            permission={`${officePerms.office_modal_tech_engagement}`}
          >
            <OfficeYearlyTechEngagement officeIdProps={officeId} />
          </PermissionCheck>

          {/* Monthly engagement score */}
          <div className="row mb-4">
            <PermissionCheck
              permission={`${officePerms.office_modal_monthly_engmnt_score}`}
            >
              <div className="col-12 col-lg-6 mb-2 mt-1 pe-lg-2">
                <div
                  className="custom-modal-inner__padding h-100"
                  style={{ backgroundColor: "#2B3041" }}
                >
                  <OfficeEngagementScore officeIdProps={officeId} />
                </div>
              </div>
            </PermissionCheck>
            <PermissionCheck
              permission={`${officePerms.office_modal_cma_tech_stats}`}
            >
              <div className="col-12 col-lg-6 mb-2 mt-1 ps-lg-1">
                <div
                  className="custom-modal-inner__padding h-100"
                  style={{ backgroundColor: "#2B3041" }}
                >
                  <OfficeToolTechStats officeIdProps={officeId} />
                </div>
              </div>
            </PermissionCheck>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default DetailsOfficeModal;
