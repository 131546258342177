/* eslint-disable no-multiple-empty-lines */
/* eslint-disable eol-last */
/* eslint-disable comma-dangle */
import { RA_BROKERAGE_AVG_SOLD_DOM_HIGHER, RA_BROKERAGE_AVG_SOLD_DOM_LOWER, RA_BROKERAGE_GCI_LAST_QUARTER } from "@constants/variables";

function formatDecimalPlaces(
  number: number,
  places: number = 2,
  local = false,
): number | string {
  if (Number.isNaN(number) || number === undefined || number === null) {
    return 0;
  }
  const roundedNumber: number = Number(number.toFixed(places));

  if (local === true) {
    // Add comma separation for large numbers
    const formattedNumber: string = roundedNumber.toLocaleString("en-US");
    return formattedNumber;
  }
  return roundedNumber;
}

function formatNumberWithCommas(number?: number): any {
  if (
    !number ||
    number === undefined ||
    Number.isNaN(number) ||
    number === null
  ) {
    return "0"; // Return 'NaN' if the input is undefined or not a number
  }

  // Format the number with comma separation for the USA locale
  const formattedNumber: string = number.toLocaleString("en-US");

  return formattedNumber;
}

function formatDate(dateString: string) {
  // Utility function to format date as "MM/DD/YYYY"
  if (dateString?.length) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  } else {
    return dateString;
  }
}
function getTickAmount(data: any) {
  if (data?.length) {
    return Math.ceil(data.length / 2);
  } else {
    return 0;
  }
}

function isObjectEmpty(objectName: any) {
  return (
    objectName &&
    typeof objectName === "object" &&
    Object.keys(objectName).length === 0
  );
}

function formatPhoneNumberWithUsa(phoneNumberString: string) {
  if (!phoneNumberString) {
    return;
  }
  // Remove all non-digit characters from the string
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");

  // Check if the number is valid
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    // If the number is valid, format it in USA phone number format
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  // If the number is invalid, return the original string
  return phoneNumberString;
}

function formatPhoneNumber(phoneNumber: string) {
  if (!phoneNumber) {
    return;
  }
  // Remove any non-digit characters from the phone number
  const cleaned = `${phoneNumber}`.replace(/\D/g, "");

  // Format the phone number with dashes after every three digits
  const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

  return formatted;
}


function formatMultiplePhoneNumbers(mobileNumbers: string) {
  if (!mobileNumbers) {
    return "";
  }
  let phoneNumbers:any = mobileNumbers.split(',').map((el) => el.trim());
  
  // Remove empty phone numbers
  phoneNumbers = phoneNumbers.filter((el:any) => el !== '');
  
  // Format each phone number
  phoneNumbers = phoneNumbers.map((phone:any) => formatPhoneNumber(phone));

  return phoneNumbers.join(',');
}
function hasRecomDraftEmailAccess(slug: string) {
  if (
    RA_BROKERAGE_GCI_LAST_QUARTER === slug ||
    RA_BROKERAGE_AVG_SOLD_DOM_HIGHER === slug ||
    RA_BROKERAGE_AVG_SOLD_DOM_LOWER === slug
  ) {
    return false;
  } else {
    return true;
  }
}

export {
  formatDate,
  formatDecimalPlaces, formatMultiplePhoneNumbers, formatNumberWithCommas,
  formatPhoneNumber, formatPhoneNumberWithUsa,
  getTickAmount,
  hasRecomDraftEmailAccess,
  isObjectEmpty
};

