/* eslint-disable jsx-a11y/anchor-is-valid */
import Placeholder from "@components/common/Placeholder";
import {
  FIRST_BADGE_NAME,
  SECOND_BADGE_NAME,
  THIRD_BADGE_NAME,
} from "@constants/variables";
import { selectAuth } from "@state/appSlice";
import { useAppSelector } from "@state/hooks";
import CirclesWithBarLoader from "@utils/loaders/CirclesWithBarLoader";
import { useEffect, useState } from "react";
import FirstBadge from "../../assets/images/position-1.png";
import SecondBadge from "../../assets/images/position-2.png";
import ThirdBadge from "../../assets/images/position-3.png";
import RecomLeaderBoardTableList from "./RecomLeaderBoardTableList";

function RecomLeaderboard({
  isDataLoading,
  currentUserData,
  leaderBoardData,
  fetchLeaderBoardDataEmit,
}: any) {
  const [activeTab, setActiveTab] = useState("weekly");
  const [filteredUserLists, setFilteredUserLists] = useState([]);
  const auth = useAppSelector(selectAuth);
  const currentUserId = auth?.user_id;
  
  useEffect(() => {
    if (leaderBoardData && leaderBoardData?.length) {
      const isCurrentUserInTop3 = [1, 2, 3]?.includes(
        currentUserData?.position,
      );
      const allUserDatasWithoutTop3 =
        leaderBoardData?.length && leaderBoardData.slice(3);
      const lastUserInfo =
        allUserDatasWithoutTop3[allUserDatasWithoutTop3?.length - 1];
      const isCurrentUserPositionLast =
        currentUserData?.user_id === lastUserInfo?.user_id &&
        currentUserData?.position === lastUserInfo?.position;
      let filteredDataList: any = [];

      if (!isCurrentUserInTop3) {
        if (allUserDatasWithoutTop3?.length) {
          if (allUserDatasWithoutTop3?.length <= 3) {
            setFilteredUserLists(allUserDatasWithoutTop3);
            return;
          }
          if (isCurrentUserPositionLast) {
            setFilteredUserLists(allUserDatasWithoutTop3.slice(-3));
            return;
          }
          if (
            currentUserData?.position !== 4 &&
            currentUserData?.position > 3
          ) {
            filteredDataList = allUserDatasWithoutTop3.filter(
              (user: any) => user.position === currentUserData?.position - 1 ||
                user.position === currentUserData?.position ||
                user.position === currentUserData?.position + 1,
            );
            setFilteredUserLists(filteredDataList);
          } else {
            filteredDataList = allUserDatasWithoutTop3?.filter(
              (user: any) => user.position >= 4 && user.position <= 6,
            );
            setFilteredUserLists(filteredDataList);
          }
        } else {
          setFilteredUserLists([]);
        }
      }
    } else {
      setFilteredUserLists([]);
    }
  }, [currentUserData, leaderBoardData]);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    fetchLeaderBoardDataEmit(tab);
  };

  function getBadgeInfo(position: any) {
    switch (true) {
      case parseInt(position, 10) === 1:
        return {
          badgeImg: FirstBadge,
          badgeName: FIRST_BADGE_NAME,
        };
      case parseInt(position, 10) === 2:
        return {
          badgeImg: SecondBadge,
          badgeName: SECOND_BADGE_NAME,
        };
      case parseInt(position, 10) === 3:
        return {
          badgeImg: ThirdBadge,
          badgeName: THIRD_BADGE_NAME,
        };
      default:
        return {
          badgeImg: "",
          badgeName: "",
        };
    }
  }

  const recommendations = [
    {
      user_id: "e35b2c5d-8232-4f68-a728-d97c63c6b069",
      user_name: "Esther Howard",
      first_name: "Esther",
      last_name: "Howard",
      total_score: 53,
      position: 1,
    },
    {
      user_id: "22f8b6f4-b136-46b3-b225-0c4147b868df",
      user_name: "Jane Cooper",
      first_name: "Jane",
      last_name: "Cooper",
      total_score: 22,
      position: 2,
    },
    {
      user_id: "f21fdcbd-9bd1-4f35-a23c-3b2101667bae",
      user_name: "Jenny Wilson",
      first_name: "Jenny",
      last_name: "Wilson",
      total_score: 6,
      position: 3,
    },
    // {
    //   "user_id": "75388759-7f24-4f38-8025-01b0a9404f6c",
    //   "user_name: "",
    //   "total_score": 3,
    //   "position": 4,
    // },
  ];

  return (
    <section
      className="rounded-4"
      style={{ background: filteredUserLists?.length ? "rgba(33, 43, 54, 1)" : "" }}
    >
      <div
        className="position-relative leaderboard-section rounded-4 "
        style={{ minHeight: "344px" }}
      >
        <div
          className="leaderboard-tab-container d-flex gap-2 align-items-center rounded-5 mt-3 px-1"
          style={{
            height: "40px",
            background: "#1E2330",
            position: "relative",
          }}
        >
          {/* Sliding Indicator */}
          <div
            className={`leaderboard-slider-indicator ${activeTab}`}
            style={{
              width: "50%", // Adjust this based on the number of tabs
              height: "30px",
              backgroundColor: "#1640D6",
              borderRadius: "20px",
              position: "absolute",
              top: "5px", // Adjust to vertically center the slider
              left: activeTab === "weekly" ? "2%" : "48%",
              transition: "left 0.3s ease-in-out", // Smooth slide animation
            }}
          />

          {/* Weekly Tab */}
          <button
            type="button"
            onClick={() => handleTabClick("weekly")}
            className={`btn leaderboard-tab-button flex-grow-1 mb-0 d-flex align-items-center justify-content-center rounded-4 px-2 ${
              activeTab === "weekly" ? "active" : ""
            }`}
            style={{
              height: "30px",
              position: "relative",
              zIndex: 1,
              border: "none",
            }}
          >
            Weekly
          </button>

          {/* All Time Tab */}
          <button
            type="button"
            onClick={() => handleTabClick("alltime")}
            className={`btn leaderboard-tab-button flex-grow-1 mb-0 d-flex align-items-center justify-content-center rounded-4 px-2 ${
              activeTab === "alltime" ? "active" : ""
            }`}
            style={{
              height: "30px",
              position: "relative",
              zIndex: 1,
              border: "none",
            }}
          >
            All Time
          </button>
        </div>

        {/* Top 3 Leaderboard Ranks */}
        <div className="row mt-3 gap-1" style={{ padding: "0px 10px" }}>
          {isDataLoading ? (
            <span
              style={{ position: "absolute", top: "50%", left: 0, right: 0 }}
            >
              <CirclesWithBarLoader />
            </span>
          ) : (
            ""
          )}
          {(!isDataLoading &&
            leaderBoardData) &&
            leaderBoardData.slice(0, 3)?.map((el: any) => (
              <div
                key={el?.user_id}
                className="col rounded-4 p-1"
                style={{
                  background: "rgba(48, 54, 71, 0.15)",
                  minHeight: "177px",
                  // width: "33%",
                  border:
                  (currentUserData && Object.keys(currentUserData)?.length && currentUserData?.position === el?.position)
                    ? "1px solid rgba(52, 252, 29, 1)"
                    : "none",
                }}
              >
                <div className="p-2 h-100">
                  <div className="text-center mb-2">
                    <img
                      src={getBadgeInfo(el?.position)?.badgeImg}
                      alt="badge"
                      className=""
                      style={{ objectPosition: "center", objectFit: "contain" }}
                    />
                  </div>
                  <h3
                    className="text-center mb-2"
                    style={{
                      fontWeight: "800",
                      fontSize: "15px",
                      color:
                      (currentUserData && Object.keys(currentUserData)?.length && currentUserData?.position === el?.position)
                        ? "rgba(52, 252, 29, 1)"
                        : "white",
                    }}
                  >
                    <Placeholder
                      loading={isDataLoading}
                      className="custom-loader-w-20 custom-loader-h-6"
                    ></Placeholder>
                    {!isDataLoading &&
                    (currentUserData && Object.keys(currentUserData)?.length && currentUserData?.position === el?.position)
                      ? "You"
                      : `${el?.first_name ?? ""} ${el?.last_name ?? ""}`}
                  </h3>
                  <p className="mb-0 text-center">
                    <Placeholder
                      loading={isDataLoading}
                      className="custom-loader-w-20 custom-loader-h-6"
                    ></Placeholder>
                    {!isDataLoading && getBadgeInfo(el?.position)?.badgeName}
                  </p>
                </div>
              </div>
            ))}
          {/* <div
            className="p-2 rounded-4 flex-grow-1"
            style={{ background: "rgba(48, 54, 71, 0.15)", minHeight: "177px" }}
          >
            <div className="text-center mb-2">
              <img
                src={FirstBadge}
                alt="badge"
                className=""
                style={{ objectPosition: "center", objectFit: "contain" }}
              />
            </div>
            <h3
              className="d-flex align align-items-center gap-2 text-white text-center mb-2"
              style={{ fontWeight: "800", fontSize: "15px" }}
            >
              <Placeholder
                loading={isDataLoading}
                className="custom-loader-w-20 custom-loader-h-6"
              ></Placeholder>
              {!isDataLoading && "Esther Howard"}
            </h3>
            <p className="mb-0 text-center">
              <Placeholder
                loading={isDataLoading}
                className="custom-loader-w-20 custom-loader-h-6"
              ></Placeholder>
              {!isDataLoading && "Mentorship Maestro"}
            </p>
          </div>
          <div
            className="p-2 rounded-4 flex-grow-1"
            style={{ background: "rgba(48, 54, 71, 0.15)" }}
          >
            <div className="text-center mb-2">
              <img
                src={SecondBadge}
                alt="badge"
                className=""
                style={{ objectPosition: "center", objectFit: "contain" }}
              />
            </div>

            <h3
              className="text-white text-center mb-2"
              style={{ fontWeight: "800", fontSize: "15px" }}
            >
              Esther Howard
            </h3>
            <p className="mb-0 text-center">Mentorship Maestro</p>
          </div>
          <div
            className="p-2 rounded-4 flex-grow-1"
            style={{ background: "rgba(48, 54, 71, 0.15)" }}
          >
            <div className="text-center mb-2">
              <img
                src={ThirdBadge}
                alt="badge"
                className=""
                style={{ objectPosition: "center", objectFit: "contain" }}
              />
            </div>

            <h3
              className="text-white text-center mb-2"
              style={{ fontWeight: "800", fontSize: "15px" }}
            >
              Esther Howard
            </h3>
            <p className="mb-0 text-center">Mentorship Maestro</p>
          </div> */}
        </div>
      </div>
      <RecomLeaderBoardTableList
        currentUserData={currentUserData}
        leaderboardListData={filteredUserLists}
        isDataLoading={isDataLoading}
      />
    </section>
  );
}

export default RecomLeaderboard;
