/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-bind */
import CardLoader from "@components/common/CardLoader";
import HelpIcon from "@components/common/HelpIcon";
import PermissionCheck from "@components/common/PermissionCheck";
import Placeholder from "@components/common/Placeholder";
import ProgressbarTooltip from "@components/common/ProgressbarTooltip";
import { officePerms } from "@constants/permission";
import { officeModalTooltip } from "@constants/tooltip-data";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { faker } from "@faker-js/faker";
import { actionUserPermissions, selectUser } from "@state/appSlice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { http } from "@utils/http";
import { Fragment, useEffect, useState } from "react";

function AgentRecruitRetention() {
  const dispatch = useAppDispatch();
  const selectedUser: any = useAppSelector(selectUser);
  const [isPermissionDataLoading, setIsPermissionDataLoading] = useState(true);

  const [isDataLoading, setIsDataLoading] = useState({
    isAgentRecruitDataLoading: true,
    isTotalAgentDataLoading: true,
    isRetentionOverviewDataLoading: true,
    isRetentionOverviewRatioDataLoading: true,
  });
  // const [isDataLoading, setIsDataLoading] = useState(true);
  const [isTotalAgentLoading, setIsTotalAgentDataLoading] = useState(true);
  const [agentRecruits, setAgentRecruits] = useState([] as any);
  const [retentionOverview, setRetentionOverview] = useState([] as any);
  const [retentionOverviewRatio, setRetentionOverviewRatio] = useState(
    {} as any,
  );
  const [totalNewAgents, setTotalNewAgents] = useState({} as any);
  const [progressbarPercentage, setProgressbarPercentage] = useState({} as any);

  useEffect(() => {
    if (selectedUser?.id) {
      setIsPermissionDataLoading(true);
      // TODO: here call user permission api
      http
        .get(
          `user-permission-site-data.json`,
        )
        .then((response: any) => {
          const { permission } = response?.data;
          const getUserPerms = response?.data?.permission[0]?.permissions;
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: getUserPerms ?? [] }));
          setIsPermissionDataLoading(false);
        })
        .catch((error) => {
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: [] }));
          setIsPermissionDataLoading(false);
          console.log(error);
        });
    }
  }, [selectedUser]);

  // initial api calls
  useEffect(() => {
    getAgentRecruits();
    getRetentionOverview();
  }, []);

  //  agents api calls
  function getAgentRecruits() {
    setIsDataLoading((prev) => ({
      ...prev,
      isAgentRecruitDataLoading: true,
    }));
    http
      .get("/office/new-agent-stats.json")
      .then((response: any) => {
        const { new_agent_stats } = response?.data;
        let theData = response?.data?.new_agent_stats;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            office_name: faker.company.name(),
          }));
        }
        setAgentRecruits(theData);

        getTotalNewAgent();
        setIsDataLoading((prev) => ({
          ...prev,
          isAgentRecruitDataLoading: false,
        }));
      })
      .catch((error: any) => {
        setIsDataLoading((prev) => ({
          ...prev,
          isAgentRecruitDataLoading: false,
        }));
        console.log(error);
      });
  }

  function getTotalNewAgent() {
    setIsDataLoading((prev) => ({
      ...prev,
      isTotalAgentDataLoading: true,
    }));
    http
      .get("/office/total-new-agents.json")
      .then((response: any) => {
        let theData = response?.data?.total_new_agents;
        if (theData?.message === SQL_NOT_EXIST_MESSAGE) {
          theData = {};
        }
        setTotalNewAgents(theData);
        setIsDataLoading((prev) => ({
          ...prev,
          isTotalAgentDataLoading: false,
        }));
      })
      .catch((error: any) => {
        setIsDataLoading((prev) => ({
          ...prev,
          isTotalAgentDataLoading: false,
        }));
        console.log(error);
      });
  }

  // get retention api calls
  function getRetentionOverview() {
    setIsDataLoading((prev) => ({
      ...prev,
      isRetentionOverviewDataLoading: true,
    }));
    http
      .get("/office/retention-overview-with-office.json")
      .then((response: any) => {
        const apiData = response?.data?.retention;
        const filteredApiData = apiData?.filter(
          (el: any) => el?.retention_ratio_display !== null ||
            el?.retention_ratio_display > 0,
        );
        let theData = filteredApiData;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            office_name: faker.company.name(),
          }));
        }
        
        setRetentionOverview(theData);
        getRetentionOverviewRatio();
        setTimeout(() => {
          setIsDataLoading((prev) => ({
            ...prev,
            isRetentionOverviewDataLoading: false,
          }));
        }, 300);
      })
      .catch((error: any) => {
        setIsDataLoading((prev) => ({
          ...prev,
          isRetentionOverviewDataLoading: false,
        }));
        console.log(error);
      });
  }

  function getRetentionOverviewRatio() {
    setIsDataLoading((prev) => ({
      ...prev,
      isRetentionOverviewRatioDataLoading: true,
    }));
    http
      .get("/office/overall-retention-ratio.json")
      .then((response: any) => {
        let theData = response?.data?.retention;
        if (theData?.message === SQL_NOT_EXIST_MESSAGE) {
          theData = {};
        }
        setRetentionOverviewRatio(theData);
        setTimeout(() => {
          setIsDataLoading((prev) => ({
            ...prev,
            isRetentionOverviewRatioDataLoading: false,
          }));
        }, 300);
      })
      .catch((error: any) => {
        setIsDataLoading((prev) => ({
          ...prev,
          isRetentionOverviewRatioDataLoading: false,
        }));
        console.log(error);
      });
  }

  function getRandomColor(stats: any, index: any) {
    let color: any = "";
    switch (true) {
      case index === 0:
        color = "#F2CA6B";
        break;
      case index === 1:
        color = "#F5822F";
        break;
      case index === 2:
        color = "#CF4AFE";
        break;
      default:
        color = "#F2CA6B";
        break;
    }
    return color;
  }

  function handleProgressbarHover(item: any) {
    setProgressbarPercentage({
      id: item.office_bw_id,
      percent: item?.percentage_of_total,
    });
  }

  return (
    <div className="row mb-4">
      <PermissionCheck permission={`${officePerms.office_new_agent_recruits}`}>
        <div className="col-lg-6 mb-4">
          <div className="" style={{ backgroundColor: "#2B3041" }}>
            <div className="custom-modal-inner__padding">
              <div className="d-flex mb-4 align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-1">
                  <div className="header-title">New Agent Recruits</div>
                  <HelpIcon tooltip={officeModalTooltip?.newRecruits} />
                </div>
                <p className="mb-0 custom-fs-12-to-14">Last 12 Months</p>
              </div>
              <div className="justify-content-between text-white">
                <div className="row align-content-start">
                  <CardLoader
                    count={5}
                    loading={isDataLoading?.isAgentRecruitDataLoading}
                  />
                  {!isDataLoading?.isAgentRecruitDataLoading &&
                    agentRecruits?.map((item: any, index: any) => (
                      <Fragment key={item?.office_bw_id}>
                        <div className="col-5 custom-fs-12-to-14">
                          { item?.office_name }
                        </div>
                        <div className="col-7">
                          <div className="d-flex align-items-baseline gap-2">
                            <p className="custom-fs-12-to-14">
                              {item?.total_new_agents}
                            </p>
                            {/* eslint-disable-next-line react/jsx-no-bind */}
                            <ProgressbarTooltip
                              item={item}
                              quotationText="of Total New Agents"
                              handleHover={handleProgressbarHover}
                              handleLeave={() => setProgressbarPercentage({})}
                              backgroundColor={`${getRandomColor(
                                item?.percentage_of_total,
                                index,
                              )}`}
                            />
                          </div>
                        </div>
                      </Fragment>
                    ))}
                </div>
                <div className="row">
                  <div className="col-5 d-flex align-items-center">
                    <h4 className="page-subtitle text-white">
                      Total New Agents
                    </h4>
                  </div>
                  <div className="col-7 d-flex align-items-center justify-content-between">
                    <div>
                      <Placeholder
                        loading={isDataLoading?.isTotalAgentDataLoading}
                        className="custom-w-24 custom-h-2"
                      ></Placeholder>
                      <p className="number-stats-5 mb-0">
                        {!isDataLoading?.isTotalAgentDataLoading &&
                          totalNewAgents?.total_new_agents}
                      </p>
                    </div>
                    {/* <div>
                    <button
                      type="button"
                      className="btn text-white fw-semibold border-white pe-auto"
                    >
                      Show Offices
                    </button>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PermissionCheck>
      <PermissionCheck permission={`${officePerms.office_retention_overview}`}>
        <div className="col-lg-6">
          <div className="" style={{ backgroundColor: "#2B3041" }}>
            <div className="custom-modal-inner__padding">
              <div className="d-flex mb-4 align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-1">
                  <div className="header-title">Retention Overview</div>
                  <HelpIcon tooltip={officeModalTooltip?.retentionOverview} />
                </div>
                <p className="mb-0 custom-fs-12-to-14">Last 12 Months</p>
              </div>
              <div className="text-white">
                <div className="row align-content-start">
                  <CardLoader
                    count={5}
                    loading={isDataLoading?.isRetentionOverviewDataLoading}
                  />
                  {!isDataLoading?.isRetentionOverviewDataLoading &&
                    retentionOverview?.map((item: any, index: any) => (
                      <Fragment key={item?.id}>
                        <div className="col-5 custom-fs-12-to-14">
                          {item?.office_name}
                        </div>
                        <div className="col-7">
                          <div className="d-flex align-items-baseline gap-2">
                            <p className="custom-fs-12-to-14">
                              {item?.retention_ratio_display == null
                                ? 0
                                : (item?.retention_ratio_display).toFixed(2)}
                              %
                            </p>
                            <div className="progress animated-progess progress-sm flex-grow-1">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${item?.retention_ratio_display}%`,
                                  backgroundColor: `${getRandomColor(
                                    item?.stats,
                                    index,
                                  )}`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                </div>
              </div>
              <div className="row text-white">
                <div className="col-5  d-flex align-items-center">
                  <h4 className="page-subtitle mb-0 text-white">
                    Overall Retention Ratio
                  </h4>
                </div>
                <div className="col-7 d-flex align-items-center justify-content-between">
                  <div>
                    <Placeholder
                      loading={
                        isDataLoading?.isRetentionOverviewRatioDataLoading
                      }
                      className="custom-w-24 custom-h-2"
                    ></Placeholder>
                    <p className="number-stats-5 mb-0">
                      {!isDataLoading?.isRetentionOverviewRatioDataLoading &&
                        `${retentionOverviewRatio?.rentention_ratio_display ?? ""} %`}
                    </p>
                  </div>
                  {/* <div>
                  <button
                    type="button"
                    className="btn text-white border-white pe-auto fw-semibold"
                  >
                    Show Offices
                  </button>
                </div> */}
                </div>
                {/* <div className="col-5 d-flex align-items-center">
                <h4 className="page-subtitle text-white">Net Turnover</h4>
              </div>
              <div className="col-7 pe-0 d-flex align-items-center justify-content-start ">
                <div className="d-flex align-items-center text-break">
                  <span className="number-stats-5 d-flex flex-wrap align-items-center">
                    $2,503,6821
                    <span className="badge badge-soft-success font-size-12 ms-1">
                      + 0.4%
                    </span>
                  </span>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </PermissionCheck>
    </div>
  );
}

export default AgentRecruitRetention;
