import { formatNumberWithCommas } from "@utils/helpers";

interface ChartContext {
  theId: any;
  colors: any;
  categories: any;
  ticks?: any;
  tickAmount?: any;
  ticksPreLast: any;
  forecast?: number;
  gridPL?: number;
  dashArray?: any;
  legendPostion?: string;
  legendPostionOnMobile?: string;
  legendHorizontalAlignment?: string;
}

function generateLineChartOptions(ctx: ChartContext) {
  const {
    theId = "line-chart",
    colors = ["#CF4AFE"],
    categories = [],
    ticks,
    tickAmount,
    ticksPreLast = 0,
    forecast = 0,
    gridPL = -10,
    dashArray = [0],
    legendPostion = 'bottom',
    legendPostionOnMobile = 'bottom',
    legendHorizontalAlignment = "center",
  } = ctx;

  const options: any = {
    chart: {
      id: theId,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: "#000",
        opacity: 0.35,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors,
    stroke: {
      curve: "smooth",
      width: 3,
      dashArray,
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories,
    },
    yaxis: {
      // ticks,
      tickAmount,
      labels: {
        offsetX: -14,
        align: "left", // Set the alignment to 'left'
        axisTicks: {
          show: false, // Hide ticks on the right side of the y-axis
        },
      },
    },
    grid: {
      padding: {
        left: gridPL,
      },
    },
    legend: {
      horizontalAlign: `${legendHorizontalAlignment}`, // Center legend for desktop
      position: legendPostion,
      markers: {
        radius: 0,
      },
    },
  };

  if (forecast !== 0) {
    options.forecastDataPoints = {
      count: forecast,
    };
  }

  if (ticksPreLast !== 0) {
    options.yaxis.labels = {
      ...options.yaxis.labels,
      formatter: (value: any) => {
        const valueFormatted = parseFloat(value).toFixed(1); 
        return `${ticksPreLast[0] ?? ""}${formatNumberWithCommas(parseFloat(valueFormatted))}${ticksPreLast[1] ?? ""}`;
      },
    };
  }

  if (window.innerWidth <= 768) {
    const updatedOptions = {
      ...options,
      xaxis: {
        ...options.xaxis,
        labels: {
          rotate: -55,
          rotateAlways: true,
        },
      },
      legend: {
        horizontalAlign: "center", // Center legend for desktop
        position: legendPostionOnMobile,
        markers: {
          radius: 0,
        },
      },
    };
    return updatedOptions;
  } else {
    return options;
  }
}

export default generateLineChartOptions;
