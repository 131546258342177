/* eslint-disable no-unreachable */
import LockIcon from "@mui/icons-material/Lock";
import { http } from "@utils/http";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import LoadingIcon from "../../view/common/LoadingIcon/LoadingIcon";
import AuthLayout from "../../view/layout/AuthLayout";
// import tracking, { getTrackingData, TrackingActions } from '../../../state/tracking';
import { yupResolver } from "@hookform/resolvers/yup";
import cookies from "@utils/cookies";
import { getLoginTitle } from "@utils/fakers-data";
import { FormFields } from "@utils/form-field";
import loginSchema from "@yup-schema/Login";

export interface LoginForm {
  email: string;
  password: string;
}

export const loginFailureMessage = "Incorrect email or password.";
export const loginApiFailureMessage = "Error logging in.";

const Login = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (cookies.get('token')) {
      history.push('/');
    }
  }, [history]);

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormFields>({
    mode: "onChange",
    resolver: yupResolver(loginSchema),
  });

  function handleLogin(data: any) {
    setIsLoading(true);
    const userLogindata = { "is_superuser": true, "user_level": 10, "token": "1f42f4673324307661bb24b5785035b3b8bad26e", "user_id": "f21fdcbd-9bd1-4f35-a23c-3b2101667bae" };
    setTimeout(() => {
      cookies.set('auth', userLogindata, { path: '/' });
      cookies.set('logged_in_auth', userLogindata, { path: '/' });
      cookies.set('token', userLogindata?.token, { path: '/' });
      cookies.set('the_brand_slug', 'willam_pit_julia_b_fee', { path: '/' });
      window.location.replace("/");
      setIsLoading(false);
    }, 1000);
    return;
    http
      .post(
        "/auth/login/",
        {
          email: data.email,
          password: data.password,
        },
        {
          headers: {
            'Authorization': `Basic ${btoa(`${data.email}:${data.password}`)}`,
          },
        },
      )
      .then((response: any) => {
        cookies.set('auth', response?.data, { path: '/' });
        cookies.set('logged_in_auth', response?.data, { path: '/' });
        cookies.set('token', response?.data?.token, { path: '/' });
        cookies.set('the_brand_slug', 'willam_pit_julia_b_fee', { path: '/' });
        
        setTimeout(() => {
          window.location.replace("/");
          setIsLoading(false);
        }, 1000);
      })
      .catch((error: any) => {
        setIsLoading(false);
      });
  }

  return (
    <AuthLayout modalTitle={getLoginTitle("Log In - Kintaro")} pageTitle="Log In">
      <form className="form-horizontal">
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
            <span className="text-danger">
              *
            </span>
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Enter Email"
            {...register("email")}
          />
          {errors?.email && (
            <p className="text-danger">
              {errors.email?.message}
            </p>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
            <span className="text-danger">
              *
            </span>
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Enter Password"
            {...register("password")}
          />
          {errors?.password && (
            <p className="text-danger">
              {errors.password?.message}
            </p>
          )}
        </div>

        <div className="d-grid">
          <button
            className="btn btn-primary btn-block"
            disabled={isLoading}
            type="button"
            onClick={handleSubmit(handleLogin)}
          >
            { isLoading ? <LoadingIcon className="mb-0 custom-h-9" loading={isLoading} /> : "Log In now"}
          </button>
        </div>
        <div className="mt-4 text-center">
          <Link to="/request-password-reset" className="text-secondary d-flex align-items-end justify-content-center">
            <LockIcon />
            &nbsp; Forgot your password?
          </Link>
        </div>
      </form>
    </AuthLayout>
  );
};

export default Login;
