import Placeholder from "@components/common/Placeholder";

function ActivePipeTopProfileCard({ isDataLoading, topProfileInfo }:any) {
  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-5 mb-1 mb-lg-0 d-flex flex-wrap align-items-center gap-2 text-break">
            <p className="mb-0 header-title-6 text-white">Overall Engagement Score</p>
            <p className="number-stats-13 d-flex text-white mb-0 pe-2">
              <Placeholder
                loading={isDataLoading}
                className="custom-loader-w-20 custom-loader-h-6"
              ></Placeholder>
              {!isDataLoading &&
                (topProfileInfo?.overall_engagement_score ?? 0)}
            </p>
        </div>
        <div className="col-12 col-lg-4 mb-1 mb-lg-0 d-flex align-items-center gap-1 text-break">
            <p className="mb-1 header-title-6 fw-normal text-white pe-1 ">Top Agent</p>
            <p className="text-left text-white header-title-6 fw-bolder mb-1">
              <Placeholder
                loading={isDataLoading}
                className="custom-loader-w-20 custom-loader-h-6"
              ></Placeholder>
              {!isDataLoading && (topProfileInfo?.first_name ?? "")}
            </p>
        </div>
        <div className="col-12 col-lg-3 mb-1 mb-lg-0 d-flex align-items-center gap-1 text-break">
            <p className="mb-0 custom-fs-12">Email</p>
            <p className="text-white mb-0 custom-fs-12 fw-medium">
              <Placeholder
                loading={isDataLoading}
                className="custom-loader-w-20 custom-loader-h-6"
              ></Placeholder>
              {!isDataLoading && (topProfileInfo?.email ?? "N/A")}
            </p>
        </div>
      </div>
      {/* <div className="d-flex align-items-center mb-2">
        <p className="number-stats d-flex text-white mb-0 pe-2">
          <Placeholder
            loading={isDataLoading}
            className="custom-loader-w-20 custom-loader-h-6"
          ></Placeholder>
          {!isDataLoading &&
            (topProfileInfo?.overall_engagement_score ?? 0)}
        </p>

        <p className="mb-0 page-subtitle-3">Overall Engagement Score</p>
      </div>
      <div>
        <p className="mb-1 header-subtitle text-white pe-1 ">Top Agent</p>
        <p className="text-left text-white header-subtitle-2 mb-1">
          <Placeholder
            loading={isDataLoading}
            className="custom-loader-w-20 custom-loader-h-6"
          ></Placeholder>
          {!isDataLoading && (topProfileInfo?.first_name ?? "")}
        </p>
        <div className="d-flex align-items-center gap-3 text-break">
          <p className="mb-0 custom-fs-12">Email</p>
          <p className="text-white mb-0 custom-fs-12">
            <Placeholder
              loading={isDataLoading}
              className="custom-loader-w-20 custom-loader-h-6"
            ></Placeholder>
            {!isDataLoading && (topProfileInfo?.email ?? "N/A")}
          </p>
        </div>
      </div> */}
    </>
  );
}

export default ActivePipeTopProfileCard;
