// const generateGciDataByYear = (gciData: any): Record<string, any> => {
//   const result: Record<string, any> = {
//     months: [],
//     gci_data: [],
//     categories: [],
//   };

//   const filteredData = gciData
//     .filter((entry: any) => (entry.the_year === "2023" || entry.the_year === "2024"))
//     .sort((a: any, b: any) => {
//       const monthOrder: any = {
//         Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
//       };

//       return monthOrder[a.short_month_name ?? a.month_short_name] - monthOrder[b.short_month_name ?? b.month_short_name];
//     });

//   filteredData.forEach((entry: any) => {
//     result.months.push(entry.short_month_name ?? entry?.month_short_name);
//     result.categories.push(`${entry.short_month_name ?? entry?.month_short_name} ${entry.the_year}`);
//     result.gci_data.push(entry.agent_monthly_gci);
//   });

//   return result;
// };
const generateGciDataByYear = (gciData: any): Record<string, any> => {
  const result: Record<string, any> = {
    months: [],
    gci_data: [],
    categories: [],
  };

  // const filteredData = gciData
  //   .filter((entry: any) => (entry.the_year === "2023" || entry.the_year === "2024"))
  //   .sort((a: any, b: any) => {
  //     const yearOrder: any = {
  //       "2023": 1,
  //       "2024": 2,
  //     };

  //     const monthOrder: any = {
  //       Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
  //     };

  //     const yearComparison = yearOrder[a.the_year] - yearOrder[b.the_year];
  //     if (yearComparison !== 0) {
  //       return yearComparison;
  //     }

  //     return monthOrder[a.short_month_name ?? a.month_short_name] - monthOrder[b.short_month_name ?? b.month_short_name];
  //   });

  const filteredData = gciData
    .sort((a:any, b:any) => {
      // Compare by year
      if (parseFloat(a.the_year) !== parseFloat(b.the_year)) {
        return (parseFloat(a.the_year) - parseFloat(b.the_year));
      }

      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.short_month_name ?? a.month_short_name] - monthOrder[b.short_month_name ?? b.month_short_name];
    }).slice(-15);

  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry.the_year).slice(-2);
    result.months.push(entry.short_month_name ?? entry?.month_short_name);
    result.categories.push(`${entry.short_month_name ?? entry?.month_short_name} ${yearSplit}`);
    result.gci_data.push(entry.agent_monthly_gci / 1000);
  });

  return result;
};

const generateOfficeGciDataByYear = (gciData: any): Record<string, any> => {
  const result: Record<string, any> = {
    months: [],
    gci: [],
    categories: [],
  };

  const filteredData = gciData
    .sort((a:any, b:any) => {
    // Compare by year
      if (parseFloat(a.the_year) !== parseFloat(b.the_year)) {
        return (parseFloat(a.the_year) - parseFloat(b.the_year));
      }

      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.short_month_name ?? a.month_short_name] - monthOrder[b.short_month_name ?? b.month_short_name];
    }).slice(-15);

  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry.the_year).slice(-2);
    const gciValue = parseFloat(entry.gci);
    const last12GciValue = parseFloat(entry.last_12_gci);
    if (gciValue === 0 || last12GciValue === 0) {
      result.gci.push(0);
    } else {
      result.gci.push((parseFloat(entry.gci) || parseFloat(entry.last_12_gci)) / 1000);
    }
    result.months.push(entry.short_month_name ?? entry?.month_short_name);
    
    result.categories.push(`${entry.short_month_name ?? entry?.month_short_name} ${yearSplit}`);
  });

  return result;
};

const generateFinancialGCIDataByYear = (year: number, gciData: any): Record<string, any> => {
  const result: Record<string, any> = {
    year,
    months: [],
    categories: [],
    gci_data: [],
  };

  const filteredData = gciData
    .sort((a:any, b:any) => {
    // Compare by year
      if (parseFloat(a.the_year) !== parseFloat(b.the_year)) {
        return (parseFloat(a.the_year) - parseFloat(b.the_year));
      }
      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_short_name] - monthOrder[b.month_short_name];
    }).slice(-15);
    
  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry.the_year ?? "").slice(-2);
    result.months.push(entry.month_short_name ?? "");
    result.categories.push(`${entry?.month_short_name ?? ""} ${yearSplit ?? ""}`);
    result.gci_data.push(((entry.gci ?? entry?.forecast_gci) ?? 0) / 1000);
  });

  return result;
};

function generateTicks(data: any, tickCount = 11) {
  const firstValue: any = data[0];
  const lastValue: any = data[data.length - 1];
  const tickStep: any = (lastValue - firstValue) / (tickCount - 1);

  return Array.from({ length: tickCount }, (_, index) => firstValue + index * tickStep);
}
export { generateFinancialGCIDataByYear, generateGciDataByYear, generateOfficeGciDataByYear, generateTicks };
