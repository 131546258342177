import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePasswordResetMutation } from '../../../state/api';
import { selectTrackingUserId } from '../../../state/appSlice';
import { useAppSelector } from '../../../state/hooks';
import tracking, { TrackingActions, getTrackingData } from '../../../state/tracking';
import Field from '../../common/Field';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';
import { emailMismatchMessage, emailPattern, requiredFieldMessage } from '../../common/validation';
import AuthLayout from '../../layout/AuthLayout';

export interface RequestPasswordResetForm {
  email: string
}

export const requestPasswordResetSuccessMessage = 'A password reset email has been sent if a Kintaro account.';
export const requestPasswordResetApiFailureMessage = 'Error resetting password.';
export const requestPasswordResetNoUserMessage = 'No user with that email exists.';

const RequestPasswordReset = () => {
  const { control, handleSubmit } = useForm();
  const [requestPasswordReset, requestPasswordResetData] = usePasswordResetMutation();
  const userId = useAppSelector(selectTrackingUserId);

  const handleRequestPasswordReset = handleSubmit((form: RequestPasswordResetForm) => {
    requestPasswordReset({ passwordReset: form }).unwrap()
      .then(() => {
        toast.dark(requestPasswordResetSuccessMessage, { type: 'success' });
        tracking.track(TrackingActions.PASSWORD_RESET_REQUEST.valueOf(), getTrackingData(userId!));
      })
      .catch((error) => {
        if (error.status === 400) {
          toast.dark(requestPasswordResetNoUserMessage, { type: 'error' });
        } else {
          toast.dark(requestPasswordResetApiFailureMessage, { type: 'error' });
        }
      });
  });

  return (
    <AuthLayout modalTitle="Reset Password - Kintaro" pageTitle="Password Reset">
      <form
        className="form-horizontal"
        onSubmit={handleRequestPasswordReset}
      >
        <div className="mb-3">
          <Field
            control={control}
            label="Email"
            name="email"
            placeholder="Enter email"
            rules={{
              required: requiredFieldMessage('Email'),
              pattern: { value: emailPattern, message: emailMismatchMessage },
            }}
          />
        </div>

        <div className="d-grid">
          {/* <LoadingIcon className="mt-1 mb-4" loading={requestPasswordResetData.isLoading} /> */}
          <button
            className="btn btn-primary btn-block"
            disabled={requestPasswordResetData.isLoading}
            type="submit"
          >
            { requestPasswordResetData.isLoading ? <LoadingIcon className="mb-0 custom-h-9" loading={requestPasswordResetData.isLoading} /> : "Reset Password"}
          </button>
        </div>
        <div className="mt-4 text-center">
          <Link to="/login" className="text-secondary d-flex align-items-end justify-content-center">
            <LockOpenIcon />
            &nbsp;
            Go back to Login
          </Link>
        </div>
      </form>
    </AuthLayout>
  );
};

export default RequestPasswordReset;
