import Pagination from "@components/common/Pagination/Pagination";
import TablePlaceHolder from "@components/common/TablePlaceHolder";
import GlobalDraftEmailModal from "@components/recommendation/GlobalDraftEmailModal";
import { RA_COMPLETED, RA_IGNORE, SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { faker } from "@faker-js/faker";
import { actionUserPermissions, selectUser } from "@state/appSlice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { http } from "@utils/http";
import { CheckMarkIcon, EmailIcon, EyeCrossIcon } from "@utils/icons";
import { useEffect, useState } from "react";
import RecommendedActionCtaModal from "./RecommendedActionCtaModal";
// import RecommendedActionCtaModal from "./RecommendedActionCtaModal";

function RecommendedActions() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(4 as any);
  const [perPage, setPerPage] = useState(5);
  const dispatch = useAppDispatch();
  const selectedUser: any = useAppSelector(selectUser);
  const [isPermissionDataLoading, setIsPermissionDataLoading] = useState(true);

  const [recommendationData, setRecommendationData] = useState([] as any);
  const [selectedRecommendationData, setSelectedRecommendationData] = useState(
    [] as any,
  );
  const [recommendedActionCtaStatus, setRecommendedActionCtaStatus] = useState(
    "" as any,
  );
  const [modals, setModals] = useState({
    draftEmailModal: false,
    recommendedActionCtaModal: false,
  });
  const [isDataLoading, setIsDataLoading] = useState({});

  useEffect(() => {
    if (selectedUser?.id) {
      setIsPermissionDataLoading(true);
      // TODO: here call user permission api
      http
        .get(
          `user-permission-site-data.json`,
        )
        .then((response: any) => {
          const { permission } = response?.data;
          const getUserPerms = response?.data?.permission[0]?.permissions;
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: getUserPerms ?? [] }));
          setIsPermissionDataLoading(false);
        })
        .catch((error) => {
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: [] }));
          setIsPermissionDataLoading(false);
          console.log(error);
        });
    }
  }, [selectedUser]);

  useEffect(() => {
    setRecommendationData([]);
    getToolsPageRecommendationData();
  }, [currentPage, perPage]);

  /**
   * Modal close emit
   * Common for all typeof modal.
   *
   * @returns {void}
   */
  function modalCloseEmit(data: any) {
    setModals((prevState) => ({
      ...prevState,
      [data.modalName]: false,
    }));
  }

  function draftEmailModalOpen(data: any) {
    setSelectedRecommendationData(data);
    setModals((prevState) => ({
      ...prevState,
      draftEmailModal: true,
    }));
  }
  function recommendedActionCtaModal(data: any, status: string) {
    setRecommendedActionCtaStatus(status);
    setSelectedRecommendationData(data);
    setModals((prevState) => ({
      ...prevState,
      recommendedActionCtaModal: true,
    }));
  }
  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected + 1);
  };
  function getToolsPageRecommendationData() {
    setIsDataLoading(true);
    http
      .get("/recommendation/fetch-recommendations-tools.json")
      .then((response: any) => {
        setIsDataLoading(false);
        const { data }: any = response;
        let theData = data?.recommendations;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            description: el?.demo_description !== "" ? el.demo_description : faker.lorem.paragraph(),
          }));
        }
        setRecommendationData(theData);
        setTotalPage(data?.last_page);
      });
  }
  return (
    <>
      {modals.draftEmailModal && (
        <GlobalDraftEmailModal
          modalStatus={modals.draftEmailModal}
          modalName="draftEmailModal"
          recommendationData={selectedRecommendationData}
          refetchData={() => {
            setRecommendationData([]);
            getToolsPageRecommendationData();
          }}
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
      {modals.recommendedActionCtaModal && (
        <RecommendedActionCtaModal
          modalStatus={modals.recommendedActionCtaModal}
          modalName="recommendedActionCtaModal"
          status={recommendedActionCtaStatus}
          recommendationData={selectedRecommendationData}
          refetchData={() => {
            setRecommendationData([]);
            getToolsPageRecommendationData();
          }}
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
      <div
        className="mb-4 custom-mw-685 mx-auto"
        style={{ backgroundColor: "#2B3041" }}
      >
        <div className="custom-modal-inner__padding">
          <div className="row">
            <div className="col">
              <p className="header-title text-left text-md-center">
                Recomended Actions
              </p>
              {isDataLoading && (
                <table className="w-100">
                  <TablePlaceHolder
                    items={recommendationData}
                    loading={isDataLoading}
                    rows={25}
                    cols={8}
                  />
                </table>
              )}
              {(!isDataLoading && recommendationData?.length)
                ? recommendationData?.map(
                  (recommendation: any, index: number) => (
                      <div
                        key={recommendation?.id}
                        className="card"
                        // style={{ backgroundColor: "#262B3C" }}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#262B3C" : "#1E2330",
                          marginTop: index !== 0 ? "-24px" : "",
                        }}
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 col-lg-6 order-1 order-lg-1 px-0">
                              <p className="mb-0 text-white fw-semibold">
                                {recommendation?.title}
                              </p>
                            </div>
                            <div className="col-12 col-lg-6 order-3 order-lg-2 px-0">
                              <div className="d-flex gap-2 justify-content-start justify-content-lg-end">
                                <button
                                  type="button"
                                  onClick={() => recommendedActionCtaModal(
                                    recommendation,
                                    RA_IGNORE,
                                  )}
                                  // disabled={isDemo()}
                                  className="btn btn-sm w-sm pe-auto border-white text-white"
                                >
                                  <EyeCrossIcon className="d-none d-lg-inline-block" />
                                  <span className="ms-1 fw-bold">Ignore</span>
                                </button>
                                <button
                                  type="button"
                                  onClick={() => recommendedActionCtaModal(
                                    recommendation,
                                    RA_COMPLETED,
                                  )}
                                  // disabled={isDemo()}
                                  className="btn button-ash w-sm text-black btn-sm fw-bold"
                                >
                                  <CheckMarkIcon className="d-none d-lg-inline-block" />
                                  Complete
                                </button>
                                {recommendation?.has_draft_mail ? (
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm w-sm fw-bold"
                                    onClick={() => draftEmailModalOpen(recommendation)}
                                    // disabled={isDemo()}
                                  >
                                    <EmailIcon
                                      width={20}
                                      height={20}
                                      className="d-none d-lg-inline-block"
                                    />
                                    Draft Email
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="d-flex order-2 order-lg-3 px-0">
                              <p className="mt-3 custom-text-fs">
                                {recommendation?.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                  ),
                )
                : !isDataLoading && (
                    <div className="row">
                      <div className="col-12 text-center p-4">
                        No Data Available
                      </div>
                    </div>
                )}
            </div>
          </div>
          <div>
            {recommendationData?.length ? (
              <div className="mt-1">
                {/* <div className="col-12 col-lg-4 ps-lg-0 d-flex gap-2 align-items-center">
                <p className="mb-0 custom-fs-12-to-14">Rows Per Page</p>
                <PerPageOptionSelectorV2
                  defaultPerPageValue={perPage}
                  perPageEmitSet={(value: any) => setPerPage(value)}
                  labelUnit=" "
                />
              </div>
              <div className="col-12 col-lg-8 d-flex justify-content-center justify-content-lg-start align-items-center">
                <Pagination
                  pageCount={totalPage}
                  perPage={perPage}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </div> */}
                <Pagination
                  pageCount={totalPage}
                  perPage={perPage}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default RecommendedActions;
