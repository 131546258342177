import { ThreeDots } from "react-loader-spinner";

function ThreeDotLoader({ isDataLoading, wrapperClasses = "", color = "#FFF5DE" }: any) {
  return (
    <ThreeDots
      visible={isDataLoading}
      height="60"
      width="60"
      color={color}
      radius="9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClass={wrapperClasses}
    />
  );
}

export default ThreeDotLoader;
