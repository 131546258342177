import Placeholder from "@components/common/Placeholder";
import { STATUS_INACTIVE } from "@constants/variables";
import { getFakerPhoneNumber } from "@utils/fakers-data";
import { formatDate } from "@utils/helpers";
import { ProfileIcon, ProfileIconInMobile } from "@utils/icons";

function AgentProfileInfo(props: any) {
  const { agentInfo, isLoadindData } = props;
  return (
    <div className="mb-1 h-100" style={{ backgroundColor: "#2B3041" }}>
      <div className="custom-modal-inner__padding">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <p className="header-subtitle text-white mb-0">Agent Profile</p>
          {agentInfo?.agent_status === STATUS_INACTIVE ? (
            <span className="badge badge-soft-danger custom-fs-static-13">
              {STATUS_INACTIVE}
            </span>
          ) : (
            ""
          )}
        </div>

        <div className="row">
          {/* profile icon */}
          <div className="col-6 col-lg-12 pe-1">
            <div className="row">
              {/* Profile Icon and left column */}
              <div className="col-3 col-lg-12 d-flex gap-3 text-break">
                <div className="d-inline-flex d-lg-flex justify-content-center">
                  <ProfileIcon classNames="d-none d-lg-block" width="60" height="60" />
                  <ProfileIconInMobile classNames="d-block d-lg-none" />
                </div>
                {/* Mobile hidden */}
                <p className="text-left d-none d-lg-flex align-items-center  text-lg-center header-subtitle-2 text-white mb-1 mt-0 mt-lg-2">
                  <Placeholder
                    loading={isLoadindData}
                    className="custom-loader-w-20 custom-loader-h-6"
                  ></Placeholder>
                  {/* {
                    !isLoadindData && `${agentInfo?.first_name} ${agentInfo?.last_name}`
                  } */}
                  {agentInfo?.first_name} {agentInfo?.last_name}
                </p>
              </div>

              {/* Right column */}
              <div className="col-9 col-lg-12 pe-1">
                <p className="text-left d-block d-lg-none text-lg-center header-subtitle-2 text-white mb-1 mt-0 mt-lg-2">
                  <Placeholder
                    loading={isLoadindData}
                    className="custom-loader-w-20 custom-loader-h-6"
                  ></Placeholder>
                  {/* {
                   !isLoadindData && getFullName(`${agentInfo?.first_name} ${agentInfo?.last_name}`)
                  } */}
                  {agentInfo?.first_name} {agentInfo?.last_name}
                </p>

                {/* mobile hidden */}
                <div className="d-none d-lg-flex gap-3 text-break mt-lg-3">
                  <p className="text-left custom-fs-12 mb-0">Office</p>
                  <p className="text-left mb-0 fw-medium text-white custom-fs-12">
                    <Placeholder
                      loading={isLoadindData}
                      className="custom-loader-w-20 custom-loader-h-6"
                    ></Placeholder>
                    {/* {
                      !isLoadindData && getOfficeName(agentInfo?.short_name)
                    } */}
                    {agentInfo?.short_name}
                  </p>
                </div>

                <div className="d-block d-lg-flex gap-3 mt-lg-3">
                  <p className="text-left mb-0 custom-fs-12">Start date</p>
                  <p className="text-left fw-medium mb-0 text-white custom-fs-12">
                    <Placeholder
                      loading={isLoadindData}
                      className="custom-loader-w-20 custom-loader-h-6"
                    ></Placeholder>
                    {formatDate(agentInfo?.start_date)}
                  </p>
                </div>
                {agentInfo?.agent_status === STATUS_INACTIVE ? (
                  <div className="d-block d-lg-flex gap-3 mt-2 mt-lg-3">
                    <p className="text-left mb-0 custom-fs-12">End date</p>
                    <p className="text-left fw-medium mb-0 text-white custom-fs-12">
                      <Placeholder
                        loading={isLoadindData}
                        className="custom-loader-w-20 custom-loader-h-6"
                      ></Placeholder>
                      {formatDate(agentInfo?.end_date)}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {/* Right aligned */}
          <div className="col-6 col-lg-12">
            {/* desktop hidden */}
            <div className="d-block d-lg-none gap-3 text-break">
              <p className="mb-0 custom-fs-12">Office</p>
              <p className="text-white mb-0 custom-fs-12">
                <Placeholder
                  loading={isLoadindData}
                  className="custom-loader-w-20 custom-loader-h-6"
                ></Placeholder>
                {/* {
                  !isLoadindData && getOfficeName(agentInfo?.short_name)
                } */}
                {agentInfo?.short_name}
              </p>
            </div>

            <div className="d-lg-flex gap-3 mt-2 mt-lg-3">
              <p className="mb-0 custom-fs-12">Phone</p>
              <p className="text-white mb-0 custom-fs-12">
                <Placeholder
                  loading={isLoadindData}
                  className="custom-loader-w-20 custom-loader-h-6"
                ></Placeholder>

                {/* {!isLoadindData && (formatPhoneNumber(agentInfo?.phone_number) ?? "N/A")} */}
                {!isLoadindData && (agentInfo?.phone_number?.length > 1 ? getFakerPhoneNumber(agentInfo?.phone_number) : "N/A")}
                {/* {!isLoadindData && getFakerPhoneNumber((agentInfo?.phone_number))} */}
              </p>
            </div>
            <div className="d-lg-flex gap-lg-3 text-break mt-2 mt-lg-3">
              <p className="mb-0 custom-fs-12">Email</p>
              <p className="text-white mb-0 custom-fs-12">
                <Placeholder
                  loading={isLoadindData}
                  className="custom-loader-w-20 custom-loader-h-6"
                ></Placeholder>

                {!isLoadindData && agentInfo?.email}
              </p>
            </div>
            <div className="d-lg-flex gap-lg-3 text-break mt-2 mt-lg-3">
              <p className="mb-0 custom-fs-12">Ninja</p>
              <p className="text-white mb-0 custom-fs-12">
                <Placeholder
                  loading={isLoadindData}
                  className="custom-loader-w-20 custom-loader-h-6"
                ></Placeholder>

                {!isLoadindData && (agentInfo?.ninja_certification === "Ninja" ? "Yes" : "No")}
              </p>
            </div>
            <div className="d-lg-flex gap-lg-3 text-break mt-2 mt-lg-3">
              <p className="mb-0 custom-fs-12">Ninja Completion Date</p>
              <p className="text-white mb-0 custom-fs-12">
                <Placeholder
                  loading={isLoadindData}
                  className="custom-loader-w-20 custom-loader-h-6"
                ></Placeholder>

                {!isLoadindData && (formatDate(agentInfo?.ninja_certification_date) ?? "")}
              </p>
            </div>
          </div>

          {/* Years at brokerage */}
          {/* <div className="col-2 col-lg-12 px-0"></div>
          <div className="col-10 col-lg-12 ps-0 ps-lg-2 mt-3 custom-width__yb">
            <div className="d-inline-block">
              <span className="header-title-4 text-white mb-0 pe-1">
                Years at brokerage
              </span>
              <div className="d-inline-block align-middle">
                <HelpIcon
                  tooltip={agentToolTip?.years_at_brokerage}
                  width={20}
                  height={20}
                />
              </div>
            </div>
            <p className="text-center number-stats-4 text-white mb-0 ">
              <Placeholder
                loading={isLoadindData}
                className="w-8 h-8"
              ></Placeholder>
              {!isLoadindData && (agentInfo?.years_as_agent || 0)}
            </p>
          </div> */}
          {/* <div className="d-block text-center mt-3">
            <div className="d-inline-block">
              <span className="header-subtitle text-white mb-0 pe-1">
                Years at brokerage
              </span>
              <div className="d-inline-block align-middle">
                <HelpIcon
                  tooltip={agentToolTip?.years_at_brokerage}
                  width={20}
                  height={20}
                />
              </div>
            </div>
            <p className="text-center number-stats-4 text-white mb-0 ">
              <Placeholder
                loading={isLoadindData}
                className="w-8 h-8"
              ></Placeholder>
              {!isLoadindData && (agentInfo?.years_as_agent || 0)}
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AgentProfileInfo;
