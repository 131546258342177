const generateRevenueData = (revenueData: any): Record<string, any> => {
  const result: Record<string, any> = {
    year: [],
    months: [],
    total_gci: [],
    new_agent_gci: [],
    revenueCategories: [],
  };
    
  const filteredData = revenueData
    .sort((a:any, b:any) => {
      // Compare by year
      if (parseFloat(a.the_year) !== parseFloat(b.the_year)) {
        return (parseFloat(a.the_year) - parseFloat(b.the_year));
      }
      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_short_name] - monthOrder[b.month_short_name];
    });
    
  filteredData.forEach((entry: any) => {
    const categories = `${entry.month_short_name ?? ""} ${entry.the_year ?? ""}`;
    result.months.push(entry.month_short_name ?? "");
    result.total_gci.push((entry.total_gci ?? 0) / 1000);
    result.new_agent_gci.push((entry.new_agent_gci ?? 0) / 1000);
    result.revenueCategories.push(categories);
  });
    
  return result;
};

function generateRevenueTicks(data1: any, data2:any, tickCount:any = 11) {
  const d1MinValue = Math.min(...data1);
  const d1MaxValue = Math.max(...data1);
  const d2MinValue = Math.min(...data2);
  const d2MaxValue = Math.max(...data2);
  const firstValue: any = Math.min(d1MinValue, d2MinValue);
  const lastValue: any = Math.max(d1MaxValue, d2MaxValue);
   
  const tickStep: any = (lastValue - firstValue) / (tickCount - 1);

  return Array.from({ length: tickCount }, (_, index) => firstValue + index * tickStep);
}

export { generateRevenueData, generateRevenueTicks };
