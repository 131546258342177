import SearchSelect from "@components/common/SearchSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import type { FormFields } from "@utils/form-field";
import { ButtonSpinnerSvg } from "@utils/icons";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, ModalBody } from "reactstrap";
import * as yup from "yup";

function CreateAgentModal(props: any) {
  // All state.
  const [openModal, setOpenModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [modalName] = useState("createAgentModal");
  const userSchema: any = yup.object().shape({
    username: yup.string().required("Username is required"),
    firstname: yup.string().required("First Name is required"),
    lastname: yup.string().required("Last Name start date is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    branch: yup.object().required("Branch is required"),
    userlabel: yup.string().required("User Label is required"),
    workphone: yup.number().required("Work Phone is required"),
    password: yup.string().required("Password end date is required"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("password"), ""], "Passwords must match")
      .required("Confirm Password is required"),
    title: yup.string().nullable(),
    bio: yup.string().nullable(),
    homephone: yup.string().nullable(),
    mobilephone: yup.string().nullable(),
    city: yup.string().nullable(),
    street: yup.string().nullable(),
    state: yup.string().nullable(),
    zip: yup.string().nullable(),
    currentstatus: yup.string().nullable(),
    twitter: yup.string().nullable(),
    instagram: yup.string().nullable(),
    facebook: yup.string().nullable(),
    linkedin: yup.string().nullable(),
  });
  const [agentInfo, setAgentInfo] = useState({
    userName: "",
    firstname: "",
    lastname: "",
    email: "",
    userlabel: "",
    workphone: "",
    password: "",
    confirm_password: "",
  } as any);
  const [selectedBranch, setSelectedBranch] = useState({} as any);

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormFields>({
    mode: "onChange",
    resolver: yupResolver(userSchema),
  });

  useEffect(() => {
    if (props.modalName === modalName) {
      setOpenModal(props.modalStatus);
    }
  }, [setOpenModal, props.modalStatus]);

  // Added an event listener to handle 'Escape' key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && openModal) {
        modalClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openModal]);

  function modalClose() {
    setOpenModal(false);
    setTimeout(() => {
      props.modalClose({
        modalName,
        isInitialpage: true,
      });
    }, 1000);
  }

  function refetchData() {
    const data:any = {
      isInitialpage: true,
    };
    
    setTimeout(() => {
      props?.refetchData(data);
    }, 1000);
  }

  function handleInputChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    const { name, value } = e.target;
    setAgentInfo((prevInfo: any) => ({
      ...prevInfo,
      [name]: value,
    }));
    setValue(name, value);
    trigger(name);
    // // Revalidate the field
    // setTimeout(() => {
    //   trigger(`${name}`);
    // }, 500);
  }

  function submitAgentData(data: any) {
    refetchData();
    modalClose();
  }
  return (
    <Modal size="lg" isOpen={openModal} centered={true} >
      <div className="modal-content" style={{ backgroundColor: "#222736" }}>
        <div className="container">
          <button
            type="button"
            onClick={modalClose}
            className="btn-close position-absolute custome-z-50 end-0 top-0 m-3"
          ></button>
        </div>
        <ModalBody className="custom-modal-outer__padding">
          <p className="mt-2 custom-form-header text-white">Create user</p>
          {/* profile info start */}
          <form>
            <div
              className="text-white mb-4"
              style={{ backgroundColor: "#2B3041" }}
            >
              <div className="custom-modal-inner__padding">
                <p className="custom-form-subheader">Profile Info</p>
                <div className="row bg-input">
                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Username
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      {...register("username")}
                      type="text"
                      name="username"
                      value={agentInfo.username}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                    <p className="text-danger">{errors?.username?.message}</p>
                  </div>

                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      First Name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      {...register("firstname")}
                      type="text"
                      name="firstname"
                      value={agentInfo.firstname}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                    <p className="text-danger">{errors?.firstname?.message}</p>
                  </div>

                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Last Name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      {...register("lastname")}
                      type="text"
                      name="lastname"
                      value={agentInfo.lastname}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                    <p className="text-danger">{errors?.lastname?.message}</p>
                  </div>

                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Password
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      {...register("password")}
                      type="password"
                      name="password"
                      value={agentInfo.password}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                    <p className="text-danger">{errors?.password?.message}</p>
                  </div>

                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Confirm Password
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      {...register("confirm_password")}
                      type="password"
                      name="confirm_password"
                      value={agentInfo.confirm_password}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                    <p className="text-danger">
                      {errors?.confirm_password?.message}
                    </p>
                  </div>
                  <div className="row pe-0">
                    <div className="mb-3 col-12 col-lg-4 pe-0 pe-lg-2">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Branch
                        <span className="text-danger">*</span>
                      </label>
                      <SearchSelect
                        {...register("branch")}
                        options={[
                          {
                            id: 1,
                            title: "one",
                          },
                          {
                            id: 2,
                            title: "two",
                          },
                        ]}
                        value={
                          JSON.stringify(selectedBranch) !== "{}"
                            ? selectedBranch
                            : "Select Branch"
                        }
                        onChange={(option: any) => {
                          setSelectedBranch(option);
                          setValue("branch", option);
                          trigger("branch");
                        }}
                        isClearable
                        getOptionValue={(option: any) => option.id}
                        getOptionLabel={(option: any) => `${option.title}`}
                        placeholder="Select Branch"
                      />
                      <p className="text-danger">{errors?.branch?.message}</p>
                    </div>

                    <div className="mb-3 col-12 col-lg-8 pe-0">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Title
                      </label>
                      <input
                        {...register("title")}
                        type="text"
                        name="title"
                        value={agentInfo.title}
                        onChange={handleInputChange}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* contact info start */}
            <div
              className="text-white mb-4"
              style={{ backgroundColor: "#2B3041" }}
            >
              <div className="custom-modal-inner__padding">
                <p className="custom-form-subheader">Contact Info</p>
                <div className="row bg-input">
                  <div className="row pe-0">
                    <div className="mb-3 col-12 col-lg-4 pe-2">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        email
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("email")}
                        type="text"
                        name="email"
                        value={agentInfo?.email}
                        onChange={handleInputChange}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder=""
                      />
                      <p className="text-danger">{errors?.email?.message}</p>
                    </div>

                    <div className="mb-3 col-12 col-lg-8 pe-0">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Bio
                      </label>
                      <input
                        {...register("bio")}
                        type="text"
                        name="bio"
                        value={agentInfo.bio}
                        onChange={handleInputChange}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Work Phone
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      {...register("workphone")}
                      type="number"
                      name="workphone"
                      value={agentInfo?.workphone}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>

                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Home Phone
                    </label>
                    <input
                      {...register("homephone")}
                      type="text"
                      name="homephone"
                      value={agentInfo.homephone}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>

                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Mobile Phone
                    </label>
                    <input
                      {...register("mobilephone")}
                      type="text"
                      name="mobilephone"
                      value={agentInfo.mobilephone}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>

                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      City
                    </label>
                    <input
                      {...register("city")}
                      type="text"
                      name="city"
                      value={agentInfo.city}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>
                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Street
                    </label>
                    <input
                      {...register("street")}
                      type="text"
                      name="street"
                      value={agentInfo.street}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>
                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      State
                    </label>
                    <input
                      {...register("state")}
                      type="text"
                      name="state"
                      value={agentInfo.state}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>
                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Zip
                    </label>
                    <input
                      {...register("zip")}
                      type="text"
                      name="zip"
                      value={agentInfo.zip}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* other info start */}
            <div
              className="text-white mb-4"
              style={{ backgroundColor: "#2B3041" }}
            >
              <div className="custom-modal-inner__padding">
                <p className="custom-form-subheader">Other Info</p>
                <div className="row bg-input">
                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      User Level
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="userlabel"
                      value={agentInfo.userlabel}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                    <p className="text-danger">{errors?.userlabel?.message}</p>
                  </div>

                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Current Status
                    </label>
                    <input
                      {...register("currentstatus")}
                      type="text"
                      name="currentstatus"
                      value={agentInfo.currentstatus}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>

                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Twitter URL
                    </label>
                    <input
                      {...register("twitter")}
                      type="text"
                      name="twitter"
                      value={agentInfo.twitter}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>

                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Instragram URL
                    </label>
                    <input
                      {...register("instagram")}
                      type="text"
                      name="instagram"
                      value={agentInfo.instagram}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>

                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Linkedin URL
                    </label>
                    <input
                      {...register("linkedin")}
                      type="text"
                      name="linkedin"
                      value={agentInfo.linkedin}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>

                  <div className="mb-3 col-12 col-lg-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Facebook URL
                    </label>
                    <input
                      {...register("facebook")}
                      type="text"
                      name="facebook"
                      value={agentInfo.facebook}
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex gap-3 justify-content-center">
              <button type="button" onClick={modalClose} className="btn btn-outline-secondary">
                Cancel
              </button>
              <button
                type="submit"
                disabled={processing}
                onClick={handleSubmit(submitAgentData)}
                className={`btn btn-primary ${processing ? "opacity-75" : ""}`}
              >
                {processing ? (
                  <div className="flex items-center">
                    <ButtonSpinnerSvg />
                    Creating...
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default CreateAgentModal;
