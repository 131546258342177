import React from 'react';
import { Label } from 'reactstrap';
import { Control, Controller, FieldPath } from 'react-hook-form';
import Select from 'react-select';
import { createUseStyles } from 'react-jss';
import {
  error,
  formBackground, formBackgroundDisabled,
  formBorder,
  formColor, formColorDisabled,
  primary,
  white,
} from '../../assets/colors';

const colors = {
  backgroundColor: formBackground,
  color: formColor,
};

const disabledColors = {
  backgroundColor: formBackgroundDisabled,
  color: formColorDisabled,
};

export const selectFieldStyles = {
  container: (base: any) => ({
    ...base,
    overflow: 'visible',
  }),
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isDisabled ? disabledColors.backgroundColor : colors.backgroundColor,
    color: state.isDisabled ? disabledColors.color : colors.color,
    border: `1px solid ${formBorder}`,
    borderColor: `${formBorder} !important`,
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    opacity: state.isDisabled ? '.125' : '.5',
  }),
  indicatorSeparator: (base: any, state: any) => ({
    ...base,
    opacity: state.isDisabled ? '.125' : '.5',
  }),
  input: (base: any) => ({
    ...base,
    color: formColor,
  }),
  menu: (base: any) => ({
    ...base,
    ...colors,
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isFocused ? primary : formBackground,
    color: state.isFocused ? white : formColor,
  }),
  placeholder: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isDisabled ? disabledColors.backgroundColor : colors.backgroundColor,
    color: state.isDisabled ? disabledColors.color : colors.color,
  }),
  singleValue: (base: any) => ({
    ...base,
    ...colors,
  }),
};

export interface SelectFieldOption {
  label: string
  options?: {
    label: string,
    value: string,
  }[]
  value?: string
}

interface SelectFieldProps {
  clearable?: boolean
  control: Control<any>
  defaultValue?: SelectFieldOption
  disabled?: boolean
  label?: string
  loading?: boolean
  multi?: boolean
  name: FieldPath<Record<string, any>> | string
  onChange?: (value: SelectFieldOption | null) => void
  options: SelectFieldOption[]
  placeholder?: string
  rules: any
}

const overrideStyles = createUseStyles({
  SelectField__error: {
    '& > [class$="-control"]': {
      'border-color': `${error} !important`,
    },
  },
});

const SelectField = ({
  clearable, control, defaultValue, disabled, label, loading,
  multi, name, onChange, options, placeholder, rules,
}: SelectFieldProps) => {
  const classes = overrideStyles();
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {
          let onChangeMethod = field.onChange;
          if (onChange != null) {
            onChangeMethod = (value: any) => {
              onChange!(value);
              field.onChange(value);
            };
          }
          return (
            <div className={fieldState.error != null ? 'form-group text-danger' : 'form-group'}>
              {label !== '' && <Label id={`${name}Label`} for={name}>{label}</Label>}
              <Select
                {...field}
                aria-labelledby={`${name}Label`}
                className={fieldState.error != null ? classes.SelectField__error : ''}
                defaultValue={defaultValue}
                id={name}
                isClearable={clearable}
                isDisabled={loading || disabled}
                isLoading={loading}
                isMulti={multi}
                name={name}
                onChange={onChangeMethod}
                options={options}
                placeholder={placeholder}
                ref={field.ref}
                styles={selectFieldStyles}
              />
              {fieldState.error != null && (
              <div className="invalid-feedback" style={{ display: 'unset' }}>
                {fieldState.error.message}
              </div>
              )}
            </div>
          );
        }}
      />
    </>
  );
};

SelectField.defaultProps = {
  clearable: false,
  defaultValue: undefined,
  disabled: false,
  label: '',
  loading: false,
  multi: false,
  onChange: () => {},
  placeholder: 'Select...',
};

export default SelectField;
