import HelpIcon from "@components/common/HelpIcon";
import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
import generateColumnChartOptions from "@utils/chart/bar-chart";
import { http } from "@utils/http";
import { generateCompletedRecommendationData } from "@utils/transform/officePage";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function CompletedRecommendation() {
  const [completedRecommendationData, setCompletedRecommendationData] =
    useState([] as any);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [chartOptions, setChartOptions] = useState({} as any);

  useEffect(() => {
    getCompletedRecommendationData();
  }, []);

  function getCompletedRecommendationData() {
    setIsDataLoading(true);
    http
      .get("/recommendation/completed-recommendations.json")
      .then((response: any) => {
        const apiData = response?.data?.recommendations;

        const theData: any = generateCompletedRecommendationData(apiData);
        setCompletedRecommendationData(theData);

        const formattedChartOptions: any = generateColumnChartOptions({
          theId: "completed-recommendation-chart",
          colors: ["#F5822F", "#CF4AFE"],
          categories: theData?.categories ?? [],
          // ticks: generateSVsLTicks(theData ?? []),
          // columnWidthProps: "70%",
          // ticksPreLast: ["$", 'k'],
          // forecast: 3,
        });
        setChartOptions(formattedChartOptions);
        setIsDataLoading(false);
      })
      .catch((error: any) => {
        setIsDataLoading(false);
        console.log(error);
      });
  }
  return (
    <div className="row mb-4">
      <div className="">
        <div
          className="custom-modal-inner__padding"
          style={{ backgroundColor: "#2A3042" }}
        >
          <div className="row row-gap-2">
            <div className="col-12 col-md-8">
              <div className="d-flex align-items-center gap-1">
                <div className="header-title">Completed recommendations</div>
                <HelpIcon
                  tooltip="This chart helps break down the total number of recommendations we
                provided, and how many were executed on an office by office basis."
                />
              </div>
            </div>
            {/* <div className="col-12 col-md-4">
              <div className="d-flex justify-content-end">
                <div className="custom-min-width-180">
                  <SearchSelect
                    options={[
                      {
                        id: 1,
                        title: "1 years",
                      },
                      {
                        id: 2,
                        title: "2 years",
                      },
                      {
                        id: 3,
                        title: "3 years",
                      },
                    ]}
                    isClearable
                    getOptionValue={(option: any) => option.id}
                    getOptionLabel={(option: any) => `${option.title}`}
                    placeholder="Select Years"
                  />
                </div>
              </div>
            </div> */}
          </div>
          <div>
            {isDataLoading && <div className="loader-circle"></div>}
            {isDataLoading ? (
              <DefaultLineChartUI />
            ) : (
              <div id="bar-column-chart" dir="ltr">
                <ReactApexChart
                  options={chartOptions}
                  series={[
                    {
                      name: "Total Recommendations",
                      data: completedRecommendationData?.total_recommendation ?? [],
                    },
                    {
                      name: "Completed Recommendations",
                      data: completedRecommendationData?.total_completed ?? [],
                    },
                  ]}
                  type="bar"
                  height={320}
                  className="apex-charts"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompletedRecommendation;
