import HelpIcon from "@components/common/HelpIcon";
import Placeholder from "@components/common/Placeholder";
import { agentToolTip } from "@constants/tooltip-data";
import generateLineChartOptions from "@utils/chart/line-chart";
import { formatNumberWithCommas, getTickAmount } from "@utils/helpers";
import { http } from "@utils/http";
import { generateGciDataByYear, generateTicks } from "@utils/transform/gci";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function ForeCastedRevenue({ agentId }: any) {
  const [gci, setGci] = useState({} as any);
  const [gciApiData, setGciApiData] = useState([] as any);
  const [theOptions, setTheOptions] = useState({} as any);
  const [isLoadingGci, setIsLoadingGci] = useState(true);

  useEffect(() => {
    if (agentId !== "") {
      getGCIForecast();
    }
  }, [agentId]);

  function getGCIForecast() {
    setIsLoadingGci(true);
    http
      .get("/agent/agents-gci-forecast.json")
      .then((response: any) => {
        const apiData = response.data.gci_forecast;
        getForcastedData(apiData);
      });
  }
  function getForcastedData(data: any) {
    return http
      .get("/serverless/agent-gci-line-chart.json")
      .then((response: any) => {
        const forecastApiData = response?.data?.forecast;
        const reverseData = forecastApiData.reverse();
        const mergedData = [...reverseData, ...data];

        const theData: any = generateGciDataByYear(mergedData);
        setGci(theData);
        setGciApiData(data);
        
        const chartOptions: any = generateLineChartOptions({
          theId: "line-chart",
          colors: ["#CF4AFE"],
          categories: theData?.categories,
          ticks: generateTicks(data?.gci_data ?? []),
          tickAmount: getTickAmount(theData?.gci_data),
          ticksPreLast: ["$", "k"],
          forecast: forecastApiData?.length,
        });

        setTheOptions(chartOptions);
        setIsLoadingGci(false);

        return response?.data?.forecast || [];
      })
      .catch((err: any) => {
        setIsLoadingGci(false);
        console.log(err);
      });
  }
  return (
    <>
      <div className="mb-4 " style={{ backgroundColor: "#2A3042" }}>
        <div className="custom-modal-inner__padding">
          <div className="row bg-input">
            <div className="row card-loader">
              <div className="col-12 col-lg-8 pe-0 ">
                <div className="d-inline-block">
                  <span className="header-title pe-2 ">
                    3 Month Forecasted GCI
                  </span>
                  <div className="d-inline-block align-middle">
                    <HelpIcon
                      tooltip={`${agentToolTip.forecastedRevenue}`}
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
                {isLoadingGci && <div className="loader-circle"></div>}
                <div id="line-chart" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: "GCI",
                        data: gci?.gci_data ?? [],
                      },
                    ]}
                    options={theOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div>
                  <p className="mb-1 mt-3 mt-lg-0">Last month&apos;s GCI</p>
                  <div className="number-stats">
                    $
                    <Placeholder
                      loading={isLoadingGci}
                      className="w-8 h-8"
                    ></Placeholder>
                    {!isLoadingGci &&
                      formatNumberWithCommas(
                        Math.round(gciApiData[0]?.agent_monthly_gci),
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForeCastedRevenue;
