import HelpIcon from "@components/common/HelpIcon";
import TablePlaceHolder from "@components/common/TablePlaceHolder";
import TableSortIcon from "@components/common/TableSortIcon";
import { toolsPageToolTip } from "@constants/tooltip-data";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { faker } from "@faker-js/faker";
import { formatNumberWithCommas } from "@utils/helpers";
import { http } from "@utils/http";
import { EmailIcon } from "@utils/icons";
import { colSortingV2 } from "@utils/table-sort";
import { useEffect, useState } from "react";

function CmaTopUsersTable(props:any) {
  const columns: any = [
    {
      name: "Agent",
      slug: "first_name",
      classNames: "",
      sort: false,
    },
    {
      name: "Sales Volume",
      slug: "sales_volume",
      classNames: "justify-content-center",
      sort: false,
    },
    {
      name: "Reports",
      slug: "reports",
      classNames: "justify-content-center",
      sort: false,
    },
    // {
    //   name: "",
    //   classNames: "d-none d-md-inline-block",
    //   sort: false,
    // },
  ];

  // table data state
  const [topUsersData, setTopUsersData] = useState([] as any);
  const [topUsersApiData, setTopUsersApiData] = useState([] as any);
  // loader state
  const [isDataLoading, setIsDataLoading] = useState(true);
  // column sorting state
  const [sortColumns, setSortColumns] = useState([] as any);
  const [sortAPIColumns, setSortAPIColumns] = useState("" as any);

  useEffect(() => {
    getTopUsersData();
  }, [sortAPIColumns]);

  function getTopUsersData() {
    setIsDataLoading(true);
    http
      .get(
        "/tools/cloudcma--reports-top-agents-last-12-months.json",
      )
      .then((response: any) => {
        setIsDataLoading(false);
        const { data }: any = response;
        let theData = data?.cloudcma_marketing;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            first_name: faker.person.firstName(),
            last_name: faker.person.lastName(),
          }));
        }
        setTopUsersData(theData);
        setTopUsersApiData(theData);
      })
      .catch((error) => {
        setIsDataLoading(false);
        console.log(error);
      });
  }
  // Define a function to handle column sorting
  const handleSort = (column: any) => {
    const sortingOutput: any = colSortingV2(
      topUsersApiData,
      sortColumns,
      column,
    );
    setSortAPIColumns(sortingOutput?.apiColumn);
    setSortColumns(sortingOutput.columns);
    // setAgents(sortingOutput.data);
  };

  const renderTableActionButton = (data: any) => (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm d-flex align-items-center justify-content-center gap-1"
      >
        <EmailIcon />
        <span className="ps-1 d-none d-md-block">Email</span>
      </button>
    </>
  );

  return (
    <div
      className="custom-modal-inner__padding h-100"
      style={{ background: "#2B3041" }}
    >
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center gap-1">
          <div className="header-title-6 fw-bold text-white">Top Users</div>
          <HelpIcon tooltip={toolsPageToolTip.toolsModalCloudCmaTopUsers} />
        </div>
        <p className="mb-0 custom-fs-12">Last 12 Months</p>
      </div>
      <div className="table-responsive">
        <table className="align-middle table-nowrap table-check table table-striped table-sm fixed-header fixed-column">
          <thead className="table-light" style={{ background: "#33394C" }}>
            <tr role="row">
              {columns.map((column: any) => (
                <th
                  key={column.slug}
                  onClick={() => {
                    if (column.sort) {
                      handleSort(column.slug);
                    }
                  }}
                  role="button"
                >
                  <div
                    className={`d-flex p-1 align-items-center ${column?.classNames}`}
                  >
                    <TableSortIcon
                      sortColumns={sortColumns}
                      column={column}
                    ></TableSortIcon>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <TablePlaceHolder
              items={topUsersData}
              loading={isDataLoading}
              rows={13}
              cols={8}
            />
            {!isDataLoading &&
              topUsersData?.map((topUser: any, id: any) => (
                <tr key={topUser.id}>
                  {/* <td>{agent.agentid}</td> */}
                  <td className="p-2 text-break">
                    {topUser.first_name} {topUser.last_name}
                  </td>
                  <td className="text-center">
                    ${formatNumberWithCommas(topUser.sales_volume)}
                  </td>
                  <td className="text-center">{topUser.reports}</td>
                  {/* <td>
                      
                    </td> */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CmaTopUsersTable;
