import React from 'react';
import { ButtonGroup } from 'reactstrap';

export interface UnitsOrDollarsSelectorProps {
  className?: string
  isUnits: boolean
  setIsUnits: (isUnits: boolean) => void
  unitsName?: string
}

const UnitsOrDollarsSelector = ({
  className, isUnits, setIsUnits, unitsName,
}: UnitsOrDollarsSelectorProps) => {
  const unitsClass = isUnits ? 'btn btn-secondary' : 'btn btn-outline-secondary';
  const dollarsClass = isUnits ? 'btn btn-outline-secondary' : 'btn btn-secondary';
  return (
    <>
      <ButtonGroup className={className} size="sm">
        <button
          className={unitsClass}
          onClick={() => setIsUnits(true)}
          type="button"
        >
          {unitsName}
        </button>
        <button
          className={dollarsClass}
          onClick={() => setIsUnits(false)}
          type="button"
        >
          Dollars
        </button>
      </ButtonGroup>
    </>
  );
};

UnitsOrDollarsSelector.defaultProps = {
  unitsName: 'Units',
};

export default UnitsOrDollarsSelector;
