import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Logout.css';
import cookies from '@utils/cookies';

function Logout() {
  const history = useHistory();
  const [showAnimation, setShowAnimation] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowAnimation(false);
      cookies.remove('auth', { path: '/' });
      cookies.remove('logged_in_auth', { path: '/' });
      cookies.remove('token', { path: '/' });
      window.location.replace("/login");
    }, 3000);

    return () => clearTimeout(timeout);
  }, [history]);

  return (
    <div className={`container mt-5 ${showAnimation ? 'fade-out' : ''}`}>
      <p>Logging out soon...</p>
    </div>
  );
}

export default Logout;
