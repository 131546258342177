import React, { useCallback } from 'react';
import { useAppSelector } from '../../../../state/hooks';
import { selectGoogleMapsLoaded } from '../../../../state/appSlice';
import SearchBar from '../../../common/SearchBar/SearchBar';

export interface ActivityHeatmapSearchProps {
  className?: string
  setLatitudeAndLongitude: (latitude: number, longitude: number) => void
}

const EmergingMarketSearch = (
  { className, setLatitudeAndLongitude }: ActivityHeatmapSearchProps,
) => {
  const googleMapsLoaded = useAppSelector(selectGoogleMapsLoaded);
  const setSearchCallback = useCallback((search: string) => {
    if (googleMapsLoaded && search !== '') {
      new google.maps.Geocoder().geocode({
        address: search,
      }).then((value) => {
        if (value.results.length > 0) {
          setLatitudeAndLongitude(
            value.results[0].geometry.location.lat(),
            value.results[0].geometry.location.lng(),
          );
        }
      });
    }
  }, [googleMapsLoaded, setLatitudeAndLongitude]);

  return (
    <>
      <SearchBar
        className={className}
        enterSubmits={false}
        placeholderText="Search for an emerging market in the map..."
        setSearch={setSearchCallback}
      />
    </>
  );
};

export default EmergingMarketSearch;
