import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { createUseStyles } from 'react-jss';

import buttonInputSvg from '../../../../assets/images/button-input.svg';
import Tooltip from '../../../common/Tooltip';
import { error, success } from '../../../../assets/colors';
import HelpIcon from '../../../common/HelpIcon';

const styles = createUseStyles({
  InputPanel_wrapper: {
    'height': '40px;',
    'border': '0',
    'padding-left': '10px',
    'color': '#ffffff',
    'backgroundColor': '#333849;',
  },
  LeftPanel_wrapper: {
    'width': '20%',
  },
  RightPanel_wrapper: {
    'width': '80%',
  },
  PieLeftPanel_wrapper: {
    'width': '70%',
    'backgroundColor': '#2a3042',
  },
  PieRightPanel_wrapper: {
    'width': '25%',
    'backgroundColor': '#2a3042',
  },
  SmallPanel_wrapper: {
    'width': '23%',
    'padding': '20px 0 0 0',
    'margin-top': '50px',
  },
  Circle: {
    'width': '5px',
    'height': '5px',
    'borderRadius': '50%',
    'background': '#A6B0CF',
  },
  HeightGraph: {
    'height': '100px',
    'overflowY': 'hidden',
    'overflowX': 'hidden',
  },
  MaxHeightGraph: {
    'height': '100px!important',
  },
  TextGraph: {
    'height': '30%',
    'top': '70px',
    'left': '20px',
  },
  LoginCard_percentageIndicator__increasing: {
    color: success,
  },
  LoginCard_percentageIndicator__decreasing: {
    color: error,
  },
  LoginCard_indicator: {
    'margin-top': '-.4em',
  },
  '@media (max-width: 767px)': {
    SmallPanel_wrapper: {
      'width': '100%',
      'padding': '20px 0 0 0',
      'margin-top': '5px',
      'textAlign': 'center',
    },
  },
});

export interface GraphLineProps {
  cumSendsNumber?: string,
  lineGraph?: boolean,
}

const RecruitingStat = () => {
  const classes = styles();
  return (
    <>
      <Tooltip />
      <div className="d-flex flex-column flex-md-row gap-8 justify-content-between">
        <Card className={`${classes.SmallPanel_wrapper} table-responsive`}>
          <CardBody>
            <div className="d-flex flex-column justify-content-between">
              <h4 className="h2">Total Transactions
                <HelpIcon tooltip="Total transactions represents the target amount of transactions you
                  are looking to filter agents by."
                />
              </h4>
              <div className="d-flex align-items-center justify-content-center">
                <h4 className="h1 py-4">
                  23
                </h4>
              </div>
              <p>You can adjust your target Total Transactions in the box below:</p>
              <div className="d-flex py-4">
                <input type="text" className={`${classes.InputPanel_wrapper} w-100`} />
                <button type="button" className={`${classes.InputPanel_wrapper} btn bg-primary btn-sm py-2 px-3`}>
                  <img src={buttonInputSvg} alt="" />&nbsp;
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className={`${classes.SmallPanel_wrapper} table-responsive`}>
          <CardBody>
            <div className="d-flex flex-column justify-content-between">
              <h4 className="h2">Average Sale Price
                <HelpIcon tooltip="This toggle is designed for managers to filter their agent search
                  criteria."
                />
              </h4>
              <div className="d-flex align-items-center justify-content-center">
                <h4 className="h1 py-4">
                  $248,509
                </h4>
              </div>
              <p>You can adjust your target Total Transactions in the box below:</p>
              <div className="d-flex py-4">
                <input type="text" className={`${classes.InputPanel_wrapper} w-100`} />
                <button type="button" className={`${classes.InputPanel_wrapper} btn bg-primary btn-sm py-2 px-3`}>
                  <img src={buttonInputSvg} alt="" />&nbsp;
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className={`${classes.SmallPanel_wrapper} table-responsive`}>
          <CardBody>
            <div className="d-flex flex-column justify-content-between">
              <h4 className="h2">Average Buy Sides
                <HelpIcon tooltip="The buy side filter is built so allow brokerage staff to filter by the
                  target amount of sides their recruits should have."
                />
              </h4>
              <div className="d-flex align-items-center justify-content-center">
                <h4 className="h1 py-4">
                  23
                </h4>
              </div>
              <p>You can adjust your target Total Transactions in the box below:</p>
              <div className="d-flex py-4">
                <input type="text" className={`${classes.InputPanel_wrapper} w-100`} />
                <button type="button" className={`${classes.InputPanel_wrapper} btn bg-primary btn-sm py-2 px-3`}>
                  <img src={buttonInputSvg} alt="" />&nbsp;
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className={`${classes.SmallPanel_wrapper} table-responsive`}>
          <CardBody>
            <div className="d-flex flex-column justify-content-between">
              <h4 className="h2">Average Sell Sides
                <HelpIcon tooltip="Average sell side is designed to help brokerage staff filter by their
                  target amount of sell sides."
                />
              </h4>
              <div className="d-flex align-items-center justify-content-center">
                <h4 className="h1 py-4">
                  23
                </h4>
              </div>
              <p>You can adjust your target Total Transactions in the box below:</p>
              <div className="d-flex py-4">
                <input type="text" className={`${classes.InputPanel_wrapper} w-100`} />
                <button type="button" className={`${classes.InputPanel_wrapper} btn bg-primary btn-sm py-2 px-3`}>
                  <img src={buttonInputSvg} alt="" />&nbsp;
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default RecruitingStat;
