import { createApi } from "@reduxjs/toolkit/query/react";
import { default as customBaseQuery } from "./apiBaseQuery";
export const api = createApi({
  baseQuery: customBaseQuery,
  tagTypes: [],
  endpoints: (build) => ({
    listObservations: build.query<
      ListObservationsResponse,
      ListObservationsArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/observations/`,
        params: { type: queryArg["type"] },
      }),
    }),
    listingsOverview: build.query<ListingsOverviewResponse, ListingsOverviewArg>({
      query: (queryArg) => ({
        url: `/api/v1/listings_overview`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          search: queryArg.search,
        },
      }),
    }),
    recruitmentRetention: build.query<RecruitmentRetentionResponse, RecruitmentRetentionArg>({
      query: (queryArg) => ({
        url: `/api/v1/recruitment_retention`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          search: queryArg.search,
        },
      }),
    }),
    newRecruits: build.query<NewRecruitsResponse, NewRecruitsArg>({
      query: (queryArg) => ({
        url: `/api/v1/new_recruits`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          search: queryArg.search,
        },
      }),
    }),
    retentionOverview: build.query<RetentionOverviewResponse, RetentionOverviewArg>({
      query: (queryArg) => ({
        url: `/api/v1/retention_overview`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          search: queryArg.search,
        },
      }),
    }),
    revenueBreakdown: build.query<RevenueBreakdownResponse, RevenueBreakdownArg>({
      query: (queryArg) => ({
        url: `/api/v1/revenue_breakdown`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          search: queryArg.search,
        },
      }),
    }),
    listUsers: build.query<ListUsersResponse, ListUsersArg>({
      query: (queryArg) => ({
        url: `/api/v1/users/`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          search: queryArg.search,
        },
      }),
    }),
    createUser: build.mutation<CreateUserResponse, CreateUserArg>({
      query: (queryArg) => ({
        url: `/api/v1/users/`,
        method: "POST",
        body: queryArg.user,
      }),
    }),
    retrieveUser: build.query<RetrieveUserResponse, RetrieveUserArg>({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.id}/`,
        params: { search: queryArg.search },
      }),
    }),
    updateUser: build.mutation<UpdateUserResponse, UpdateUserArg>({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.user,
        params: { search: queryArg.search },
      }),
    }),
    partialUpdateUser: build.mutation<
      PartialUpdateUserResponse,
      PartialUpdateUserArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.user,
        params: { search: queryArg.search },
      }),
    }),
    destroyUser: build.mutation<DestroyUserResponse, DestroyUserArg>({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.id}/`,
        method: "DELETE",
        params: { search: queryArg.search },
      }),
    }),
    listAgentPlatforms: build.query<
      ListAgentPlatformsResponse,
      ListAgentPlatformsArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/agent_platforms/`,
        params: { mls_agent_id: queryArg.mlsAgentId },
      }),
    }),
    listPlatforms: build.query<ListPlatformsResponse, ListPlatformsArg>({
      query: () => ({ url: `/api/v1/platforms/` }),
    }),
    listQuartilePlatformDatas: build.query<
      ListQuartilePlatformDatasResponse,
      ListQuartilePlatformDatasArg
    >({
      query: () => ({ url: `/api/v1/platform_quartiles/` }),
    }),
    listListAgents: build.query<ListListAgentsResponse, ListListAgentsArg>({
      query: (queryArg) => ({
        url: `/api/v1/agents/`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          ordering: queryArg.ordering,
          search: queryArg.search,
        },
      }),
    }),
    listModalEmail: build.query<ListModalEmailResponse, ListModalEmailArg>({
      query: (queryArg) => ({
        url: `/api/v1/modals/email`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          ordering: queryArg.ordering,
          search: queryArg.search,
        },
      }),
    }),
    listModalCMA: build.query<ListModalCMAResponse, ListModalCMAArg>({
      query: (queryArg) => ({
        url: `/api/v1/modals/cma`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          ordering: queryArg.ordering,
          search: queryArg.search,
        },
      }),
    }),
    listModalWebsite: build.query<ListModalWebsiteResponse, ListModalWebsiteArg>({
      query: (queryArg) => ({
        url: `/api/v1/modals/website`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          ordering: queryArg.ordering,
          search: queryArg.search,
        },
      }),
    }),
    retrieveRetrieveAgent: build.query<
      RetrieveRetrieveAgentResponse,
      RetrieveRetrieveAgentArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/agents/${queryArg.id}/`,
        params: { ordering: queryArg.ordering, search: queryArg.search },
      }),
    }),
    byMonthAgentProductionByMonth: build.query<
      ByMonthAgentProductionByMonthResponse,
      ByMonthAgentProductionByMonthArg
    >({
      query: (queryArg) => ({ url: `/api/v1/agents/${queryArg.id}/by_month/` }),
    }),
    listEmergingMarkets: build.query<
      ListEmergingMarketsResponse,
      ListEmergingMarketsArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/emerging_markets/`,
        params: { limit: queryArg.limit, offset: queryArg.offset },
      }),
    }),
    createEmergingMarket: build.mutation<
      CreateEmergingMarketResponse,
      CreateEmergingMarketArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/emerging_markets/`,
        method: "POST",
        body: queryArg.emergingMarket,
      }),
    }),
    byMlsEmergingMarket: build.query<
      ByMlsEmergingMarketResponse,
      ByMlsEmergingMarketArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/emerging_markets/by_mls/`,
        params: { mls: queryArg.mls },
      }),
    }),
    retrieveEmergingMarket: build.query<
      RetrieveEmergingMarketResponse,
      RetrieveEmergingMarketArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/emerging_markets/${queryArg.id}/`,
      }),
    }),
    updateEmergingMarket: build.mutation<
      UpdateEmergingMarketResponse,
      UpdateEmergingMarketArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/emerging_markets/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.emergingMarket,
      }),
    }),
    partialUpdateEmergingMarket: build.mutation<
      PartialUpdateEmergingMarketResponse,
      PartialUpdateEmergingMarketArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/emerging_markets/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.emergingMarket,
      }),
    }),
    destroyEmergingMarket: build.mutation<
      DestroyEmergingMarketResponse,
      DestroyEmergingMarketArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/emerging_markets/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    dataEmergingMarket: build.query<
      DataEmergingMarketResponse,
      DataEmergingMarketArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/emerging_markets/${queryArg.id}/data/`,
        params: { brokerages: queryArg.brokerages },
      }),
    }),
    listListingsHotSpots: build.query<
      ListListingsHotSpotsResponse,
      ListListingsHotSpotsArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/listings/hot_spots/`,
        params: {
          minimum_latitude: queryArg.minimumLatitude,
          maximum_latitude: queryArg.maximumLatitude,
          minimum_longitude: queryArg.minimumLongitude,
          maximum_longitude: queryArg.maximumLongitude,
          mls: queryArg.mls,
        },
      }),
    }),
    listListingsByMonths: build.query<
      ListListingsByMonthsResponse,
      ListListingsByMonthsArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/listings/by_month/`,
        params: {
          minimum_latitude: queryArg.minimumLatitude,
          maximum_latitude: queryArg.maximumLatitude,
          minimum_longitude: queryArg.minimumLongitude,
          maximum_longitude: queryArg.maximumLongitude,
          mls: queryArg.mls,
        },
      }),
    }),
    listMls: build.query<ListMlsResponse, ListMlsArg>({
      query: () => ({ url: `/api/v1/mls/` }),
    }),
    listOffices: build.query<ListOfficesResponse, ListOfficesArg>({
      query: (queryArg) => ({
        url: `/api/v1/offices/`,
        params: { ordering: queryArg.ordering },
      }),
    }),
    listNotifications: build.query<
      ListNotificationsResponse,
      ListNotificationsArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notifications/`,
        params: {
          key: queryArg.key,
          text: queryArg.text,
          first_name: queryArg.first_name,
          agent: queryArg.agent,
          email: queryArg.email,
          status: queryArg.status,
          sent_date: queryArg.sent_date,
          reply_date: queryArg.reply_date,
        },
      }),
    }),
    retrieveNotification: build.query<
      RetrieveNotificationResponse,
      RetrieveNotificationArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notifications/${queryArg.id}/`,
        params: {
          category: queryArg.category,
          status: queryArg.status,
          action: queryArg.action,
          agent: queryArg.agent,
        },
      }),
    }),
    updateNotification: build.mutation<
      UpdateNotificationResponse,
      UpdateNotificationArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notifications/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.notification,
        params: {
          category: queryArg.category,
          status: queryArg.status,
          action: queryArg.action,
          agent: queryArg.agent,
        },
      }),
    }),
    partialUpdateNotification: build.mutation<
      PartialUpdateNotificationResponse,
      PartialUpdateNotificationArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notifications/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.notification,
        params: {
          category: queryArg.category,
          status: queryArg.status,
          action: queryArg.action,
          agent: queryArg.agent,
        },
      }),
    }),
    login: build.mutation<LoginResponse, LoginArg>({
      query: (queryArg) => ({
        url: `/api/v1/auth/login/`,
        method: "POST",
        body: queryArg.basicLogin,
      }),
    }),
    loginAsUser: build.mutation<LoginAsUserResponse, LoginAsUserArg>({
      query: (queryArg) => ({
        url: `/api/v1/auth/login_as_user/`,
        method: "POST",
        body: queryArg.tokenLogin,
        params: { user_id: queryArg.userId },
      }),
    }),
    logout: build.mutation<LogoutResponse, LogoutArg>({
      query: () => ({ url: `/api/v1/auth/logout/`, method: "POST" }),
    }),
    passwordReset: build.mutation<PasswordResetResponse, PasswordResetArg>({
      query: (queryArg) => ({
        url: `/api/v1/auth/password_reset/`,
        method: "POST",
        body: queryArg.passwordReset,
      }),
    }),
    passwordResetLogin: build.mutation<
      PasswordResetLoginResponse,
      PasswordResetLoginArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/password_reset_login/`,
        method: "POST",
        body: queryArg.tokenLogin,
      }),
    }),
    completeAllNotification: build.mutation<
      CompleteAllNotificationResponse,
      CompleteAllNotificationArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notifications/complete_all/`,
        method: "PATCH",
        body: queryArg.notification,
        params: {
          category: queryArg.category,
          status: queryArg.status,
          action: queryArg.action,
        },
      }),
    }),
    draftGroupEmailNotification: build.mutation<
      DraftGroupEmailNotificationResponse,
      DraftGroupEmailNotificationArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notifications/draft_email/`,
        method: "PATCH",
        body: queryArg.notification,
        params: { action: queryArg.action },
      }),
    }),
    ignoreAllNotification: build.mutation<
      IgnoreAllNotificationResponse,
      IgnoreAllNotificationArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notifications/ignore_all/`,
        method: "PATCH",
        body: queryArg.notification,
        params: {
          category: queryArg.category,
          status: queryArg.status,
          action: queryArg.action,
        },
      }),
    }),
    draftEmailNotification: build.mutation<
      DraftEmailNotificationResponse,
      DraftEmailNotificationArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notifications/${queryArg.id}/draft_email/`,
        method: "PATCH",
        body: queryArg.notification,
      }),
    }),
  }),
});
export type ListObservationsResponse = /** status 200  */ Observation[];
export type ListObservationsArg = {
  type: string;
};
export type ListingsOverviewResponse = /** status 200  */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Offices[];
};
export type RecruitmentRetentionResponse = /** status 200  */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: RecruitmentRetention[];
};
export type NewRecruitsResponse = /** status 200  */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: NewRecruits[];
};
export type RetentionOverviewResponse = /** status 200  */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: RetentionOverview[];
};
export type RevenueBreakdownResponse = /** status 200  */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: RevenueBreakdown[];
};
export type ListUsersResponse = /** status 200  */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: User[];
};
export type ListingsOverviewArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** A search term. */
  search?: string;
};

export type RecruitmentRetentionArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** A search term. */
  search?: string;
};

export type NewRecruitsResponseArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** A search term. */
  search?: string;
};

export type NewRecruitsArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** A search term. */
  search?: string;
};

export type RetentionOverviewArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** A search term. */
  search?: string;
};

export type RevenueBreakdownArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** A search term. */
  search?: string;
};
export type ListUsersArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** A search term. */
  search?: string;
};
export type CreateUserResponse = /** status 201  */ User;
export type CreateUserArg = {
  user: User;
};
export type RetrieveUserResponse = /** status 200  */ User;
export type RetrieveUserArg = {
  id: string;
  /** A search term. */
  search?: string;
};
export type UpdateUserResponse = /** status 200  */ User;
export type UpdateUserArg = {
  id: string;
  /** A search term. */
  search?: string;
  user: User;
};
export type PartialUpdateUserResponse = /** status 200  */ User;
export type PartialUpdateUserArg = {
  id: string;
  /** A search term. */
  search?: string;
  user: User;
};
export type DestroyUserResponse = unknown;
export type DestroyUserArg = {
  id: string;
  /** A search term. */
  search?: string;
};
export type ListAgentPlatformsResponse = /** status 200  */ AgentPlatform[];
export type ListAgentPlatformsArg = {
  mlsAgentId: string;
};
export type ListPlatformsResponse = /** status 200  */ Platform[];
export type ListPlatformsArg = void;
export type ListQuartilePlatformDatasResponse =
  /** status 200  */ QuartilePlatformData[];
export type ListQuartilePlatformDatasArg = void;
export type ListListAgentsResponse = /** status 200  */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: ListAgent[];
};
export type ListModalEmailResponse = /** status 200  */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: ListAgent[];
  agents?: any;
  highest_user_engagement?: any;
  cumulative_sends?: any;
  avg_open_rate?: any;
  top_engagement?: any;
  bottom_engagement?: any;
  avg_CTR_percents?: any;
};

export type ListModalCMAResponse = /** status 200  */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: ListAgent[];
  agents?: any;
  highest_user_engagement?: any;
  total_reports_cma_per_month?: any;
  buy_sides_per_month?: any;
  sell_sides_per_month?: any;
  top_engagement?: any;
  bottom_engagement?: any;
};

export type ListModalWebsiteResponse = /** status 200  */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: ListAgent[];
  agents?: any;
  highest_user_engagement?: any;
  total_leads?: any;
  contact_form_leads?: any;
  property_inquires?: any;
  top_engagement?: any;
  bottom_engagement?: any;
};
export type ListListAgentsArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
};
export type ListModalEmailArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
};
export type ListModalCMAArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
};
export type ListModalWebsiteArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
};
export type RetrieveRetrieveAgentResponse = /** status 200  */ RetrieveAgent;
export type RetrieveRetrieveAgentArg = {
  id: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
};
export type ByMonthAgentProductionByMonthResponse =
  /** status 200  */ AgentProductionByMonth[];
export type ByMonthAgentProductionByMonthArg = {
  id: string;
};
export type ListEmergingMarketsResponse = /** status 200  */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: EmergingMarket[];
};
export type ListEmergingMarketsArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type CreateEmergingMarketResponse = /** status 201  */ EmergingMarket;
export type CreateEmergingMarketArg = {
  emergingMarket: EmergingMarket;
};
export type ByMlsEmergingMarketResponse = /** status 200  */ EmergingMarket[];
export type ByMlsEmergingMarketArg = {
  /** The MLS to return results from. */
  mls: string[];
};
export type RetrieveEmergingMarketResponse = /** status 200  */ EmergingMarket;
export type RetrieveEmergingMarketArg = {
  /** A UUID string identifying this emerging market. */
  id: string;
};
export type UpdateEmergingMarketResponse = /** status 200  */ EmergingMarket;
export type UpdateEmergingMarketArg = {
  /** A UUID string identifying this emerging market. */
  id: string;
  emergingMarket: EmergingMarket;
};
export type PartialUpdateEmergingMarketResponse =
  /** status 200  */ EmergingMarket;
export type PartialUpdateEmergingMarketArg = {
  /** A UUID string identifying this emerging market. */
  id: string;
  emergingMarket: EmergingMarket;
};
export type DestroyEmergingMarketResponse = unknown;
export type DestroyEmergingMarketArg = {
  /** A UUID string identifying this emerging market. */
  id: string;
};
export type DataEmergingMarketResponse =
  /** status 200  */ EmergingMarketDetailData;
export type DataEmergingMarketArg = {
  /** A UUID string identifying this emerging market. */
  id: string;
  /** Filter retrieve emerging market data for these brokerages. */
  brokerages: string[];
};
export type ListListingsHotSpotsResponse = /** status 200  */ ListingsHotSpot[];
export type ListListingsHotSpotsArg = {
  minimumLatitude: number;
  maximumLatitude: number;
  minimumLongitude: number;
  maximumLongitude: number;
  mls: string[];
};
export type ListListingsByMonthsResponse = /** status 200  */ ListingsByMonth[];
export type ListListingsByMonthsArg = {
  minimumLatitude: number;
  maximumLatitude: number;
  minimumLongitude: number;
  maximumLongitude: number;
  mls: string[];
};
export type ListMlsResponse = /** status 200  */ Mls[];
export type ListMlsArg = void;
export type ListOfficesResponse = /** status 200  */ Office[];
export type ListOfficesArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
};
export type ListNotificationsResponse = /** status 200  */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Notification[];
};
export type ListNotificationsArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  category?: string;
  action?: string;
  key?: string;
  text?: string;
  first_name?: string;
  agent?: string;
  email?: string;
  status?: string;
  sent_date?: string;
  reply_date?: null,
};
export type RetrieveNotificationResponse = /** status 200  */ Notification;
export type RetrieveNotificationArg = {
  id: string;
  category?: string;
  status?: string;
  action?: string;
  agent?: string;
};
export type UpdateNotificationResponse = /** status 200  */ Notification;
export type UpdateNotificationArg = {
  id: string;
  category?: string;
  status?: string;
  action?: string;
  agent?: string;
  notification: Notification;
};
export type PartialUpdateNotificationResponse = /** status 200  */ Notification;
export type PartialUpdateNotificationArg = {
  id: string;
  category?: string;
  status?: string;
  action?: string;
  agent?: string;
  notification: Notification;
};
export type LoginResponse = /** status 201  */ Auth;
export type LoginArg = {
  basicLogin: BasicLogin;
};
export type LoginAsUserResponse = /** status 201  */ Auth;
export type LoginAsUserArg = {
  /** The user to login as. */
  userId: string;
  tokenLogin: TokenLogin;
};
export type LogoutResponse = unknown;
export type LogoutArg = void;
export type PasswordResetResponse = unknown;
export type PasswordResetArg = {
  passwordReset: PasswordReset;
};
export type PasswordResetLoginResponse = /** status 201  */ Auth;
export type PasswordResetLoginArg = {
  tokenLogin: TokenLogin;
};
export type CompleteAllNotificationResponse = /** status 200  */ Notification;
export type CompleteAllNotificationArg = {
  /** Update all notifications with this category. */
  category?: string;
  /** Update all notifications with this status. */
  status?: string;
  /** Update all notifications with this action. */
  action?: string;
  notification: Notification;
};
export type DraftGroupEmailNotificationResponse = /** status 200  */ string;
export type DraftGroupEmailNotificationArg = {
  /** Update all notifications with this action. */
  action: string;
  notification: Notification;
};
export type IgnoreAllNotificationResponse = /** status 200  */ Notification;
export type IgnoreAllNotificationArg = {
  /** Update all notifications with this category. */
  category?: string;
  /** Update all notifications with this status. */
  status?: string;
  /** Update all notifications with this action. */
  action?: string;
  notification: Notification;
};
export type DraftEmailNotificationResponse = /** status 200  */ string;
export type DraftEmailNotificationArg = {
  id: string;
  notification: Notification;
};
export type Observation = {
  id?: string;
  text?: string;
};
export type User = {
  id?: string;
  user_offices?: {
    office: {
      group: {
        organization: {
          id?: string;
          name?: string;
        };
        id?: string;
        mls_subscriptions: {
          id?: string;
          mls: {
            id?: string;
            lookup_ids?: {
              id?: string;
              lookup_id?: string;
            }[];
            name?: string;
          };
        }[];
        name?: string;
      };
      id?: string;
      name?: string;
    };
  }[];
  avialible_data?: {
    branches: any;
    brands: any;
    companies: any;
  };
  is_active?: boolean;
  username?: any;
  password?: string;
  last_login?: null;
  email?: string;
  avatar_url?: string;
  first_name?: string;
  last_name?: string;
  street?: string,
  city?: string,
  state?: string,
  zip?: string,
  birthday?: any,
  start_date?: '2022-06-05T09:21:18Z',
  work_phone?: string,
  home_phone?: string,
  mobile_phone?: string,
  user_level?: string,
  current_status?: string,
  publish?: false,
  bio?: string,
  facebook_url?: string,
  linkedin_url?: string,
  twitter_url?: string,
  instagram_url?: string,
  is_superuser?: boolean;
  title?: string;
  brands?: any,
  companies?: any,
  branches?: any,
};
export type Offices = {
  id?: string;
  office_name?: string;
  buy_sides?: any,
  list_sides?: any,
  gci?: any,
  avg_listings_price?: any,
  gci_under_contract?: any,
  listings?: any,
  total_active?: any,
  start_date?: any,
  end_date?: any,
};

export type RecruitmentRetention = {
  id?: string;
  previous_agent?: any,
  current_agent?: any,
  net_revenue?: any,
  short_name?: any,
  new_agents?: any,
  start_date?: any,
  end_date?: any,
};

export type RevenueBreakdown = {
  id?: string;
  existing_agent?: any,
  recruited_agent?: any,
  short_name?: any,
};

export type NewRecruits = {
  id?: string;
  current_agent?: any,
  short_name?: any,
};

export type RetentionOverview = {
  id?: string;
  existing_agent?: any,
  short_name?: any,
};

export type AgentPlatform = {
  engagement?: number;
  mls_agent_id?: string;
  platform?: string;
};
export type Platform = {
  category?: string;
  display_name?: string;
  group?: string | null;
  id?: string;
  logo?: string;
  match_name?: string;
  platform_data?: {
    average_logins?: number;
    average_users?: number;
    last_weeks_logins?: number;
    last_weeks_users?: number;
    office_id?: string;
    office_name?: string;
  }[];
};
export type QuartilePlatformData = {
  id?: string;
  platform?: string;
  office?: string;
  office_name?: string;
  platform_name?: string;
  quartile?: number;
  agent_count?: number;
  average_engagement?: number;
};
export type ListAgent = {
  email?: string;
  id?: string;
  name?: string;
  office_id?: string;
  office_name?: string;
  average_listing?: string;
  buy_sides?: number;
  engagement?: number;
  gci?: string;
  sell_sides?: number;
  under_contract?: string;
};
export type RetrieveAgent = {
  email?: string;
  id?: string;
  name?: string;
  office_id?: string;
  office_name?: string;
  average_listing?: string;
  buy_sides?: number;
  engagement?: number;
  gci?: string;
  sell_sides?: number;
  under_contract?: string;
  average_listing_previous_year?: string;
  buy_sides_previous_year?: string;
  sell_sides_previous_year?: string;
  gci_previous_year?: string;
};
export type AgentProductionByMonth = {
  date?: string;
  agent_id?: string;
  closed_dollars: string;
  closed_sides: number;
};
export type EmergingMarket = {
  center_latitude?: number;
  center_longitude?: number;
  created_on?: string;
  maximum_longitude?: number;
  emerging_market_insights: {
    id?: string;
    text: string;
  }[];
  id?: string;
  mls: string;
  mls_name?: string;
  name?: string;
  maximum_latitude?: number;
  minimum_latitude?: number;
  minimum_longitude?: number;
  zoom?: number;
};
export type EmergingMarketDetailData = {
  by_groups?: {
    group_id?: string | null;
    group_name?: string;
    mls_office_name?: string | null;
    sales_dollars_active?: string;
    sales_units_active?: number;
  }[];
  emerging_market_id: string;
  sales_dollars_active?: string;
  sales_dollars_year_to_date?: string;
  sales_dollars_two_years_to_date?: string;
  sales_units_active?: number;
  sales_units_year_to_date?: number;
};
export type ListingsHotSpot = {
  latitude: number;
  longitude: number;
  weight: number;
};
export type ListingsByMonth = {
  date?: string;
  mls: string;
  sales: string;
  units: number;
};
export type Mls = {
  id?: string;
  lookup_ids?: {
    id?: string;
    lookup_id?: string;
  }[];
  name?: string;
};
export type Office = {
  id?: string;
  name?: string;
  agent_count?: number;
  average_listing?: string;
  average_listing_previous_year?: string;
  buy_sides?: number;
  buy_sides_previous_year?: number;
  days_on_market?: number;
  days_on_market_previous_year?: number;
  gci?: string;
  gci_previous_year?: string;
  gci_ytd?: string;
  gci_ytd_previous_year?: string;
  sell_sides?: number;
  sell_sides_previous_year?: number;
  total_active?: number;
  under_contract?: string;
  belongs_to_office: boolean;
};
export type Notification = {
  key?: string;
  id?: string;
  agent?: string;
  category?: "CELEBRATION" | "DEAL_FLOW" | "REVENUE_OPPORTUNITY";
  status?:
  | "IGNORED"
  | "PENDING"
  | "COMPLETED"
  | "COMPLETED_RESOLVED"
  | "COMPLETED_UNRESOLVED";
  text?: string;
  action?:
  | "AGENT_CELEBRATION__CLOSED_BROKERAGE_PROVIDED_LEAD"
  | "AGENT_CELEBRATION__FIRST_CLOSE"
  | "AGENT_CELEBRATION__HIGHEST_CLOSE"
  | "AGENT_CELEBRATION__RECORD_MONTH"
  | "AGENT_DEAL_FLOW__LOW_PRODUCTION"
  | "AGENT_DEAL_FLOW__NO_PRODUCTION"
  | "AGENT_DEAL_FLOW__STUCK_DEAL"
  | "AGENT_REVENUE_OPPORTUNITY__DISCOUNTED_COMMISSION"
  | "AGENT_REVENUE_OPPORTUNITY__LOW_CMA_USAGE"
  | "AGENT_REVENUE_OPPORTUNITY__LOW_EMAIL_MARKETING_USAGE"
  | "AGENT_REVENUE_OPPORTUNITY__NEW_EMERGING_MARKET";
};
export type Auth = {
  is_superuser: boolean;
  token: string;
  user_id: string;
  user_level?: string;
};
export type BasicLogin = {
  email: string;
  password: string;
};
export type TokenLogin = {
  token: string;
};
export type PasswordReset = {
  email: string;
};
export const {
  useListObservationsQuery,
  useListingsOverviewQuery,
  useRecruitmentRetentionQuery,
  useRevenueBreakdownQuery,
  useNewRecruitsQuery,
  useRetentionOverviewQuery,
  useListUsersQuery,
  useCreateUserMutation,
  useRetrieveUserQuery,
  useUpdateUserMutation,
  usePartialUpdateUserMutation,
  useDestroyUserMutation,
  useListAgentPlatformsQuery,
  useListPlatformsQuery,
  useListQuartilePlatformDatasQuery,
  useListListAgentsQuery,
  useListModalEmailQuery,
  useListModalCMAQuery,
  useListModalWebsiteQuery,
  useRetrieveRetrieveAgentQuery,
  useByMonthAgentProductionByMonthQuery,
  useListEmergingMarketsQuery,
  useCreateEmergingMarketMutation,
  useByMlsEmergingMarketQuery,
  useRetrieveEmergingMarketQuery,
  useUpdateEmergingMarketMutation,
  usePartialUpdateEmergingMarketMutation,
  useDestroyEmergingMarketMutation,
  useDataEmergingMarketQuery,
  useListListingsHotSpotsQuery,
  useListListingsByMonthsQuery,
  useListMlsQuery,
  useListOfficesQuery,
  useListNotificationsQuery,
  useRetrieveNotificationQuery,
  useUpdateNotificationMutation,
  usePartialUpdateNotificationMutation,
  useLoginMutation,
  useLoginAsUserMutation,
  useLogoutMutation,
  usePasswordResetMutation,
  usePasswordResetLoginMutation,
  useCompleteAllNotificationMutation,
  useDraftGroupEmailNotificationMutation,
  useIgnoreAllNotificationMutation,
  useDraftEmailNotificationMutation,
} = api;
