import HelpIcon from "@components/common/HelpIcon";
import Placeholder from "@components/common/Placeholder";
import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
import { financialToolTip } from "@constants/tooltip-data";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import generateLineChartOptions from "@utils/chart/line-chart";
import { formatDecimalPlaces, formatNumberWithCommas } from "@utils/helpers";
import { http } from "@utils/http";
import { generateTicks } from "@utils/transform/financial";
import { generateSalesPriceTrendsDataByYear } from "@utils/transform/financialPage";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function SalePriceTrends() {
  const [sPTStats, setSPTStats] = useState({} as any);
  const [isSPTStatsDataLoading, setIsSPTStatsDataLoading] = useState(true);
  const [isSPTDataLoading, setIsSPTDataLoading] = useState(true);
  const [sptData, setSptData] = useState({} as any);
  const [theOptions, setTheOptions] = useState({} as any);

  useEffect(() => {
    getSPTStats();
    getSptData();
  }, []);

  function getSptData() {
    http
      .get("financial/sales-price-trends-month-histogram.json")
      .then((response: any) => {
        let apiData = response?.data?.sales_price_trends;
        if (apiData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
          apiData = [];
        }
        const theData: any = generateSalesPriceTrendsDataByYear(2023, apiData);
        
        setSptData(theData);

        const chartOptions: any = generateLineChartOptions({
          theId: "line-chart-sales-price-trends",
          colors: ["#CF4AFE", "#F2CA6B"],
          categories: theData?.categories ?? [],
          ticks: generateTicks(theData?.last_12_average_close_price ?? []),
          ticksPreLast: ["$", "k"],
        });
        setTheOptions(chartOptions);
        setIsSPTDataLoading(false);
      })
      .catch((error: any) => {
        setIsSPTDataLoading(false);
        console.log(error);
      });
  }

  function getSPTStats() {
    setIsSPTStatsDataLoading(true);
    http
      .get("/financial/sales-price-trends.json")
      .then((response: any) => {
        const apiData = response?.data?.sales_price_trends;
        setSPTStats(apiData);
        setIsSPTStatsDataLoading(false);
      })
      .catch((error: any) => console.log(error));
  }
  
  return (
    <>
      <div className="mb-4" style={{ backgroundColor: "#2B3041" }}>
        <div className="custom-modal-inner__padding">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row row-gap-2 row-gap-md-0">
                <div className="col-md-7">
                  <div className="d-flex align-items-center gap-1">
                    <div className="header-title">Sales Price Trends</div>
                    <HelpIcon
                      tooltip={`${financialToolTip.salesPriceTrends}`}
                    />
                  </div>
                </div>
                {/* <div className="col-md-5">
                  <div className="">
                    <SearchSelect
                      options={[
                        {
                          id: 1,
                          title: "2023",
                        },
                        {
                          id: 2,
                          title: "2022",
                        },
                        {
                          id: 2,
                          title: "2021",
                        },
                      ]}
                      isClearable
                      getOptionValue={(option: any) => option.id}
                      getOptionLabel={(option: any) => `${option.title}`}
                      placeholder="Select Year"
                    />
                  </div>
                </div> */}
              </div>
              {isSPTDataLoading && <div className="loader-circle"></div>}
              {
                isSPTDataLoading ? (
                <DefaultLineChartUI/>
                )
                  : (
                <div id="line-chart-sales-price-trends" dir="ltr">
                <ReactApexChart
                  series={[
                    {
                      name: "Last 12 Months",
                      data: sptData?.last_12_average_close_price,
                    },
                    {
                      name: "Previous Period",
                      data: sptData?.previous_period_average_close_price,
                    },
                  ]}
                  options={theOptions}
                  type="line"
                  height={320}
                  className="apex-charts"
                />
              </div>
                  )
}
              {/* <div id="line-chart-sales-price-trends" dir="ltr">
                <ReactApexChart
                  series={[
                    {
                      name: "Last 12 Months",
                      data: sptData?.last_12_average_close_price,
                    },
                    {
                      name: "Previous Period",
                      data: sptData?.previous_period_average_close_price,
                    },
                  ]}
                  options={theOptions}
                  type="line"
                  height={320}
                  className="apex-charts"
                />
              </div> */}
              {/* <div id="line-chart" dir="ltr">
                <ReactApexChart
                  series={series}
                  options={
                    window.innerWidth <= 768 ? mobileOptions : desktopOptions
                  }
                  type="line"
                  height={320}
                  className="apex-charts"
                />
              </div> */}
            </div>
            <div className="col-12 col-md-4 text-break">
              <div className="">
                <div>
                  <p className="mb-0 mt-2 mt-md-0">Today</p>
                  <div className="d-flex align-items-center gap-1">
                    <div className="number-stats">
                      <Placeholder
                        loading={isSPTStatsDataLoading}
                        className="custom-w-24 custom-h-2"
                      ></Placeholder>
                      {!isSPTStatsDataLoading &&
                        `$${formatNumberWithCommas(
                          sPTStats?.avg_closeprice_day,
                        )}`}
                    </div>
                    <div className="">
                      <Placeholder
                        loading={isSPTStatsDataLoading}
                        className="custom-w-2 custom-h-2"
                      ></Placeholder>
                      {!isSPTStatsDataLoading &&
                        (() => {
                          if (sPTStats?.percent_diff_day < 0) {
                            return (
                              <span className="badge badge-soft-danger custom-badge-12-to-14 p-1 me-1">
                                {formatDecimalPlaces(
                                  sPTStats?.percent_diff_day,
                                )}
                                %
                              </span>
                            );
                          } else {
                            return (
                              <span className="badge badge-soft-success custom-badge-12-to-14 p-1 me-1">
                                +{" "}
                                {formatDecimalPlaces(
                                  sPTStats?.percent_diff_day,
                                )}
                                %
                              </span>
                            );
                          }
                        })()}
                    </div>
                  </div>
                  <div className="ps-3 ps-md-0">
                    <hr />
                  </div>
                </div>
                <div>
                  <p className="mb-0">This Month</p>
                  <div className="d-flex align-items-center gap-1">
                    <div className="number-stats">
                      <Placeholder
                        loading={isSPTStatsDataLoading}
                        className="custom-w-24 custom-h-2"
                      ></Placeholder>
                      {!isSPTStatsDataLoading &&
                        `$${formatNumberWithCommas(
                          sPTStats?.avg_closeprice_mtd,
                        )}`}
                    </div>
                    <div className="">
                      <Placeholder
                        loading={isSPTStatsDataLoading}
                        className="custom-w-2 custom-h-2"
                      ></Placeholder>
                      {!isSPTStatsDataLoading &&
                        (() => {
                          if (sPTStats?.percent_diff_month && sPTStats?.percent_diff_month < 0) {
                            return (
                              <span className="badge badge-soft-danger custom-badge-12-to-14 p-1 me-1">
                                {formatDecimalPlaces(
                                  sPTStats?.percent_diff_month,
                                )}
                                %
                              </span>
                            );
                          } else {
                            return (
                              <span className="badge badge-soft-success custom-badge-12-to-14 p-1 me-1">
                                +{" "}
                                {formatDecimalPlaces(
                                  sPTStats?.percent_diff_month,
                                )}
                                %
                              </span>
                            );
                          }
                        })()}
                    </div>
                  </div>
                  <div className="ps-3 ps-md-0">
                    <hr />
                  </div>
                </div>
                <div>
                  <p className="mb-0">Year to Date</p>
                  <div className="d-flex align-items-center gap-1">
                    <div className="number-stats d-flex">
                      <Placeholder
                        loading={isSPTStatsDataLoading}
                        className="custom-w-24 custom-h-2"
                      ></Placeholder>
                      {!isSPTStatsDataLoading &&
                        `$${formatNumberWithCommas(
                          sPTStats?.avg_closeprice_ytd,
                        )}`}
                    </div>
                    <div className="">
                      <Placeholder
                        loading={isSPTStatsDataLoading}
                        className="custom-w-2 custom-h-2"
                      ></Placeholder>
                      {!isSPTStatsDataLoading &&
                        (() => {
                          if (sPTStats?.percent_diff_year < 0) {
                            return (
                              <span className="badge badge-soft-danger custom-badge-12-to-14 p-1 me-1">
                                {formatDecimalPlaces(
                                  sPTStats?.percent_diff_year,
                                )}
                                %
                              </span>
                            );
                          } else {
                            return (
                              <span className="badge badge-soft-success custom-badge-12-to-14 p-1 me-1">
                                +{" "}
                                {formatDecimalPlaces(
                                  sPTStats?.percent_diff_year,
                                )}
                                %
                              </span>
                            );
                          }
                        })()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SalePriceTrends;
