import React from 'react';
import ReactTooltip from 'react-tooltip';
import { createUseStyles } from 'react-jss';

const styles = createUseStyles({
  Tooltip: {
    'max-width': '300px',
    'z-index': '100',
  },
});

const Tooltip = () => {
  const classes = styles();
  return (
    <ReactTooltip className={classes.Tooltip} multiline />
  );
};

export default Tooltip;
