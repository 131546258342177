import React, { useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { useAppSelector } from '../../../state/hooks';
import { selectTrackingUserId } from '../../../state/appSlice';
import tracking, { getTrackingData, TrackingActions } from '../../../state/tracking';
import Account from './Account/Account';

const Settings = () => {
  const userId = useAppSelector(selectTrackingUserId);
  useEffect(() => {
    tracking.track(
      TrackingActions.TOOLS_PAGE_OPENED.valueOf(),
      getTrackingData(userId!),
    );
  }, [userId]);

  return (
    <>
      <MetaTags>
        <title>Settings - Kintaro</title>
      </MetaTags>
      <Account />
    </>
  );
};

export default Settings;
