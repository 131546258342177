import { formatNumberWithCommas } from "@utils/helpers";
import { generateChartFormatOnTableHover } from "@utils/transform/toolsCma";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function CmaAgentTableLast12OpenChart({ agentsInfo, fieldToShow, chartTitle }: any) {
  const [chartData, setChartData] = useState([] as any);

  useEffect(() => {
    if (agentsInfo?.chart) {
      const theFormattedChartData = generateChartFormatOnTableHover(
        agentsInfo?.chart,
      );
      setChartData(theFormattedChartData);
    }
  }, [agentsInfo]);

  function getOptions({ color, ticksPreLast = 0, categories = [] }: any) {
    const options: any = {
      chart: {
        id: "line-chart",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        type: "line",
        // width: 100,
        // height: 160,
        dataLabels: {
          enabled: false,
        },
        // sparkline: {
        //   enabled: true,
        // },
      },
      colors: [`${color}`],
      stroke: {
        curve: "smooth",
        width: 3,
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
        // labels: {
        //   rotate: -25,
        //   rotateAlways: true,
        // },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        categories,
      },
      yaxis: {
        // ticks,
        // tickAmount,
        labels: {
          // offsetX: -14,
          // align: "left", // Set the alignment to 'left'
          axisTicks: {
            show: false, // Hide ticks on the right side of the y-axis
          },
        },
      },
      legend: {
        show: false,
      },
    };
    if (ticksPreLast !== 0) {
      options.yaxis.labels = {
        ...options.yaxis.labels,
        formatter: (value: any) => {
          const valueFormatted = parseFloat(value).toFixed(1);
          return `${ticksPreLast[0] ?? ""}${formatNumberWithCommas(
            parseFloat(valueFormatted),
          )}${ticksPreLast[1] ?? ""}`;
        },
      };
    }
    return options;
  }
  return (
    <div>
      <div id="line-chart">
        {/* {(chartData?.total_leads?.length && ( */}
          <ReactApexChart
            options={getOptions({
              color: "#FFF",
              ticksPreLast: ["", ""],
              categories: chartData?.categories ?? [],
              // categories: ["Jan", "Feb", "Mar"],
            })}
            series={[
              {
                name: `${chartTitle}`,
                data: chartData?.[fieldToShow] ?? [],
              },
              // {
              //   name: `${chartTitle}`,
              //   data: [1, 3, 1],
              // },
            ]}
            type="line"
            className="custom-table-apexchart"
          />
        {/* )) || ""} */}
      </div>
    </div>
  );
}

export default CmaAgentTableLast12OpenChart;
