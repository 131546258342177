import React, { useEffect, useMemo } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { createUseStyles } from 'react-jss';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { debounce } from 'lodash';

const styles = createUseStyles({
  SearchBar: {
    width: '100%',
  },
  SearchBarField: {
    'border-radius': '2em',
  },
  SearchBarIcon: {
    'border-radius': '2em 0 0 2em',
  },
});

export interface SearchBarProps {
  className?: string
  enterSubmits?: boolean
  placeholderText?: string
  setSearch: (search: string) => void
}

const SearchBar = ({
  className, enterSubmits, placeholderText, setSearch,
}: SearchBarProps) => {
  const classes = styles();

  const debouncedCallback = useMemo(
    () => debounce(setSearch, 500),
    [setSearch],
  );
  useEffect(() => () => {
    debouncedCallback.cancel();
  }, [debouncedCallback]);

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!enterSubmits && event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className={className}>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText className={classes.SearchBarIcon}>
              <SearchIcon />
            </InputGroupText>
          </InputGroupAddon>
          <input
            className={`form-control ${classes.SearchBarField}`}
            onChange={(event) => debouncedCallback(event.currentTarget.value)}
            onKeyPress={onKeyPress}
            placeholder={placeholderText}
          />
        </InputGroup>
      </div>
    </>
  );
};

SearchBar.defaultProps = {
  enterSubmits: true,
  placeholderText: 'Search...',
};

export default SearchBar;
