import React from 'react';
import { Col, Row } from 'reactstrap';

const Footer = () => (
  <>
    <footer className="footer">
      <div className="container-fluid">
        <Row>
          <Col sm={6}>
            {new Date().getFullYear()}
            &nbsp;
            © Kintaro.
          </Col>
        </Row>
      </div>
    </footer>
  </>
);

export default Footer;
