import React, { useEffect } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { useAppDispatch } from '../../state/hooks';
import { setGoogleMapsLoaded } from '../../state/appSlice';

const googleMapsLibraries = ['visualization'] as any;
const GoogleMapsLoader = () => {
  const dispatch = useAppDispatch();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY ?? '',
    libraries: googleMapsLibraries,
  });

  useEffect(() => {
    dispatch(setGoogleMapsLoaded(isLoaded));
  }, [dispatch, isLoaded]);

  return (<></>);
};

export default GoogleMapsLoader;
