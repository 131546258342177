const generateFinancialDataByYear = (theData: any): Record<string, any> => {
  const result: Record<string, any> = {
    months: [],
    last_12_gci: [],
    previous_period_gci: [],
    last_12_list_sides: [],
    previous_period_list_sides: [],
    last_12_sell_sides: [],
    previous_period_sell_sides: [],
    last_12_company_dollar: [],
    previous_period_company_dollar: [],
    categories: [],
  };

  const filteredData = theData
    .sort((a:any, b:any) => {
    // Compare by year
      if (parseFloat(a.the_year) !== parseFloat(b.the_year)) {
        return (parseFloat(a.the_year) - parseFloat(b.the_year));
      }

      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_short_name] - monthOrder[b.month_short_name];
    });

  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry.the_year).slice(-2);
    const formattedCategories = `${entry.month_short_name} ${yearSplit}`;
    
    result.months.push(entry.month_short_name);
    result.last_12_gci.push(entry.last_12_gci / 1000);
    result.previous_period_gci.push(entry.previous_period_gci / 1000);
    result.last_12_list_sides.push(entry.last_12_list_sides);
    result.previous_period_list_sides.push(entry.previous_period_list_sides);
    result.last_12_sell_sides.push(entry.last_12_sell_sides);
    result.previous_period_sell_sides.push(entry.previous_period_sell_sides);
    result.last_12_company_dollar.push(entry.last_12_company_dollar / 1000);
    result.previous_period_company_dollar.push(entry.previous_period_company_dollar / 1000);
    result.categories.push(formattedCategories);
  });

  return result;
};

const generateListingMetricDataByYear = (theData: any): Record<string, any> => {
  const result: Record<string, any> = {
    months: [],
    categories: [],
    last_12_total_listings: [],
    previous_period_total_listings: [],
    last_12_total_sold_listings: [],
    previous_period_total_sold_listings: [],
    last_12_avg_sell_price: [],
    previous_period_avg_sell_price: [],
    last_12_avg_list_price: [],
    previous_period_avg_list_price: [], // Corrected the typo here
  };
  
  const filteredData = theData
    .sort((a:any, b:any) => {
      // Compare by year
      if (parseFloat(a.the_year) !== parseFloat(b.the_year)) {
        return (parseFloat(a.the_year) - parseFloat(b.the_year));
      }
      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_short_name] - monthOrder[b.month_short_name];
    });
  
  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry.the_year).slice(-2);
    const formattedCategories = `${entry.month_short_name} ${yearSplit}`;
    result.months.push(entry.month_short_name);
    result.last_12_total_listings.push(entry.last_12_total_listings);
    result.previous_period_total_listings.push(entry.previous_period_total_listings);
    result.last_12_total_sold_listings.push(entry.last_12_total_sold_listings);
    result.previous_period_total_sold_listings.push(entry.previous_period_total_sold_listings);
    result.last_12_avg_sell_price.push(entry.last_12_avg_sell_price / 1000);
    result.previous_period_avg_sell_price.push(entry.previous_period_avg_sell_price / 1000);
    result.last_12_avg_list_price.push(entry.last_12_avg_list_price / 1000);
    result.previous_period_avg_list_price.push(entry.previous_period_avg_list_price / 1000);
    result.categories.push(formattedCategories);
  });
  return result;
};

function generateTicks(data: any, tickCount = 11) {
  const firstValue: any = Math.min(...data);
  const lastValue: any = Math.max(...data);
  const tickStep: any = (lastValue - firstValue) / (tickCount - 1);

  return Array.from({ length: tickCount }, (_, index) => firstValue + index * tickStep);
}

const generateListingFinancialDataByYear = (year: number, theData: any): Record<string, any> => {
  const result: Record<string, any> = {
    year,
    months: [],
    last_12_total_listingss: [],
    previous_period_total_listingss: [],
    last_12_total_sold_listings: [],
    previous_period_total_sold_listings: [],
    last_12_average_list_price: [],
    previous_period_average_list_price: [], // Corrected the typo here
  };
  // const filteredData = theData
  //   .filter((entry: any) => entry.the_year === year.toString())
  //   .sort((a: any, b: any) => {
  //     const monthOrder: any = {
  //       Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
  //     };

  //     return monthOrder[a.short_month_name] - monthOrder[b.short_month_name];
  //   });

  const filteredData = theData
    .sort((a:any, b:any) => {
      // Compare by year
      if (parseFloat(a.the_year) !== parseFloat(b.the_year)) {
        return (parseFloat(a.the_year) - parseFloat(b.the_year));
      }
      // If years are the same, compare by month
      const monthOrder: any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.short_month_name] - monthOrder[b.short_month_name];
    });

  filteredData.forEach((entry: any) => {
    result.months.push(entry.short_month_name);
    result.last_12_total_listingss.push(entry.last_12_total_listingss);
    result.previous_period_total_listingss.push(entry.previous_period_total_listingss);
    result.last_12_total_sold_listings.push(entry.last_12_total_sold_listings);
    result.previous_period_total_sold_listings.push(entry.previous_period_total_sold_listings);
    result.last_12_average_list_price.push(entry.last_12_average_list_price);
    result.previous_period_average_list_price.push(entry.previous_period_average_list_price);
  });

  return result;
};

export { generateFinancialDataByYear, generateListingFinancialDataByYear, generateListingMetricDataByYear, generateTicks };
