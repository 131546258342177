/* eslint-disable camelcase */
// List Side vs Sell Side
const generateAgentQuartitleData = (data: any) => {
  const result:any = {
    cloudCMA: [],
    gmail: [],
    printerLogic_SaaS: [],
    homeSpotter: [],
    sir_access: [],
    testimonial_tree: [],
    video_on_demand: [],
    xpressDocs: [],
    zoom: [],
  };

  data.forEach((entry:any) => {
    const productName = entry.product_tool;
    const { quartile } = entry;
    const activeAgentsCount = entry.active_agents_count;
  
    // Push the value into the corresponding array based on the product_tool
    switch (productName) {
      case "CloudCMA":
        result.cloudCMA[quartile - 1] = activeAgentsCount;
        break;
      case "Gmail":
        result.gmail[quartile - 1] = activeAgentsCount;
        break;
      case "PrinterLogic SaaS":
        result.printerLogic_SaaS[quartile - 1] = activeAgentsCount;
        break;
      case "HomeSpotter":
        result.homeSpotter[quartile - 1] = activeAgentsCount;
        break;
      case "SIR Access":
        result.sir_access[quartile - 1] = activeAgentsCount;
        break;
      case "Testimonial Tree":
        result.testimonial_tree[quartile - 1] = activeAgentsCount;
        break;
      case "Video On Demand":
        result.video_on_demand[quartile - 1] = activeAgentsCount;
        break;
      case "XpressDocs":
        result.xpressDocs[quartile - 1] = activeAgentsCount;
        break;
      case "Zoom":
        result.zoom[quartile - 1] = activeAgentsCount;
        break;
      default:
        break;
    }
  });
  
  return result;
};

function generateAgentQuartitleTicks(data: any, tickCount:any = 11) {
  const { total_sell_side, total_list_side, total_double_side } = data;
  
  const d1MinValue = Math.min(...total_sell_side);
  const d1MaxValue = Math.max(...total_sell_side);
  const d2MinValue = Math.min(...total_list_side);
  const d2MaxValue = Math.max(...total_list_side);
  const d3MinValue = Math.min(...total_double_side);
  const d3MaxValue = Math.max(...total_double_side);

  const firstValue: any = Math.min(d1MinValue, d2MinValue, d3MinValue);
  const lastValue: any = Math.max(d1MaxValue, d2MaxValue, d3MaxValue);
   
  const tickStep: any = (lastValue - firstValue) / (tickCount - 1);

  return Array.from({ length: tickCount }, (_, index) => firstValue + index * tickStep);
}

function generateTicks(data: any, tickCount = 11) {
  const firstValue: any = Math.min(...data);
  const lastValue: any = Math.max(...data);
  const tickStep: any = (lastValue - firstValue) / (tickCount - 1);

  return Array.from({ length: tickCount }, (_, index) => firstValue + index * tickStep);
}

export { generateAgentQuartitleData, generateAgentQuartitleTicks, generateTicks };
