import HelpIcon from "@components/common/HelpIcon";
import Placeholder from "@components/common/Placeholder";
import { STATUS_UP } from "@constants/variables";
import { formatNumberWithCommas } from "@utils/helpers";
import { DecreaseIndicator, IncreaseIndicator } from "@utils/icons";

function KPICard({
  title,
  indicatorStatus,
  toolTipText,
  value,
  text,
  loading,
  loaderClassName = "custom-w-2",
}: any) {
  return (
    <div
      className="custom-modal-inner__padding h-100"
      style={{ backgroundColor: "#2A3042" }}
    >
      <div className="">
        <div className="d-inline-block mb-2 mb-lg-0 custom-min-h-60">
          <span className="header-title pe-1 fw-normal">{title}</span>
          <div className="d-inline-block align-middle">
            <HelpIcon tooltip={`${toolTipText}`} width={20} height={20} />
          </div>
        </div>
        <div className="text-break">
          <div className="d-flex gap-2 align-items-center">
            <Placeholder
              loading={loading}
              className={`${loaderClassName} custom-h-2`}
            ></Placeholder>
            {!loading && (
              <>
                <span className="number-stats-2 text-white d-flex align-items-center">
                 {title !== 'Average Days on Market' ? '$' : ''} {`${formatNumberWithCommas(value)}`}
                </span>
                <span className="d-flex align-items-center">
                  {indicatorStatus === STATUS_UP ? (
                    <IncreaseIndicator />
                  ) : (
                    <DecreaseIndicator />
                  )}
                </span>
              </>
            )}
          </div>
          <p className="text-white custom-fs-12">{text}</p>
        </div>
      </div>
    </div>
  );
}

export default KPICard;
