/* eslint-disable camelcase */
/* eslint-disable no-multiple-empty-lines */
const generateByAgentsToolsCmaPieChartData = (data:any) => {
  const result:any = {
    reportPercentageData: [],
    reportCountData: [],
    reportLabels: [],
  };
 
  // eslint-disable-next-line no-unused-expressions
  data?.length && data.forEach((item:any) => {
    result.reportLabels.push(item.report_type); // Add report_type to labels array
    result.reportCountData.push(item.report_count); // Add report_count to series array
    result.reportPercentageData.push(item.report_type_percentage); // Add report_count to series array
  });
  return result;
};

const generateByAgentsToolsNeutrinoPieChartData = (data:any) => {
  const result:any = {
    reportPercentageData: [],
    reportCountData: [],
    reportLabels: [],
  };
 
  // eslint-disable-next-line no-unused-expressions
  data?.length && data.forEach((item:any) => {
    result.reportLabels.push(item.modified_type); // Add report_type to labels array
    result.reportCountData.push(item.type_count); // Add type_count to series array
    result.reportPercentageData.push(item.type_percentage); // Add report_count to series array
  });
  return result;
};

const generateEngagementDataByAgent = (theData: any): Record<string, any> => {
  const result: Record<string, any> = {
    months: [],
    cloudcmascore: [],
    emailmarketingscore: [],
    websitescore: [],
    categories: [],
  };

  const filteredData = theData
    .sort((a:any, b:any) => {
    // // Compare by year
    //   if (a.year !== b.year) {
    //     return a.year - b.year;
    //   }
      // Compare by year
      if (parseFloat(a.year) !== parseFloat(b.year)) {
        return (parseFloat(a.year) - parseFloat(b.year));
      }
      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_short_name] - monthOrder[b.month_short_name];
    }).slice(-15);

  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry.year).slice(-2);
    result.months.push(entry?.month_short_name);
    result.cloudcmascore.push(entry.cloudcmascore);
    result.emailmarketingscore.push(entry.emailmarketingscore);
    result.websitescore.push(entry.websitescore);
    result.categories.push(`${entry?.month_short_name} ${yearSplit}`);
  });

  return result;
};

export { generateByAgentsToolsCmaPieChartData, generateByAgentsToolsNeutrinoPieChartData, generateEngagementDataByAgent };
