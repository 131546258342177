/* eslint-disable no-multiple-empty-lines */
const agentToolTip: any = {
  forecastedRevenue: "The forecasted GCI graph uses 48 months of historical data to forecast GCI for the next three months.",
  company_dollar:
    "Company dollar represents the net revenue that the agent is bringing to the brokerage.",
  sales_volume:
    "Sales Volume represents the total transaction volume of list and sell sides year-to-date.",
  years_at_brokerage:
    "This shows the amount of time that an agent has spent at the brokerage.",
  sell_sides:
    "Sell sides represent the year to date sell sides from the agent.",
  gci: "GCI represents the year to date GCI from the agent.",
  list_sides:
    "List sides represent the year to date list sides from the agent.",
  sell_vs_list_sides:
    "Sell sides and List sides represent the year to date sell sides and list sides from the agent.",
  pending_gci:
    "Pending GCI represents the amount of pending GCI that the agent has.",
  forecasted_revenue:
    "The forecasted revenue graph uses historical data to help forecast revenue on an office basis.",
  gci_chart:
    "This GCI chart represents the amount of GCI that the agent has done year over year.",
  company_dollar_chart:
    "The company dollar chart represents the net company dollar revenue that the agent has done year over year.",
  gci_vs_company_dollar_chart:
    "This GCI and company dollar chart represents the amount of GCI and the net company dollar revenue that the agent has done year over year.",
  sell_sides_chart:
    "The sell sides chart displays the amount of sell sides the agent has done year over year.",
  list_sides_chart:
    "The list side chart shows the amount of list sides that the agent has done year over year.",
  list_vs_sell_sides_chart:
    "The list side and sell sides chart displays the amount of list sides and sell sides the agent has done year over year.",
  total_listings_chart:
    "Total listings show the comparative total listings on a year-over-year basis.",
  sold_listings_chart:
    "Sold listings show the comparative sold listings on a year-over-year basis.",
  total_vs_sold_listings_chart:
    "Total listings and Sold listings show the comparative total listings and sold listings on a year-over-year basis.",
  average_list_price_chart:
    "Average list price shows the average list price on a year-over-year basis.",
  average_sold_price_chart:
    "Average sold price shows the comparative average sold price on a year-over-year basis.",
  avg_list_vs_sold_price_chart:
    "Average list price and Average sold price shows the average list price and comparative average sold price on a year-over-year basis.",
  technology_engagement:
    "This card helps the brokerage staff understand the average engagement of a tool, as well as the collective engagement across all tools. This is benchmarked against the previous year.",
  recent_activity:
    "The recent activity table helps sales managers see the recent activity for an internal agent and reach out to more deeply engage with the individual.",
  agentTechEngmnt: "This card helps the brokerage staff understand the average engagement of a tool on an agent by agent basis. This is benchmarked against other agents.",
  agentMonthlyEngmntScore: "The monthly engagement chart shows engagement across the various tools on an agent by agent basis.",
};
const homepageToolTip: any = {
  avgMkt: "The number of days when a property has a signed listing agreement and the Close Date of the company’s listings year-to-date.",
  avgLP: "The average of all listings taken year-to-date by the company",
  closedGCI: "The total closed Gross Commission Income (GCI) year-to-date by the company.",
};
const financialToolTip = {
  forecastedRevenue:
    "The forecasted GCI graph uses 48 months of historical data to forecast GCI for the next three months.",
  salesPriceTrends:
    "Kintaro analyzes market price trends and benchmarks them against the previous year to help understand what’s happening in the market.",
  totalGCI:
    "Total GCI shows the total GCI that the brokerage has brought into the business over the last 12 months.",
  listings:
    "The listings chart shows the total number of listings across the brokerage over the last 12 months.",
  sellSide:
    "The sell side chart helps the brokerage know across their offices how many sell sides they have conducted over the last 12 months.",
  listSide:
    "The total list sides helps the brokerage know across their offices how many list sides they have conducted over the last 12 months.",
  totalListingsChart:
    "This shows the trend line of total listings within an office.",
  avgListPriceChart:
    "Average list price shows the trend line of the listing price.",
  avgSoldPriceChart:
    "Average sold price shows the trend line of the sales price.",
  soldToListRatioChart:
    "The sale-to-list ratio measures the difference between the final purchase price and the original asking price of a property.",
};

const officeModalTooltip = {
  forecastedRevenue: "The forecasted GCI graph uses 48 months of historical data to forecast GCI for the next three months.",
  gci: "This GCI chart represents the amount of GCI that the office has done year over year.",
  companyDollar: "The company dollar chart represents the net company dollar revenue that the office has done year over year.",
  gci_vs_company_dollar_chart: "This GCI and company dollar chart represents the amount of GCI and the net company dollar revenue that the office has done year over year.",
  sellSides: "The sell sides chart displays the amount of sell sides the office has done year over year.",
  listSides: "The list side chart shows the amount of list sides that the office has done year over year.",
  list_vs_sell_sides_chart: "The list side and sell sides chart displays the amount of list sides and sell sides that the office has done year over year.",
  ytdSalesVolume: "The sales volume card shows the total year-to-date sales volume based on the office.",
  ytdCompanyDollar: "Company dollar shows the YTD company dollar volume.",
  ytdSellSides: "The sell side shows the year-to-date sell sides at this office.",
  ytdGci: "GCI shows the total GCI on an office-by-office basis.",
  ytdListSides: "List sides shows the year-to-date list of sides at the office.",
  ytdPendingGci: "Pending GCI shows the current pending GCI year to date.",
  totalListings: "Total listings show the comparative total listings on a year-over-year basis.",
  soldListings: "Sold listings show the comparative sold listings on a year-over-year basis.",
  total_vs_sold_listings_chart: "Total listings and Sold listings show the comparative total listings and sold listings on a year-over-year basis.",
  avgListPrice: "Average list price shows the average list price on a year-over-year basis.",
  avgSoldPrice: "Average sold price shows the comparative average sold price on a year-over-year basis.",
  avg_list_vs_sold_price_chart: "Average list price and Average sold price shows the average list price and the comparative average sold price on a year-over-year basis.",
  newRecruits: "This card is designed to help visualize the total agents recruited during the year to date.",
  retentionOverview: "This card is designed to show the retention leaderboard including the year to date turnover rate and the net turnover (retained minus recruited). The benchmark is against the previous year.",
  officePerformance: "This card helps break down the agents/revenue signed, the agents/revenue gained and the net impact. It also helps the team benchmark this performance against the previous year.",
  officeAgentRetention: "This chart shows the amount of agents gained and lost inside of an office over the last 12 months and the percentage change from the previous 12 months.",
  officeTechEngmnt: "This card helps the brokerage staff understand the average engagement of a tool on an office by office basis. This is benchmarked against other offices.",
  officeMonthlyEngmntScore: "The monthly engagement chart shows engagement across the various tools on an office by office basis.",
};
const toolsPageToolTip = {
  toolsAgentEngmntByQuartile: "Engagement by production quartile helps break out technology logins on a tool by tool basis.",
  toolsModalActivePipe: "This card helps show the top user inside the brokerage.",
  toolsEngmntOverTimeChart: "Engagement over time shows the engagement of a tool over the last 12 month period.",
  toolsCumulativeSendsChart: "Cumulative sends shows total sends over a 12 month period.",
  toolsAvgOpenRateChart: "Average open rate shows open rate trends over a 12 month period.",
  toolsAvgCtrChart: "Click through rate shows the click through of the company over a 12 month period.",
  toolsApBottomUsers: "Bottom users are the bottom users inside the app. We benchmark this alongside sales volume so we can correlate sales volume to engagement.",
  toolsApTopUsers: "Top users are the top users inside the app. We benchmark this alongside sales volume so we can correlate sales volume to engagement.",
  toolsNeutrinoEngmntOverTime: "Engagement over time shows the engagement of a tool over the last 12 month period.",
  toolsNeutrinoPieChart: "Form submission shows the different types of website form submissions over a 12 month period.",
  toolsModalNeutrino: "This card helps show the top user inside the brokerage.",
  toolsModalNeutrinoWebLeads: "Web lead breaks out the different types of web leads that have been submitted over a 12 month period.",
  toolsModalNeutrinoBottomUsers: "Bottom users are the bottom users inside the app. We benchmark this alongside sales volume so we can correlate sales volume to engagement.",
  toolsModalNeutrinoTopUsers: "Top users are the top users inside the app. We benchmark this alongside sales volume so we can correlate sales volume to engagement.",
  toolsModalCloudCma: "This card helps show the top user inside the brokerage.",
  toolsCloudCmaEngmntOverTime: "Engagement over time shows the engagement of a tool over the last 12 month period.",
  toolsModalCloudCmaReportTypePieChart: "Report types shows the type of CloudCMAs produced over a 12 month period.",
  toolsModalCloudCmaCrmPieChart: "CRM report type shows the trend line of the different report types over a 12 month period.",
  toolsModalCloudCmaBottomUsers: "Bottom users are the bottom users inside the app. We benchmark this alongside sales volume so we can correlate sales volume to engagement.",
  toolsModalCloudCmaTopUsers: "Top users are the top users inside the app. We benchmark this alongside sales volume so we can correlate sales volume to engagement.",

};
// `The ${toolsName} score helps a user understand their monthly engagement score for ${toolsName} on a monthly basis. Engagement is calculated using logins as an indicator.`
const getToolsLandingPageToolTip = (toolsName: string) => `The ${toolsName} score helps a user understand their monthly engagement score for ${toolsName} on a monthly basis. Engagement is calculated using logins over a 30 day period as an indicator.`;
export { agentToolTip, financialToolTip, getToolsLandingPageToolTip, homepageToolTip, officeModalTooltip, toolsPageToolTip };

