import SearchSelect from '@components/common/SearchSelect';
import cookies from '@utils/cookies';
import { http } from '@utils/http';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

function AdminBrandSelector() {
  const history = useHistory();
  // Brand id
  const [brandListOptions, setBrandListOptions] = useState([] as any);
  const [selectedBrandList, setSelectedBrandList] = useState({} as any);
  const [isBrandDataLoading, setIsBrandDataLoading] = useState(true);

  useEffect(() => {
    getAllBrands();
  }, []);

  const reloadPage = () => {
    window.location.reload();
  };
  
  function getAllBrands() {
    setIsBrandDataLoading(true);
    http
      .get("/brands-data.json/")
      .then((response: any) => {
        const { data }: any = response;
        setBrandListOptions(data?.brands);
        const localStorageBrandSlug = cookies.get("the_brand_slug");
        const selectedBrandSlug = data?.brands?.find((brand:any) => brand?.brand_code === localStorageBrandSlug);
        if (selectedBrandSlug) {
          setSelectedBrandList(selectedBrandSlug);
        }
        setIsBrandDataLoading(false);
      })
      .catch((err:any) => {
        setIsBrandDataLoading(false);
        console.log(err);
      });
  }
  return (
    <div>
    <SearchSelect
      options={brandListOptions}
      value={
        JSON.stringify(selectedBrandList) !== "{}"
          ? selectedBrandList
          : "Select Brand"
      }
      onChange={(option: any) => {
        setSelectedBrandList(option);
        cookies.set("the_brand_slug", option?.brand_code);
        setTimeout(() => {
          reloadPage();
        }, 100);
      }}
      isClearable
      width="180px"
      isLoading={isBrandDataLoading}
      getOptionValue={(option: any) => option?.id}
      getOptionLabel={(option: any) => `${option?.name}`}
      placeholder="Select Brand"
    />
  </div>
  );
}

export default AdminBrandSelector;
