import React from 'react';
import { Card, CardBody } from 'reactstrap';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { createUseStyles } from 'react-jss';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip from '../../../common/Tooltip';
import IncreaseDecreaseIndicator from '../../../common/IncreaseDecreaseIndicator/IncreaseDecreaseIndicator';
import { error, success } from '../../../../assets/colors';
import HelpIcon from '../../../common/HelpIcon';
import FavoritedAgents from '../FavoritedAgents/FavoritedAgents';
import AgentOpportunities from '../AgentOpportunities/AgentOpportunities';

const styles = createUseStyles({
  LeftPanel_wrapper: {
    'width': '20%',
  },
  RightPanel_wrapper: {
    'width': '80%',
  },
  PieLeftPanel_wrapper: {
    'width': '25%',
    'backgroundColor': '#2a3042',
    'padding': '16px',
  },
  PieRightPanel_wrapper: {
    'width': '75%',
    'backgroundColor': '#2a3042',
    'padding': '16px',
  },
  SmallPanel_wrapper: {
    'width': '27%',
  },
  Circle: {
    'width': '5px',
    'height': '5px',
    'borderRadius': '50%',
    'background': '#A6B0CF',
  },
  HeightGraph: {
    'height': '200px',
    'overflowY': 'hidden',
    'overflowX': 'hidden',
  },
  TextGraph: {
    'height': '30%',
    'top': '70px',
    'left': '20px',
  },
  LoginCard_percentageIndicator__increasing: {
    color: success,
  },
  LoginCard_percentageIndicator__decreasing: {
    color: error,
  },
  LoginCard_indicator: {
    'margin-top': '-.4em',
  },
  TitleFilters: {
    'fontSize': '26px',
    'padding': '16px 16px 0 16px',
  },
  '@media (max-width: 767px)': {
    PieRightPanel_wrapper: {
      'width': '100%',
      'backgroundColor': '#2a3042',
      'padding': '16px',
    },
    PieLeftPanel_wrapper: {
      'width': '100%',
      'backgroundColor': '#2a3042',
      'padding': '16px',
    },
    TitleFilters: {
      'fontSize': '18px',
      'padding': '16px 16px 0 16px',
    },
  },
});

export interface GraphLineProps {
  cumSendsNumber?: string,
  pieGraph?: boolean,
  lineGraph?: boolean,
  favoritedAgents?: boolean,
  agentOpportunities?: boolean,
}

const GraphLine = ({
  cumSendsNumber,
  pieGraph,
  lineGraph,
  favoritedAgents,
  agentOpportunities,
}: GraphLineProps) => {
  const classes = styles();
  const smallGraphOptions1: EChartsOption = {
    title: {
      text: 'Avg CTR',
      textStyle: {
        fontSize: '24px',
        color: '#fff',
      },
      show: false,
    },
    grid: {
      top: 5,
      right: 5,
      bottom: 5,
      left: 5,
    },
    xAxis: {
      type: 'category',
      data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      show: false,
    },
    yAxis: {
      type: 'value',
      show: false,
    },
    lineStyle: {
      color: 'green',
    },
    legend: {
      show: false,
      bottom: 'bottom',
      data: ['test1'],
      textStyle: {
        color: '#fff',
      },
    },
    series: [
      {
        data: ['30', '38', '35', '45', '42', '66', '64', '50', '59', '70', '60', '65'],
        name: 'test1',
        smooth: true,
        type: 'line',
        showSymbol: false,
      },
    ],
    tooltip: {
      confine: true,
      trigger: 'axis',
    },
  };
  const smallGraphOptions2: EChartsOption = {
    title: {
      text: 'Avg CTR',
      textStyle: {
        fontSize: '24px',
        color: '#fff',
      },
      show: false,
    },
    grid: {
      top: 5,
      right: 5,
      bottom: 5,
      left: 5,
    },
    xAxis: {
      type: 'category',
      data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      show: false,
    },
    yAxis: {
      type: 'value',
      show: false,
    },
    lineStyle: {
      color: 'yellow',
    },
    legend: {
      show: false,
      bottom: 'bottom',
      data: ['test1'],
      textStyle: {
        color: '#fff',
      },
    },
    series: [
      {
        data: ['30', '38', '35', '45', '42', '66', '64', '50', '59', '70', '60', '65'],
        name: 'test1',
        smooth: true,
        type: 'line',
        showSymbol: false,
      },
    ],
    tooltip: {
      confine: true,
      trigger: 'axis',
    },
  };
  const smallGraphOptions3: EChartsOption = {
    title: {
      text: 'Avg CTR',
      textStyle: {
        fontSize: '24px',
        color: '#fff',
      },
      show: false,
    },
    grid: {
      top: 5,
      right: 5,
      bottom: 5,
      left: 5,
    },
    xAxis: {
      type: 'category',
      data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      show: false,
    },
    yAxis: {
      type: 'value',
      show: false,
    },
    legend: {
      show: false,
      bottom: 'bottom',
      data: ['test1'],
      textStyle: {
        color: '#fff',
      },
    },
    series: [
      {
        data: ['30', '38', '35', '45', '42', '66', '64', '50', '59', '70', '60', '65'],
        name: 'test1',
        smooth: true,
        type: 'line',
        showSymbol: false,
      },
    ],
    tooltip: {
      formatter: () => {
        const amount = '5';
        const percentage = '20%';
        return `${amount} (${percentage})`;
      },
      trigger: 'item',
    },
  };
  const pieGraphOptions = {
    // title: {
    //   text: 'Agent Saturation',
    //   textStyle: {
    //     fontSize: '24px',
    //     color: '#fff',
    //   },
    // },
    legend: {
      bottom: 'bottom',
      left: 'left',
      orient: 'horizontal',
      textStyle: {
        color: '#fff',
      },
      data: [{
        value: 40,
        name: 'Agents That Match The Profile',
        label: {
          show: true,
          fontSize: 20,
        },
      }, {
        value: 15,
        name: 'Converted Agents',
      }],
    },
    tooltip: {
      trigger: 'item',
      position: 'top',
    },
    series: [
      {
        name: ['sd', 'sdvsdfsdv', 'sdvsdvsdd'],
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['35%', '50%'],
        left: '20%',
        avoidLabelOverlap: false,
        startAngle: 180,
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 2,
        },
        labelLine: {
          show: false,
        },
        data: [{
          value: 40,
          name: 'Agents That Match The Profile',
          label: {
            show: false,
            position: 'inside',
            color: '#fff',
          },
        }, {
          value: 60,
          name: 'Converted Agents',
          label: {
            show: false,
            position: 'inside',
            color: '#fff',
          },
        }],
      },
    ],
  };

  const increasingUsers = true;
  const percentageIndicatorClass = increasingUsers
    ? classes.LoginCard_percentageIndicator__increasing
    : classes.LoginCard_percentageIndicator__decreasing;
  const indicator = increasingUsers
    ? (<KeyboardArrowUpIcon className={classes.LoginCard_indicator} />)
    : (<KeyboardArrowDownIcon className={classes.LoginCard_indicator} />);
  return (
    <>
      <Tooltip />
      {pieGraph && (
        <div className="d-flex flex-column flex-md-row gap-4 mb-4">
          <CardBody className={`${classes.PieLeftPanel_wrapper}`}>
            <div className="d-flex flex-column justify-content-between">
              <h4 className={`${classes.TitleFilters} text`}>
                Agent Saturation
                <HelpIcon tooltip="Based on the filters above, the agent saturation graph helps the
                  brokerage staff understand how much market share they have amongst a particular
                  subset of agents.
                  "
                />
              </h4>
            </div>
            <ReactECharts option={pieGraphOptions} />
            <p className="pt-4">Acme Realty currently has recruited 13.5% of available agent condidates</p>
          </CardBody>
          <CardBody className={`${classes.PieRightPanel_wrapper} `}>
            {agentOpportunities && (
              <AgentOpportunities />
            )}
          </CardBody>
        </div>
      )}
      {favoritedAgents && (
        <CardBody className={`${classes.PieRightPanel_wrapper} w-100 mb-4`}>
          <FavoritedAgents />
        </CardBody>
      )}
      {lineGraph && (
        <div className="d-flex flex-column flex-md-row gap-8 justify-content-between">
          <Card className={`${classes.SmallPanel_wrapper} table-responsive`}>
            <CardBody>
              <div className="d-flex flex-column justify-content-between">
                <div className="h2">Cumulative Sends
                  {/* <HelpIcon tooltip="Cumulative Sends" /> */}
                </div>
                <div className="d-flex align-items-center">
                  <div className="h1 w-50">
                    {cumSendsNumber}
                    <span className={percentageIndicatorClass}>{indicator}</span>
                  </div>
                  <div className={`${classes.HeightGraph} w-75`}>
                    <ReactECharts option={smallGraphOptions1} />
                  </div>
                </div>
                <IncreaseDecreaseIndicator
                  change="12"
                  increasing
                  text="Higher than other offices"
                />
              </div>
            </CardBody>
          </Card>
          <Card className={`${classes.SmallPanel_wrapper} table-responsive`}>
            <CardBody>
              <div className="d-flex flex-column justify-content-between">
                <div className="h2">Avg Open Rate
                  {/* <HelpIcon tooltip="Avg Open Rate" /> */}
                </div>
                <div className="d-flex align-items-center">
                  <div className="h1 w-50">
                    17% <span className={percentageIndicatorClass}>{indicator}</span>
                  </div>
                  <div className={`${classes.HeightGraph} w-75`}>
                    <ReactECharts option={smallGraphOptions2} />
                  </div>
                </div>
                <IncreaseDecreaseIndicator
                  change="2"
                  increasing
                  text="Higher than other offices"
                />
              </div>
            </CardBody>
          </Card>
          <Card className={`${classes.SmallPanel_wrapper} table-responsive`}>
            <CardBody>
              <div className="d-flex flex-column justify-content-between">
                <div className="h2">Avg CTR
                  {/* <HelpIcon tooltip="Avg CTR" /> */}
                </div>
                <div className="d-flex align-items-center">
                  <div className="h1 w-50">
                    30% <span className={percentageIndicatorClass}>{indicator}</span>
                  </div>
                  <div className={`${classes.HeightGraph} w-75`}>
                    <ReactECharts option={smallGraphOptions3} />
                  </div>
                </div>
                <IncreaseDecreaseIndicator
                  change="26"
                  increasing
                  text="Higher than other offices"
                />
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
};

export default GraphLine;
