import SearchSelect from "@components/common/SearchSelect";
import ReactApexChart from "react-apexcharts";

function EmergingMarketAlert() {
  const series = [
    {
      name: "current",
      data: [0, 200, 95, 0, 50, 30, 700, 680, 490, 410, 10],
    },
  ];
  const options: any = {
    chart: {
      id: "line-chart",
      toolbar: {
        show: false,
      },
      // dropShadow: {
      //   enabled: false,
      //   enabledOnSeries: undefined,
      //   top: 0,
      //   left: 0,
      //   blur: 3,
      //   color: '#000',
      //   opacity: 0.35,
      // },
    },
    dataLabels: {
      enabled: false,
    },
    
    colors: ["#CF4AFE", "#F2CA6B"],
    stroke: {
      curve: "smooth",
      width: 4,
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [
        "May 21",
        "Jul 21",
        "Sept 21",
        "Oct 21",
        "Dec 21",
        "Feb 22",
        "May 22",
        "Aug 22",
        "Nov 22",
        "Feb 23",
      ],
      style: {
        colors: "#CF4AFE",
      },
    },
    yaxis: {
      labels: {
        show: true,
        formatter(val:any) {
          return `$${val}m`;
        },
        style: {
          colors: "#CF4AFE",
          fontSize: '12px',
          fontWeight: 600,
          cssClass: 'apexcharts-yaxis-label',
        },
      },
      min: 0,
      max: 700,
      tickAmount: 7,
    },
    legend: {
      symbolRadius: 0,
    },
  };
  return (
    <>
      <div className="card" style={{ backgroundColor: "#2B3041" }}>
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row row-gap-2 row-gap-md-0">
                <div className="col-md-7">
                  <div className="d-flex align-items-center gap-1">
                    <div className="header-title ps-0 ps-md-3">EMERGING MARKET ALERT!</div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="ps-0 ps-md-3">
                    <SearchSelect
                      options={[
                        {
                          id: 1,
                          title: "units",
                        },
                        {
                          id: 2,
                          title: "dollars",
                        },
                      ]}
                      isClearable
                      getOptionValue={(option: any) => option.id}
                      getOptionLabel={(option: any) => `${option.title}`}
                      placeholder="Select Units"
                    />
                  </div>
                </div>
              </div>
              <div id="line-chart" dir="ltr">
                <ReactApexChart
                  series={series}
                  options={options}
                  type="line"
                  height={320}
                  className="apex-charts"
                />
              </div>
            </div>
            <div className="col-12 col-md-4 text-break">
              <div className="">
                <div className="header-title mb-1">ROUND ROCK</div>
                <p className="mb-1">Last Year</p>
                <p className="number-stats mb-0 ">$2,996,775,456</p>
                <div className="d-flex align-items-center gap-2 ">
                  <div>
                    <span className="badge badge-soft-danger font-size-12 me-1">
                      256.0%
                    </span>
                  </div>
                  <span>from previous year</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmergingMarketAlert;
