import React from 'react';
import Decimal from 'decimal.js';
import { debounce } from 'lodash';
import ReactECharts from 'echarts-for-react';
import { EChartOption } from 'echarts';
import { Card, CardBody } from 'reactstrap';
import { createUseStyles } from 'react-jss';
import {
  EmergingMarket, EmergingMarketDetailData,
} from '../../../../state/api';
import UnitsOrDollarsSelector from '../../../common/UnitsOrDollarsSelector/UnitsOrDollarsSelector';
import { useAppSelector } from '../../../../state/hooks';
import { selectTrackingUserId } from '../../../../state/appSlice';
import tracking, { getTrackingData, TrackingActions } from '../../../../state/tracking';
import { createShortNumber } from '../../../common/formatting';

export interface EmergingMarketShareProps {
  emergingMarket: EmergingMarket
  emergingMarketData: EmergingMarketDetailData
}

const styles = createUseStyles({
  EmergingMarketShare: {
    'min-height': '100%',
  },
});

const EmergingMarketShare = ({ emergingMarket, emergingMarketData }: EmergingMarketShareProps) => {
  const classes = styles();
  const [viewInUnits, setViewInUnits] = React.useState(false);
  const userId = useAppSelector(selectTrackingUserId);

  const trackHover = debounce(
    () => tracking.track(
      TrackingActions.MARKET_SHARE_HOVERED.valueOf(),
      getTrackingData(userId!),
    ),
    1000,
  );

  const handleSetViewInUnits = (view: boolean) => {
    setViewInUnits(view);
    tracking.track(
      TrackingActions.MARKET_SHARE_UNITS_CHANGED.valueOf(),
      getTrackingData(userId!, { units: view ? 'units' : 'dollars' }),
    );
  };

  let marketShare;
  if (viewInUnits) {
    const ownedAmount = emergingMarketData.by_groups!
      .filter((it) => it.group_name != null)
      .map((it) => it.sales_units_active!)
      .reduce((a, b) => a + b, 0);
    marketShare = ((ownedAmount / emergingMarketData.sales_units_active!) * 100)
      .toFixed(1);
  } else {
    const ownedAmount = emergingMarketData.by_groups!
      .filter((it) => it.group_name != null)
      .map((it) => new Decimal(it.sales_dollars_active!))
      .reduce((a, b) => a.add(b), new Decimal(0));
    marketShare = ownedAmount
      .div(new Decimal(emergingMarketData.sales_dollars_active!))
      .mul(100)
      .toFixed(1);
  }
  if (marketShare === 'NaN') {
    marketShare = 0;
  }

  const pieData = emergingMarketData.by_groups!.map((it) => ({
    name: it.group_name != null ? it.group_name! : it.mls_office_name!,
    value: viewInUnits ? it.sales_units_active! : parseFloat(it.sales_dollars_active!),
  }));
  const brokerageSales = pieData.map((it) => it.value!).reduce((a, b) => a + b, 0);
  pieData.push({
    name: 'Other Competitors',
    value: viewInUnits ? emergingMarketData.sales_units_active! - brokerageSales
      : parseFloat(emergingMarketData.sales_dollars_active!) - brokerageSales,
  });

  const graphOptions: EChartOption = {
    legend: {
      top: 'middle',
      left: 'left',
      orient: 'vertical',
      textStyle: {
        color: '#fff',
      },
    },
    series: [
      {
        name: viewInUnits ? 'Units' : 'Sales ($)',
        type: 'pie',
        radius: ['40%', '70%'],
        left: '40%',
        avoidLabelOverlap: false,
        startAngle: 180,
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          alignTo: 'edge',
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: pieData,
      },
    ],
    tooltip: {
      formatter: (tooltip: any) => {
        trackHover();
        const amount = `${tooltip.data.name}: ${!viewInUnits ? '$' : ''}${createShortNumber(tooltip.data.value)} ${viewInUnits ? 'units' : ''}`;
        const percentage = `${((tooltip.data.value / (viewInUnits ? emergingMarketData.sales_units_active! : parseFloat(emergingMarketData.sales_dollars_active!))) * 100).toFixed(1)}%`;
        return `${amount} (${percentage})`;
      },
      trigger: 'item',
    },
  };

  return (
    <>
      <Card className={classes.EmergingMarketShare}>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="h4 card-title">
              <span className="text-uppercase">{emergingMarket.name}</span>
              &nbsp;Market Share (
              {viewInUnits ? 'Units' : '$'}
              ):
              &nbsp;
              {marketShare}
              %
            </div>
            <UnitsOrDollarsSelector isUnits={viewInUnits} setIsUnits={handleSetViewInUnits} />
          </div>
          <ReactECharts option={graphOptions} />
        </CardBody>
      </Card>
    </>
  );
};

export default EmergingMarketShare;
