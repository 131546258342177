import { useState } from "react";
import Select from "react-select";

const PerPageOptionSelectorV2 = (props: any) => {
  const { labelUnit } = props;
  const label = labelUnit ?? "/ page";

  const [perPageOptions, setPerPageOptions] = useState([
    { label: `5 ${label}`, value: 5 },
    { label: `10 ${label}`, value: 10 },
    { label: `20 ${label}`, value: 20 },
    { label: `30 ${label}`, value: 30 },
    { label: `40 ${label}`, value: 40 },
    { label: `50 ${label}`, value: 50 },
  ] as any);
  const [selectedPerpage, setSelectedPerpage] = useState(perPageOptions.find((option: any) => option.value === props?.defaultPerPageValue) || perPageOptions[1] as any);
  
  return (
    <Select
      styles={{
        control: (base:any, state:any) => ({
          ...base,
          fontSize: 14,
          cursor: "pointer",
          // border: state.isFocused && "1px solid #ffffff !important",
          borderColor: "#ffffff",
          boxShadow: state.isFocused && "0 0 0 1px #ffffff !important",
          background: "#2A3042 !important",
          width: props?.width ?? "",
          padding: props?.insidePadding ?? "",
          "&:hover": {
            // Overwrittes the different states of border
            borderColor: "#ffffff",
          },
        }),
        input: (base) => ({
          ...base,
          color: "white",
          fontSize: 14,
          "input:focus": {
            boxShadow: "none",
          },
        }),
        placeholder: (base) => ({
          ...base,
          fontSize: 14,
          color: "#ffffff",
        }),
        option: (styles, state) => ({
          ...styles,
          cursor: "pointer",
          // color: "#BFC8E2",
          background: state.isSelected ? "#1e2330" : "#2A3042",
          color: "#ffffff",
          width: props?.optionWidth ?? "",
          backgroundColor: "#2A3042", // Set background color for options
          "&:hover": {
            backgroundColor: "#31394E", // Set background color for options on hover
          },
        }),
        menu: (base) => ({
          ...base,
          color: "#ffffff",
          backgroundColor: "#2A3042", // Set background color for the entire menu
        }),
        singleValue: (base) => ({
          ...base,
          color: "#ffffff", // Set text color for the selected option
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: "none",
        }),
      }}
      options={perPageOptions}
      value={
        JSON.stringify(selectedPerpage) !== "{}"
          ? selectedPerpage
          : "Select Per Page"
      }
      onChange={(option: any) => {
        setSelectedPerpage(option);
        props?.perPageEmitSet(option?.value);
      }}
      getOptionValue={(option: any) => option.value}
      getOptionLabel={(option: any) => `${option.label}`}
      placeholder="Select Per Page"
    />
  );
};

export default PerPageOptionSelectorV2;
