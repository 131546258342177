import React, { ReactNode } from 'react';
import { VersionedApp } from '@components/VersionedApp';

interface PublicLayoutProps {
  children: ReactNode,
}

const PublicLayout: React.FC<PublicLayoutProps> = ({ children }: PublicLayoutProps) => (
  <VersionedApp>{children}</VersionedApp>
);

export default PublicLayout;
