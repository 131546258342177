import CirclesWithBarLoader from "@utils/loaders/CirclesWithBarLoader";
import { useEffect, useState } from "react";
import BgFirstImg from "../../assets/images/bg-first.png";
import BgSecondImg from "../../assets/images/bg-second.jpg";
import BgThirdImg from "../../assets/images/bg-third.png";
import DefaultBannerImg from "../../assets/images/default-banner.png";
import DefaultImg from "../../assets/images/default-bg.png";
import RecommendationGlobe from "../../assets/images/recommendation-globe-2.png";

function RecomTopBanner({ isDataLoading, currentUserData }: any) {
  const [bannerContainer, setBannerContainer] = useState({
    img: DefaultImg,
    styles: "",
  } as any);
  function isCurrentUserInTheRank(userData: any) {
    return [1, 2, 3, 4].includes(userData?.position);
  }

  useEffect(() => {
    const currentUserPosition = parseInt(currentUserData?.position, 10);

    switch (currentUserPosition) {
      case 1:
        setBannerContainer({
          img: BgFirstImg,
          styles: "d-grid align-items-center",
        });
        break;
      case 2:
        setBannerContainer({
          img: BgSecondImg,
          styles: "d-grid justify-content-center align-items-center",
        });
        break;
      case 3:
        setBannerContainer({
          img: BgThirdImg,
          styles: "d-grid justify-content-center align-items-center",
        });
        break;        
      default:
        setBannerContainer({
          img: DefaultImg,
          styles: "",
        }); // Default background color
    }
  }, [currentUserData]); // Only runs when currentUserData changes

  function getTopBanner() {
    const currentUserPosition = parseInt(currentUserData?.position, 10);

    switch (currentUserPosition) {
      case 1:
        return <FirstBanner/>;
      case 2:
        return <SecondBanner/>;
      case 3:
        return <ThirdBanner/>;
      case 4:
        return <FourthBanner/>;
      default:
        return <DefaultBanner />;
    }
  }

  const FirstBanner = () => (
    <div className="d-flex gap-2 align-items-center justify-content-between rounded-4 p-3" style={{ backgroundColor: "rgba(48, 54, 71, 0.67)", width: "68%" }}>
      <div className="flex-grow-1">
        <h3 className="text-white fw-bolder pb-2" style={{ fontSize: "18px" }}>
          Congratulations!
        </h3>
        <h3 className="text-white fw-medium pb-2" style={{ fontSize: "14px" }}>
          You are the winner and took an honorable{" "}
          <span style={{ color: "rgba(52, 252, 29, 1)" }}>First Place</span>.
        </h3>
      </div>
    </div>
  );
  const SecondBanner = () => (
    <div className="d-flex gap-2 align-items-center justify-content-between rounded-4 p-3" style={{ backgroundColor: "rgba(48, 54, 71, 0.33)" }}>
      <div className="flex-grow-1">
        <h3 className="text-white fw-bolder pb-2" style={{ fontSize: "18px" }}>
          Congratulations!
        </h3>
        <h3 className="text-white fw-medium pb-2" style={{ fontSize: "14px" }}>
          You are the winner and took an honorable{" "}
          <span style={{ color: "rgba(52, 252, 29, 1)" }}>Second Place</span>.
        </h3>
      </div>
    </div>
  );

  const ThirdBanner = () => (
    <div className="d-flex gap-2 align-items-center justify-content-between rounded-4 p-3" style={{ backgroundColor: "rgba(48, 54, 71, 0.33)" }}>
      <div className="flex-grow-1">
        <h3 className="text-white fw-bolder pb-2" style={{ fontSize: "18px" }}>
          Congratulations!
        </h3>
        <h3 className="text-white fw-medium pb-2" style={{ fontSize: "14px" }}>
          You are the winner and took an honorable
          <span style={{ color: "rgba(52, 252, 29, 1)" }}>Third Place</span>.
        </h3>
      </div>
    </div>
  );

  const FourthBanner = () => (
    <div className="d-flex gap-2 align-items-center justify-content-between">
      <div className="flex-grow-1">
        <h3 className="text-white fw-bolder pb-2" style={{ fontSize: "18px" }}>
          Congratulations!
        </h3>
        <h3 className="text-white fw-medium pb-2" style={{ fontSize: "14px" }}>
          You achieve this month position #4 in general rating.
        </h3>
        <h3 className="text-white fw-medium pb-2" style={{ fontSize: "14px" }}>
          You are doing better than 98% of managers!
        </h3>
      </div>
      <img
        src={RecommendationGlobe}
        alt="globe"
        // className="w-100 flex-grow-1"
        style={{ objectPosition: "center", objectFit: "contain" }}
      />
    </div>
  );

  const DefaultBanner = () => (
    <div className="d-flex gap-2 align-items-center justify-content-between">
      <div className="flex-grow-1">
        <h3 className="text-white fw-bolder pb-2" style={{ fontSize: "18px" }}>
          Attention!
        </h3>
        <h3 className="text-white fw-medium pb-2" style={{ fontSize: "14px" }}>
          Please be aware, that the more people you connect with via e-mail, the higher place in Rating List you will take.
        </h3>
      </div>
      <img
        src={DefaultBannerImg}
        alt="default banner"
        // className="w-100 flex-grow-1"
        style={{ objectPosition: "center", objectFit: "contain" }}
      />
    </div>
  );
  const Loader = () => (
    <div
      className="position-absolute d-flex align-items-center justify-content-center"
      style={{ top: "50%", left: "50%", right: "50%", bottom: "50%" }}
    >
      <CirclesWithBarLoader />
    </div>
  );
  return (
    <div
      className={`position-relative rounded-4 p-3 mb-3 ${bannerContainer?.styles}`}
      style={{
        backgroundImage: `url(${bannerContainer?.img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: "147px",
      }}
    >
      {isDataLoading ? <Loader /> : getTopBanner()}
    </div>
  );

  // isCurrentUserInTheRank(currentUserData) ?
  // <div>ranked</div> :
  // <div>not ranked</div>
  // <div className="row gap-1">
  //   <div className="col d-flex flex-column justify-content-center">
  //     <div className="h-100">
  //       <h3
  //         className="text-white fw-bolder pb-2"
  //         style={{ fontSize: "18px" }}
  //       >
  //         Congratulations!
  //       </h3>
  //       <h3
  //         className="text-white fw-medium pb-2"
  //         style={{ fontSize: "14px" }}
  //       >
  //         You achieve this month position #4 in general rating.
  //       </h3>
  //       <h3
  //         className="text-white fw-medium pb-2"
  //         style={{ fontSize: "14px" }}
  //       >
  //         You are doing better than 98% of managers!
  //       </h3>
  //     </div>
  //   </div>
  //   <div className="col">
  //     <div className="h-100">
  //       <img
  //         src={DefaultBanner}
  //         alt="globe"
  //         className="w-100 flex-grow-1"
  //         style={{ objectPosition: "center", objectFit: "contain" }}
  //       />
  //     </div>
  //   </div>
  // </div>
}

export default RecomTopBanner;
