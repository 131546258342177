import { CirclesWithBar } from "react-loader-spinner";

function CirclesWithBarLoader({ isDataLoading, wrapperClasses = "justify-content-center", color = "#FFF5DE" }: any) {
  return (
    <CirclesWithBar
      visible={isDataLoading}
      height="60"
      width="60"
      color="#FFF5DE"
      outerCircleColor="#FFF5DE"
      innerCircleColor="#FFF5DE"
      barColor="#FFF5DE"
      ariaLabel="circles-with-bar-loading"
      wrapperStyle={{}}
      wrapperClass={wrapperClasses}
    />
  );
}

export default CirclesWithBarLoader;
