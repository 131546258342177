import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import error from '../../../assets/images/error-img.png';

const Error500 = () => (
  <>
    <div className="account-pages my-5 pt-5">
      <MetaTags>
        <title>Server Error - Kintaro</title>
      </MetaTags>
      <Container>
        <Row>
          <Col lg="12">
            <div className="text-center mb-5">
              <h1 className="display-2 fw-medium">
                500
              </h1>
              <h4 className="text-uppercase">Internal Server Error</h4>
              <div className="mt-5 text-center">
                <div
                  className="btn btn-primary"
                  onClick={() => window.location.reload()}
                  onKeyDown={() => window.location.reload()}
                  role="button"
                  tabIndex={0}
                >
                  Reload Page
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8" xl="6">
            <div>
              <img src={error} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

export default Error500;
