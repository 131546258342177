import CustomCircleSpinner from "@components/common/CustomCircleSpinner";
import { RA_DRAFT_EMAIL } from "@constants/variables";
import { faker } from "@faker-js/faker";
import { isDemo } from "@utils/fakers-data";
import { http } from "@utils/http";
import ThreeDotLoader from "@utils/loaders/ThreeDotLoader";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Modal, ModalBody } from "reactstrap";

function GlobalDraftEmailModal(props: any) {
  // All state.
  const [openModal, setOpenModal] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState({
    isDataFetching: false,
    isRActionDataLoading: false,
  });
  const [modalName] = useState("draftEmailModal");
  const [mailTemplateData, setMailTemplateData] = useState({} as any);
  const { recommendationData } = props;

  useEffect(() => {
    if (props.modalName === modalName) {
      setOpenModal(props.modalStatus);
    }
  }, [setOpenModal, props.modalStatus]);

  useEffect(() => {
    if (recommendationData?.slug) {
      setIsDataLoading((prev: any) => ({
        ...prev,
        isDataFetching: true,
      }));
      http
        .get("recommendation/fetch-recommendations-mail-templates.json")
        .then((response: any) => {
          const { data } = response;
          setMailTemplateData(data?.mail_templates);
          setIsDataLoading((prev: any) => ({
            ...prev,
            isDataFetching: false,
          }));
        })
        .catch((error: any) => {
          setIsDataLoading((prev: any) => ({
            ...prev,
            isDataFetching: false,
          }));
          console.log(error);
        });
    }
  }, [recommendationData]);

  function modalClose() {
    setOpenModal(false);
    setTimeout(() => {
      props.modalClose({
        modalName,
      });
    }, 500);
  }
  function getEmailData() {
    const email = mailTemplateData?.the_variable_data?.to_emails ?? "test@gmail.com";
    const subject = mailTemplateData?.subject ?? "test subject";
    const body = mailTemplateData?.mail_body ?? "test body";

    if (isDemo()) {
      const fakerFName = faker.person.firstName();
      return {
        email: faker.internet.email({ firstName: fakerFName, lastName: "" }),
        subject: faker.lorem.sentence({ min: 5, max: 7 }),
        body: `Hi ${fakerFName}, \n\n${faker.lorem.paragraphs({ min: 1, max: 2 })}`,
      };
    }

    return { email, subject, body };
  }

  function generateMailtoLink(email:any, subject:any, body:any) {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return `mailto:${email}${params}`;
  }

  function openMailBox() {
    const { email, subject, body } = getEmailData();

    const mailtoLink = generateMailtoLink(email, subject, body);
    window.location.href = mailtoLink;
    
    if (isDemo()) {
      setIsDataLoading((prev: any) => ({
        ...prev,
        isRActionDataLoading: true,
      }));

      setTimeout(() => {
        setIsDataLoading((prev: any) => ({
          ...prev,
          isRActionDataLoading: false,
        }));
        modalClose();
      }, 300);
      return;
    }
    submitRecommendAction();
  }

  function submitRecommendAction() {
    const submitData = {
      recommendation_id: recommendationData?.id,
      action_type: RA_DRAFT_EMAIL,
    };
    setIsDataLoading((prev: any) => ({
      ...prev,
      isRActionDataLoading: true,
    }));
    http
      .post("/recommendation/recommendation-action", submitData)
      .then((response: any) => {
        // toast.success(response?.data?.message, { duration: 3000 });
        setIsDataLoading((prev: any) => ({
          ...prev,
          isRActionDataLoading: false,
        }));
        // props.refetchData();
        modalClose();
      })
      .catch((error: any) => {
        console.log(error);
        toast.error(error?.message, { duration: 3000 });
        setIsDataLoading((prev: any) => ({
          ...prev,
          isRActionDataLoading: false,
        }));
      });
  }
  return (
    <Modal size="md" isOpen={openModal}>
      <div className="modal-content">
        <div className="container">
          <p className="custom-modal-title mt-2"></p>
          <button
            type="button"
            onClick={modalClose}
            className="btn-close position-absolute custom-right-2 custom-top-1 mb-4"
          ></button>
        </div>
        <ModalBody className="">
          <div className="row mb-3">
            <div className="header-title-2 text-center">Draft Email ?</div>
            <ThreeDotLoader
              wrapperClasses="justify-content-center mb-2"
              isDataLoading={isDataLoading?.isDataFetching}
            />

            {!isDataLoading?.isDataFetching ? (
              <div>
                <p className="text-center mt-3">
                  Are you sure that you want to draft an email for all this
                  notification? This will not mark the notification as ignored
                  or completed.
                </p>

                <div className="d-flex align-items-center justify-content-center gap-2">
                  <button
                    type="button"
                    className="btn btn-outline-secondary bg-secondary text-black btn-sm fw-bold"
                    onClick={modalClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    disabled={
                      isDataLoading?.isDataFetching ||
                      isDataLoading?.isRActionDataLoading
                    }
                    className="btn btn-primary btn-sm fw-bold"
                    style={{ minWidth: "70px" }}
                    onClick={() => openMailBox()}
                  >
                    <CustomCircleSpinner
                      loading={isDataLoading?.isRActionDataLoading}
                    />
                    {!isDataLoading?.isRActionDataLoading && "Draft Email"}
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default GlobalDraftEmailModal;
