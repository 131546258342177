import Pagination from "@components/common/Pagination/Pagination";
import PerPageOptionSelector from "@components/common/PerPageOptionSelector";
import SearchSelect from "@components/common/SearchSelect";
import TablePlaceHolder from "@components/common/TablePlaceHolder";
import TableSortIcon from "@components/common/TableSortIcon";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { faker } from "@faker-js/faker";
import { http } from "@utils/http";
import { colSortingV2 } from "@utils/table-sort";
import { useEffect, useState } from "react";

function OfficeEngagementTable() {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const data1: any = [
    {
      id: 1,
      name: "New Canaan",
      email_engagement_score: "1%",
      cma: "2%",
      website: "10%",
      open_house: "5%",
    },
    {
      id: 2,
      name: "Harbor Point",
      email_engagement_score: "2%",
      cma: "6%",
      website: "10%",
      open_house: "4%",
    },
    {
      id: 3,
      name: "Ridgefield",
      email_engagement_score: "3%",
      cma: "3%",
      website: "10%",
      open_house: "6%",
    },
    {
      id: 4,
      name: "Westport",
      email_engagement_score: "4%",
      cma: "8%",
      website: "10%",
      open_house: "7%",
    },
    {
      id: 5,
      name: "Essex",
      email_engagement_score: "5%",
      cma: "10%",
      website: "10%",
      open_house: "8%",
    },
    {
      id: 6,
      name: "Olivia",
      email_engagement_score: "6%",
      cma: "8%",
      website: "10%",
      open_house: "9%",
    },
    {
      id: 7,
      name: "James",
      email_engagement_score: "7%",
      cma: "5%",
      website: "10%",
      open_house: "5%",
    },
    {
      id: 8,
      name: "Sophia",
      email_engagement_score: "8%",
      cma: "6%",
      website: "10%",
      open_house: "20%",
    },
    {
      id: 9,
      name: "David",
      email_engagement_score: "9%",
      cma: "7%",
      website: "10%",
      open_house: "15%",
    },
    {
      id: 10,
      name: "Emma",
      email_engagement_score: "10%",
      cma: "9%",
      website: "10%",
      open_house: "14%",
    },
  ];
  const columns: any = [
    // {
    //   name: "#",
    //   slug: "",
    //   sort: false,
    // },
    {
      name: "Office",
      slug: "office",
      sort: true,
      subHeaderClassNames: "",
    },
    {
      name: "Email Engagement Score",
      slug: "email_engagement_score",
      sort: true,
      subHeaderClassNames: "justify-content-center",
    },
    {
      name: "CMA",
      slug: "cma",
      sort: true,
      subHeaderClassNames: "justify-content-center",
    },
    {
      name: "Website",
      slug: "website",
      sort: true,
      subHeaderClassNames: "justify-content-center",
    },
    // {
    //   name: "Open House",
    //   slug: "open_house",
    //   sort: true,
    // },
  ];
  // const columns: any = [
  //   // {
  //   //   name: "#",
  //   //   slug: "",
  //   //   sort: false,
  //   // },
  //   {
  //     name: "Office",
  //     slug: "short_name",
  //     sort: false,
  //     subHeaderClassNames: "",
  //   },
  //   {
  //     name: "Tool Type",
  //     slug: "tool_type",
  //     subHeaderClassNames: "justify-content-center",
  //     sort: false,
  //   },
  //   {
  //     name: "Engagement Score",
  //     slug: "engagement_score",
  //     subHeaderClassNames: "justify-content-center",
  //     sort: false,
  //   },
  // ];
  const [officeEngagement, setOfficeEngagement] = useState([] as any);
  const [officeEngagementApiData, setOfficeEngagementApiData] = useState(
    [] as any,
  );
  const [sortColumns, setSortColumns] = useState([] as any);
  const [sortAPIColumns, setSortAPIColumns] = useState("" as any);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0 as any);
  const [perPage, setPerPage] = useState(10);
  const [officeOptions, setOfficeOptions] = useState([] as any);
  const [selectedOfficeOption, setSelectedOfficeOption] = useState({} as any);
  const [isOfficeInfoDataLoading, setIsOfficeInfoDataLoading] = useState(true);

  useEffect(() => {
    getOfficeEngagementData();
  }, [currentPage, perPage, sortAPIColumns, selectedOfficeOption]);

  useEffect(() => {
    getAllOfficeData();
  }, []);

  function getAllOfficeData() {
    setIsOfficeInfoDataLoading(true);
    http
      .get("/office/office-info.json")
      .then((response: any) => {
        const { data } = response;
        let theData = data.office_info;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            office_name: faker.company.name(),
          }));
        }
        
        setOfficeOptions(theData);
        setIsOfficeInfoDataLoading(false);
      })
      .catch((error) => {
        setIsOfficeInfoDataLoading(false);
        console.log(error);
      });
  }

  function getOfficeEngagementData() {
    setIsDataLoading(true);
    http
      .get("/tools/landing-page-engagement-score.json")
      .then((response: any) => {
        const { data } = response;
        let theData = data.engagement_score;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            office: faker.company.name(),
          }));
        }
        setOfficeEngagement(theData);
        setOfficeEngagementApiData(theData);
        setTotalPage(data?.last_page);
        setIsDataLoading(false);
      })
      .catch((error) => {
        setIsDataLoading(false);
        console.log(error);
      });
  }

  // Define a function to handle column sorting
  const handleSort = (column: any) => {
    const sortingOutput: any = colSortingV2(
      officeEngagementApiData,
      sortColumns,
      column,
    );
    setSortColumns(sortingOutput.columns);
    setSortAPIColumns(sortingOutput?.apiColumn);
    // setOffices(sortingOutput.data);
  };

  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  return (
    <div className="mb-4">
      <div
        className="custom-modal-inner__padding"
        style={{ backgroundColor: "#2B3041" }}
      >
        <div className="row row-gap-2 row-gap-md-0 mb-3 ">
          <div className="col-6 col-md-7">
            <div className="d-flex flex-wrap align-items-center gap-1">
              <div className="header-title">Office Engagement</div>
            </div>
          </div>
          <div className="col-6 col-md-5 d-flex justify-content-end">
            <SearchSelect
              options={officeOptions}
              width="200px"
              value={
                JSON.stringify(selectedOfficeOption) !== "{}"
                  ? selectedOfficeOption
                  : "Select Office"
              }
              onChange={(option: any) => {
                setSelectedOfficeOption(option);
              }}
              isLoading={isOfficeInfoDataLoading}
              isClearable
              getOptionValue={(option: any) => option.office_id}
              getOptionLabel={(option: any) => option.office_name}
              placeholder="Select Office"
            />
          </div>
        </div>
        <div className="table-responsive scrollable-body-2">
          <table className="align-middle table-nowrap table-check table table-striped table-sm fixed-header fixed-column">
            <thead className="table-light">
              <tr role="row">
                {columns.map((column: any) => (
                  <th
                    key={column.slug}
                    onClick={() => {
                      if (column.sort) {
                        handleSort(column.slug);
                      }
                    }}
                    role="button"
                    className={`${column?.headerClassNames}`}
                  >
                    <div
                      className={`d-flex align-items-center ${column?.subHeaderClassNames}`}
                    >
                      <TableSortIcon
                        sortColumns={sortColumns}
                        column={column}
                      ></TableSortIcon>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <TablePlaceHolder
                items={officeEngagement}
                loading={isDataLoading}
                rows={13}
                cols={9}
              />
              {!isDataLoading &&
                officeEngagement?.map((office: any, id: any) => (
                  <tr key={office.id}>
                    <td className="ps-2">
                      {office.office}
                    </td>
                    <td className="ps-2 text-center first-column">
                      {office.email_engagement_score}
                    </td>
                    <td className="text-center">{office?.cma}</td>
                    <td className="text-center">{office?.website}</td>
                    {/* <td className="text-center">{office?.open_house}</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-3 d-flex gap-2 align-items-center">
            <p className="mb-0 custom-fs-12-to-14">Rows Per Page</p>
            <PerPageOptionSelector
              perPageEmitSet={(value: any) => setPerPage(value)}
              labelUnit=" "
              // placeholderText="select office"
            />
          </div>
          <div className="col-12 col-md-9 d-flex justify-content-center justify-content-md-start align-items-center">
            <Pagination
              pageCount={totalPage}
              perPage={perPage}
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfficeEngagementTable;
