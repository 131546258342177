import { Decimal } from 'decimal.js';

export const createShortNumber = (value: number | string) => {
  const number = new Decimal(value);
  if (number.gte(1000000000)) {
    return `${number.div(1000000000).toFixed(1).replace(/\.0$/, '')}b`;
  }
  if (number.gte(1000000)) {
    return `${number.div(1000000).toFixed(1).replace(/\.0$/, '')}m`;
  }
  if (number.gte(1000)) {
    return `${number.div(1000).toFixed(1).replace(/\.0$/, '')}k`;
  }
  return number.toFixed(1).replace(/\.0$/, '');
};

export const formatNumberString = (value: string) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatCurrencyString = (value: string) => `$${formatNumberString(value)}`;

export const formatDecimalNumber = (value: string) => `${formatNumberString(parseFloat(value).toFixed(1).toString()).replace(/(\.0+|0+)$/, '')}`;
