/* eslint-disable no-plusplus */
// import HelpIcon from "@components/common/HelpIcon";
// import SearchSelect from "@components/common/SearchSelect";
// import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
// import { officeModalTooltip } from "@constants/tooltip-data";
// import generateLineChartOptions from "@utils/chart/line-chart";
// import { http } from "@utils/http";
// import { generateTicks } from "@utils/transform/financial";
// import {
//   generateListingFinancialDataByYear,
//   generateSellSideFinancialDataByYear,
// } from "@utils/transform/officePage";
import { useEffect, useState } from "react";
// import ReactApexChart from "react-apexcharts";
import PermissionCheck from "@components/common/PermissionCheck";
import { officePerms } from "@constants/permission";
import { actionUserPermissions, selectUser } from "@state/appSlice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { http } from "@utils/http";
import OfficeAvgListVsSoldPriceChart from "./OfficeAvgListVsSoldPriceChart";
import OfficeGciVsCompanyChart from "./OfficeGciVsCompanyChart";
import OfficeListVsSellSideChart from "./OfficeListVsSellSideChart";
import OfficeTotalVsSoldListingsChart from "./OfficeTotalVsSoldListingsChart";

function ComparativeFinancial({ officeId }: any) {
  const dispatch = useAppDispatch();
  const selectedUser: any = useAppSelector(selectUser);
  const [isPermissionDataLoading, setIsPermissionDataLoading] = useState(true);

  const [monthOptions, setMonthOptions] = useState([
    {
      id: 1,
      title: "Last 3 Months",
      value: 3,
    },
    {
      id: 2,
      title: "Last 6 Months",
      value: 6,
    },
    {
      id: 3,
      title: "Last 12 Months",
      value: 12,
    },
  ] as any);
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[2] as any);
  const [sellSideFinancialComparisonData, setSellSideFinancialComparisonData] =
    useState({} as any);
  const [listSideFinancialComparisonData, setListSideFinancialComparisonData] =
    useState({} as any);
  const [theGciOptions, setTheGciOptions] = useState({} as any);

  // sell side financial api state
  const [theListSidesOption, setTheListSidesOptions] = useState({} as any);
  const [theSellSidesOptions, setTheSellSidesOptions] = useState({} as any);
  const [theCompanyDollarOptions, setTheCompanyDollarOptions] = useState(
    {} as any,
  );

  // listing financial api state
  const [theTotalListingOptions, setTheTotalListingOptions] = useState(
    {} as any,
  );
  const [theSoldListingOptions, setTheSoldListingOptions] = useState({} as any);
  const [theAverageListingOptions, setTheAverageListingOptions] = useState(
    {} as any,
  );
  const [theAverageSoldOptions, setTheAverageSoldOptions] = useState({} as any);

  // data loading state
  const [isSellSideDataLoading, setIsSellSideDataLoading] = useState(false);
  const [isListSideDataLoading, setIsListSideDataLoading] = useState(false);

  useEffect(() => {
    if (selectedUser?.id) {
      setIsPermissionDataLoading(true);
      // TODO: here call user permission api
      http
        .get(
          `user-permission-site-data.json`,
        )
        .then((response: any) => {
          const { permission } = response?.data;
          const getUserPerms = response?.data?.permission[0]?.permissions;
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: getUserPerms ?? [] }));
          setIsPermissionDataLoading(false);
        })
        .catch((error) => {
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: [] }));
          setIsPermissionDataLoading(false);
          console.log(error);
        });
    }
  }, [selectedUser, officeId]);

  // useEffect(() => {
  //   if (officeId !== "") {
  //     getSellSideFinancialComparisonReport();
  //     getListSideFinancialComparisonData();
  //   }
  // }, [officeId, selectedMonth]);

  // function getSellSideFinancialComparisonReport() {
  //   setIsSellSideDataLoading(true);

  //   http
  //     .get("/office/sell-side-financial-comparison-last-12", {
  //       params: {
  //         agent_id: officeId,
  //         per_page: 500,
  //         months: selectedMonth?.value,
  //       },
  //     })
  //     .then((response: any) => {
  //       const theData: any = generateSellSideFinancialDataByYear(
  //         2023,
  //         response.data.last_12_sell_sides,
  //       );
  //       setSellSideFinancialComparisonData(theData);

  //       const gciChartOptions: any = generateLineChartOptions({
  //         theId: "line-chart-gci",
  //         colors: ["#CF4AFE", "#EFC2FF"],
  //         categories: theData?.categories,
  //         ticks: generateTicks(theData?.last_12_gci ?? []),
  //         ticksPreLast: ["$", " k"],
  //       });
  //       setTheGciOptions(gciChartOptions);

  //       const listSidesChartOptions: any = generateLineChartOptions({
  //         theId: "line-chart-buy-sides",
  //         colors: ["#E98B48", "#FFBE90"],
  //         categories: theData?.categories,
  //         ticks: generateTicks(theData?.last_12_list_sides ?? []),
  //         ticksPreLast: [],
  //         gridPL: 10,
  //       });

  //       setTheListSidesOptions(listSidesChartOptions);

  //       const sellSidesChartOptions: any = generateLineChartOptions({
  //         theId: "line-chart-sell-sides",
  //         colors: ["#6BF2F2", "#B6FFFF"],
  //         categories: theData?.categories,
  //         ticks: generateTicks(theData?.last_12_sell_sides ?? []),
  //         ticksPreLast: [],
  //         gridPL: 10,
  //       });
  //       setTheSellSidesOptions(sellSidesChartOptions);

  //       const comapnyDollarChartOptions: any = generateLineChartOptions({
  //         theId: "line-chart-comapany-dollar",
  //         colors: ["#F2CA6B", "#FFF5DE"],
  //         categories: theData?.categories,
  //         ticks: generateTicks(theData?.last_12_company_dollar ?? []),
  //         ticksPreLast: ["$", " k"],
  //       });
  //       setTheCompanyDollarOptions(comapnyDollarChartOptions);

  //       const averageSoldChartOptions: any = generateLineChartOptions({
  //         theId: "line-chart-average-sold-price",
  //         colors: ["#6BF2F2", "#B6FFFF"],
  //         categories: theData?.categories,
  //         ticks: generateTicks(theData?.l12_avg_closed_price ?? []),
  //         ticksPreLast: ["$", " k"],
  //       });
  //       setTheAverageSoldOptions(averageSoldChartOptions);

  //       setIsSellSideDataLoading(false);
  //     })
  //     .catch((err: any) => {
  //       setIsSellSideDataLoading(false);
  //       console.log(err);
  //     });
  // }

  // function getListSideFinancialComparisonData() {
  //   setIsListSideDataLoading(true);
  //   http
  //     .get("/office/listings-financial-comparison-last-12", {
  //       params: {
  //         agent_id: officeId,
  //         per_page: 1000,
  //         months: selectedMonth?.value,
  //       },
  //     })
  //     .then((response: any) => {
  //       const theData: any = generateListingFinancialDataByYear(
  //         2023,
  //         response.data.last_12_listing,
  //       );
  //       setListSideFinancialComparisonData(theData);
  //       const totalListingsChartOptions: any = generateLineChartOptions({
  //         theId: "line-chart-total-listings",
  //         colors: ["#CF4AFE", "#EFC2FF"],
  //         categories: theData?.categories,
  //         ticks: generateTicks(theData?.last_12_total_listings ?? []),
  //         ticksPreLast: [],
  //         gridPL: 10,
  //       });
  //       setTheTotalListingOptions(totalListingsChartOptions);

  //       const totalSoldListingsChartOptions: any = generateLineChartOptions({
  //         theId: "line-chart-total-listings",
  //         colors: ["#F2CA6B", "#FFF5DE"],
  //         categories: theData?.categories,
  //         ticks: generateTicks(theData?.last_12_total_sold_listings ?? []),
  //         ticksPreLast: [],
  //         gridPL: 10,
  //       });
  //       setTheSoldListingOptions(totalSoldListingsChartOptions);

  //       const averageListingChartOptions: any = generateLineChartOptions({
  //         theId: "line-chart-average-listing-price",
  //         colors: ["#E98B48", "#FFBE90"],
  //         categories: theData?.categories,
  //         ticks: generateTicks(theData?.last_12_average_list_price ?? []),
  //         ticksPreLast: ["$", "k"],
  //       });
  //       setTheAverageListingOptions(averageListingChartOptions);

  //       setIsListSideDataLoading(false);
  //     })
  //     .catch((err: any) => {
  //       setIsListSideDataLoading(false);
  //       console.log(err);
  //     });
  // }

  return (
    <>
      <div className="row mb-4 custom-parent--row">
        <PermissionCheck
          permission={`${officePerms.office_modal_gci_vs_company_dollar_chart}`}
        >
          <div className="col-12 col-lg-6 mb-2 mt-1 custom-column">
            <div
              className="custom-modal-inner__padding h-100"
              style={{ backgroundColor: "#2B3041" }}
            >
              <OfficeGciVsCompanyChart officeIdProps={officeId} />
            </div>
          </div>
          
        </PermissionCheck>
        <PermissionCheck
          permission={`${officePerms.office_modal_list_sell_sides_chart}`}
        >
          <div className="col-12 col-lg-6 mb-2 mt-1 custom-column">
            <div
              className="custom-modal-inner__padding h-100"
              style={{ backgroundColor: "#2B3041" }}
            >
              <OfficeListVsSellSideChart officeIdProps={officeId} />
            </div>
          </div>
        </PermissionCheck>
        <PermissionCheck
          permission={`${officePerms.office_modal_total_listing_sold_listings_chart}`}
        >
          <div className="col-12 col-lg-6 mb-2 mt-1 custom-column">
            <div
              className="custom-modal-inner__padding h-100"
              style={{ backgroundColor: "#2B3041" }}
            >
              <OfficeTotalVsSoldListingsChart officeIdProps={officeId} />
            </div>
          </div>
        </PermissionCheck>
        <PermissionCheck
          permission={`${officePerms.office_modal_avg_list_sold_price_chart}`}
        >
          <div className="col-12 col-lg-6 mb-2 mt-1 custom-column">
            <div
              className="custom-modal-inner__padding h-100"
              style={{ backgroundColor: "#2B3041" }}
            >
              <OfficeAvgListVsSoldPriceChart officeIdProps={officeId} />
            </div>
          </div>
        </PermissionCheck>
      </div>
      {/* <div className="mb-4" style={{ backgroundColor: "#2B3041" }}>
        <div className="custom-modal-inner__padding">
          <div className="row row-gap-4">
            <div className="row mb-3">
              <div className="col-6 col-lg-7 pe-0">
                <div className="header-title">Comparative Financials</div>
              </div>
              <div className="col-6 col-lg-5 pe-0 d-inline-flex justify-content-end">
                <SearchSelect
                  options={monthOptions}
                  value={
                    JSON.stringify(selectedMonth) !== "{}"
                      ? selectedMonth
                      : "Select a Month"
                  }
                  onChange={(option: any) => setSelectedMonth(option)}
                  // isClearable
                  // width="190px"
                  getOptionValue={(option: any) => option.value}
                  getOptionLabel={(option: any) => `${option.title}`}
                  placeholder="Select Months"
                />
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title-2 mb-0 fw-medium">GCI</p>
                <HelpIcon tooltip={`${officeModalTooltip?.gci}`} />
              </div>
              {isSellSideDataLoading && <div className="loader-circle"></div>}
              {isSellSideDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-gci" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: sellSideFinancialComparisonData?.last_12_gci,
                      },
                      {
                        name: "Previous Period",
                        data: sellSideFinancialComparisonData?.previous_period_gci,
                      },
                    ]}
                    options={theGciOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>
            <div className="col-12 col-lg-6">
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title-2 mb-0 fw-medium">Company Dollar</p>
                <HelpIcon tooltip={`${officeModalTooltip?.companyDollar}`} />
              </div>
              {isSellSideDataLoading && <div className="loader-circle"></div>}
              {isSellSideDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-company-dollar" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: sellSideFinancialComparisonData?.last_12_company_dollar,
                      },
                      {
                        name: "Previous Period",
                        data: sellSideFinancialComparisonData?.previous_period_company_dollar,
                      },
                    ]}
                    options={theCompanyDollarOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>

            <div className="col-12 col-lg-6">
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title-2 mb-0 fw-medium">List sides</p>
                <HelpIcon tooltip={`${officeModalTooltip?.listSides}`} />
              </div>
              {isSellSideDataLoading && <div className="loader-circle"></div>}
              {isSellSideDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-buy-sides" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: sellSideFinancialComparisonData?.last_12_list_sides,
                      },
                      {
                        name: "Previous Period",
                        data: sellSideFinancialComparisonData?.previous_period_list_sides,
                      },
                    ]}
                    options={theListSidesOption}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>

            <div className="col-12 col-lg-6">
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title-2 mb-0 fw-medium">Sell sides</p>
                <HelpIcon tooltip={`${officeModalTooltip?.sellSides}`} />
              </div>
              {isSellSideDataLoading && <div className="loader-circle"></div>}
              {isSellSideDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-buy-sides" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: sellSideFinancialComparisonData?.last_12_sell_sides,
                      },
                      {
                        name: "Previous Period",
                        data: sellSideFinancialComparisonData?.previous_period_sell_sides,
                      },
                    ]}
                    options={theSellSidesOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>

            <div className="col-12 col-lg-6">
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title-2 mb-0 fw-medium">Total Listings</p>
                <HelpIcon tooltip={officeModalTooltip?.totalListings} />
              </div>
              {isListSideDataLoading && <div className="loader-circle"></div>}
              {isListSideDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-total-listings" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: listSideFinancialComparisonData?.last_12_total_listings,
                      },
                      {
                        name: "Previous Period",
                        data: listSideFinancialComparisonData?.previous_period_total_listings,
                      },
                    ]}
                    options={theTotalListingOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>
            <div className="col-12 col-lg-6">
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title-2 mb-0 fw-medium">Sold Listings</p>
                <HelpIcon tooltip={officeModalTooltip?.soldListings} />
              </div>
              {isListSideDataLoading && <div className="loader-circle"></div>}
              {isListSideDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-total-listings" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: listSideFinancialComparisonData?.last_12_total_sold_listings,
                      },
                      {
                        name: "Previous Period",
                        data: listSideFinancialComparisonData?.previous_period_total_sold_listings,
                      },
                    ]}
                    options={theSoldListingOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>

            <div className="col-12 col-lg-6">
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title-2 mb-0 fw-medium">
                  Average List Price
                </p>
                <HelpIcon tooltip={officeModalTooltip?.avgListPrice} />
              </div>
              {isListSideDataLoading && <div className="loader-circle"></div>}
              {isListSideDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-average-listings" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: listSideFinancialComparisonData?.last_12_average_list_price,
                      },
                      {
                        name: "Previous Period",
                        data: listSideFinancialComparisonData?.previous_period_average_list_price,
                      },
                    ]}
                    options={theAverageListingOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>

            <div className="col-12 col-lg-6">
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title-2 mb-0 fw-medium">
                  Average sold price
                </p>
                <HelpIcon tooltip={officeModalTooltip?.avgSoldPrice} />
              </div>
              {isSellSideDataLoading && <div className="loader-circle"></div>}
              {isSellSideDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-average-listings" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: sellSideFinancialComparisonData?.last_12_avg_closed_price,
                      },
                      {
                        name: "Previous Period",
                        data: sellSideFinancialComparisonData?.pp_avg_closed_price,
                      },
                    ]}
                    options={theAverageSoldOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ComparativeFinancial;
