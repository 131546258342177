export const blue = '#556ee6';
export const indigo = '#564ab1';
export const purple = '#6f42c1';
export const pink = '#e83e8c';
export const red = '#f46a6a';
export const orange = '#f1734f';
export const yellow = '#f1b44c';
export const green = '#34c38f';
export const teal = '#050505';
export const cyan = '#50a5f1';

export const white = '#fff';
export const gray100 = '#212529';
export const gray200 = '#2a3042';
export const gray300 = '#32394e';
export const gray400 = '#a6b0cf';
export const gray500 = '#bfc8e2';
export const gray600 = '#c3cbe4';
export const gray700 = '#f6f6f6';
export const gray800 = '#eff2f7';
export const gray900 = '#f8f9fa';
export const black = '#000';

export const primary = blue;
export const primary33Opacity = 'rgba(85, 110, 230, .33)';
export const success = green;
export const success33Opacity = 'rgba(52, 195, 143, .33)';
export const warning = yellow;
export const yellow33Opacity = 'rgba(241, 180, 76, .33)';
export const error = red;
export const error33Opacity = 'rgb(244, 106, 106, .33)';

export const formBackground = '#2e3446';
export const formBorder = gray300;
export const formColor = gray500;
export const formBackgroundDisabled = '#232938';
export const formColorDisabled = '#51586b';
