/* eslint-disable camelcase */
import ComperativeFinancial from "@components/agent/details/ComperativeFinancial";
import ForeCastedRevenue from "@components/agent/details/ForecastedRevenue";
import HelpIcon from "@components/common/HelpIcon";
import PermissionCheck from "@components/common/PermissionCheck";
import Placeholder from "@components/common/Placeholder";
import { agentPerms } from "@constants/permission";
import { agentToolTip } from "@constants/tooltip-data";
import { faker } from "@faker-js/faker";
import { actionUserPermissions, selectUser } from "@state/appSlice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { http } from "@utils/http";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import AgentEngagementScore from "./details/AgentEngagementScore";
import AgentProfileInfo from "./details/AgentProfileInfo";
import AgentToolTechStats from "./details/AgentToolTechStats";
import AgentYearlyTechEngagement from "./details/AgentYearlyTechEngagement";
import RecomendedAction from "./details/RecomendedAction";
import YtdAgentCard from "./details/YtdAgentCard";
import YtdListVsSellSide from "./details/YtdListVsSellSide";

function DetailAgentModal(props: any) {
  const dispatch = useAppDispatch();
  const selectedUser: any = useAppSelector(selectUser);
  const [isPermissionDataLoading, setIsPermissionDataLoading] = useState(true);
  // All state.
  const [openModal, setOpenModal] = useState(false);
  const [modalName] = useState("detailAgentModal");

  const [agentId, setAgentId] = useState("");
  const [agentOfficeId, setAgentOfficeId] = useState(null as any);
  const [agent, setAgent] = useState({} as any);
  const [agentYTDReport, setAgentYTDReport] = useState({} as any);
  const [isLoadingAgent, setIsLoadingAgent] = useState(true);
  const [isLoadingAgentYTDReport, setIsLoadingAgentYTDReport] = useState(true);
  const [isLoadingAgentYears, setIsLoadingAgentYears] = useState(true);

  const DUMMY_DATA: any = [
    {
      id: 1,
      title: "CloudCma",
      stats: "77",
      color: "#F5822F",
    },
    {
      id: 4,
      title: "Email",
      stats: "54",
      color: "#6BF2F2",
    },
    {
      id: 5,
      title: "Website",
      stats: "37",
      color: "#F2CA6B",
    },
  ];

  useEffect(() => {
    if (selectedUser?.id) {
      setIsPermissionDataLoading(true);
      // TODO: here call user permission api
      http
        .get(
          `user-permission-site-data.json`,
        )
        .then((response: any) => {
          const { permission } = response?.data;
          const getUserPerms = response?.data?.permission[0]?.permissions;
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: getUserPerms ?? [] }));
          setIsPermissionDataLoading(false);
        })
        .catch((error) => {
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: [] }));
          setIsPermissionDataLoading(false);
          console.log(error);
        });
    }
  }, [selectedUser]);

  useEffect(() => {
    if (props.modalName === modalName) {
      setOpenModal(props.modalStatus);
      setAgentId(props?.agentId);
    }
  }, [setOpenModal, props.modalStatus]);

  // Added an event listener to handle 'Escape' key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && openModal) {
        modalClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openModal]);

  useEffect(() => {
    if (agentId !== "") {
      getAgentInfo();
      getAgentYtdReport();
    }
  }, [agentId]);

  function getAgentInfo() {
    http
      .get("/agent/agents-info.json")
      .then((response: any) => {
        const { agent_info } = response?.data;
        let theData = agent_info;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          const fakeAgentFirstName = faker.person.firstName();
          const fakeAgentLastName = faker.person.lastName();
          theData = {
            ...theData,
            first_name: fakeAgentFirstName,
            last_name: fakeAgentLastName,
            short_name: faker.company.name(),
            phone_number: faker.phone.number('(###) ###-####'),
            email: faker.internet.email({ firstName: fakeAgentFirstName, lastName: "" }),
          };
        }
        setAgent(theData);
        setAgentOfficeId(agent_info?.office_bw_id);
        setIsLoadingAgent(false);
      });
  }

  function getAgentYtdReport() {
    http
      .get("/agent/agents-ytd-report.json")
      .then((response: any) => {
        setAgentYTDReport(response.data?.agents_ytd_report);
        setIsLoadingAgentYTDReport(false);
      });
  }

  function modalClose() {
    setOpenModal(false);
    setTimeout(() => {
      props.modalClose({
        modalName,
      });
    }, 500);
  }

  function getRandomColor(stats: any) {
    let color: any = "";
    switch (true) {
      case stats >= 0 && stats <= 50:
        color = "#F2CA6B";
        break;
      case stats > 50 && stats <= 70:
        color = "#F5822F";
        break;
      case stats > 70:
        color = "#CF4AFE";
        break;
      default:
        color = "#F2CA6B";
        break;
    }

    return color;
  }
  return (
    <Modal
      size="xl"
      isOpen={openModal}
      centered={true}
      
    >
      <div className="modal-content" style={{ background: "#222736" }}>
        <div className="container mb-4">
          <p className="custom-modal-title mt-2"></p>
          <button
            type="button"
            onClick={modalClose}
            className="btn-close position-absolute custom-top-2 custom-right-4 mb-4"
          ></button>
        </div>
        <ModalBody className="custom-modal-outer__padding">
          <div className="row mb-4">
            <div className="col-lg-4 pe-lg-1">
              <div className="row h-100">
                {" "}
                {/* want card equal add h-100 then in the next line add mb-2 mb-lg-0 */}
                <PermissionCheck
                  permission={`${agentPerms.agent_modal_profile}`}
                >
                  <div className="col-12 mb-2">
                    <AgentProfileInfo
                      isLoadindData={isLoadingAgent}
                      agentInfo={agent}
                    />
                  </div>
                </PermissionCheck>
                <PermissionCheck
                  permission={`${agentPerms.agent_modal_years_brokerage}`}
                >
                  <div className="col-12 mb-2 mb-lg-0">
                    <div className="h-100 d-grid align-items-center" style={{ backgroundColor: "#2A3042" }}>
                      <div className="custom-modal-inner__padding d-grid align-items-center">
                        <div className="d-flex justify-content-between align-items-center text-center">
                          <div className="d-inline-block">
                            <span className="header-title-5 text-white mb-0 pe-1">
                              Years at brokerage
                            </span>
                            <div className="d-inline-block align-middle">
                              <HelpIcon
                                tooltip={agentToolTip?.years_at_brokerage}
                                width={20}
                                height={20}
                              />
                            </div>
                          </div>

                          <p className="text-center text-break number-stats-10 text-white mb-0 ">
                            <Placeholder
                              loading={isLoadingAgent}
                              className="custom-loader-w-20 custom-loader-h-6"
                            ></Placeholder>
                            {!isLoadingAgent && (agent?.years_as_agent || 0)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </PermissionCheck>
              </div>
            </div>

            <div className="col-lg-8 ps-lg-1">
              <div className="row custom-mb-9">
                <PermissionCheck
                  permission={`${agentPerms.agent_modal_sales_volume}`}
                >
                  <div className="col-12 col-lg-6 mb-2 mb-lg-0 pe-lg-1">
                    <div
                      className="d-grid align-items-center h-100"
                      style={{ backgroundColor: "#2A3042" }}
                    >
                      <div className="custom-modal-inner__padding">
                        <YtdAgentCard
                          cardTitle="Sales Volume"
                          isDataLoading={isLoadingAgentYTDReport}
                          tooltipText={agentToolTip?.sales_volume}
                          cardSubtitile="YTD"
                          isAmountDollar={true}
                          amount={agentYTDReport?.ytd_sales_volume}
                          percentText="From Previous Period"
                          percentValue={agentYTDReport?.percent_ytd_sales_volume}
                        />
                        {/* <div className="d-inline-block">
                          <span className="header-title-4 text-white mb-0 pe-1">
                            Sales Volume
                          </span>
                          <div className="d-inline-block align-middle">
                            <HelpIcon
                              tooltip={agentToolTip?.sales_volume}
                              width={20}
                              height={20}
                            />
                          </div>
                        </div> */}
                        {/* <p className="mb-0 custom-fs-12-to-14">Year to Date</p>
                        <p className="text-center text-break number-stats-4 text-white mb-0 ">
                          &#36;
                          <Placeholder
                            loading={isLoadingAgentYTDReport}
                            className="w-8 h-8"
                          ></Placeholder>
                          {!isLoadingAgentYTDReport &&
                            formatNumberWithCommas(
                              agentYTDReport?.ytd_sales_volume,
                            )}
                        </p>
                        <p className="text-center custom-fs-12-to-14">
                        <Placeholder
                          loading={isLoadingAgentYTDReport}
                          className="custom-w-12 custom-loader-h-6"
                        ></Placeholder>
                          { !isLoadingAgentYTDReport && (() => {
                            if (agentYTDReport?.percent_ytd_sales_volume > 0) {
                              return (
                                <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                                  +{" "}
                                  {formatDecimalPlaces(
                                    agentYTDReport?.percent_ytd_sales_volume,
                                  )}
                                  %
                                </span>
                              );
                            } else {
                              return (
                                <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                                  {formatDecimalPlaces(
                                    agentYTDReport?.percent_ytd_sales_volume,
                                  )}
                                  %
                                </span>
                              );
                            }
                          })()}
                          <span>From Previous Period</span>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </PermissionCheck>
                <PermissionCheck
                  permission={`${agentPerms.agent_modal_company_dollar}`}
                >
                  <div className="col-12 col-lg-6 ps-lg-1">
                    <div className="h-100" style={{ backgroundColor: "#2A3042" }}>
                      <div className="custom-modal-inner__padding">
                        <YtdAgentCard
                          cardTitle="Company Dollar"
                          isDataLoading={isLoadingAgentYTDReport}
                          tooltipText={agentToolTip?.company_dollar}
                          cardSubtitile="YTD"
                          isAmountDollar={true}
                          amount={agentYTDReport?.ytd_company_dollar}
                          percentText="From Previous Period"
                          percentValue={
                            agentYTDReport?.percent_ytd_company_dollar
                          }
                        />
                      </div>
                    </div>
                  </div>
                </PermissionCheck>
              </div>
              <div className="row">
                <PermissionCheck
                  permission={`${agentPerms.agent_modal_list_vs_sell_sides}`}
                >
                  <div className="col-12 col-lg-6 mb-2 mb-lg-0 pe-lg-1">
                    <div className="h-100" style={{ backgroundColor: "#2A3042" }}>
                      <div className="custom-modal-inner__padding">
                        <YtdListVsSellSide
                          isDataLoading={isLoadingAgentYTDReport}
                          agentYTDReportData={agentYTDReport}
                        />
                      </div>
                      {/* <div className="custom-modal-inner__padding">
                        <div className="d-inline-block">
                          <span className="header-title-4 text-white mb-0 pe-1">
                            Sell Sides
                          </span>
                          <div className="d-inline-block align-middle">
                            <HelpIcon
                              tooltip={agentToolTip?.sell_sides}
                              width={20}
                              height={20}
                            />
                          </div>
                        </div>
                        <p className="mb-0 custom-fs-12-to-14">Year to Date</p>
                        <p className="text-center text-break number-stats-4 text-white mb-1">
                          <Placeholder
                            loading={isLoadingAgentYTDReport}
                            className="w-8 h-8"
                          ></Placeholder>
                          {!isLoadingAgentYTDReport &&
                            (agentYTDReport?.ytd_sell_side ?? 0)}
                        </p>
                        <p className="text-center custom-fs-12-to-14">
                          <Placeholder
                            loading={isLoadingAgentYTDReport}
                            className="custom-w-12 custom-loader-h-6"
                          ></Placeholder>
                          {!isLoadingAgentYTDReport &&
                            (() => {
                              if (agentYTDReport?.percent_sell_side > 0) {
                                return (
                                  <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                                    +{" "}
                                    {formatDecimalPlaces(
                                      agentYTDReport?.percent_sell_side,
                                    )}
                                    %
                                  </span>
                                );
                              } else {
                                return (
                                  <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                                    {formatDecimalPlaces(
                                      agentYTDReport?.percent_sell_side,
                                    )}
                                    %
                                  </span>
                                );
                              }
                            })()}
                          <span>From Previous Period</span>
                        </p>
                      </div>

                      <div className="custom-modal-inner__padding">
                        <div className="d-inline-block">
                          <span className="header-title-4 text-white mb-0 pe-1">
                            List Sides
                          </span>
                          <div className="d-inline-block align-middle">
                            <HelpIcon
                              tooltip={agentToolTip?.list_sides}
                              width={20}
                              height={20}
                            />
                          </div>
                        </div>
                        <p className="mb-0 custom-fs-12-to-14">Year to Date</p>
                        <p className="text-center text-break number-stats-4 text-white mb-1">
                          <Placeholder
                            loading={isLoadingAgentYTDReport}
                            className="w-8 h-8"
                          ></Placeholder>
                          {!isLoadingAgentYTDReport &&
                            (agentYTDReport?.ytd_list_sides ?? 0)}
                        </p>
                        <p className="text-center custom-fs-12-to-14">
                          <Placeholder
                            loading={isLoadingAgentYTDReport}
                            className="custom-w-12 custom-loader-h-6"
                          ></Placeholder>
                          {!isLoadingAgentYTDReport &&
                            (() => {
                              if (agentYTDReport?.percent_list_side > 0) {
                                return (
                                  <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                                    +{" "}
                                    {formatDecimalPlaces(
                                      agentYTDReport?.percent_list_side,
                                    )}
                                    %
                                  </span>
                                );
                              } else {
                                return (
                                  <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                                    {formatDecimalPlaces(
                                      agentYTDReport?.percent_list_side,
                                    )}
                                    %
                                  </span>
                                );
                              }
                            })()}
                          <span>From Previous Period</span>
                        </p>
                      </div> */}
                    </div>
                  </div>
                </PermissionCheck>
                <div className="col-12 col-lg-6 ps-lg-1">
                  <div className="row h-100">
                  <PermissionCheck
                    permission={`${agentPerms.agent_modal_gci}`}
                  >
                    <div className="col-12 d-grid align-items-center custom-mb-9 ">
                      <div
                        className="h-100 d-grid align-items-center"
                        style={{ backgroundColor: "#2A3042" }}
                      >
                        <div className="custom-modal-inner__padding ">
                          <YtdAgentCard
                            cardTitle="GCI"
                            isDataLoading={isLoadingAgentYTDReport}
                            tooltipText={agentToolTip?.gci}
                            cardSubtitile="YTD"
                            isAmountDollar={true}
                            amount={agentYTDReport?.ytd_gci}
                            percentText="From Previous Period"
                            percentValue={agentYTDReport?.precent_gci}
                          />
                        </div>
                      </div>
                    </div>
                  </PermissionCheck>
                  <PermissionCheck
                    permission={`${agentPerms.agent_modal_pending_gci}`}
                  > 
                    <div className="col-12 d-grid align-items-center">
                      <div
                        className="h-100 d-grid align-items-center"
                        style={{ backgroundColor: "#2A3042" }}
                      >
                        <div className="custom-modal-inner__padding">
                          <YtdAgentCard
                            cardTitle="Pending GCI"
                            isDataLoading={isLoadingAgentYTDReport}
                            tooltipText={agentToolTip?.pending_gci}
                            cardSubtitile="YTD"
                            isAmountDollar={true}
                            amount={agentYTDReport?.ytd_gci_under_contract}
                            percentText="From Previous Period"
                            percentValue={
                              agentYTDReport?.precent_gci_under_contract
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </PermissionCheck>
                  </div>

                  {/* <div className="" style={{ backgroundColor: "#2A3042" }}>
                    <div className="custom-modal-inner__padding">
                      <div className="d-inline-block">
                        <span className="header-title-4 text-white mb-0 pe-1">
                          GCI
                        </span>
                        <div className="d-inline-block align-middle">
                          <HelpIcon
                            tooltip={agentToolTip?.gci}
                            width={20}
                            height={20}
                          />
                        </div>
                      </div>
                      <p className="mb-0 custom-fs-12-to-14">Year to Date</p>
                      <p className="text-center text-break number-stats-4 text-white mb-1">
                        $
                        <Placeholder
                          loading={isLoadingAgentYTDReport}
                          className="w-8 h-8"
                        ></Placeholder>
                        {!isLoadingAgentYTDReport &&
                          formatNumberWithCommas(agentYTDReport?.ytd_gci)}
                      </p>
                      <p className="text-center custom-fs-12-to-14">
                        <Placeholder
                          loading={isLoadingAgentYTDReport}
                          className="custom-w-12 custom-loader-h-6"
                        ></Placeholder>
                        {!isLoadingAgentYTDReport && (() => {
                          if (agentYTDReport?.precent_gci > 0) {
                            return (
                              <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                                +{" "}
                                {formatDecimalPlaces(
                                  agentYTDReport?.precent_gci,
                                )}
                                %
                              </span>
                            );
                          } else {
                            return (
                              <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                                {formatDecimalPlaces(
                                  agentYTDReport?.precent_gci,
                                )}
                                %
                              </span>
                            );
                          }
                        })()}
                        <span>From Previous Period</span>
                      </p>
                    </div>

                    <div className="custom-modal-inner__padding">
                      <div className="d-inline-block">
                        <span className="header-title-4 text-white mb-0 pe-1">
                          Pending GCI
                        </span>
                        <div className="d-inline-block align-middle">
                          <HelpIcon
                            tooltip={agentToolTip?.pending_gci}
                            width={20}
                            height={20}
                          />
                        </div>
                      </div>
                      <p className="mb-0 custom-fs-12-to-14">Year to Date</p>
                      <p className="text-center number-stats-4 text-white mb-1">
                        $
                        <Placeholder
                          loading={isLoadingAgentYTDReport}
                          className="w-8 h-8"
                        ></Placeholder>
                        {!isLoadingAgentYTDReport &&
                          formatNumberWithCommas(
                            agentYTDReport?.ytd_gci_under_contract,
                          )}
                      </p>
                      <p className="text-center custom-fs-12-to-14">
                        <Placeholder
                          loading={isLoadingAgentYTDReport}
                          className="custom-w-12 custom-loader-h-6"
                        ></Placeholder>
                        {!isLoadingAgentYTDReport && (() => {
                          if (agentYTDReport?.precent_gci_under_contract > 0) {
                            return (
                              <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                                +{" "}
                                {formatDecimalPlaces(
                                  agentYTDReport?.precent_gci_under_contract,
                                )}
                                %
                              </span>
                            );
                          } else {
                            return (
                              <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                                {formatDecimalPlaces(
                                  agentYTDReport?.precent_gci_under_contract,
                                )}
                                %
                              </span>
                            );
                          }
                        })()}
                        <span>From Previous Period</span>
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <PermissionCheck
            permission={`${agentPerms.agent_modal_three_month_forecast}`}
          > 
            <ForeCastedRevenue agentId={agentId}></ForeCastedRevenue>
          </PermissionCheck>
          <PermissionCheck
            permission={`${agentPerms.agent_modal_recom_list}`}
          > 
            <RecomendedAction agentId={agentId}></RecomendedAction>
          </PermissionCheck>
          <ComperativeFinancial agentId={agentId}></ComperativeFinancial>

          {/* profile info start */}
          <PermissionCheck
            permission={`${agentPerms.agent_modal_tech_engagement}`}
          > 
            <AgentYearlyTechEngagement agentId={agentId} officeIdProps={agentOfficeId}/>
          </PermissionCheck>
           {/* Monthly engagement score */}
           <div className="row mb-4">
            <PermissionCheck
              permission={`${agentPerms.agent_modal_monthly_engmnt_score}`}
            > 
              <div className="col-12 col-lg-6 mb-2 mt-1 pe-lg-2">
                <div
                  className="custom-modal-inner__padding h-100"
                  style={{ backgroundColor: "#2B3041" }}
                >
                  <AgentEngagementScore agentIdProps={agentId} />
                </div>
              </div>
            </PermissionCheck>
            <PermissionCheck
              permission={`${agentPerms.agent_modal_cma_tech_stats}`}
            > 
              <div className="col-12 col-lg-6 mb-2 mt-1 ps-lg-1">
                <div
                  className="custom-modal-inner__padding h-100"
                  style={{ backgroundColor: "#2B3041" }}
                >
                  <AgentToolTechStats agentIdProps={agentId} />
                </div>
              </div>
            </PermissionCheck>
          </div>
          {/* <RecentActivity></RecentActivity> */}
        </ModalBody>
      </div>
    </Modal>
  );
}

export default DetailAgentModal;
