import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { createUseStyles } from 'react-jss';
import EmergingMarketAlert from '../Markets/EmergingMarketAlert/EmergingMarketAlert';
import {
  EmergingMarket,
  useCreateEmergingMarketMutation,
  useLazyListListingsByMonthsQuery,
  useLazyRetrieveEmergingMarketQuery,
  useListMlsQuery,
  useUpdateEmergingMarketMutation,
} from '../../../state/api';
import EmergingMarketHeatmap, {
  EmergingMarketBounds,
  EmergingMarketCenterAndZoom,
} from '../Markets/EmergingMarketHeatmap/EmergingMarketHeatmap';
import Field from '../../common/Field';
import { maxLengthMessage, requiredFieldMessage } from '../../common/validation';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';
import SelectField, { SelectFieldOption } from '../../common/SelectField';
import EmergingMarketSearch from '../Markets/EmergingMarketSearch/EmergingMarketSearch';

const styles = createUseStyles({
  EditEmergingMarket: {
    'display': 'flex',
    'flex-direction': 'column',
  },
  EditEmergingMarket_form__loading: {
    'display': 'none',
  },
  EditEmergingMarket_bottom: {
    'display': 'flex',
    'flex-direction': 'row',
    'margin-top': '1em',
    'width': '100%',
    '& > *': {
      'width': '100%',
    },
  },
});

const emergingMarketInitialStateData = {
  centerLatitude: 39,
  centerLongitude: -95,
  zoom: 4,
} as EmergingMarketCenterAndZoom;

interface EmergingMarketForm {
  mls: SelectFieldOption
  name: string
  insight1: string
  insight2: string
  insight3: string
}

interface EmergingMarketPath {
  id?: string
}

export const createFailureMessage = 'Error creating emerging market.';
export const createSuccessMessage = 'Successfully created emerging market.';
export const updateFailureMessage = 'Error updating emerging market.';
export const updateSuccessMessage = 'Successfully updated emerging market.';

const defaultValues = {
  mls: undefined as SelectFieldOption | undefined,
  name: '',
  insight1: '',
  insight2: '',
  insight3: '',
};

const EditEmergingMarket = () => {
  const classes = styles();
  // keep track of initial center and zoom so that the map can be updated on retrieve EM
  const [
    initialCenterAndZoom,
    setInitialCenterAndZoom,
  ] = useState(emergingMarketInitialStateData as EmergingMarketCenterAndZoom);
  // keep track of the updated center and zoom so that we can save any map movements
  const [
    updatedCenterAndZoom,
    setUpdatedCenterAndZoom,
  ] = useState(emergingMarketInitialStateData as EmergingMarketCenterAndZoom);
  const [
    emergingMarketBounds,
    setEmergingMarketBounds,
  ] = useState(null as EmergingMarketBounds | null);
  const [triggerListListingsByMonthData, listingsByMonthData] = useLazyListListingsByMonthsQuery();
  const pathVariables: EmergingMarketPath = useParams();
  const [createEmergingMarket, createEmergingMarketData] = useCreateEmergingMarketMutation();
  const [updateEmergingMarket, updateEmergingMarketData] = useUpdateEmergingMarketMutation();
  const [
    triggerRetrieveEmergingMarket,
    retrieveEmergingMarketData,
  ] = useLazyRetrieveEmergingMarketQuery();
  const mlsData = useListMlsQuery();
  const [mls, setMls] = useState(null as string | null);
  const { control, handleSubmit, reset } = useForm({ defaultValues });

  // retrieve the data for the form if we are updating
  useEffect(() => {
    if (pathVariables.id !== undefined) {
      triggerRetrieveEmergingMarket({ id: pathVariables.id });
    }
  }, [pathVariables, triggerRetrieveEmergingMarket]);
  // populate form if we are updating and the query was a success
  // set the initial center and zoom of the map
  useEffect(() => {
    if (retrieveEmergingMarketData.isSuccess) {
      const [insight1, insight2, insight3] = retrieveEmergingMarketData.data
        .emerging_market_insights!.map((it) => it.text);
      reset({
        mls: {
          label: retrieveEmergingMarketData.data.mls_name!,
          value: retrieveEmergingMarketData.data.mls,
        },
        name: retrieveEmergingMarketData.data.name,
        insight1,
        insight2: insight2 != null ? insight2 : '',
        insight3: insight3 != null ? insight3 : '',
      });
      setMls(retrieveEmergingMarketData.data.mls);
      setInitialCenterAndZoom({
        centerLatitude: retrieveEmergingMarketData.data!.center_latitude!,
        centerLongitude: retrieveEmergingMarketData.data!.center_longitude!,
        zoom: retrieveEmergingMarketData.data!.zoom!,
      });
    }
  }, [
    reset, retrieveEmergingMarketData, setMls, setInitialCenterAndZoom,
  ]);
  // update the listings by month data every time the bounds change
  // this gets updated by the map
  useEffect(() => {
    if (mls != null && emergingMarketBounds != null) {
      triggerListListingsByMonthData({
        maximumLatitude: emergingMarketBounds.maximumLatitude,
        maximumLongitude: emergingMarketBounds.maximumLongitude,
        minimumLatitude: emergingMarketBounds.minimumLatitude,
        minimumLongitude: emergingMarketBounds.minimumLongitude,
        mls: [mls],
      });
    }
  }, [emergingMarketBounds, mls, triggerListListingsByMonthData]);

  const selectMls = useCallback((value: SelectFieldOption | null) => {
    if (value != null) {
      setMls(value.value!);
    } else {
      setMls(null);
    }
  }, []);

  const searchForPlace = useCallback((latitude: number, longitude: number) => {
    const centerAndZoom = {
      centerLatitude: latitude,
      centerLongitude: longitude,
      zoom: 13,
    };
    setInitialCenterAndZoom(centerAndZoom);
    setUpdatedCenterAndZoom(centerAndZoom);
  }, [setInitialCenterAndZoom, setUpdatedCenterAndZoom]);

  const handleCreateOrUpdate = handleSubmit((form: EmergingMarketForm) => {
    const emergingMarketRequest = {
      center_latitude: updatedCenterAndZoom.centerLatitude,
      center_longitude: updatedCenterAndZoom.centerLongitude,
      zoom: updatedCenterAndZoom.zoom,
      maximum_latitude: emergingMarketBounds!.maximumLatitude,
      maximum_longitude: emergingMarketBounds!.maximumLongitude,
      minimum_latitude: emergingMarketBounds!.minimumLatitude,
      minimum_longitude: emergingMarketBounds!.minimumLongitude,
      mls: form.mls.value,
      name: form.name,
      emerging_market_insights: [form.insight1, form.insight2, form.insight3]
        .filter((it) => it != null && it !== '')
        .map((it) => ({ text: it })),
    } as EmergingMarket;
    if (pathVariables.id === undefined) {
      createEmergingMarket({ emergingMarket: emergingMarketRequest }).unwrap()
        .then(() => toast.dark(createSuccessMessage, { type: 'success' }))
        .catch(() => toast.dark(createFailureMessage, { type: 'error' }));
    } else {
      updateEmergingMarket({ id: pathVariables.id, emergingMarket: emergingMarketRequest }).unwrap()
        .then(() => toast.dark(updateSuccessMessage, { type: 'success' }))
        .catch(() => toast.dark(updateFailureMessage, { type: 'error' }));
    }
  });

  const mlsDropdownOptions = mlsData.isSuccess
    ? mlsData.data.map((it) => ({ label: it.name!, value: it.id! })) : [];

  let mapAndGraph;
  const mlsIds = useMemo(() => (mls != null ? [mls] : []), [mls]);
  if (mls != null) {
    mapAndGraph = (
      <div data-testid="mapAndGraph">
        <hr />
        <EmergingMarketSearch
          className="mb-3"
          setLatitudeAndLongitude={searchForPlace}
        />
        <EmergingMarketHeatmap
          initialCenterAndZoom={initialCenterAndZoom}
          mlsIds={mlsIds}
          setEmergingMarketBounds={setEmergingMarketBounds}
          setEmergingMarketCenterAndZoom={setUpdatedCenterAndZoom}
        />
        <LoadingIcon loading={!listingsByMonthData.isSuccess} />
        {listingsByMonthData.data != null
        && (
          <div className={classes.EditEmergingMarket_bottom}>
            <EmergingMarketAlert listingsByMonth={listingsByMonthData.data} />
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <MetaTags>
        <title>Create Emerging Market - Kintaro</title>
      </MetaTags>
      <div className={classes.EditEmergingMarket}>
        <LoadingIcon loading={retrieveEmergingMarketData.isLoading} />
        <Breadcrumb>
          <BreadcrumbItem><a href="/manage-emerging-markets">Manage Emerging Markets</a></BreadcrumbItem>
          <BreadcrumbItem active>Edit</BreadcrumbItem>
        </Breadcrumb>
        <form
          className={retrieveEmergingMarketData.isLoading ? classes.EditEmergingMarket_form__loading : 'form-horizontal'}
          onSubmit={handleCreateOrUpdate}
        >
          <div className="mt-3">
            <SelectField
              control={control}
              label="MLS"
              loading={!mlsData.isSuccess}
              name="mls"
              onChange={selectMls}
              options={mlsDropdownOptions}
              placeholder="Select MLS..."
              rules={{
                required: requiredFieldMessage('MLS'),
              }}
            />
          </div>
          {mapAndGraph}
          <hr />
          <Field
            control={control}
            label="Name"
            name="name"
            placeholder="The name of the emerging market"
            rules={{
              required: requiredFieldMessage('Name'),
            }}
          />
          <Field
            className="mt-2"
            control={control}
            label="Insight 1"
            name="insight1"
            placeholder={'This will be displayed in the "Emerging Market Alert" card.'}
            rules={{
              required: requiredFieldMessage('Insight 1'),
              maxLength: { value: 254, message: maxLengthMessage('Insight 1', 255) },
            }}
          />
          <Field
            className="mt-2"
            control={control}
            label="Insight 2"
            name="insight2"
            placeholder={'This will be displayed in the "Emerging Market Alert" card.'}
            rules={{
              maxLength: { value: 254, message: maxLengthMessage('Insight 1', 255) },
            }}
          />
          <Field
            className="mt-2"
            control={control}
            label="Insight 3"
            name="insight3"
            placeholder={'This will be displayed in the "Emerging Market Alert" card.'}
            rules={{
              maxLength: { value: 254, message: maxLengthMessage('Insight 1', 255) },
            }}
          />
          <div className="float-end mb-3">
            <LoadingIcon
              className="mt-1 mb-4"
              loading={createEmergingMarketData.isLoading || updateEmergingMarketData.isLoading}
            />
            <button
              className="btn btn-primary btn-block"
              disabled={createEmergingMarketData.isLoading || updateEmergingMarketData.isLoading}
              type="submit"
            >
              {'id' in pathVariables ? 'Update' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditEmergingMarket;
