import { SORT_ASC, SORT_DESC } from '@constants/variables';
import collect from 'collect.js';

function tableSortData(data: any, sortedColumns: any[]) {
  let sortedCollection: any = collect(data).sortByDesc('gci');
  let output: any = [];
  
  sortedColumns.forEach((item: any) => {
    if (item.order === 'desc') {
      sortedCollection = sortedCollection.sortByDesc(item.column);
    } else {
      sortedCollection = sortedCollection.sortBy(item.column);
    }
    output = sortedCollection.all();
  });
  return output;
}

const colSorting = (data: any, sortColumns: any, column: any) => {
  let newColumns: any;
  const columnIndex = sortColumns.findIndex(
    (item: any) => item.column === column,
  );
  if (columnIndex !== -1) {
    // Column is already sorted, toggle the order
    const newSortColumns = [...sortColumns];
    let theOrder: any = "default";
    if (sortColumns[columnIndex].order === "desc") {
      theOrder = "asc";
    } else if (sortColumns[columnIndex].order === "asc") {
      // theOrder = 'default';
      theOrder = "desc";
    } else {
      theOrder = "desc";
    }
    newSortColumns[columnIndex] = {
      column,
      order: theOrder,
    };
    newColumns = newSortColumns;
  } else {
    // Column is not in sortedColumns, add it with 'asc' order
    newColumns = [...sortColumns, { column, order: "desc" }];
  }
  // setAgents(sortData(agents, newColumns));
  return {
    data: tableSortData(data, newColumns),
    columns: newColumns,
  };
};

function tableSortData2(data: any, sortedColumns: any[]) {
  const rawData:any = [...data];

  let sortedCollection: any = collect(data);
  let output: any = [];
  
  if (sortedColumns?.length) {
    sortedColumns.forEach((item: any) => {
      if (item.order === 'desc') {
        sortedCollection = sortedCollection.sortByDesc(item.column);
      }
    });
    output = sortedCollection.all();
    return output;
  } else {
    return rawData;
  }
}

const colSortingV2 = (data: any, sortColumns: any, column: any) => {
  let newColumns: any;
  const newSortColumns = [...sortColumns];
  let theOrder: any = "";
  const columnIndex = sortColumns.findIndex(
    (item: any) => item.column === column,
  );

  if (columnIndex !== -1) {
    // Column is already sorted, toggle the order
    // const newSortColumns:any = [...sortColumns];
    // newSortColumns.splice(columnIndex, 1);
    // newColumns = newSortColumns;
    if (sortColumns[columnIndex].order === SORT_DESC) {
      theOrder = SORT_ASC;
      newSortColumns[columnIndex] = {
        column,
        order: theOrder,
      };
    } else if (sortColumns[columnIndex].order === SORT_ASC) {
      theOrder = "";
      newSortColumns.splice(columnIndex, 1);
    }
    
    newColumns = newSortColumns;
  } else {
    // Column is not in sortedColumns, add it with 'asc' order
    newColumns = [...sortColumns, { column, order: SORT_DESC }];
  }
  
  return {
    // data: tableSortData2(data, newColumns),
    columns: newColumns,
    apiColumn: getFormattedApiColumn(newColumns),
  };
};

const getFormattedApiColumn = (column:any) => {
  const apiColumns:any = [];
  // eslint-disable-next-line array-callback-return
  column?.filter((el:any) => {
    if (el.order === SORT_DESC) {
      apiColumns.push(`-${el.column}`);
    } else if (el.order === SORT_ASC) {
      apiColumns.push(`${el.column}`);
    }
  });
  return apiColumns.join(',');
};

export default tableSortData;
export { colSorting, colSortingV2 };
