import cookies from '@utils/cookies';
import axios, { AxiosInstance } from 'axios';

// Retrieve the authentication cookie
const token: any = cookies.get('token');
const theBrandSlug: any = cookies.get('the_brand_slug');

// site api instance
const http: AxiosInstance = axios.create({
  // baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`,
  baseURL: `${process.env.REACT_APP_DEMO_BASE_URL}`,
  // headers: {
  //   'Authorization': `Bearer ${token}`,
  //   'Content-Type': 'application/json',
  //   'The-Brand-Slug': theBrandSlug ?? "",
  // },
});

export { http };
