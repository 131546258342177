import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import GraphLine from './GraphContentType/GraphLine';

import RecomendedActions from './RecomendedActions/RecomendedActions';

import RecruitingStat from './RecruitingStat/RecruitingStat';

const Recruiting = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [statusFilter, setStatusFilter] = useState('Filters' as string | undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [typeFilter, setTypeFilter] = useState(undefined as string | undefined);

  return (
    <>
      <MetaTags>
        <title>Recruiting - Kintaro</title>
      </MetaTags>
      <div className="d-flex justify-content-between pt-4 align-items-end">
        <div>
          <h4>
            Recruiting Dashboard
          </h4>
          <p>
            We have identified potential recruiting opportunities based
            on profiles built around the following average benchmarks
          </p>
        </div>
      </div>
      <RecruitingStat />
      <GraphLine pieGraph agentOpportunities />
      <GraphLine favoritedAgents />
      <RecomendedActions />
    </>
  );
};

export default Recruiting;
