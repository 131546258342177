import Pagination from "@components/common/Pagination/Pagination";
import PerPageOptionSelector from "@components/common/PerPageOptionSelector";
import SearchSelect from "@components/common/SearchSelect";
import TablePlaceHolder from "@components/common/TablePlaceHolder";
import TableSortIcon from "@components/common/TableSortIcon";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { faker } from "@faker-js/faker";
import { http } from "@utils/http";
import { colSortingV2 } from "@utils/table-sort";
import { useEffect, useState } from "react";

function OfficeToolEngagementTable() {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isOfficeInfoDataLoading, setIsOfficeInfoDataLoading] = useState(true);
  const columns: any = [
    // {
    //   name: "#",
    //   slug: "",
    //   sort: false,
    // },
    {
      name: "Office",
      slug: "office",
      sort: false,
      subHeaderClassNames: "",
    },
    {
      name: "Gmail",
      slug: "gmail",
      subHeaderClassNames: "justify-content-center",
      sort: false,
    },
    {
      name: "CloudCMA",
      slug: "cloudcma",
      subHeaderClassNames: "justify-content-center",
      sort: false,
    },
    {
      name: "HomeSpotter",
      slug: "home_spotter",
      subHeaderClassNames: "justify-content-center",
      sort: false,
    },
    {
      name: "PrinterLogic SaaS",
      slug: "printerlogic_saas",
      subHeaderClassNames: "justify-content-center",
      sort: false,
    },
    {
      name: "SIR Access",
      slug: "sir_access",
      subHeaderClassNames: "justify-content-center",
      sort: false,
    },
    {
      name: "Testimonial Tree",
      slug: "testimonial_tree",
      subHeaderClassNames: "justify-content-center",
      sort: false,
    },
    {
      name: "Video On Demand",
      slug: "video_on_demand",
      subHeaderClassNames: "justify-content-center",
      sort: false,
    },
    {
      name: "XpressDocs",
      slug: "xpress_docs",
      subHeaderClassNames: "justify-content-center",
      sort: false,
    },
    {
      name: "Zoom",
      slug: "zoom",
      subHeaderClassNames: "justify-content-center",
      sort: false,
    },
  ];
  const [officeToolEngData, setOfficeToolEngData] = useState([] as any);
  const [officeToolEngApiData, setOfficeToolEngApiData] = useState([] as any);
  const [officeOptions, setOfficeOptions] = useState([] as any);
  const [selectedOfficeOption, setSelectedOfficeOption] = useState({} as any);

  const [sortColumns, setSortColumns] = useState([] as any);
  const [sortAPIColumns, setSortAPIColumns] = useState("" as any);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0 as any);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    getOfficeToolEngData();
  }, [currentPage, perPage, selectedOfficeOption, sortAPIColumns]);

  useEffect(() => {
    getAllOfficeData();
  }, []);

  function getAllOfficeData() {
    setIsOfficeInfoDataLoading(true);
    http
      .get("/office/office-info.json")
      .then((response: any) => {
        const { data } = response;
        let theData = data.office_info;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            office_name: faker.company.name(),
          }));
        }

        setOfficeOptions(theData);
        setIsOfficeInfoDataLoading(false);
      })
      .catch((error) => {
        setIsOfficeInfoDataLoading(false);
        console.log(error);
      });
  }

  function getOfficeToolEngData() {
    setIsDataLoading(true);
    http
      .get("/tools/okta-log-engagement-score.json")
      .then((response: any) => {
        const { data } = response;

        let theData = data.engagement_score;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            office: faker.company.name(),
          }));
        }
        setOfficeToolEngData(theData);
        setOfficeToolEngData(theData);
        setTotalPage(data?.last_page);
        setIsDataLoading(false);
      })
      .catch((error) => {
        setIsDataLoading(false);
        console.log(error);
      });
  }

  // Define a function to handle column sorting
  const handleSort = (column: any) => {
    const sortingOutput: any = colSortingV2(
      officeToolEngApiData,
      sortColumns,
      column,
    );
    setSortColumns(sortingOutput.columns);
    setSortAPIColumns(sortingOutput?.apiColumn);
    // setOffices(sortingOutput.data);
  };

  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  return (
    <div className="mb-4">
      <div
        className="custom-modal-inner__padding"
        style={{ backgroundColor: "#2B3041" }}
      >
        <div className="row row-gap-2 row-gap-md-0 mb-3 ">
          <div className="col-6 col-md-7">
            <div className="d-flex flex-wrap align-items-center gap-1">
              <div className="header-title">Office Tool Opens</div>
            </div>
          </div>
          <div className="col-6 col-md-5 d-inline-flex justify-content-end">
            <SearchSelect
              options={officeOptions}
              width="200px"
              value={
                JSON.stringify(selectedOfficeOption) !== "{}"
                  ? selectedOfficeOption
                  : "Select Office"
              }
              onChange={(option: any) => {
                setSelectedOfficeOption(option);
              }}
              isLoading={isOfficeInfoDataLoading}
              isClearable
              getOptionValue={(option: any) => option.office_id}
              getOptionLabel={(option: any) => `${option.office_name}`}
              placeholder="Select Office"
            />
          </div>
        </div>
        <div className="table-responsive scrollable-body-2">
          <table className="align-middle table-nowrap table-check table table-striped table-sm fixed-header fixed-column">
            <thead className="table-light">
              <tr role="row">
                {columns.map((column: any) => (
                  <th
                    key={column.slug}
                    onClick={() => {
                      if (column.sort) {
                        handleSort(column.slug);
                      }
                    }}
                    role="button"
                    className={`${column?.headerClassNames}`}
                  >
                    <div
                      className={`d-flex align-items-center ${column?.subHeaderClassNames}`}
                    >
                      <TableSortIcon
                        sortColumns={sortColumns}
                        column={column}
                      ></TableSortIcon>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <TablePlaceHolder
                items={officeToolEngData}
                loading={isDataLoading}
                rows={13}
                cols={10}
              />
              {!isDataLoading &&
                officeToolEngData?.map((officeTool: any, id: any) => (
                  <tr key={officeTool.id}>
                    <td className="ps-2 first-column">
                      {officeTool.office}
                    </td>
                    <td className="text-center">{officeTool?.gmail ?? 0}</td>
                    <td className="text-center">{officeTool?.cloudcma ?? 0}</td>
                    <td className="text-center">
                      {officeTool?.home_spotter ?? 0}
                    </td>
                    <td className="text-center">
                      {officeTool?.printerlogic_saas ?? 0}
                    </td>
                    <td className="text-center">
                      {officeTool?.sir_access ?? 0}
                    </td>
                    <td className="text-center">
                      {officeTool?.testimonial_tree ?? 0}
                    </td>
                    <td className="text-center">
                      {officeTool?.video_on_demand ?? 0}
                    </td>
                    <td className="text-center">
                      {officeTool?.xpress_docs ?? 0}
                    </td>
                    <td className="text-center">{officeTool?.zoom ?? 0}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-3 d-flex gap-2 align-items-center">
            <p className="mb-0 custom-fs-12-to-14">Rows Per Page</p>
            <PerPageOptionSelector
              perPageEmitSet={(value: any) => setPerPage(value)}
              labelUnit=" "
              // placeholderText="select office"
            />
          </div>
          <div className="col-12 col-md-9 d-flex justify-content-center justify-content-md-start align-items-center">
            <Pagination
              pageCount={totalPage}
              perPage={perPage}
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfficeToolEngagementTable;
