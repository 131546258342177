import HelpIcon from "@components/common/HelpIcon";
import Placeholder from "@components/common/Placeholder";
import { officeModalTooltip } from "@constants/tooltip-data";
import { formatDecimalPlaces } from "@utils/helpers";

function OfficeAgentRetentionInfo(props: any) {
  const { officeAgentgainedInfo, officeAgentLostInfo, isDataLoading } = props;
  return (
    <div className="custom-h-100-auto" style={{ backgroundColor: "#2B3041" }}>
      <div className="custom-modal-inner__padding">
        <div className="d-inline-block">
          <span className="header-title-5 text-white mb-0 pe-1">
            Agent Retention
          </span>
          <div className="d-inline-block align-middle">
            <HelpIcon tooltip={officeModalTooltip?.officeAgentRetention} width={20} height={20} />
          </div>
        </div>
        <div className="mt-2">
          <div className="row">
            <div className="col-12 col-lg-4 pe-0">
              <span className="custom-fs-12">Agents Gained</span>
            </div>
            <div className="col-12 col-lg-8">
              <div className="d-flex gap-2 mt-1 mt-lg-0">
                <span className="text-white fw-medium custom-fs-12">
                  <Placeholder
                    loading={isDataLoading?.isAgentGainedDataLoading}
                    className="custom-w-12 custom-loader-h-6"
                  ></Placeholder>
                  {!isDataLoading?.isAgentGainedDataLoading &&
                    officeAgentgainedInfo?.last_12_total_new_agents}
                </span>
                <p className="d-block mb-0">
                  <p className="mb-0">
                    <Placeholder
                      loading={isDataLoading?.isAgentGainedDataLoading}
                      className="custom-w-12 custom-loader-h-6"
                    ></Placeholder>
                    {!isDataLoading?.isAgentGainedDataLoading &&
                      (officeAgentgainedInfo?.delta_total_new_agents > 0 ? (
                        <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                          +{" "}
                          {formatDecimalPlaces(
                            officeAgentgainedInfo?.delta_total_new_agents,
                          )}
                          %
                          {/* { !isDataLoading?.isAgentGainedDataLoading && (`+ ${formatDecimalPlaces(officeAgentgainedInfo?.delta_total_new_agents)}%`) } */}
                        </span>
                      ) : (
                        <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                          {formatDecimalPlaces(
                            officeAgentgainedInfo?.delta_total_new_agents,
                          )}
                          %
                        </span>
                      ))}
                  </p>
                  <p
                    className="mb-0 mt-1 custom-text-fs"
                    style={{ color: "#BFC8E2" }}
                  >
                    Previous Period
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <div className="row">
            <div className="col-12 col-lg-4">
              <span className="custom-fs-12">Agents Lost</span>
            </div>
            <div className="col-12 col-lg-8">
              <div className="d-flex gap-2 mt-1 mt-lg-0">
                <span className="text-white fw-medium custom-fs-12">
                  <Placeholder
                    loading={isDataLoading?.isAgentLostDataLoading}
                    className="custom-w-12 custom-loader-h-6"
                  ></Placeholder>
                  {!isDataLoading?.isAgentLostDataLoading &&
                    officeAgentLostInfo?.l12_total_agents_lost}
                </span>
                <p className="d-block mb-0">
                  <p className="mb-0">
                    <Placeholder
                      loading={isDataLoading?.isAgentLostDataLoading}
                      className="custom-w-12 custom-loader-h-6"
                    ></Placeholder>
                    {!isDataLoading?.isAgentLostDataLoading &&
                      (officeAgentLostInfo?.delta_total_lost_agents > 0 ? (
                        <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                          +{" "}
                          {formatDecimalPlaces(
                            officeAgentLostInfo?.delta_total_lost_agents,
                          )}
                          %
                        </span>
                      ) : (
                        <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                          {formatDecimalPlaces(
                            officeAgentLostInfo?.delta_total_lost_agents,
                          )}
                          %
                        </span>
                      ))}
                  </p>
                  <p
                    className="mb-0 mt-1 custom-text-fs"
                    style={{ color: "#BFC8E2" }}
                  >
                    Previous Period
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfficeAgentRetentionInfo;
