/* eslint-disable camelcase */
import { getToolsLandingPageToolTip } from "@constants/tooltip-data";
import { http } from "@utils/http";
import { useEffect, useState } from "react";
import CategoryCardsDetailsModal from "./CategoryCardsDetailsModal";
import SingleCategoryCard from "./SingleCategoryCard";
import ActivePipeCardDetailsModal from "./active-pipe/ActivePipeCardDetailsModal";
import CmaCardDetailsModal from "./cma/CmaCardDetailsModal";
import NeutrinoCardDetailsModal from "./neutrino/NeutrinoCardDetailsModal";

function MultipleCategoryCards() {
  const [cardId, setCardId] = useState("" as any);
  const [isDataLoading, setIsDataLoading] = useState({
    isActivePipeDataLoading: true,
    isCmaDataLoading: true,
    isNeutrinoDataLoading: true,
    // isMaxaDataLoading: true,
    // isHomespotterDataLoading: true,
    // isXpressdocsDataLoading: true,
  });
  const [cardData, setCardData] = useState({
    activePipe: {},
    neutrino: {},
    cma: {},
    maxa: {},
    homespotter: {},
    xpressdocs: {},
  } as any);

  const [modals, setModals] = useState({
    activePipeModal: false,
    neutrinoModal: false,
    cmaModal: false,
    cardsDetailsModal: false,
  });

  const dummyData = [
    {
      id: 1,
      title: "CloudCMA",
      stats: 6,
      textPercentage: "",
      text: "",
      color: "#34C28F",
      toolTipText: getToolsLandingPageToolTip("CloudCMA"),
      cta: true,
      slug: "",
    },
    {
      id: 2,
      title: "Neutrino",
      stats: 5,
      textPercentage: "",
      text: "",
      color: "#34C28F",
      toolTipText: getToolsLandingPageToolTip("Neutrino"),
      cta: false,
    },
    {
      id: 3,
      title: "ActivePipe",
      stats: 15,
      textPercentage: "",
      text: "",
      color: "#34C28F",
      toolTipText: getToolsLandingPageToolTip("ActivePipe"),
      cta: false,
    },
    {
      id: 4,
      title: "Maxa",
      stats: 14,
      textPercentage: "",
      text: "",
      color: "#34C28F",
      toolTipText: getToolsLandingPageToolTip("Maxa"),
      cta: false,
    },
    {
      id: 5,
      title: "Homespotter",
      stats: 1,
      textPercentage: "",
      text: "",
      color: "#34C28F",
      toolTipText: getToolsLandingPageToolTip("Homespotter"),
      cta: false,
    },
    {
      id: 6,
      title: "Xpressdocs",
      stats: 17,
      textPercentage: "",
      text: "",
      color: "#34C28F",
      toolTipText: getToolsLandingPageToolTip("Xpressdocs"),
      cta: false,
    },
    // {
    //   id: 7,
    //   title: "CRM",
    //   stats: 46,
    //   textPercentage: 3,
    //   text: "",
    //   color: "#34C28F",
    //   toolTipText: getToolsLandingPageToolTip("CRM"),
    //   cta: false,
    // },
  ];

  useEffect(() => {
    getCmaData();
    getActivePipeData();
    getNeutrinoData();
  }, []);

  function getCmaData() {
    setDataLoadingState("isCmaDataLoading", true);
    http
      .get("/tools/overall-cloudcma-engagement-score.json")
      .then((response: any) => {
        const cloudcmaEngagementScore =
          response?.data?.cloudcma_engagement?.overall_engagement_score ?? 0;
        setCardData((prev: any) => ({
          ...prev,
          cma: {
            id: "cma",
            title: "CMA",
            stats: cloudcmaEngagementScore,
            textPercentage: "",
            text: "",
            color: "#34C28F",
            toolTipText: getToolsLandingPageToolTip("Cloud CMA"),
            cta: true,
            slug: "cma",
          },
        }));
        setDataLoadingState("isCmaDataLoading", false);
      })
      .catch((error: any) => {
        setDataLoadingState("isCmaDataLoading", false);
        console.log(error);
      });
  }

  function getActivePipeData() {
    setDataLoadingState("isActivePipeDataLoading", true);
    http
      .get("/tools/overall-email-marketing-engagement-score.json")
      .then((response: any) => {
        const activePipeScore =
          response?.data?.email_engagement?.overall_engagement_score ?? 0;
        setCardData((prev: any) => ({
          ...prev,
          activePipe: {
            id: "activePipe",
            title: "ActivePipe",
            stats: activePipeScore,
            textPercentage: "",
            text: "",
            color: "#34C28F",
            toolTipText: getToolsLandingPageToolTip("ActivePipe"),
            cta: true,
          },
        }));
        setDataLoadingState("isActivePipeDataLoading", false);
      })
      .catch((error: any) => {
        setDataLoadingState("isActivePipeDataLoading", false);
        console.log(error);
      });
  }
  function getNeutrinoData() {
    setDataLoadingState("isNeutrinoDataLoading", true);
    http
      .get("/tools/overall-website-engagement-score.json")
      .then((response: any) => {
        const neutrinoEngagementScore =
          response?.data?.website_engagement?.overall_engagement_score ?? 0;
        setCardData((prev: any) => ({
          ...prev,
          neutrino: {
            id: "neutrino",
            title: "Neutrino",
            stats: neutrinoEngagementScore,
            textPercentage: "",
            text: "",
            color: "#34C28F",
            toolTipText: getToolsLandingPageToolTip("Neutrino"),
            cta: true,
          },
        }));
        setDataLoadingState("isNeutrinoDataLoading", false);
      })
      .catch((error: any) => {
        setDataLoadingState("isNeutrinoDataLoading", false);
        console.log(error);
      });
  }
  /**
   * Modal close emit
   * Common for all typeof modal.
   *
   * @returns {void}
   */
  function modalCloseEmit(data: any) {
    setModals((prevState) => ({
      ...prevState,
      [data.modalName]: false,
    }));
  }

  function cardDetailsModalOpen(data: any) {
    setCardId(data);
    setModals((prevState) => ({
      ...prevState,
      cardsDetailsModal: true,
    }));
  }

  function activePipeCardModalOpen() {
    setModals((prevState) => ({
      ...prevState,
      activePipeModal: true,
    }));
  }

  function neutrinoCardModalOpen() {
    setModals((prevState) => ({
      ...prevState,
      neutrinoModal: true,
    }));
  }

  function cmaCardModalOpen() {
    setModals((prevState) => ({
      ...prevState,
      cmaModal: true,
    }));
  }

  function setDataLoadingState(fieldName: string, value: boolean) {
    setIsDataLoading((prev: any) => ({
      ...prev,
      [fieldName]: value,
    }));
  }

  return (
    <>
      {modals.cardsDetailsModal && (
        <CategoryCardsDetailsModal
          modalStatus={modals.cardsDetailsModal}
          modalName="cardsDetailsModal"
          cardIdData={cardId}
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
      {modals.activePipeModal && (
        <ActivePipeCardDetailsModal
          modalStatus={modals.activePipeModal}
          modalName="activePipeModal"
          // cardIdData={cardId}
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
      {modals.cmaModal && (
        <CmaCardDetailsModal
          modalStatus={modals.cmaModal}
          modalName="cmaModal"
          // cardIdData={cardId}
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
      {modals.neutrinoModal && (
        <NeutrinoCardDetailsModal
          modalStatus={modals.neutrinoModal}
          modalName="neutrinoModal"
          // cardIdData={cardId}
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
      <div className="row row-gap-3 mb-3">
        {/* {!isObjectEmpty(cardData?.activePipe) && (
          <SingleCategoryCard
            key={cardData?.activePipe?.id}
            cardItem={cardData?.activePipe}
            cardDetailsModalEmit={(id: any) => console.log({ id })}
          />
        )} */}
        <SingleCategoryCard
          key={cardData?.activePipe?.id}
          cardItem={cardData?.activePipe}
          isDataLoadingProps={isDataLoading?.isActivePipeDataLoading}
          cardDetailsModalEmit={(id: any) => activePipeCardModalOpen()}
        />

        <SingleCategoryCard
          key={cardData?.neutrino?.id}
          cardItem={cardData?.neutrino}
          isDataLoadingProps={isDataLoading?.isNeutrinoDataLoading}
          cardDetailsModalEmit={(id: any) => neutrinoCardModalOpen()}
        />

        <SingleCategoryCard
          key={cardData?.cma?.id}
          cardItem={cardData?.cma}
          isDataLoadingProps={isDataLoading?.isCmaDataLoading}
          cardDetailsModalEmit={(id: any) => cmaCardModalOpen()}
        />

        {/* {dummyData?.length &&
          dummyData?.map((item: any) => (
            <SingleCategoryCard
              key={item?.id}
              cardItem={item}
              cardDetailsModalEmit={(id: any) => cardDetailsModalOpen(id)}
            />
          ))} */}
      </div>
    </>
  );
}

export default MultipleCategoryCards;
