import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDestroyEmergingMarketMutation, useListEmergingMarketsQuery } from '../../../state/api';
import Pagination from '../../common/Pagination/Pagination';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import IconButton from '../../common/IconButton/IconButton';

export const deleteErrorMessage = 'Error deleting emerging market.';
export const deleteSuccessMessage = 'Successfully deleted emerging market.';

const ManageEmergingMarkets = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [
    emergingMarketPendingDeletion,
    setEmergingMarketPendingDeletion,
  ] = useState(undefined as string | undefined);
  const [page, setPage] = useState(1);
  const emergingMarkets = useListEmergingMarketsQuery({ limit: 10, offset: (page - 1) * 10 });
  const [trigger] = useDestroyEmergingMarketMutation();

  const openModal = useCallback((id: string) => {
    setEmergingMarketPendingDeletion(id);
    setConfirmOpen(true);
  }, [setEmergingMarketPendingDeletion]);

  useEffect(() => {
    if (!confirmOpen) {
      setEmergingMarketPendingDeletion(undefined);
    }
  }, [confirmOpen, setEmergingMarketPendingDeletion]);

  const deleteEmergingMarket = useCallback(() => trigger({ id: emergingMarketPendingDeletion! })
    .unwrap()
    .then(() => toast.dark(deleteSuccessMessage, { type: 'success' }))
    .catch(() => toast.dark(deleteErrorMessage, { type: 'error' })),
  [emergingMarketPendingDeletion, trigger]);

  let table;
  if (emergingMarkets.isSuccess && emergingMarkets.data!.count! > 0) {
    table = (
      <Table>
        <thead>
          <tr className="font-weight-semibold bg-light">
            <th>Name</th>
            <th>MLS</th>
            <th>Created on</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {
            emergingMarkets.data!.results!.map((it) => (
              <tr key={it.id}>
                <td className="text-dark font-weight-semibold">
                  <a href={`/market/${it.id}`}>{it.name}</a>
                </td>
                <td>{it.mls_name}</td>
                <td>{it.created_on!.split('T')[0]}</td>
                <td>
                  <a
                    href={`/edit-emerging-market/${it.id}`}
                    className="btn btn-outline-primary"
                  >
                    <EditIcon />
                  </a>
                </td>
                <td>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => openModal(it.id!)}
                    data-testid="deleteButton"
                    type="button"
                  >
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    );
  } else {
    table = (
      <div>
        Looks like you haven&apos;t created any emerging markets yet. Click the + button to do so.
      </div>
    );
  }

  return (
    <>
      <ConfirmationDialog
        acceptText="Delete"
        body="Are you sure that you want to delete this Emerging Market? You can't undo this."
        open={confirmOpen}
        rejectText="Cancel"
        setOpen={setConfirmOpen}
        submitOnClick={deleteEmergingMarket}
        title="Delete Emerging Market?"
      />
      <Card>
        <CardBody>
          <div className="d-flex mb-4">
            <div className="mt-2 me-4 h4 fs-2 card-title">Emerging Markets</div>
            <IconButton destination="/edit-emerging-market">
              <AddIcon />
            </IconButton>
          </div>
          {table}
          <LoadingIcon className="mb-2 mt-4" loading={!emergingMarkets.isSuccess} />
          <Pagination
            count={emergingMarkets.isSuccess ? emergingMarkets.data!.count! : 0}
            elementsPerPage={10}
            page={page}
            setPage={(pageNumber: number) => setPage(pageNumber)}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default ManageEmergingMarkets;
