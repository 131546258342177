import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Card, CardBody, Table } from 'reactstrap';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import defaultAvatarSvg from '../../../../assets/images/default-avatar.svg';
import starSvg from '../../../../assets/images/star.svg';
import { gray300 } from '../../../../assets/colors';
import starTransparentSvg from '../../../../assets/images/starTransparent.svg';
import Tooltip from '../../../common/Tooltip';
import Pagination from '../../../common/Pagination/Pagination';
import HelpIcon from '../../../common/HelpIcon';

const styles = createUseStyles({
  ListingDataTable_clickableTableHeader_mobile: {
    cursor: 'pointer',
    '&:hover': {
      'color': 'rgb(239, 242, 247)',
    },
    'paddingTop': '20px',
  },
  ListingDataTable_clickableTableHeader: {
    'whiteSpace': 'nowrap',
    cursor: 'pointer',
    '&:hover': {
      'color': 'rgb(239, 242, 247)',
    },
  },
  ListingDataTable_row: {
    'transition': '.25s',
    '&:hover': {
      'color': 'white',
      'background-color': gray300,
    },
  },
});

const AgentBreakdown = () => {
  const classes = styles();
  const [page, setPage] = useState(1);
  const isMobile = window.screen.width <= 998;

  const agentBreakdown = [
    {
      id: 1,
      agent: 'David McHenry',
      contacts: '500',
      loginPerMo: '0',
      sendsPerMo: '0',
      openRate: '0%',
      CTR: <img src={starTransparentSvg} alt="" />,
    },
    {
      id: 2,
      agent: 'Darlene Robertson',
      contacts: '300',
      loginPerMo: '2',
      sendsPerMo: '2',
      openRate: '2%',
      CTR: <img src={starTransparentSvg} alt="" />,
    },
    {
      id: 3,
      agent: 'Cody Fisher',
      contacts: '20000',
      loginPerMo: '10',
      sendsPerMo: '10',
      openRate: '10%',
      CTR: <img src={starTransparentSvg} alt="" />,
    },
    {
      id: 4,
      agent: 'Ralph Edwards',
      contacts: '100',
      loginPerMo: '100',
      sendsPerMo: '100',
      openRate: '100%',
      CTR: <img src={starTransparentSvg} alt="" />,
    },
    {
      id: 5,
      agent: 'Jacob Jones',
      contacts: '0',
      loginPerMo: '55',
      sendsPerMo: '55',
      openRate: '55%',
      CTR: <img src={starTransparentSvg} alt="" />,
    },
  ];
  const rows = agentBreakdown.map((it) => (
    <tr key={it.id} className={classes.ListingDataTable_row}>
      <td className="text-dark font-weight-semibold">
        <div className="d-flex gap-1 align-items-center">
          <img className="rounded-circle" src={defaultAvatarSvg} alt="" height="32" />
          <div>{it.agent}</div>
        </div>
      </td>
      <td className="pt-4">{it.contacts}</td>
      <td className="pt-4">{it.loginPerMo}</td>
      <td className="pt-4">{it.sendsPerMo}</td>
      <td className="pt-4">{it.openRate}</td>
      <td className="pt-4">{it.CTR}</td>
    </tr>
  ));
  const rowsMobile1 = (
    <>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6 pt-4">Agent</td>
        <td className="text-muted h6">
          <div className="text-dark font-weight-semibold d-flex gap-2 align-items-center">
            <img className="rounded-circle mt-1" src={defaultAvatarSvg} alt="" height="32" />
            <div className="text-muted fs-6 pt-1">{agentBreakdown[0].agent}</div>
          </div>
        </td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Buy or Sell Side</td>
        <td className="text-muted h6">{agentBreakdown[0].contacts}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Average Sides</td>
        <td className="text-muted h6">{agentBreakdown[0].loginPerMo}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Average Sale Price</td>
        <td className="text-muted h6">{agentBreakdown[0].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Lead Score</td>
        <td className="text-muted h6">{agentBreakdown[0].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6"><img src={starSvg} alt="" /></td>
        <td className="text-muted h6">
          <img src={starTransparentSvg} alt="" />
        </td>
      </tr>
    </>
  );
  const rowsMobile2 = (
    <>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6 pt-4">Agent</td>
        <td className="text-muted h6">
          <div className="text-dark font-weight-semibold d-flex gap-2 align-items-center">
            <img className="rounded-circle mt-1" src={defaultAvatarSvg} alt="" height="32" />
            <div className="text-muted fs-6 pt-1">{agentBreakdown[1].agent}</div>
          </div>
        </td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Buy or Sell Side</td>
        <td className="text-muted h6">{agentBreakdown[1].contacts}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Average Sides</td>
        <td className="text-muted h6">{agentBreakdown[1].loginPerMo}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Average Sale Price</td>
        <td className="text-muted h6">{agentBreakdown[1].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Lead Score</td>
        <td className="text-muted h6">{agentBreakdown[1].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6"><img src={starSvg} alt="" /></td>
        <td className="text-muted h6">
          <img src={starTransparentSvg} alt="" />
        </td>
      </tr>
    </>
  );
  const rowsMobile3 = (
    <>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6 pt-4">Agent</td>
        <td className="text-muted h6">
          <div className="text-dark font-weight-semibold d-flex gap-2 align-items-center">
            <img className="rounded-circle mt-1" src={defaultAvatarSvg} alt="" height="32" />
            <div className="text-muted fs-6 pt-1">{agentBreakdown[2].agent}</div>
          </div>
        </td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Buy or Sell Side</td>
        <td className="text-muted h6">{agentBreakdown[2].contacts}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Average Sides</td>
        <td className="text-muted h6">{agentBreakdown[2].loginPerMo}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Average Sale Price</td>
        <td className="text-muted h6">{agentBreakdown[2].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Lead Score</td>
        <td className="text-muted h6">{agentBreakdown[2].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6"><img src={starSvg} alt="" /></td>
        <td className="text-muted h6">
          <img src={starTransparentSvg} alt="" />
        </td>
      </tr>
    </>
  );
  const rowsMobile4 = (
    <>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6 pt-4">Agent</td>
        <td className="text-muted h6">
          <div className="text-dark font-weight-semibold d-flex gap-2 align-items-center">
            <img className="rounded-circle mt-1" src={defaultAvatarSvg} alt="" height="32" />
            <div className="text-muted fs-6 pt-1">{agentBreakdown[3].agent}</div>
          </div>
        </td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Buy or Sell Side</td>
        <td className="text-muted h6">{agentBreakdown[3].contacts}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Average Sides</td>
        <td className="text-muted h6">{agentBreakdown[3].loginPerMo}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Average Sale Price</td>
        <td className="text-muted h6">{agentBreakdown[3].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Lead Score</td>
        <td className="text-muted h6">{agentBreakdown[3].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6"><img src={starSvg} alt="" /></td>
        <td className="text-muted h6">
          <img src={starTransparentSvg} alt="" />
        </td>
      </tr>
    </>
  );
  const rowsMobile5 = (
    <>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6 pt-4">Agent</td>
        <td className="text-muted h6">
          <div className="text-dark font-weight-semibold d-flex gap-2 align-items-center">
            <img className="rounded-circle mt-1" src={defaultAvatarSvg} alt="" height="32" />
            <div className="text-muted fs-6 pt-1">{agentBreakdown[4].agent}</div>
          </div>
        </td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Buy or Sell Side</td>
        <td className="text-muted h6">{agentBreakdown[4].contacts}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Average Sides</td>
        <td className="text-muted h6">{agentBreakdown[4].loginPerMo}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Average Sale Price</td>
        <td className="text-muted h6">{agentBreakdown[4].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6">Lead Score</td>
        <td className="text-muted h6">{agentBreakdown[4].openRate}</td>
      </tr>
      <tr className={classes.ListingDataTable_row}>
        <td className="text-dark font-weight-semibold text-nowrap h6"><img src={starSvg} alt="" /></td>
        <td className="text-muted h6">
          <img src={starTransparentSvg} alt="" />
        </td>
      </tr>
    </>
  );

  return (
    <>
      <Tooltip />
      <Card className="table-responsive">
        <CardBody className="p-0">
          <div className="d-flex mb-4 justify-content-between">
            <div className="me-4 h4 fs-2 card-title my-2 lh-lg">Agent Opportunities
              <HelpIcon tooltip="The agent opportunities table is designed to help the brokerage staff look
                at baseline financial metrics, an agent’s likelihood to move and ultimately select the agents that
                they’re interested in recruiting."
              />
            </div>
          </div>
          {isMobile ? (
            <div className={classes.ListingDataTable_clickableTableHeader_mobile}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex justify-content-between w-75 align-items-center">
                    <Typography>{agentBreakdown[0].agent}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Table className="bg-transparent">
                      <thead />
                      <tbody>
                        {rowsMobile1}
                      </tbody>
                    </Table>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex justify-content-between w-75 align-items-center">
                    <Typography>{agentBreakdown[1].agent}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Table className="bg-transparent">
                      <thead />
                      <tbody>
                        {rowsMobile2}
                      </tbody>
                    </Table>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex justify-content-between w-75 align-items-center">
                    <Typography>{agentBreakdown[2].agent}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Table className="bg-transparent">
                      <thead />
                      <tbody>
                        {rowsMobile3}
                      </tbody>
                    </Table>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex justify-content-between w-75 align-items-center">
                    <Typography>{agentBreakdown[3].agent}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Table className="bg-transparent">
                      <thead />
                      <tbody>
                        {rowsMobile4}
                      </tbody>
                    </Table>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex justify-content-between w-75 align-items-center">
                    <Typography>{agentBreakdown[4].agent}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Table className="bg-transparent">
                      <thead />
                      <tbody>
                        {rowsMobile5}
                      </tbody>
                    </Table>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          ) : (
            <Table>
              <thead>
                <tr className="font-weight-semibold bg-light">
                  <th
                    className={classes.ListingDataTable_clickableTableHeader}
                  >
                    Agent
                  </th>
                  <th
                    className={classes.ListingDataTable_clickableTableHeader}
                  >
                    Buy or Sell Side
                  </th>
                  <th
                    className={classes.ListingDataTable_clickableTableHeader}
                  >
                    Average Sides
                  </th>
                  <th
                    className={classes.ListingDataTable_clickableTableHeader}
                  >
                    Average Sale Price
                  </th>
                  <th
                    className={classes.ListingDataTable_clickableTableHeader}
                  >
                    Lead Score
                  </th>
                  <th
                    className={classes.ListingDataTable_clickableTableHeader}
                  >
                    <img src={starSvg} alt="" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </Table>
          )}
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <p>Showing 1 to 5 of 57 entries</p>
            <div className="overflow-hidden">
              <Pagination
                count={57}
                elementsPerPage={3}
                page={page}
                setPage={setPage}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AgentBreakdown;
