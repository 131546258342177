/* eslint-disable no-multiple-empty-lines */
const generateListingFinancialDataByYear = (year: number, theData: any): Record<string, any> => {
  const result: Record<string, any> = {
    year,
    months: [],
    last_12_total_listings: [],
    previous_period_total_listings: [],
    last_12_total_sold_listings: [],
    previous_period_total_sold_listings: [],
    last_12_average_list_price: [],
    previous_period_average_list_price: [],
    categories: [],
  };
  const filteredData = theData
    .sort((a:any, b:any) => {
      // Compare by year
      if (parseFloat(a.the_year) !== parseFloat(b.the_year)) {
        return (parseFloat(a.the_year) - parseFloat(b.the_year));
      }
      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_short_name] - monthOrder[b.month_short_name];
    });
    
  filteredData.forEach((entry:any) => {
    const yearSplit = String(entry.the_year).slice(-2);
    const formattedCategories = `${entry.month_short_name} ${yearSplit}`;
    result.months.push(entry?.month_short_name);
    result.last_12_total_listings.push(entry.last_12_total_listings);
    result.previous_period_total_listings.push(entry.previous_period_total_listings);
    result.last_12_total_sold_listings.push(entry.last_12_total_sold_listings);
    result.previous_period_total_sold_listings.push(entry.previous_period_total_sold_listings);
    result.last_12_average_list_price.push(entry.last_12_average_list_price / 1000);
    result.previous_period_average_list_price.push(entry.previous_period_average_list_price / 1000);
    result.categories.push(formattedCategories);
  });

  return result;
};

const generateSellSideFinancialDataByYear = (year: number, theData: any): Record<string, any> => {
  const result: Record<string, any> = {
    year,
    months: [],
    last_12_gci: [],
    previous_period_gci: [],
    last_12_list_sides: [],
    previous_period_list_sides: [],
    last_12_sell_sides: [],
    previous_period_sell_sides: [],
    last_12_company_dollar: [],
    previous_period_company_dollar: [], // Corrected the typo here
    last_12_avg_closed_price: [],
    pp_avg_closed_price: [],
    categories: [],
  };

  const filteredData = theData
    .sort((a:any, b:any) => {
      // Compare by year
      if (parseFloat(a.the_year) !== parseFloat(b.the_year)) {
        return (parseFloat(a.the_year) - parseFloat(b.the_year));
      }
      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_short_name] - monthOrder[b.month_short_name];
    });

  // const filteredData = theData
  //   .filter((entry: any) => entry.the_year === year.toString())
  //   .sort((a: any, b: any) => {
  //     const monthOrder: any = {
  //       Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
  //     };

  //     return monthOrder[a.short_month_name] - monthOrder[b.short_month_name];
  //   });

  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry.the_year).slice(-2);
    const formattedCategories = `${entry.month_short_name} ${yearSplit}`;

    result.months.push(entry.month_short_name);
    result.last_12_gci.push(entry.last_12_gci / 1000);
    result.previous_period_gci.push(entry.previous_period_gci / 1000);
    result.last_12_list_sides.push(entry.last_12_list_sides);
    result.previous_period_list_sides.push(entry.previous_period_list_sides);
    result.last_12_sell_sides.push(entry.last_12_sell_sides);
    result.previous_period_sell_sides.push(entry.previous_period_sell_sides);
    result.last_12_company_dollar.push(entry.last_12_company_dollar / 1000);
    result.previous_period_company_dollar.push(entry.previous_period_company_dollar / 1000);
    result.last_12_avg_closed_price.push(entry.last_12_avg_closed_price / 1000);
    result.pp_avg_closed_price.push(entry.pp_avg_closed_price / 1000);
    result.categories.push(formattedCategories);
  });

  return result;
};

const generateEngagementDataByOffice = (theData: any): Record<string, any> => {
  const result: Record<string, any> = {
    months: [],
    avgcloudcmascore: [],
    avgemailmarketingscore: [],
    avgwebsitescore: [],
    categories: [],
  };

  const filteredData = theData
    .sort((a:any, b:any) => {
      // Compare by year
      if (parseFloat(a.year) !== parseFloat(b.year)) {
        return (parseFloat(a.year) - parseFloat(b.year));
      }
      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_short_name] - monthOrder[b.month_short_name];
    }).slice(-15);

  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry.year).slice(-2);
    result.months.push(entry?.month_short_name);
    result.avgcloudcmascore.push(entry.avgcloudcmascore);
    result.avgemailmarketingscore.push(entry.avgemailmarketingscore);
    result.avgwebsitescore.push(entry.avgwebsitescore);
    result.categories.push(`${entry?.month_short_name} ${yearSplit}`);
  });

  return result;
};

const generateByOfficeToolsCmaPieChartData = (data:any) => {
  const result:any = {
    reportPercentageData: [],
    reportCountData: [],
    reportLabels: [],
  };
 
  // eslint-disable-next-line no-unused-expressions
  data?.length && data.forEach((item:any) => {
    result.reportLabels.push(item.report_type); // Add report_type to labels array
    result.reportCountData.push(item.report_count); // Add report_count to series array
    result.reportPercentageData.push(item.report_type_percentage); // Add report_count to series array
  });
  return result;
};

const generateByOfficeToolsNeutrinoPieChartData = (data:any) => {
  const result:any = {
    reportPercentageData: [],
    reportCountData: [],
    reportLabels: [],
  };
 
  // eslint-disable-next-line no-unused-expressions
  data?.length && data.forEach((item:any) => {
    result.reportLabels.push(item.modified_type); // Add report_type to labels array
    result.reportCountData.push(item.type_count); // Add type_count to series array
    result.reportPercentageData.push(item.type_percentage); // Add report_count to series array
  });
  return result;
};

const generateCompletedRecommendationData = (data: any): Record<string, any> => {
  const result: Record<string, any> = {
    months: [],
    total_recommendation: [],
    total_completed: [],
    categories: [],
  };
  const filteredData = data
    .sort((a:any, b:any) => {
      // Compare by year
      if (parseFloat(a.year) !== parseFloat(b.year)) {
        return (parseFloat(a.year) - parseFloat(b.year));
      }
      // If years are the same, compare by month
      const monthOrder: any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_name] - monthOrder[b.month_name];
    });

  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry.year ?? "").slice(-2);
    const formattedCategories = `${entry.month_name ?? ""} ${yearSplit}`;
    result.months.push(entry.month_name ?? "");
    result.total_recommendation.push(entry.total_recommendation ?? "");
    result.total_completed.push(entry.total_completed ?? "");
    result.categories.push(formattedCategories);
  });
    
  return result;
};

const generateNinjaAgentQuartitleData = (data: any) => {
  const result:any = {
    ninja: [],
    non_Ninja: [],
  };

  data.forEach((entry:any) => {
    const categoryName = entry.ninja_sales;
    const { quartile } = entry;
    const activeAgentsCount = entry.active_agents_count;
  
    // Push the value into the corresponding array based on the product_tool
    switch (categoryName) {
      case "Ninja":
        result.ninja[quartile - 1] = activeAgentsCount;
        break;
      case "non-Ninja":
        result.non_Ninja[quartile - 1] = activeAgentsCount;
        break;
      default:
        break;
    }
  });
  
  return result;
};

const generateNinjaAgentSalesVolumeActivityData = (data: any) => {
  const result:any = {
    ninja: [] as any,
    non_Ninja: [],
  };

  data.forEach((entry:any) => {
    const categoryName = entry.ninja_status;
    const { quartile } = entry;
    const avgSalesVolume:any = entry.avg_sales_volume;
  
    // Push the value into the corresponding array based on the product_tool
    switch (categoryName) {
      case "Ninja":
        { 
          const formattedValue:any = (avgSalesVolume);
          result.ninja[quartile - 1] = formattedValue;
        }
        break;
      case "non-Ninja":
        { 
          const formattedValue:any = (avgSalesVolume);
          result.non_Ninja[quartile - 1] = formattedValue;
        }
        
        break;
      default:
        break;
    }
  });
  
  return result;
};

export { generateByOfficeToolsCmaPieChartData, generateByOfficeToolsNeutrinoPieChartData, generateCompletedRecommendationData, generateEngagementDataByOffice, generateListingFinancialDataByYear, generateNinjaAgentQuartitleData, generateNinjaAgentSalesVolumeActivityData, generateSellSideFinancialDataByYear };

