// import HelpIcon from "@components/common/HelpIcon";
// import SearchSelect from "@components/common/SearchSelect";
// import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
// import { agentToolTip } from "@constants/tooltip-data";
import generateLineChartOptions from "@utils/chart/line-chart";
import { http } from "@utils/http";
import {
  generateFinancialDataByYear,
  generateListingMetricDataByYear,
  generateTicks,
} from "@utils/transform/financial";
import { useEffect, useState } from "react";
// import ReactApexChart from "react-apexcharts";
import PermissionCheck from "@components/common/PermissionCheck";
import { agentPerms } from "@constants/permission";
import { actionUserPermissions, selectUser } from "@state/appSlice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import AgentAvgListVsSoldPriceChart from "./AgentAvgListVsSoldPriceChart";
import AgentGciVsCompanyChart from "./AgentGciVsCompanyChart";
import AgentListVsSellSideChart from "./AgentListVsSellSideChart";
import AgentTotalVsSoldListingsChart from "./AgentTotalVsSoldListingsChart";

function ComperativeFinancial({ agentId }: any) {
  const dispatch = useAppDispatch();
  const selectedUser: any = useAppSelector(selectUser);
  const [isPermissionDataLoading, setIsPermissionDataLoading] = useState(true);

  const [monthOptions, setMonthOptions] = useState([
    {
      id: 1,
      title: "Last 3 Months",
      value: 3,
    },
    {
      id: 2,
      title: "Last 6 Months",
      value: 6,
    },
    {
      id: 3,
      title: "Last 12 Months",
      value: 12,
    },
  ] as any);
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[2] as any);
  const [financials, setFinancials] = useState({} as any);
  const [listingMetricData, setListingMetricData] = useState({} as any);
  // financial chart options state
  const [theGciOptions, setTheGciOptions] = useState({} as any);
  const [theListSidesOption, setTheListSidesOptions] = useState({} as any);
  const [theSellSidesOptions, setTheSellSidesOptions] = useState({} as any);
  const [theCompanyDollarOptions, setTheCompanyDollarOptions] = useState(
    {} as any,
  );
  const [isListingFinancialDataLoading, setIsListingFinancialDataLoading] =
    useState(true);
  // listing metrics chart options state
  const [theTotalListingOptions, setTheTotalListingOptions] = useState(
    {} as any,
  );
  const [theTotalSoldListingOption, setTheTotalSoldListingOptions] = useState(
    {} as any,
  );
  const [theAvgListPriceOptions, setTheAvgListPriceOptions] = useState(
    {} as any,
  );
  const [theAvgSellPriceOptions, setTheAvgSellPriceOptions] = useState(
    {} as any,
  );
  const [isListingMetricDataLoading, setIsListingMetricDataLoading] =
    useState(true);

  useEffect(() => {
    if (selectedUser?.id) {
      setIsPermissionDataLoading(true);
      // TODO: here call user permission api
      http
        .get(
          `user-permission-site-data.json`,
        )
        .then((response: any) => {
          const { permission } = response?.data;
          const getUserPerms = response?.data?.permission[0]?.permissions;
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: getUserPerms ?? [] }));
          setIsPermissionDataLoading(false);
        })
        .catch((error) => {
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: [] }));
          setIsPermissionDataLoading(false);
          console.log(error);
        });
    }
  }, [selectedUser]);

  // useEffect(() => {
  //   if (agentId !== "") {
  //     getFinancialReport();
  //     getListingMetricReport();
  //   }
  // }, [agentId, selectedMonth]);

  function getFinancialReport() {
    setIsListingFinancialDataLoading(true);
    http
      .get("/agent/agents-financial-report.json")
      .then((response: any) => {
        const theData: any = generateFinancialDataByYear(
          response.data.financial_report,
        );
        setFinancials(theData);
        const gciChartOptions: any = generateLineChartOptions({
          theId: "line-chart-gci",
          colors: ["#CF4AFE", "#EFC2FF"],
          categories: theData?.categories,
          ticks: generateTicks(theData?.last_12_gci ?? []),
          ticksPreLast: ["$", " k"],
        });
        setTheGciOptions(gciChartOptions);

        const listSidesChartOptions: any = generateLineChartOptions({
          theId: "line-chart-list-sides",
          colors: ["#E98B48", "#FFBE90"],
          categories: theData?.categories,
          ticks: generateTicks(theData?.last_12_list_sides ?? []),
          ticksPreLast: [],
          gridPL: 10,
        });
        setTheListSidesOptions(listSidesChartOptions);

        const sellSidesChartOptions: any = generateLineChartOptions({
          theId: "line-chart-sell-sides",
          colors: ["#6BF2F2", "#B6FFFF"],
          categories: theData?.categories,
          ticks: generateTicks(theData?.last_12_sell_sides ?? []),
          ticksPreLast: [],
          gridPL: 10,
        });
        setTheSellSidesOptions(sellSidesChartOptions);

        const comapnyDollarChartOptions: any = generateLineChartOptions({
          theId: "line-chart-comapany-dollar",
          colors: ["#F2CA6B", "#FFF5DE"],
          categories: theData?.categories,
          ticks: generateTicks(theData?.last_12_company_dollar ?? []),
          ticksPreLast: ["$", " k"],
        });
        setTheCompanyDollarOptions(comapnyDollarChartOptions);
        setIsListingFinancialDataLoading(false);
      });
  }

  function getListingMetricReport() {
    setIsListingMetricDataLoading(true);
    http
      .get("/agent/listing-metric", {
        params: {
          agent_id: agentId,
          per_page: 500,
          months: selectedMonth?.value,
        },
      })
      .then((response: any) => {
        const theData: any = generateListingMetricDataByYear(
          2023,
          response?.data?.listing_metric,
        );
        setListingMetricData(theData);

        const totalListingChartOptions: any = generateLineChartOptions({
          theId: "line-chart-total-listings",
          colors: ["#CF4AFE", "#EFC2FF"],
          categories: theData?.categories,
          ticks: generateTicks(theData?.last_12_total_listings ?? []),
          ticksPreLast: [],
          gridPL: 10,
          // ticksPreLast: ['$', ' k'],
        });
        setTheTotalListingOptions(totalListingChartOptions);

        const totalSoldListingChartOptions: any = generateLineChartOptions({
          theId: "line-chart-sold-list",
          colors: ["#F2CA6B", "#FFF5DE"],
          categories: theData?.categories,
          ticks: generateTicks(theData?.last_12_total_sold_listings ?? []),
          ticksPreLast: [],
          gridPL: 10,
        });
        setTheTotalSoldListingOptions(totalSoldListingChartOptions);

        const avgListPriceChartOptions: any = generateLineChartOptions({
          theId: "line-chart-avg-list-price",
          colors: ["#E98B48", "#FFBE90"],
          categories: theData?.categories,
          ticks: generateTicks(theData?.last_12_avg_list_price ?? []),
          ticksPreLast: ["$", " k"],
        });
        setTheAvgListPriceOptions(avgListPriceChartOptions);

        const avgSellPriceChartOptions: any = generateLineChartOptions({
          theId: "line-chart-avg-sell-price",
          colors: ["#6BF2F2", "#B6FFFF"],
          categories: theData?.categories,
          ticks: generateTicks(theData?.last_12_avg_sell_price ?? []),
          ticksPreLast: ["$", " k"],
        });
        setTheAvgSellPriceOptions(avgSellPriceChartOptions);
        setIsListingMetricDataLoading(false);
      });
  }

  return (
    <>
      <div className="row mb-3 custom-parent--row">
        <PermissionCheck
          permission={`${agentPerms.agent_modal_gci_company_dollar_chart}`}
        >
          <div className="col-12 col-lg-6 mb-2 mt-1 custom-column">
            <div
              className="custom-modal-inner__padding h-100"
              style={{ backgroundColor: "#2B3041" }}
            >
              <AgentGciVsCompanyChart agentIdProps={agentId} />
            </div>
          </div>
        </PermissionCheck>
        <PermissionCheck
          permission={`${agentPerms.agent_modal_list_sell_side_chart}`}
        >
          <div className="col-12 col-lg-6 mb-2 mt-1 custom-column">
            <div
              className="custom-modal-inner__padding h-100"
              style={{ backgroundColor: "#2B3041" }}
            >
              <AgentListVsSellSideChart agentIdProps={agentId} />
            </div>
          </div>
        </PermissionCheck>
        <PermissionCheck
          permission={`${agentPerms.agent_modal_total_listings_sold_listings_chart}`}
        >
          <div className="col-12 col-lg-6 mb-2 mt-1 custom-column">
            <div
              className="custom-modal-inner__padding h-100"
              style={{ backgroundColor: "#2B3041" }}
            >
              <AgentTotalVsSoldListingsChart agentIdProps={agentId} />
            </div>
          </div>
        </PermissionCheck>
        <PermissionCheck
          permission={`${agentPerms.agent_modal_avg_lists_solds_chart}`}
        >
          <div className="col-12 col-lg-6 mb-2 mt-1 custom-column">
            <div
              className="custom-modal-inner__padding h-100"
              style={{ backgroundColor: "#2B3041" }}
            >
              <AgentAvgListVsSoldPriceChart agentIdProps={agentId} />
            </div>
          </div>
        </PermissionCheck>
      </div>
      {/* <div className="mb-4" style={{ backgroundColor: "#2B3041" }}>
        <div className="custom-modal-inner__padding">
          <div className="row row-gap-4 row-gap-lg-0">
            <div className="row mb-3">
              <div className="col-6 col-lg-7 pe-0">
                <div className="header-title">Comparative Financials</div>
              </div>
              <div className="col-6 col-lg-5 pe-0 d-inline-flex justify-content-end">
                <SearchSelect
                  options={monthOptions}
                  value={
                    JSON.stringify(selectedMonth) !== "{}"
                      ? selectedMonth
                      : "Select a Month"
                  }
                  onChange={(option: any) => setSelectedMonth(option)}
                  // isClearable
                  // width="190px"
                  getOptionValue={(option: any) => option.value}
                  getOptionLabel={(option: any) => `${option.title}`}
                  placeholder="Select Months"
                />
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title mb-0 fw-medium">GCI</p>
                <HelpIcon tooltip={agentToolTip.gci_chart} />
              </div>
              <div>
                {isListingFinancialDataLoading && (
                  <div className="loader-circle"></div>
                )}
                {isListingFinancialDataLoading ? (
                  <DefaultLineChartUI />
                ) : (
                  <div id="line-chart-gci" dir="ltr">
                    <ReactApexChart
                      series={[
                        {
                          name: `${selectedMonth?.title}`,
                          data: financials?.last_12_gci,
                        },
                        {
                          name: "Previous Period",
                          data: financials?.previous_period_gci,
                        },
                      ]}
                      options={theGciOptions}
                      type="line"
                      height={320}
                      className="apex-charts"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title mb-0 fw-medium">Company Dollar</p>
                <HelpIcon tooltip={agentToolTip?.company_dollar_chart} />
              </div>
              {isListingFinancialDataLoading && (
                <div className="loader-circle"></div>
              )}
              {isListingFinancialDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-company-dollar" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: financials?.last_12_company_dollar,
                      },
                      {
                        name: "Previous Period",
                        data: financials?.previous_period_company_dollar,
                      },
                    ]}
                    options={theCompanyDollarOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>

            <div className="col-12 col-lg-6" style={{ marginTop: "30px" }}>
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title mb-0 fw-medium">List sides</p>
                <HelpIcon tooltip={agentToolTip?.list_sides_chart} />
              </div>
              {isListingFinancialDataLoading && (
                <div className="loader-circle"></div>
              )}
              {isListingFinancialDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-list-sides" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: financials?.last_12_list_sides,
                      },
                      {
                        name: "Previous Period",
                        data: financials?.previous_period_list_sides,
                      },
                    ]}
                    options={theListSidesOption}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>

            <div className="col-12 col-lg-6" style={{ marginTop: "30px" }}>
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title mb-0 fw-medium">Sell sides</p>
                <HelpIcon tooltip={agentToolTip?.sell_sides_chart} />
              </div>
              {isListingFinancialDataLoading && (
                <div className="loader-circle"></div>
              )}
              {isListingFinancialDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-sell-sides" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: financials?.last_12_sell_sides,
                      },
                      {
                        name: "Previous Period",
                        data: financials?.previous_period_sell_sides,
                      },
                    ]}
                    options={theSellSidesOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>

            <div className="col-12 col-lg-6" style={{ marginTop: "30px" }}>
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title mb-0 fw-medium">Total Listings</p>
                <HelpIcon tooltip={agentToolTip.total_listings_chart} />
              </div>
              {isListingMetricDataLoading && (
                <div className="loader-circle"></div>
              )}
              {isListingMetricDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-total-listings" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: listingMetricData?.last_12_total_listings,
                      },
                      {
                        name: "Previous Period",
                        data: listingMetricData?.previous_period_total_listings,
                      },
                    ]}
                    options={theTotalListingOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>

            <div className="col-12 col-lg-6" style={{ marginTop: "30px" }}>
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title mb-0 fw-medium">Sold Listings</p>
                <HelpIcon tooltip={agentToolTip.sold_listings_chart} />
              </div>
              {isListingMetricDataLoading && (
                <div className="loader-circle"></div>
              )}
              {isListingMetricDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-sold-listings" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: listingMetricData?.last_12_total_sold_listings,
                      },
                      {
                        name: "Previous Period",
                        data: listingMetricData?.previous_period_total_sold_listings,
                      },
                    ]}
                    options={theTotalSoldListingOption}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>

            <div className="col-12 col-lg-6" style={{ marginTop: "30px" }}>
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title mb-0 fw-medium">
                  Average List Price
                </p>
                <HelpIcon tooltip={agentToolTip.average_list_price_chart} />
              </div>
              {isListingMetricDataLoading && (
                <div className="loader-circle"></div>
              )}
              {isListingMetricDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-avg-list-price" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: listingMetricData?.last_12_avg_list_price,
                      },
                      {
                        name: "Previous Period",
                        data: listingMetricData?.previous_period_avg_list_price,
                      },
                    ]}
                    options={theAvgListPriceOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>

            <div className="col-12 col-lg-6" style={{ marginTop: "30px" }}>
              <div className="d-flex gap-1 align-items-center">
                <p className="header-title mb-0 fw-medium">
                  Average sold price
                </p>
                <HelpIcon tooltip={agentToolTip.average_sold_price_chart} />
              </div>
              {isListingMetricDataLoading && (
                <div className="loader-circle"></div>
              )}
              {isListingMetricDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div id="line-chart-avg-sold-price" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: `${selectedMonth?.title}`,
                        data: listingMetricData?.last_12_avg_sell_price,
                      },
                      {
                        name: "Previous Period",
                        data: listingMetricData?.previous_period_avg_sell_price,
                      },
                    ]}
                    options={theAvgSellPriceOptions}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ComperativeFinancial;
