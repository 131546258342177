import HelpIcon from "@components/common/HelpIcon";
import defaultChartOptions from "@utils/chart/default-chart-options";
import generateStackedColumnChartOptions from "@utils/chart/stacked-column-chart";
import { http } from "@utils/http";
import {
  generateRevenueData,
  generateRevenueTicks,
} from "@utils/transform/revenue";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function RevenueBreakdown({ officeId }: any) {
  const [revenueData, setRevenueData] = useState([] as any);
  const [formattedRevenueData, setFormattedRevenueData] = useState({} as any);
  const [theSCChartOptions, setTheSCChartOptions] = useState({} as any);
  const [isRevenueDataLoading, setIsRevenueDataLoading] = useState([] as any);
  useEffect(() => {
    if (officeId !== "") {
      getRevenueData();
    }
  }, [officeId]);

  function getRevenueData() {
    http
      .get("/office/gci-breakdown.json")
      .then((response: any) => {
        const apiData: any = response.data?.gci_breakdown;
        const theData: any = generateRevenueData(apiData);
        setRevenueData(apiData);
        setFormattedRevenueData(theData);
        const ticksd1 = theData?.total_gci ?? [];
        const ticksd2 = theData?.new_agent_gci ?? [];
        const stackedColumnChartOptions: any =
          generateStackedColumnChartOptions({
            theId: "stacked-column-chart",
            colors: ["#F5822F", "#CF4AFE"],
            categories: theData.revenueCategories ?? [],
            ticks: generateRevenueTicks(ticksd1, ticksd2),
            ticksPreLast: ["$", " k"],
          });
        setTheSCChartOptions(stackedColumnChartOptions);
        setIsRevenueDataLoading(false);
      });
  }

  return (
    <div className="row mb-4">
      <div className="">
        <div
          className="custom-modal-inner__padding"
          style={{ backgroundColor: "#2A3042" }}
        >
          <div className="row row-gap-2">
            <div className="col-12 col-md-8">
              <div className="d-flex align-items-center gap-1">
                <div className="header-title">GCI Breakdown</div>
                <HelpIcon tooltip="This graph helps show which percentage of revenue is attributed to new recruited agents vs existing agents." />
              </div>
            </div>
            {/* <div className="col-12 col-md-4">
              <div className="d-flex justify-content-end">
                <div className="custom-min-width-180">
                  <SearchSelect
                    options={[
                      {
                        id: 1,
                        title: "Last Months",
                      },
                      {
                        id: 2,
                        title: "Current Months",
                      },
                      {
                        id: 3,
                        title: "Next Months",
                      },
                    ]}
                    isClearable
                    getOptionValue={(option: any) => option.id}
                    getOptionLabel={(option: any) => `${option.title}`}
                    placeholder="Select Months"
                  />
                </div>
              </div>
            </div> */}
          </div>
          {isRevenueDataLoading && <div className="loader-circle"></div>}
          {isRevenueDataLoading ? (
            <div id="stacked-column-chart" dir="ltr">
              <ReactApexChart
                options={defaultChartOptions}
                series={[]}
                type="bar"
                height={320}
                className="apex-charts"
              />
            </div>
          ) : (
            <div id="stacked-column-chart" dir="ltr">
              <ReactApexChart
                options={theSCChartOptions}
                series={[
                  {
                    name: "Existing agent GCI",
                    data: formattedRevenueData?.total_gci,
                  },
                  {
                    name: "Recruited agent GCI",
                    data: formattedRevenueData?.new_agent_gci,
                  },
                ]}
                type="bar"
                height={320}
                className="apex-charts"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RevenueBreakdown;
