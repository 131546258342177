/* eslint-disable no-unreachable */
/* eslint-disable camelcase */
import Pagination from "@components/common/Pagination/Pagination";
import PerPageOptionSelectorV2 from "@components/common/PerPageOptionSelectorV2";
import PermissionCheck from "@components/common/PermissionCheck";
import SearchSelect from "@components/common/SearchSelect";
import TablePlaceHolder from "@components/common/TablePlaceHolder";
import GlobalDraftEmailModal from "@components/recommendation/GlobalDraftEmailModal";
import { homePerms } from "@constants/permission";
import { RA_COMPLETED, RA_IGNORE, SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { faker } from "@faker-js/faker";
import { actionUserPermissions, selectUser } from "@state/appSlice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { http } from "@utils/http";
import { CheckMarkIcon, EmailIcon, EyeCrossIcon } from "@utils/icons";
import { useEffect, useState } from "react";
import HomepageNotificationModal from "./HomepageNotificationModal";

function RecommendedActions() {
  const dispatch = useAppDispatch();
  const selectedUser: any = useAppSelector(selectUser);
  const [isPermissionDataLoading, setIsPermissionDataLoading] = useState(true);

  const [recommendationData, setRecommendationData] = useState([] as any);
  const [selectedRecommendationData, setSelectedRecommendationData] = useState(
    [] as any,
  );
  const [homepageNotificationStatus, setHomepageNotificationStatus] = useState(
    "" as any,
  );
  const [selectedFilterType, setSelectedFilterType] = useState({} as any);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0 as any);
  const [perPage, setPerPage] = useState(20);
  const [modals, setModals] = useState({
    draftEmailModal: false,
    homepageNotificationModal: false,
  });
  const [isDataLoading, setIsDataLoading] = useState({});

  useEffect(() => {
    setRecommendationData([]);
    getHomePageRecommendationData();
  }, [currentPage, perPage, selectedFilterType]);

  useEffect(() => {
    if (selectedUser?.id) {
      setIsPermissionDataLoading(true);
      http
        .get(
          `user-permission-site-data.json`,
        )
        .then((response: any) => {
          const { permission } = response?.data;
          const getUserPerms = response?.data?.permission[0]?.permissions;
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: getUserPerms ?? [] }));
          setIsPermissionDataLoading(false);
        })
        .catch((error) => {
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: [] }));
          setIsPermissionDataLoading(false);
          console.log(error);
        });
    }
  }, [selectedUser]);
  /**
   * Modal close emit
   * Common for all typeof modal.
   *
   * @returns {void}
   */
  function modalCloseEmit(data: any) {
    setModals((prevState) => ({
      ...prevState,
      [data.modalName]: false,
    }));
  }

  function draftEmailModalOpen(data: any) {
    setSelectedRecommendationData(data);
    setModals((prevState) => ({
      ...prevState,
      draftEmailModal: true,
    }));
  }
  function homepageNotificationModalOpen(data: any, status: string) {
    setHomepageNotificationStatus(status);
    setSelectedRecommendationData(data);
    setModals((prevState) => ({
      ...prevState,
      homepageNotificationModal: true,
    }));
  }
  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  function getHomePageRecommendationData() {
    setIsDataLoading(true);
    // setTimeout(() => {
    //   const { recommendations, last_page }: any = fetch_recommendation_data;
    //   let theData = recommendations;
    //   theData = theData?.map((el: any) => ({
    //     ...el,
    //     description: el?.demo_description !== "" ? el.demo_description : faker.lorem.paragraph(),
    //   }));
    //   setRecommendationData(theData.slice(0, 20));
    //   setPerPage(20);
    //   setTotalPage(last_page);
    //   setIsDataLoading(false);
    // }, 500);
    // return;
    http
      .get("/recommendation/fetch-recommendations.json")
      .then((response: any) => {
        setIsDataLoading(false);
        const { data }: any = response;
        let theData = data?.recommendations;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            description: el?.demo_description !== "" ? el.demo_description : faker.lorem.paragraph(),
          }));
        }
        setRecommendationData(theData);
        setTotalPage(data?.last_page);
      });
  }

  return (
    <>
      {modals.draftEmailModal && (
        <GlobalDraftEmailModal
          modalStatus={modals.draftEmailModal}
          modalName="draftEmailModal"
          recommendationData={selectedRecommendationData}
          refetchData={() => {
            setRecommendationData([]);
            getHomePageRecommendationData();
          }}
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
      {modals.homepageNotificationModal && (
        <HomepageNotificationModal
          modalStatus={modals.homepageNotificationModal}
          modalName="homepageNotificationModal"
          status={homepageNotificationStatus}
          recommendationData={selectedRecommendationData}
          refetchData={() => {
            setRecommendationData([]);
            getHomePageRecommendationData();
          }}
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
      <PermissionCheck permission={`${homePerms.home_recommendation_lists}`}>
        <div className="mb-4">
          <div
            className="custom-modal-outer__padding custom-recommendation-bg"
            // style={{ backgroundColor: "#2A3042" }}
          >
            <div className="row">
              <div className="d-flex justify-content-between flex-wrap px-lg-0 mb-2 ">
                <p className="header-title mb-2 mb-lg-0">Recomended Actions</p>
                <PermissionCheck
                  permission={`${homePerms.home_recommendation_filter}`}
                >
                  <div className="d-flex justify-content-start justify-content-md-end gap-2 ">
                    <div>
                      <SearchSelect
                        value={
                          JSON.stringify(selectedFilterType) !== "{}"
                            ? selectedFilterType
                            : "Filter by Type"
                        }
                        options={[
                          {
                            id: 1,
                            title: "Agent",
                            value: "agent",
                          },
                          {
                            id: 2,
                            title: "Office",
                            value: "office",
                          },
                          {
                            id: 3,
                            title: "Market",
                            value: "market",
                          },
                          {
                            id: 4,
                            title: "Tools",
                            value: "tools",
                          },
                          {
                            id: 5,
                            title: "Financial",
                            value: "financial",
                          },
                          {
                            id: 6,
                            title: "Brokerage",
                            value: "brokerage",
                          },
                        ]}
                        isClearable
                        width="160px"
                        getOptionValue={(option: any) => option.value}
                        getOptionLabel={(option: any) => `${option.title}`}
                        onChange={(option: any) => setSelectedFilterType(option)}
                        placeholder="Filter by Type"
                      />
                    </div>
                  </div>
                </PermissionCheck>
              </div>

              {isDataLoading && (
                <div className="px-2 px-lg-0">
                  <table className="w-100">
                    <TablePlaceHolder
                      items={recommendationData}
                      loading={true}
                      rows={13}
                      cols={8}
                    />
                  </table>
                </div>
              )}

              {
                (!isDataLoading && recommendationData?.length) ? recommendationData?.map((recommendation: any, index: number) => (
                  <div
                    key={recommendation?.id}
                    className="card"
                    style={{
                      backgroundColor: index % 2 === 0 ? "#262B3C" : "#1E2330",
                      marginTop: index !== 0 ? "-24px" : "",
                    }}
                  >
                    <div className="custom-modal-inner__padding">
                      <div className="row">
                        <div className="col-12 col-lg-6 order-1 order-lg-1 px-0">
                          <p className="mb-0 text-white fw-semibold">
                            {recommendation?.title}{" "}
                            {/* <span className="text-secondary opacity-25">
                              #{recommendation?.id}
                            </span> */}
                          </p>
                        </div>
                        <div className="col-12 col-lg-6 order-3 order-lg-2 px-0">
                          <div className="d-flex gap-2 justify-content-start justify-content-lg-end">
                            <PermissionCheck
                              permission={`${homePerms.home_recommendation_ignore}`}
                            >
                              <button
                                type="button"
                                onClick={() => homepageNotificationModalOpen(
                                  recommendation,
                                  RA_IGNORE,
                                )}
                                // disabled={isDemo()}
                                className="btn btn-sm w-sm pe-auto border-white text-white"
                              >
                                <EyeCrossIcon className="d-none d-lg-inline-block" />
                                <span className="ms-1 fw-bold">Ignore</span>
                              </button>
                            </PermissionCheck>
                            <PermissionCheck
                              permission={`${homePerms.home_recommendation_complete}`}
                            >
                              <button
                                type="button"
                                onClick={() => homepageNotificationModalOpen(
                                  recommendation,
                                  RA_COMPLETED,
                                )}
                                // disabled={isDemo()}
                                className="btn button-ash w-sm text-black btn-sm fw-bold"
                              >
                                <CheckMarkIcon className="d-none d-lg-inline-block" />
                                Complete
                              </button>
                            </PermissionCheck>
                            <PermissionCheck
                              permission={`${homePerms.home_recommendation_draft_mail}`}
                            >
                              {recommendation?.has_draft_mail ? (
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm w-sm fw-bold"
                                  onClick={() => draftEmailModalOpen(recommendation)}
                                  // disabled={isDemo()}
                                >
                                  <EmailIcon
                                    width={20}
                                    height={20}
                                    className="d-none d-lg-inline-block"
                                  />
                                  Draft Email
                                </button>
                              ) : (
                                ""
                              )}
                            </PermissionCheck>
                          </div>
                        </div>
                        <div className="d-flex order-2 order-lg-3 px-0">
                          <p className="mt-3 custom-text-fs">
                            {recommendation?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )) : (
                  !isDataLoading && (
                    <div className="row">
                      <div className="col-12 text-center p-4">No Data Available</div>
                    </div>
                  )
                )
              }
            </div>

            {(!isDataLoading && recommendationData?.length && (
              <div className="row mt-1">
                <div className="col-12 col-md-4 ps-lg-0 d-flex gap-2 align-items-center">
                  <p className="mb-0 custom-fs-12-to-14">Rows Per Page</p>
                  <PerPageOptionSelectorV2
                    defaultPerPageValue={perPage}
                    perPageEmitSet={(value: any) => setPerPage(value)}
                    labelUnit=" "
                  />
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-center justify-content-md-start align-items-center">
                  <Pagination
                    pageCount={totalPage}
                    perPage={perPage}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            )) ||
              ""}
          </div>
        </div>
      </PermissionCheck>
    </>
  );
}

export default RecommendedActions;
