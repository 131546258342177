interface ChartContext {
  theId: any;
  colors: any;
  categories: any;
  legendPositionOnMobile?:any
  legendPositionOnDesktop?:any
}

function generatePieChartOptions(ctx: ChartContext) {
  const {
    theId = "pie-chart",
    colors = ["#F5822F", "#CF4AFE"],
    categories = [],
    legendPositionOnDesktop = "right",
    legendPositionOnMobile = "bottom",
  } = ctx;

  const options: any = {
    chart: {
      id: theId,
      type: "donut",
    },
    plotOptions: {
      pie: {
        // size: "100%", // Adjust the size here (percentage or pixel value)
        expandOnClick: false,
      },
      donut: {
        size: "100%",
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 500, // Set the height for mobile devices
          },
          legend: {
            position: `${legendPositionOnMobile}`,
            horizontalAlign: "left",
          },
        },
      },
    ],
    legend: {
      horizontalAlign: "left",
      show: true,
      position: `${legendPositionOnDesktop}`,
      align: "start",
      markers: {
        radius: 0,
      },
    },
    labels: categories ?? [],
    colors,
    dataLabels: {
      enabled: true,
      // formatter(val: any) {
      //   return "";
      // },
    },
    stroke: {
      colors: "transparent",
    },
    tooltip: {
      enabled: true, // Ensure that tooltips are enabled
      // You can customize tooltip further here if needed
    },
  };

  return options;
}

export default generatePieChartOptions;
