import HelpIcon from "@components/common/HelpIcon";
import Placeholder from "@components/common/Placeholder";
import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
import { toolsPageToolTip } from "@constants/tooltip-data";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import generateLineChartOptions from "@utils/chart/line-chart";
import { formatDecimalPlaces, formatNumberWithCommas } from "@utils/helpers";
import { http } from "@utils/http";
import { generateEmailMarketingChartFormat } from "@utils/transform/toolsActivePipe";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function ApAvgOpenRate() {
  const [chartData, setChartData] = useState([] as any);
  const [chartApiData, setChartApiData] = useState([] as any);
  const [theOptions, setTheOptions] = useState({} as any);
  const [isChartDataLoading, setIsChartDataLoading] = useState(true);
  // const [otherInfo, setOtherInfo] = useState({} as any);

  useEffect(() => {
    http
      .get(
        "/tools/active-pipe--website-email-marketing-send-open-rate-last-12-month-overall.json",
      )
      .then((response: any) => {
        let apiData = response?.data?.email_marketing;
        if (apiData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
          apiData = [];
        }
        const theData: any = generateEmailMarketingChartFormat(apiData);
        setChartData(theData);
        setChartApiData(apiData);

        const chartOptions: any = generateLineChartOptions({
          theId: "line-chart-sales-price-trends",
          colors: ["#6BF2F2"],
          categories: theData?.categories ?? [],
          gridPL: 3,
          // ticks: generateTicks(theData?.last_12_average_close_price ?? []),
          ticksPreLast: ["", ""],
        });
        setTheOptions(chartOptions);
        setIsChartDataLoading(false);
      })
      .catch((error: any) => {
        setIsChartDataLoading(false);
        console.log(error);
      });
  }, []);
  return (
    <div
      className="custom-modal-inner__padding h-100"
      style={{ backgroundColor: "#2A3042" }}
    >
      <div className="row row-gap-1 row-gap-md-0">
        <div className="col-12">
          <div className="row row-gap-2 row-gap-md-0">
            <div className="col-12 col-lg-6">
              <div className="d-inline-block">
                <span className="header-title-6 fw-bold text-white pe-1 ">Open Rate</span>
                <div className="d-inline-block align-middle">
                  <HelpIcon
                    tooltip={`${toolsPageToolTip.toolsAvgOpenRateChart}`}
                    width={20}
                    height={20}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="">
                <p className="custom-text-fs mb-1">This Month</p>
                <p className="d-flex align-items-center gap-1 number-stats-12 text-white mb-0">
                  <Placeholder
                    loading={isChartDataLoading}
                    className="custom-loader-w-20 custom-loader-h-6"
                  ></Placeholder>
                  {!isChartDataLoading &&
                    formatNumberWithCommas(
                      chartData?.latestMonthEngagementObj?.last_12_open_rate,
                    )}
                  %
                </p>
                <div className="d-flex gap-2 align-items-center">
                  <Placeholder
                    loading={isChartDataLoading}
                    className="custom-loader-w-20 custom-loader-h-6"
                  ></Placeholder>
                  {!isChartDataLoading &&
                    (() => {
                      if (
                        chartData?.latestMonthEngagementObj
                          ?.percent_diff_open_rate < 0
                      ) {
                        return (
                          <span className="badge badge-soft-danger custom-badge-12-to-14 p-1 me-1">
                            {formatDecimalPlaces(
                              chartData?.latestMonthEngagementObj
                                ?.percent_diff_open_rate,
                            )}
                            %
                          </span>
                        );
                      } else {
                        return (
                          <span className="badge badge-soft-success custom-badge-12-to-14 p-1 me-1">
                            +{" "}
                            {formatDecimalPlaces(
                              chartData?.latestMonthEngagementObj
                                ?.percent_diff_open_rate,
                            )}
                            %
                          </span>
                        );
                      }
                    })()}
                  <span className="custom-fs-10">From Previous Year</span>
                </div>
              </div>
            </div>
          </div>
          {isChartDataLoading && <div className="loader-circle"></div>}
          {isChartDataLoading ? (
            <DefaultLineChartUI />
          ) : (
            <div id="line-chart-engagement-score" dir="ltr">
              <ReactApexChart
                series={[
                  {
                    name: "Open Rate",
                    data: chartData?.last_12_open_rate,
                  },
                ]}
                options={theOptions}
                type="line"
                height={320}
                className="apex-charts"
              />
            </div>
          )}
        </div>
        {/* <div className="col-md-4">
          <div className="">
            <p className="custom-text-fs mb-1">This Month</p>
            <p className="number-stats mb-0">
              <Placeholder
                loading={isChartDataLoading}
                className="custom-loader-w-20 custom-loader-h-6"
              ></Placeholder>
              {!isChartDataLoading &&
                formatNumberWithCommas(
                  chartData?.latestMonthEngagementObj?.last_12_open_rate,
                )}%
            </p>
            <div className="d-flex gap-2 align-items-center">
              <Placeholder
                loading={isChartDataLoading}
                className="custom-loader-w-20 custom-loader-h-6"
              ></Placeholder>
              {!isChartDataLoading &&
                (() => {
                  if (
                    chartData?.latestMonthEngagementObj
                      ?.percent_diff_open_rate < 0
                  ) {
                    return (
                      <span className="badge badge-soft-danger custom-badge-12-to-14 p-1 me-1">
                        {formatDecimalPlaces(
                          chartData?.latestMonthEngagementObj
                            ?.percent_diff_open_rate,
                        )}
                        %
                      </span>
                    );
                  } else {
                    return (
                      <span className="badge badge-soft-success custom-badge-12-to-14 p-1 me-1">
                        +{" "}
                        {formatDecimalPlaces(
                          chartData?.latestMonthEngagementObj
                            ?.percent_diff_open_rate,
                        )}
                        %
                      </span>
                    );
                  }
                })()}
              <span className="custom-fs-10">From Previous Year</span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default ApAvgOpenRate;
