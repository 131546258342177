import TablePlaceHolder from "@components/common/TablePlaceHolder";
import { ThreeVerticalDotIcon } from "@utils/icons";

function RecomLeaderBoardTableList({
  currentUserData,
  leaderboardListData,
  isDataLoading,
}: any) {
  return (
    (!isDataLoading && leaderboardListData?.length) ? (
    <div>
      <div
        className="rounded-2"
        style={{
          background:
            "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.14) 60%)",
        }}
      >
        <div style={{ margin: "16px 13px 13px 13px" }}>
          <ThreeVerticalDotIcon />
        </div>
      </div>
      <div>
        {leaderboardListData?.length ?
          leaderboardListData?.map((userData: any) => (
            <div
              key={userData?.user_id}
              className="d-flex align-items-center gap-3 mb-3"
              style={{
                margin:
                  currentUserData?.position === userData?.position
                    ? "0px"
                    : "20px",
                background:
                  currentUserData?.position === userData?.position
                    ? "linear-gradient(121deg, rgba(71,58,65,1) 0%, rgba(60,60,95,1) 98%)"
                    : "",
                padding:
                  currentUserData?.position === userData?.position
                    ? "0px 20px"
                    : "",
              }}
            >
              <p className="mb-0 text-white" style={{ fontSize: "16px" }}>
                {userData?.position}
              </p>
              <div className="">
                <p className="mb-0" style={{ fontSize: "14px", color: currentUserData?.position === userData?.position ? "rgba(52, 252, 29, 1)" : "white" }}>
                  {currentUserData?.position === userData?.position
                    ? "You"
                    : `${userData?.first_name ?? ""} ${
                      userData?.last_name ?? ""
                    }`}
                </p>
                <p
                  className="mb-0 mt-1"
                  style={{ color: "#919EAB", fontSize: "12px" }}
                >
                  {userData?.office_name}
                </p>
              </div>
            </div>
          )) : ""}
      </div>
      {/* <div
        className="d-flex align-items-center gap-3 mb-3 "
        style={{
          // background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(77,58,32,1) 60%, rgba(0,212,255,1) 100%)",
          padding: "0px 20px",
          background:
            "linear-gradient(121deg, rgba(71,58,65,1) 0%, rgba(60,60,95,1) 98%)",
        }}
      >
        <p className="mb-0 text-white" style={{ fontSize: "16px" }}>
          12
        </p>
        <div className="">
          <p className="mb-0" style={{ fontSize: "14px", color: "#34FC1D" }}>
            You
          </p>
          <p
            className="mb-0 mt-1"
            style={{ color: "#919EAB", fontSize: "12px" }}
          >
            Office#3
          </p>
        </div>
      </div>
      <div
        className="d-flex align-items-center gap-3 mb-4"
        style={{ margin: "20px" }}
      >
        <p className="mb-0 text-white" style={{ fontSize: "16px" }}>
          13
        </p>
        <div className="">
          <p className="mb-0 text-white" style={{ fontSize: "14px" }}>
            Savannah Nguyen
          </p>
          <p
            className="mb-0 mt-1"
            style={{ color: "#919EAB", fontSize: "12px" }}
          >
            Office#3
          </p>
        </div>
      </div> */}
    </div>
    ) : (
    <table className="w-100 mt-4">
      <TablePlaceHolder
        items={leaderboardListData}
        loading={isDataLoading}
        rows={8}
        cols={6}
      />
    </table>
    )
  );
}

export default RecomLeaderBoardTableList;
