import HelpIcon from "@components/common/HelpIcon";
import SearchSelect from "@components/common/SearchSelect";
import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
import { agentToolTip } from "@constants/tooltip-data";
import generateLineChartOptions from "@utils/chart/line-chart";
import { getTickAmount } from "@utils/helpers";
import { http } from "@utils/http";
import { generateFinancialDataByYear } from "@utils/transform/financial";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function AgentListVsSellSideChart({ agentIdProps }: any) {
  const [monthOptions, setMonthOptions] = useState([
    {
      id: 1,
      title: "Last 3 Months",
      value: 3,
    },
    {
      id: 2,
      title: "Last 6 Months",
      value: 6,
    },
    {
      id: 3,
      title: "Last 12 Months",
      value: 12,
    },
  ] as any);
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[2] as any);

  const [chartOptions, setChartOptions] = useState({} as any);

  const [isListingFinancialDataLoading, setIsListingFinancialDataLoading] =
    useState(true);

  const [financials, setFinancials] = useState({} as any);

  useEffect(() => {
    if (agentIdProps !== "") {
      getFinancialReport();
    }
  }, [agentIdProps, selectedMonth]);

  function getFinancialReport() {
    setIsListingFinancialDataLoading(true);
    http
      .get("/agent/agents-financial-report.json")
      .then((response: any) => {
        const theData: any = generateFinancialDataByYear(
          response.data.financial_report,
        );
        setFinancials(theData);
        const chartFormattedOptions: any = generateLineChartOptions({
          theId: "line-chart-gci",
          colors: ["#E98B48", "#E98B48", "#6BF2F2", "#6BF2F2"],
          categories: theData?.categories,
          // ticks: generateTicks(theData?.last_12_gci ?? []),
          dashArray: [0, 5, 0, 5],
          gridPL: 1,
          legendPostion: "top",
          tickAmount: getTickAmount(theData?.last_12_list_sides),
          ticksPreLast: ["", ""],
        });
        setChartOptions(chartFormattedOptions);
        setIsListingFinancialDataLoading(false);
      }).catch((error) => {
        setIsListingFinancialDataLoading(false);
        console.log(error);
      });
  }
  return (
    <>
      <div className="row mb-3">
        <div className="col-6 col-lg-7">
          <div className="d-inline-block">
            <span className="header-title-5 text-white mb-0 fw-medium pe-1">
              List Sides Vs Sell Sides 
            </span>
            <div className="d-inline-block align-middle">
              <HelpIcon
                tooltip={`${agentToolTip.list_vs_sell_sides_chart}`}
                width={20}
                height={20}
              />
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-5 d-inline-flex justify-content-end">
          <SearchSelect
            options={monthOptions}
            value={
              JSON.stringify(selectedMonth) !== "{}"
                ? selectedMonth
                : "Select a Month"
            }
            onChange={(option: any) => setSelectedMonth(option)}
            // isClearable
            // width="190px"
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => `${option.title}`}
            placeholder="Select Months"
          />
        </div>
      </div>

      <div>
        {isListingFinancialDataLoading && <div className="loader-circle"></div>}
        {isListingFinancialDataLoading ? (
          <DefaultLineChartUI />
        ) : (
          <div id="line-chart-gci" dir="ltr">
            <ReactApexChart
              series={[
                {
                  name: `${selectedMonth?.title} List Sides`,
                  data: financials?.last_12_list_sides,
                },
                {
                  name: "Previous Period List Sides",
                  data: financials?.previous_period_list_sides,
                },
                {
                  name: `${selectedMonth?.title} Sell Sides`,
                  data: financials?.last_12_sell_sides,
                },
                {
                  name: "Previous Period Sell Sides",
                  data: financials?.previous_period_sell_sides,
                },
              ]}
              options={chartOptions}
              type="line"
              height={400}
              className="apex-charts custom-legend-bg"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default AgentListVsSellSideChart;
