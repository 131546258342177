import mixpanel from 'mixpanel-browser';

mixpanel.init('1394b0886099ec5447001ce3d6951576', { debug: window.ENVIRONMENT !== 'production' });

export enum TrackingActions {
  AUTHENTICATED = 'authenticated',
  LOGIN = 'login',
  LOGOUT = 'logout',
  PASSWORD_RESET_REQUEST = 'password_reset_request',
  PASSWORD_RESET_SUCCESS = 'password_reset_success',

  AGENTS_PAGE_OPENED = 'agents_page_opened',
  MARKETS_PAGE_OPENED = 'markets_page_opened',
  OFFICES_PAGE_OPENED = 'offices_page_opened',
  TOOLS_PAGE_OPENED = 'tools_page_opened',

  AGENT_DETAIL_VIEWED = 'agent_detail_viewed',
  AGENTS_TABLE_ORDERED = 'agents_table_ordered',
  AGENTS_TABLE_PAGED = 'agents_table_paged',

  MARKET_ALERT_HOVERED = 'market_alert_hovered',
  MARKET_ALERT_UNITS_CHANGED = 'market_alert_units_changed',
  MARKET_MAP_VIEWED = 'market_map_viewed',
  MARKET_SHARE_HOVERED = 'market_share_hovered',
  MARKET_SHARE_UNITS_CHANGED = 'market_share_units_changed',

  OBSERVATION_PAGED = 'observation_paged',

  OFFICES_LISTING_TABLE_ORDERED = 'offices_listing_table_ordered',

  TOOLS_QUARTILE_GRAPH_TOOL_SELECTED = 'tools_quartile_graph_tool_selected',
}

export const getTrackingData = (userId: string, data?: any) => ({
  environment: process.env.REACT_APP_ENVIRONMENT,
  user_id: userId,
  ...data,
});

export default mixpanel;
