/* eslint-disable camelcase */
import HelpIcon from "@components/common/HelpIcon";
import Placeholder from "@components/common/Placeholder";
import TablePlaceHolder from "@components/common/TablePlaceHolder";
import { officeModalTooltip } from "@constants/tooltip-data";
import { http } from "@utils/http";
import { useEffect, useState } from "react";

function OfficeYearlyTechEngagement({ officeIdProps }: any) {
  const [ytdEngagmentData, setYtdEngagementData] = useState({} as any);
  const [ytdAvgOfficeData, setYtdAvgOfficeData] = useState({} as any);
  const [isYtdEngagementDataLoading, setIsYtdEngagementDataLoading] =
    useState(true);
  const [isAvgOfficeDataLoading, setIsAvgOfficeDataLoading] =
    useState(true);

  useEffect(() => {
    if (officeIdProps) {
      getYtdEngagementData();
    }
  }, [officeIdProps]);

  useEffect(() => {
    getYtdAvgAgentOfficeData();
  }, []);

  function getYtdEngagementData() {
    setIsYtdEngagementDataLoading(true);
    http
      .get("/office/yearly-tech-engagement.json")
      .then((response: any) => {
        const { engagement_score } = response?.data;
        setYtdEngagementData(engagement_score[0]);

        setIsYtdEngagementDataLoading(false);
      })
      .catch((error) => {
        setIsYtdEngagementDataLoading(false);
        console.log(error);
      });
  }

  function getYtdAvgAgentOfficeData() {
    setIsAvgOfficeDataLoading(true);
    http
      .get("/office/average-office-tech-engagement.json")
      .then((response: any) => {
        const { engagement_score } = response?.data;
        setYtdAvgOfficeData(engagement_score[0]);

        setIsAvgOfficeDataLoading(false);
      })
      .catch((error) => {
        setIsAvgOfficeDataLoading(false);
        console.log(error);
      });
  }

  return (
    <div className="mb-4" style={{ backgroundColor: "#2A3042" }}>
      <div className="custom-modal-inner__padding">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="d-flex gap-1 align-items-center">
            <p className="header-title mb-0">Tech Engagement</p>
            <HelpIcon tooltip={officeModalTooltip.officeTechEngmnt}/>
          </div>
          <p className="mb-0">Last 12 months</p>
        </div>
        <div className="row mt-2">
          <div className="col-lg-8">
            <table className="w-100">
              <TablePlaceHolder
                items={ytdEngagmentData}
                loading={isYtdEngagementDataLoading}
                rows={6}
                cols={8}
              />
            </table>
            <div>
              {!isYtdEngagementDataLoading && (
                <>
                  <div className="row align-content-start">
                    <div className="col-md-5 fw-medium ">CloudCMA</div>
                    <div className="col-md-7">
                      <div className="d-flex align-items-baseline gap-2">
                        <p>{ytdEngagmentData?.cma_engagement_score}%</p>
                        <div className="progress animated-progess progress-md flex-grow-1">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${ytdEngagmentData?.cma_engagement_score}%`,
                              backgroundColor: `#F5822F`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div>
              {!isYtdEngagementDataLoading && (
                <>
                  <div className="row align-content-start">
                    <div className="col-md-5 fw-medium ">Email</div>
                    <div className="col-md-7">
                      <div className="d-flex align-items-baseline gap-2">
                        <p>{ytdEngagmentData?.email_engagement_score}%</p>
                        <div className="progress animated-progess progress-md flex-grow-1">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${ytdEngagmentData?.email_engagement_score}%`,
                              backgroundColor: `#6BF2F2`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div>
              {!isYtdEngagementDataLoading && (
                <>
                  <div className="row align-content-start">
                    <div className="col-md-5 fw-medium ">Website</div>
                    <div className="col-md-7">
                      <div className="d-flex align-items-baseline gap-2">
                        <p>{ytdEngagmentData?.web_engagement_score}%</p>
                        <div className="progress animated-progess progress-md flex-grow-1">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${ytdEngagmentData?.web_engagement_score}%`,
                              backgroundColor: `#F2CA6B`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <p className="mb-1">Average Engagement - All Offices</p>
            <p className="number-stats-4 text-white mb-0 d-flex align-items-center gap-2">
              <Placeholder
                loading={isAvgOfficeDataLoading}
                className="custom-w-12 custom-h-9"
              ></Placeholder>
              {
                !isAvgOfficeDataLoading && ytdAvgOfficeData?.average_engagement_score
              }%
            </p>
            {/* <p>
              <span className="badge badge-soft-success  me-1">+0.4%</span>
              <span>From Previous Period</span>
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfficeYearlyTechEngagement;
