import HelpIcon from "@components/common/HelpIcon";
import Placeholder from "@components/common/Placeholder";
import { formatDecimalPlaces, formatNumberWithCommas } from "@utils/helpers";

interface IytdProps {
  cardTitle: string;
  tooltipText: string;
  amount: number;
  isAmountDollar: boolean;
  isDataLoading: boolean;
  cardSubtitile: string;
  percentText: string;
  percentValue: number;
}
function YtdOfficeCard({
  cardTitle,
  tooltipText,
  isDataLoading,
  amount,
  isAmountDollar,
  cardSubtitile,
  percentText,
  percentValue,
}: IytdProps) {
  return (
    <div className="">
      <div className="d-flex align-items-center mb-1 justify-content-between">
        <div className="d-inline-block">
          <span className="header-title-5 text-white mb-0 pe-1">
            {cardTitle ?? ""}
          </span>
          <div className="d-inline-block align-middle">
            <HelpIcon tooltip={tooltipText ?? ""} width={20} height={20} />
          </div>
        </div>
        <p className="d-flex gap-1 align-items-center text-center text-break number-stats-10 text-white mb-0 ">
          {isAmountDollar ? "$" : ""}
          <Placeholder
            loading={isDataLoading}
            className="custom-w-12 custom-loader-h-6"
          ></Placeholder>
          {!isDataLoading && formatNumberWithCommas(amount)}
        </p>
      </div>
      <div className="d-flex align-items-center mb-1 justify-content-between">
        <p className="mb-0 custom-text-fs">{cardSubtitile ?? "YTD"}</p>
        <p className="d-flex gap-1 align-items-center mb-0 text-center custom-text-fs">
          <span className="me-1">{ percentText ?? "From Previous Period"}</span>
          <Placeholder
            loading={isDataLoading}
            className="custom-w-12 custom-loader-h-6"
          ></Placeholder>
          {!isDataLoading &&
            (() => {
              if (percentValue > 0) {
                return (
                  <span className="badge badge-soft-success custom-badge-12-to-14">
                    + {formatDecimalPlaces(percentValue)}%
                  </span>
                );
              } else {
                return (
                  <span className="badge badge-soft-danger custom-badge-12-to-14">
                    {formatDecimalPlaces(percentValue)}%
                  </span>
                );
              }
            })()}
        </p>
      </div>
    </div>
  );
}

export default YtdOfficeCard;
