/* eslint-disable camelcase */
import HelpIcon from "@components/common/HelpIcon";
import Placeholder from "@components/common/Placeholder";
import { agentToolTip } from "@constants/tooltip-data";
import { formatDecimalPlaces, isObjectEmpty } from "@utils/helpers";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function YtdOfficeListVsSellSide({ isDataLoading, officeYTDReportData }: any) {
  const [tickAmountData, setTickAmountData] = useState(2 as any);
  const [sellSideChartHeight, setSellSideChartHeight] = useState("" as any);
  const [listSideChartHeight, setListSideChartHeight] = useState("" as any);

  useEffect(() => {
    const maxHeight = 260;
    const minHeight = 200;
    const { ytd_sell_sides, ytd_list_sides } = officeYTDReportData;
    if (isObjectEmpty(officeYTDReportData)) {
      setSellSideChartHeight(minHeight);
      setListSideChartHeight(minHeight);
    } else {
      const isSaleSideValueLessThanZero = checkYtdValueIszero(ytd_sell_sides);
      const isListSideValueLessThanZero = checkYtdValueIszero(ytd_list_sides);
      if (ytd_sell_sides === 0 && ytd_list_sides === 0) {
        setSellSideChartHeight(minHeight);
        setListSideChartHeight(minHeight);
      } else if (ytd_sell_sides > ytd_list_sides) {
        const updatedMinHeight =
          Math.ceil(maxHeight / ytd_sell_sides) * ytd_list_sides;
        setTickAmountData(Math.ceil(ytd_sell_sides / 2));
        // eslint-disable-next-line no-unused-expressions
        isListSideValueLessThanZero
          ? setListSideChartHeight(minHeight)
          : setListSideChartHeight(updatedMinHeight);
        setSellSideChartHeight(maxHeight);
      } else {
        const updatedMinHeight =
          Math.ceil(maxHeight / ytd_list_sides) * ytd_sell_sides;
        // eslint-disable-next-line no-unused-expressions
        isSaleSideValueLessThanZero
          ? setSellSideChartHeight(minHeight)
          : setSellSideChartHeight(updatedMinHeight);

        setListSideChartHeight(maxHeight);
        setTickAmountData(Math.ceil(officeYTDReportData?.ytd_list_sides / 2));
      }
    }
  }, [officeYTDReportData]);

  function checkYtdValueIszero(data: any) {
    return data <= 0;
  }

  function generateColumnChartOptions(ctx: any) {
    const {
      theId = "column-chart",
      colors = ["#F5822F", "#CF4AFE"],
      categories = [],
      tickAmount = 2,
      isChartStacked = false,
      columnWidthProps = "55%",
    } = ctx;
    const options: any = {
      chart: {
        id: theId,
        // height: 260,
        stacked: isChartStacked,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          marginTop: 10,
          columnWidth: `${columnWidthProps}`,
          // dataLabels: {
          //   position: 'top', // top, center, bottom
          // },
        },
      },
      dataLabels: {
        enabled: false,
        // formatter(val: any) {
        //   return `${val}`;
        // },
        // offsetY: -80,
        // style: {
        //   fontSize: "18px",
        //   margin: "5px",
        //   colors: ["#304758"],
        // },
      },

      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      colors,
      xaxis: {
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false, // Hide x-axis labels
        },
        categories,
      },
      yaxis: {
        tickAmount,
        show: false, // Hide y-axis
      },
      grid: {
        show: false,
        // padding: {
        //   left: -10,
        // },
      },
      // title: {
      //   text: "sell sides",
      //   floating: true,
      //   // offsetY: 300,
      //   align: 'center',
      //   style: {
      //     color: '#444',
      //   },
      // },
      legend: {
        show: true,
        horizontalAlign: "center", // Center legend for desktop
      },
    };

    return options;
  }

  return (
    <div>
      <div className="title"></div>
      <div className="d-inline-block">
        <span className="header-title-5 text-white mb-0 pe-1">
          Sell Sides Vs. List Sides
        </span>
        <div className="d-inline-block align-middle">
          <HelpIcon
            tooltip={agentToolTip?.sell_vs_list_sides}
            width={20}
            height={20}
          />
        </div>
      </div>
      <p className="mb-0 custom-text-fs">YTD</p>
      <div className="row">
        <div className="col-6 d-flex flex-column justify-content-end">
          <div className="row">
            <div className="col-5 pe-0 d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Placeholder
                  loading={isDataLoading}
                  className="w-8 h-8"
                ></Placeholder>
                {!isDataLoading && (
                  <p className="text-end number-stats-10 text-white mb-1">
                    {officeYTDReportData?.ytd_sell_sides ?? 0}
                  </p>
                )}
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Placeholder
                  loading={isDataLoading}
                  className="custom-w-12 custom-loader-h-6"
                ></Placeholder>
                {!isDataLoading &&
                  (() => {
                    if (officeYTDReportData?.percent_sell_sides > 0) {
                      return (
                        <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                          +{" "}
                          {formatDecimalPlaces(
                            officeYTDReportData?.percent_sell_sides,
                          )}
                          %
                        </span>
                      );
                    } else {
                      return (
                        <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                          {formatDecimalPlaces(
                            officeYTDReportData?.percent_sell_sides,
                          )}
                          %
                        </span>
                      );
                    }
                  })()}
              </div>
            </div>
            <div className="col-7">
              <Placeholder
                loading={isDataLoading}
                className="custom-w-12 custom-loader-h-260"
              ></Placeholder>
              <div id="line-chart-sales-price-trends" dir="ltr">
                {!isDataLoading && (
                  <ReactApexChart
                    series={[
                      {
                        name: "Sell Sides",
                        data: officeYTDReportData?.ytd_sell_sides
                          ? [officeYTDReportData?.ytd_sell_sides]
                          : [],
                      },
                    ]}
                    options={generateColumnChartOptions({
                      theId: "line-chart",
                      colors: ["#CF4AFE"],
                      categories: [""],
                      columnWidthProps: "100%",
                      tickAmount: tickAmountData,
                    })}
                    // options={options}
                    type="bar"
                    height={sellSideChartHeight}
                    className="apex-charts"
                  />
                )}
              </div>
              <p className="text-center">Sell Sides</p>
            </div>
          </div>
        </div>
        <div className="col-6 d-flex flex-column justify-content-end">
          <div className="row">
            <div className="col-7">
              <Placeholder
                loading={isDataLoading}
                className="custom-w-12 custom-loader-h-260"
              ></Placeholder>
              {!isDataLoading && (
                <div id="line-chart-sales-price-trends" dir="ltr">
                  <ReactApexChart
                    series={[
                      {
                        name: "List Sides",
                        data: officeYTDReportData?.ytd_list_sides
                          ? [officeYTDReportData?.ytd_list_sides]
                          : [],
                      },
                    ]}
                    options={generateColumnChartOptions({
                      theId: "line-chart",
                      colors: ["#F5822F"],
                      categories: [""],
                      columnWidthProps: "100%",
                      tickAmount: tickAmountData,
                    })}
                    // options={options}
                    type="bar"
                    height={listSideChartHeight}
                    className="apex-charts"
                  />
                </div>
              )}

              <p className="text-center">List Sides</p>
            </div>
            <div className="col-5 ps-0 d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Placeholder
                  loading={isDataLoading}
                  className="w-8 h-8"
                ></Placeholder>
                {!isDataLoading && (
                  <p className="text-end number-stats-10 text-white mb-1">
                    {officeYTDReportData?.ytd_list_sides ?? 0}
                  </p>
                )}
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Placeholder
                  loading={isDataLoading}
                  className="custom-w-12 custom-loader-h-6"
                ></Placeholder>
                {!isDataLoading &&
                  (() => {
                    if (officeYTDReportData?.percent_list_sides > 0) {
                      return (
                        <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                          +{" "}
                          {formatDecimalPlaces(
                            officeYTDReportData?.percent_list_sides,
                          )}
                          %
                        </span>
                      );
                    } else {
                      return (
                        <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                          {formatDecimalPlaces(
                            officeYTDReportData?.percent_list_sides,
                          )}
                          %
                        </span>
                      );
                    }
                  })()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=""></div>
      <div className="text-center custom-text-fs">
        <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
          %
        </span>{" "}
        <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
          %
        </span>{" "}
        - difference from previous period
      </div>
    </div>
  );
}

export default YtdOfficeListVsSellSide;
