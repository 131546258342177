import HelpIcon from "@components/common/HelpIcon";
import { toolsPageToolTip } from "@constants/tooltip-data";
import { faker } from "@faker-js/faker";
import { http } from "@utils/http";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import ActivePipeTopProfileCard from "./ActivePipeTopProfileCard";
import ApAgentOpportunities from "./ApAgentOpportunities";
import ApAvgCtr from "./ApAvgCtr";
import ApAvgOpenRate from "./ApAvgOpenRate";
import ApBottomUsersTable from "./ApBottomUsersTable";
import ApCumulativeSends from "./ApCumulativeSends";
import ApEngagementOverTime from "./ApEngagementOverTime";
import ApOfficeOverview from "./ApOfficeOverview";
import ApTopUsersTable from "./ApTopUsersTable";

function ActivePipeCardDetailsModal(props: any) {
  // All state.
  const [openModal, setOpenModal] = useState(false);
  const [modalName] = useState("activePipeModal");
  const [isAPEngagementDataLoading, setIsAPEngagementDataLoading] =
    useState(true);
  const [aPEngagementScore, setApEngagementScore] = useState({} as any);
  const [selectedPersonInfo, setSelecedPersonInfo] = useState({} as any);

  useEffect(() => {
    if (props.modalName === modalName) {
      setOpenModal(props.modalStatus);
    }
  }, [setOpenModal, props.modalStatus]);

  // Added an event listener to handle 'Escape' key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && openModal) {
        modalClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openModal]);

  useEffect(() => {
    getTopProfileEngagementScore();
  }, []);

  function modalClose() {
    setOpenModal(false);
    setTimeout(() => {
      props.modalClose({
        modalName,
      });
    }, 1000);
  }

  function getTopProfileEngagementScore() {
    setIsAPEngagementDataLoading(true);
    http
      .get("/tools/active-pipe--email-marketing-engagement-score.json")
      .then((response: any) => {
        const apEngagementScoreObj = response?.data?.email_engagement;
        let theData = apEngagementScoreObj;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          const fakerFirstName = faker.person.firstName();
          theData = {
            ...theData,
            first_name: fakerFirstName,
            email: faker.internet.email({ firstName: fakerFirstName, lastName: "" }),
          };
        }
        setApEngagementScore(theData);
        setIsAPEngagementDataLoading(false);
      })
      .catch((error: any) => {
        setIsAPEngagementDataLoading(false);
        console.log(error);
      });
  }

  function getRandomColor(stats: any) {
    let color: any = "";
    switch (true) {
      case stats >= 40 && stats <= 50:
        color = "#F2CA6B";
        break;
      case stats > 50 && stats <= 70:
        color = "#F2CA6B";
        break;
      case stats > 70:
        color = "#CF4AFE";
        break;
      default:
        color = "#F5822F";
        break;
    }

    return color;
  }

  return (
    <Modal
      size="xl"
      isOpen={openModal}
      centered={true}
    >
      <div className="modal-content" style={{ background: "#222736" }}>
        <div className="container mb-4">
          <p className="custom-modal-title mt-2"></p>
          <button
            type="button"
            onClick={modalClose}
            className="btn-close position-absolute custom-top-2 custom-right-4 mb-4"
          ></button>
        </div>
        <ModalBody className="custom-modal-outer__padding">
          <div className="row mb-3">
            <div className="d-flex justify-content-between">
              {/* <div className="header-title">Email</div> */}
            </div>
          </div>
          {/* Active Pipe Card */}
          <div className="row custom-mb-2">
            <div className="col-12">
              <div className="mb-1" style={{ backgroundColor: "#2B3041" }}>
                <div className="custom-modal-inner__padding">
                  {/* <p className="header-subtitle text-white">ActivePipe</p> */}
                  <div className="d-inline-block">
                    <span className="header-title-6 fw-bold text-white pe-1 ">
                      ActivePipe
                    </span>
                    <div className="d-inline-block align-middle">
                      <HelpIcon
                        tooltip={`${toolsPageToolTip.toolsModalActivePipe}`}
                        width={20}
                        height={20}
                      />
                    </div>
                  </div>
                  <ActivePipeTopProfileCard
                    isDataLoading={isAPEngagementDataLoading}
                    topProfileInfo={aPEngagementScore} 
                  />
                  {/* <div className="row">
                    <div className="col-7 col-lg-12">
                      <div className="row">
                        <div className="col-5 col-lg-12">
                          <div className="d-flex justify-content-center">
                            <ProfileIcon height="60" />
                          </div>
                        </div>
                        <div className="col-7 col-lg-12 px-0">
                          <p className="text-left text-lg-center text-white header-subtitle-2 mb-1">
                            <Placeholder
                              loading={isAPEngagementDataLoading}
                              className="custom-loader-w-20 custom-loader-h-6"
                            ></Placeholder>
                            {!isAPEngagementDataLoading &&
                              (aPEngagementScore?.first_name ?? "")}
                          </p>
                          
                          <p className="mb-0 d-flex d-lg-none page-subtitle-3">
                            Engagement Score
                          </p>
                          <p className="number-stats d-flex d-lg-none text-white mb-0">
                            <Placeholder
                              loading={isAPEngagementDataLoading}
                              className="custom-loader-w-20 custom-loader-h-6"
                            ></Placeholder>
                            {!isAPEngagementDataLoading &&
                              (aPEngagementScore?.overall_engagement_score ??
                                0)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-5 col-lg-12">
                      <div className="d-lg-flex align-items-center gap-3 text-break">
                        <p className="mb-0 custom-fs-12">Email</p>
                        <p className="text-white mb-0 custom-fs-12">
                          <Placeholder
                            loading={isAPEngagementDataLoading}
                            className="custom-loader-w-20 custom-loader-h-6"
                          ></Placeholder>
                          {!isAPEngagementDataLoading &&
                            (aPEngagementScore?.email ?? "N/A")}
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-none d-lg-flex align-items-center header-subtitle-2 flex-column flex-lg-row gap-2 flex-lg-row-reverse justify-content-lg-end">
                        <p className="mb-0 page-subtitle-3">Engagement Score</p>
                        <p className="number-stats text-white mb-0">
                          <Placeholder
                            loading={isAPEngagementDataLoading}
                            className="custom-loader-w-20 custom-loader-h-6"
                          ></Placeholder>
                          {!isAPEngagementDataLoading &&
                            (aPEngagementScore?.overall_engagement_score ?? 0)}
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row custom-parent--row h-100">
            <div className="col-12 col-lg-6 mb-2 custom-column">
              <ApEngagementOverTime />
            </div>
            <div className="col-12 col-lg-6 mb-2 mt-1 mt-lg-0 custom-column">
              <ApCumulativeSends />
            </div>
            <div className="col-12 col-lg-6 mb-2 mt-1 custom-column">
              <ApAvgCtr />
            </div>
            <div className="col-12 col-lg-6 mb-2 mt-1 custom-column">
              <ApAvgOpenRate />
            </div>
          </div>

          <ApAgentOpportunities cardId={props?.cardIdData} />
          <ApOfficeOverview cardId={props?.cardIdData} />
          <div className="row row-gap-2 custom-mb-2 custom-parent--row">
            <div className="col-12 col-lg-6 custom-column">
              <ApTopUsersTable cardId={props?.cardIdData} />
            </div>
            <div className="col-12 col-lg-6 custom-column">
              <ApBottomUsersTable cardId={props?.cardIdData} />
            </div>
          </div>
          {/* <RecomendedAction /> */}
        </ModalBody>
      </div>
    </Modal>
  );
}

export default ActivePipeCardDetailsModal;
