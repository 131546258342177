import React, { useEffect } from 'react';
import { GoogleMap, GoogleMapProps } from '@react-google-maps/api';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';
import { useLazyListListingsHotSpotsQuery } from '../../../../state/api';
import { useAppSelector } from '../../../../state/hooks';
import { selectGoogleMapsLoaded, selectTrackingUserId } from '../../../../state/appSlice';
import tracking, { getTrackingData, TrackingActions } from '../../../../state/tracking';

export interface EmergingMarketCenterAndZoom {
  centerLatitude: number
  centerLongitude: number
  zoom: number
}

export interface EmergingMarketBounds {
  maximumLatitude: number
  maximumLongitude: number
  minimumLatitude: number
  minimumLongitude: number
}

export interface EmergingMarketHeatmapProps {
  initialCenterAndZoom: EmergingMarketCenterAndZoom
  mlsIds: string[]
  setEmergingMarketBounds?: (emergingMarketBounds: EmergingMarketBounds) => void
  setEmergingMarketCenterAndZoom?:
    (emergingMarketCenterAndZoom: EmergingMarketCenterAndZoom) => void
  updateKey?: string
}

const EmergingMarketHeatmap = ({
  initialCenterAndZoom,
  mlsIds,
  setEmergingMarketBounds,
  setEmergingMarketCenterAndZoom,
  updateKey,
}: EmergingMarketHeatmapProps) => {
  const [pageWidth, setPageWidth] = React.useState(0);
  const [map, setMap] = React.useState(null as google.maps.Map | null);
  const [heatmap, setHeatmap] = React.useState(
    null as google.maps.visualization.HeatmapLayer | null,
  );
  const [trigger, listings] = useLazyListListingsHotSpotsQuery();
  const googleMapsLoaded = useAppSelector(selectGoogleMapsLoaded);
  const userId = useAppSelector(selectTrackingUserId);

  useEffect(() => {
    const handleResize = () => {
      const pageContent = document.querySelector('.page-content');
      if (pageContent != null) {
        setPageWidth(pageContent.clientWidth - 24);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
  });
  const handleLoad = React.useCallback((loadedMap: google.maps.Map) => setMap(loadedMap), [setMap]);
  const handleUnload = React.useCallback(() => setMap(null), [setMap]);
  const handleBoundsChanged = React.useCallback(() => {
    if (map != null) {
      const mapBounds = map.getBounds();
      if (mapBounds != null) {
        const newBounds = {
          centerLatitude: mapBounds.getCenter().lat(),
          centerLongitude: mapBounds.getCenter().lng(),
          maximumLongitude: mapBounds.getNorthEast().lng(),
          mls: mlsIds,
          maximumLatitude: mapBounds.getNorthEast().lat(),
          minimumLatitude: mapBounds.getSouthWest().lat(),
          minimumLongitude: mapBounds.getSouthWest().lng(),
          zoom: map.getZoom()!,
        };
        trigger(newBounds);
        if (setEmergingMarketBounds) {
          setEmergingMarketBounds(newBounds);
        }
        if (setEmergingMarketCenterAndZoom) {
          setEmergingMarketCenterAndZoom(newBounds);
        }
        tracking.track(
          TrackingActions.MARKET_MAP_VIEWED.valueOf(),
          getTrackingData(userId!, newBounds),
        );
      }
    }
  }, [map, mlsIds, setEmergingMarketBounds, setEmergingMarketCenterAndZoom, trigger, userId]);

  const googleMapProps = React.useMemo(() => ({
    center: {
      lat: initialCenterAndZoom.centerLatitude,
      lng: initialCenterAndZoom.centerLongitude,
    },
    mapContainerStyle: {
      width: pageWidth,
      height: 400,
    },
    onIdle: handleBoundsChanged,
    onLoad: handleLoad,
    onUnmount: handleUnload,
    options: {
      disableDefaultUI: true,
      scaleControl: true,
      zoomControl: true,
    },
    zoom: initialCenterAndZoom.zoom,
  } as GoogleMapProps),
  // Include update key in dependency list so that we can re-center the map
  // after selecting the same emerging market
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [initialCenterAndZoom, handleBoundsChanged, handleLoad, handleUnload, pageWidth, updateKey]);

  let mapElement: React.ReactElement | undefined;
  if (googleMapsLoaded) {
    mapElement = (
      <GoogleMap {...googleMapProps} />
    );
    if (listings.isSuccess) {
      const heatmapData = listings.data!.map((it) => ({
        location: new google.maps.LatLng(it.latitude!, it.longitude!),
        weight: it.weight!,
      }));
      if (heatmap != null) {
        heatmap.setData(heatmapData);
      } else {
        setHeatmap(new google.maps.visualization.HeatmapLayer({
          data: heatmapData,
          map,
          radius: 20,
        }));
      }
    }
  }

  return (
    <>
      <div className="h5">
        Activity Heatmap
      </div>
      <LoadingIcon loading={!googleMapsLoaded} />
      {mapElement}
    </>
  );
};
EmergingMarketHeatmap.defaultProps = {
  updateKey: '',
};

export default EmergingMarketHeatmap;
