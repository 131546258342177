import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Auth, User } from './api';
import { RootState } from './store';

export interface AuthState {
  auth: Auth | undefined
  error: boolean
  googleMapsLoaded: boolean
  loggedInAuth: Auth | undefined
  user: User | undefined
  userPermissions: any
}

export const initialAppState: AuthState = {
  auth: undefined,
  error: false,
  googleMapsLoaded: false,
  loggedInAuth: undefined,
  user: undefined,
  userPermissions: [],
};

export const appSlice = createSlice({
  name: 'auth',
  initialState: initialAppState,
  reducers: {
    setAuth: (state, action: PayloadAction<Auth | undefined>) => {
      state.auth = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setGoogleMapsLoaded: (state, action: PayloadAction<boolean>) => {
      state.googleMapsLoaded = action.payload;
    },
    setLoggedInAuth: (state, action: PayloadAction<Auth | undefined>) => {
      state.loggedInAuth = action.payload;
    },
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload;
    },
    actionUserPermissions: (state, action) => {
      state.userPermissions = action.payload.permissions;
    },
  },
});

export const selectUserPermissions = (state: RootState) => state.app.userPermissions;
export const selectAuth = (state: RootState) => state.app.auth;
export const selectError = (state: RootState) => state.app.error;
export const selectGoogleMapsLoaded = (state: RootState) => state.app.googleMapsLoaded;
export const selectLoggedInUser = (state: RootState) => state.app.loggedInAuth;
export const selectUser = (state: RootState) => state.app.user;
export const selectMlsIdList = createSelector([selectUser], (user) => {
  if (user != null) {
    return user.user_offices!.flatMap((it) => it.office.group.mls_subscriptions!)
      .map((it) => it.mls.id!)
      .filter((element, index, array) => array.indexOf(element) === index);
  }
  return [];
});
export const selectGroupIdList = createSelector([selectUser], (user) => {
  if (user != null) {
    return user.user_offices!.map((it) => it.office.group.id!)
      .filter((element, index, array) => array.indexOf(element) === index);
  }
  return [];
});
export const selectOfficeIdList = createSelector([selectUser], (user) => {
  if (user != null) {
    return user.user_offices!.map((it) => it.office.id!)
      .filter((element, index, array) => array.indexOf(element) === index);
  }
  return [];
});
export const selectTrackingUserId = createSelector([selectLoggedInUser], (user) => {
  if (user != null) {
    return user.user_id;
  }
  return null;
});

export const {
  setAuth,
  setError,
  setGoogleMapsLoaded,
  setLoggedInAuth,
  setUser,
  actionUserPermissions,
} = appSlice.actions;

export default appSlice.reducer;
