import React, { useEffect } from 'react';
import toast, { Toaster, useToasterStore } from "react-hot-toast";
import { Redirect, Route } from 'react-router-dom';
import { selectAuth } from '../state/appSlice';
import { useAppSelector } from '../state/hooks';

interface AppRouteProps {
  Component: React.FC<any>,
  exact?: boolean,
  isAuthProtected: boolean,
  isSuperuserProtected: boolean,
  Layout: React.FC<any>
  path: string
}

const AppRoute = ({
  Component, exact, isAuthProtected, isSuperuserProtected, Layout, path,
}: AppRouteProps) => {
  const auth = useAppSelector(selectAuth);
  const { toasts } = useToasterStore();
  const TOAST_LIMIT = 1;
  // Enforce Limit
  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
  }, [toasts]);

  return (
    <Route
      exact={exact}
      path={path}
      render={() => {
        if ((isAuthProtected || isSuperuserProtected) && auth === undefined) {
          return (
            <Redirect to={{ pathname: '/login', state: { from: path } }} />
          );
        }
        if (isSuperuserProtected && !auth!.is_superuser) {
          return (
            <Redirect to={{ pathname: '/', state: { from: path } }} />
          );
        }

        return (
          <Layout>
            <Component />
            <Toaster />
          </Layout>
        );
      }}
    />
  );
};

AppRoute.defaultProps = {
  exact: false,
};

export default AppRoute;
