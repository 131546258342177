import { Tooltip } from "bootstrap";
import { useEffect } from "react";
// export interface HelpIconProps {
//   tooltip: string;
//   width?: number;
//   height?: number;
// }

const HelpIcon = ({ tooltip, width = 16, height = 16 }: any) => {
  useEffect(() => {
    // Find the tooltip element
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]'),
    );

    // Initialize tooltips
    const tooltipList = tooltipTriggerList.map(
      (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl, {
        placement: "top", // Adjust placement as needed
      }),
    );

    // // Cleanup when the component unmounts
    // return () => {
    //   tooltipList.forEach((tooltipProps: any) => {
    //     tooltipProps.dispose();
    //   });
    // };
  }, []);

  return (
    // <div className="custom-tooltip-container">
    //   <svg
    //     xmlns="http://www.w3.org/2000/svg"
    //     width={`${width}`}
    //     height={`${height}`}
    //     viewBox={`0 0 ${width} ${height}`}
    //     fill="none"
    //     className="cursor-pointer"
    //   >
    //     <path
    //       d="M7.33301 12.0007H8.66634V10.6673H7.33301V12.0007ZM7.99967 1.33398C4.31967 1.33398 1.33301 4.32065 1.33301 8.00065C1.33301 11.6807 4.31967 14.6673 7.99967 14.6673C11.6797 14.6673 14.6663 11.6807 14.6663 8.00065C14.6663 4.32065 11.6797 1.33398 7.99967 1.33398ZM7.99967 13.334C5.05967 13.334 2.66634 10.9407 2.66634 8.00065C2.66634 5.06065 5.05967 2.66732 7.99967 2.66732C10.9397 2.66732 13.333 5.06065 13.333 8.00065C13.333 10.9407 10.9397 13.334 7.99967 13.334ZM7.99967 4.00065C6.52634 4.00065 5.33301 5.19398 5.33301 6.66732H6.66634C6.66634 5.93398 7.26634 5.33398 7.99967 5.33398C8.73301 5.33398 9.33301 5.93398 9.33301 6.66732C9.33301 8.00065 7.33301 7.83398 7.33301 10.0007H8.66634C8.66634 8.50065 10.6663 8.33398 10.6663 6.66732C10.6663 5.19398 9.47301 4.00065 7.99967 4.00065Z"
    //       fill="white"
    //     />
    //   </svg>
    //   <div className="custom-tooltip-wrapper">{tooltip}</div>
    // </div>
    <div className="cursor-pointer" data-bs-toggle="tooltip" data-placement="top" data-bs-custom-class="custom-tooltip" title={tooltip} >
      <svg xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox={`0 0 ${width} ${height}`} fill="none">
        <path d="M7.33301 12.0007H8.66634V10.6673H7.33301V12.0007ZM7.99967 1.33398C4.31967 1.33398 1.33301 4.32065 1.33301 8.00065C1.33301 11.6807 4.31967 14.6673 7.99967 14.6673C11.6797 14.6673 14.6663 11.6807 14.6663 8.00065C14.6663 4.32065 11.6797 1.33398 7.99967 1.33398ZM7.99967 13.334C5.05967 13.334 2.66634 10.9407 2.66634 8.00065C2.66634 5.06065 5.05967 2.66732 7.99967 2.66732C10.9397 2.66732 13.333 5.06065 13.333 8.00065C13.333 10.9407 10.9397 13.334 7.99967 13.334ZM7.99967 4.00065C6.52634 4.00065 5.33301 5.19398 5.33301 6.66732H6.66634C6.66634 5.93398 7.26634 5.33398 7.99967 5.33398C8.73301 5.33398 9.33301 5.93398 9.33301 6.66732C9.33301 8.00065 7.33301 7.83398 7.33301 10.0007H8.66634C8.66634 8.50065 10.6663 8.33398 10.6663 6.66732C10.6663 5.19398 9.47301 4.00065 7.99967 4.00065Z" fill="white"/>
      </svg>
    </div>
  );
};

export default HelpIcon;
