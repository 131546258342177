import RecomLeaderboard from "@components/RecommendationPage/RecomLeaderboard";
import RecomSlider from "@components/RecommendationPage/RecomSlider";
import RecomTopBanner from "@components/RecommendationPage/RecomTopBanner";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { selectAuth } from "@state/appSlice";
import { useAppSelector } from "@state/hooks";
import { http } from "@utils/http";
import { useEffect, useState } from "react";

function Recommendations() {
  const [isDataLoading, setIsDataLoading] = useState({
    isLeaderboardTopPositionDataLoading: false,
  });
  const [topLeaderboardData, setTopLeaderboardData] = useState([] as any);
  const [currentUserData, setCurrentUserData] = useState({} as any);
  const auth = useAppSelector(selectAuth);
  const currentUserId = auth?.user_id;

  function setDataLoadingState(fieldName: string, value: boolean) {
    setIsDataLoading((prev: any) => ({
      ...prev,
      [fieldName]: value,
    }));
  }

  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  function fetchLeaderboardData(type: string = "weekly") {
    setDataLoadingState("isLeaderboardTopPositionDataLoading", true);
    setCurrentUserData({});
    setTopLeaderboardData([]);
    http
      .get(`/recommendation/get-leaderboard.json`)
      .then((res: any) => {
        let theData = res?.data?.leaderboard;
        if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
          theData = [];
        }
        setTopLeaderboardData(theData);
        setCurrentUserData(
          theData.find((u: any) => u.user_id === currentUserId),
        );
        setDataLoadingState("isLeaderboardTopPositionDataLoading", false);
      })
      .catch((err) => {
        setCurrentUserData({});
        setDataLoadingState("isLeaderboardTopPositionDataLoading", false);
        console.log(err);
      });
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-7">
          <RecomTopBanner
            currentUserData={currentUserData}
            isDataLoading={isDataLoading?.isLeaderboardTopPositionDataLoading}
          />
          <div className="mb-3">
            <RecomSlider />
          </div>
        </div>
        <div className="col-12 col-lg-5">
          <div className="">
            <RecomLeaderboard
              isDataLoading={isDataLoading?.isLeaderboardTopPositionDataLoading}
              currentUserData={currentUserData}
              fetchLeaderBoardDataEmit={(type: any) => fetchLeaderboardData(type)}
              leaderBoardData={topLeaderboardData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recommendations;
