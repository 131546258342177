import Pagination from "@components/common/Pagination/Pagination";
import PerPageOptionSelector from "@components/common/PerPageOptionSelector";
import TablePlaceHolder from "@components/common/TablePlaceHolder";
import TableSortIcon from "@components/common/TableSortIcon";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { faker } from "@faker-js/faker";
import { http } from "@utils/http";
import { EyeIcon } from "@utils/icons";
import { colSortingV2 } from "@utils/table-sort";
import { useEffect, useState } from "react";
import DetailsOfficeModal from "./DetailsOfficeModal";

function RecruitmentRetentionTable() {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [sortColumns, setSortColumns] = useState([] as any);
  const [sortAPIColumns, setSortAPIColumns] = useState('' as any);
  const [agentRecruitRetention, setAgentRecruitRetention] = useState([] as any);
  const [modals, setModals] = useState({
    deleteOfficeModal: false,
    createOfficeModal: false,
    updateOfficeModal: false,
    detailsOfficeModal: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0 as any);
  const [perPage, setPerPage] = useState(10);

  const columns: any = [
    // {
    //   name: "#",
    //   slug: "",
    //   sort: false,
    // },
    {
      name: "Office",
      slug: "office_name",
      headerClassNames: "ps-2",
      subHeaderClassNames: "",
      sort: true,
    },
    {
      name: "Agent Turnover",
      slug: "total_agents_resignation",
      headerClassNames: "",
      subHeaderClassNames: "justify-content-center",
      sort: true,
    },
    
    {
      name: "Agents Recruited",
      slug: "total_new_agents",
      headerClassNames: "",
      subHeaderClassNames: "justify-content-center",
      sort: true,
    },
    {
      name: "Net Agents",
      slug: "total_agents",
      headerClassNames: "",
      subHeaderClassNames: "justify-content-center",
      sort: true,
    },
    {
      name: "Agent Turnover Ratio",
      slug: "new_agent_to_agent_resignation_ratio",
      headerClassNames: "",
      subHeaderClassNames: "justify-content-center",
      sort: true,
    },
    // {
    //   name: "Net Revenue",
    //   slug: "net_revenue",
    //   sort: true,
    // },
    // {
    //   name: "",
    //   // classNames: "d-none d-md-block",
    //   headerClassNames: "",
    //   subHeaderClassNames: "",
    //   sort: false,
    // },
  ];

  useEffect(() => {
    getAgentRecruitRetention();
  }, [currentPage, perPage, sortAPIColumns]);

  function getAgentRecruitRetention() {
    setIsDataLoading(true);
    http
      .get("/office/agent-recruiting-retention-table.json")
      .then((response: any) => {
        const { data } = response;
        let theData = data?.retention;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            office_name: faker.company.name(),
          }));
        }
        
        setAgentRecruitRetention(theData);
        setTotalPage(data?.last_page);
        setIsDataLoading(false);
      })
      .catch((error: any) => {
        setIsDataLoading(false);
        console.log(error);
      });
  }

  /**
   * Modal close emit
   * Common for all typeof modal.
   *
   * @returns {void}
   */
  function modalCloseEmit(data: any) {
    setModals((prevState) => ({
      ...prevState,
      [data.modalName]: false,
    }));
  }

  function deleteOfficeModalOpen(data: any) {
    setModals((prevState) => ({
      ...prevState,
      deleteOfficeModal: true,
    }));
  }

  function createOfficeModalOpen(data: any) {
    setModals((prevState) => ({
      ...prevState,
      createOfficeModal: true,
    }));
  }

  function updateOfficeModalOpen(data: any) {
    setModals((prevState) => ({
      ...prevState,
      createOfficeModal: true,
    }));
  }

  function detailsOfficeModalOpen(data: any) {
    setModals((prevState) => ({
      ...prevState,
      detailsOfficeModal: true,
    }));
  }
  // Define a function to handle column sorting
  const handleSort = (column: any) => {
    const sortingOutput: any = colSortingV2(
      agentRecruitRetention,
      sortColumns,
      column,
    );
    setSortColumns(sortingOutput.columns);
    setSortAPIColumns(sortingOutput?.apiColumn);
    // setAgentRecruitRetention(sortingOutput.data);
  };

  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const renderTableActionButton = (data: any) => (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm d-flex align-items-center gap-1"
        // onClick={() => detailsOfficeModalOpen("")}
      >
        <span>
          <EyeIcon />
        </span>
        <span className="ps-1 d-none d-md-block">View More</span>
      </button>
    </>
  );
  
  return (
    <>
      {/* {modals.deleteOfficeModal && (
        <DeleteAgentModal
          modalStatus={modals.deleteOfficeModal}
          modalName="deleteOfficeModal"
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}

      {modals.createOfficeModal && (
        <CreateAgentModal
          modalStatus={modals.createOfficeModal}
          modalName="createOfficeModal"
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}

      {modals.updateOfficeModal && (
        <UpdateAgentModal
          modalStatus={modals.updateOfficeModal}
          modalName="updateOfficeModal"
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
*/}
      {modals.detailsOfficeModal && (
        <DetailsOfficeModal
          modalStatus={modals.detailsOfficeModal}
          modalName="detailsOfficeModal"
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
      <div className="row">
        <div className="">
          <div
            className="custom-modal-inner__padding"
            style={{ backgroundColor: "#2A3042" }}
          >
            <div className="d-flex mb-3 justify-content-between align-items-center">
              <div className="header-title">Recruitment & Retention</div>
              <p className="mb-0">Last 12 Months</p>
            </div>
            <div className="table-responsive scrollable-body-2">
              <table className="align-middle table-nowrap table-check table table-striped table-sm fixed-header fixed-column">
                <thead className="table-light">
                  <tr role="row">
                    {columns.map((column: any) => (
                      <th
                        key={column.slug}
                        onClick={() => {
                          if (column.sort) {
                            handleSort(column.slug);
                          }
                        }}
                        role="button"
                        className={`${column?.headerClassNames}`}
                      >
                        <div
                          className={`d-flex align-items-center ${column?.subHeaderClassNames}`}
                        >
                          <TableSortIcon
                            sortColumns={sortColumns}
                            column={column}
                          ></TableSortIcon>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <TablePlaceHolder
                    items={agentRecruitRetention}
                    loading={isDataLoading}
                    rows={13}
                    cols={8}
                  />
                  {!isDataLoading &&
                    agentRecruitRetention?.map((agentRR: any, id: any) => (
                      <tr key={agentRR.id}>
                        {/* <td className="ps-2">{agentRR.id}</td> */}
                        <td className="ps-2 first-column">
                          <div className="d-flex justify-content-between gap-1">
                            {agentRR.office_name ?? ""}
                            <span className="d-flex d-md-none justify-content-end">
                              {renderTableActionButton(agentRR)}
                            </span>
                          </div>
                        </td>
                        <td className="text-center">
                          {agentRR.total_agents_resignation ?? 0}
                        </td>
                        <td className="text-center">{agentRR.total_new_agents}</td>
                        <td className="text-center">{agentRR.total_agents}</td>
                        <td className="text-center">
                          {agentRR.new_agent_to_agent_resignation_ratio ? parseFloat(agentRR.new_agent_to_agent_resignation_ratio).toFixed(2) : 0 } %
                        </td>
                        {/* <td>{agentRR.net_revenue}</td> */}
                        {/* <td className="d-none d-md-block">
                          {renderTableActionButton(agentRR)}
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>

              {/* {((agentRecruitRetention?.length && totalPage > 1) && (
                <div>
                  <Pagination
                    pageCount={totalPage}
                    perPage={perPage}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                  />
                </div>
              )) ||
                ""} */}
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-3 d-flex gap-2 align-items-center">
                <p className="mb-0 custom-fs-12-to-14">Rows Per Page</p>
                <PerPageOptionSelector
                  perPageEmitSet={(value: any) => setPerPage(value)}
                  labelUnit=" "
                  // placeholderText="select office"
                />
              </div>
              <div className="col-12 col-md-9 d-flex justify-content-center justify-content-md-start align-items-center">
                <Pagination
                  pageCount={totalPage}
                  perPage={perPage}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruitmentRetentionTable;
