import Select from "react-select";

function SearchSelect(props:any) {
  return (
    <Select
      classNamePrefix="project"
      className={`${props?.classNames}`}
      styles={{
        control: (base, state) => ({
          ...base,
          fontSize: 13,
          cursor: "pointer",
          border: state.isFocused && "1px solid #1E2330 !important",
          borderColor: "#1E2330",
          boxShadow: state.isFocused && "0 0 0 1px #1E2330 !important",
          background: "#1E2330 !important",
          width: props?.width ?? "",
          padding: props?.insidePadding ?? "",
          "&:hover": {
            // Overwrittes the different states of border
            borderColor: "#1E2330",
          },
        }),
        input: (base) => ({
          ...base,
          color: "#BFC8E2",
          fontSize: 13,
          "input:focus": {
            boxShadow: "none",
          },
        }),
        placeholder: (base) => ({
          ...base,
          fontSize: 13,
          color: "#BFC8E2",
        }),
        option: (styles, state) => ({
          ...styles,
          cursor: "pointer",
          color: "#BFC8E2",
          background: state.isSelected ? "#31394E" : "#1E2330",
          width: props?.optionWidth ?? "",
          backgroundColor: "#1E2330", // Set background color for options
          "&:hover": {
            backgroundColor: "#31394E", // Set background color for options on hover
          },
        }),
        menu: (base) => ({
          ...base,
          color: "#BFC8E2",
          backgroundColor: "#1E2330", // Set background color for the entire menu
        }),
        singleValue: (base) => ({
          ...base,
          color: "#BFC8E2", // Set text color for the selected option
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: 'none',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
      menuPortalTarget={document.body} 
      {...props}
    />
  );
}

export default SearchSelect;
