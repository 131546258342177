import Pagination from "@components/common/Pagination/Pagination";
import TablePlaceHolder from "@components/common/TablePlaceHolder";
import TableSortIcon from "@components/common/TableSortIcon";
import { colSortingV2 } from "@utils/table-sort";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
// css import
import PerPageOptionSelector from "@components/common/PerPageOptionSelector";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { faker } from "@faker-js/faker";
import { http } from "@utils/http";
import { SearchIcon } from "@utils/icons";
import "react-loading-skeleton/dist/skeleton.css";
import { Popover, PopoverBody } from "reactstrap";
import NeutrinoAgentTableLast12OpenChart from "./NeutrinoAgentTableLast12OpenChart";

function NeutrinoAgentOpportunities(props: any) {
  // const data1: any = [
  //   {
  //     id: 15,
  //     agent: "Bonnie Stein",
  //     contract: "63",
  //     logins_per_mo: "55",
  //     sends_per_mo: "8",
  //     open_rate: "9",
  //     // ctr: "0",
  //   },
  //   {
  //     id: 16,
  //     agent: "Kate Mercado",
  //     contract: "35",
  //     logins_per_mo: "35",
  //     sends_per_mo: "0",
  //     open_rate: "9",
  //     // ctr: "0",
  //   },
  //   {
  //     id: 17,
  //     agent: "Cindy Rafeld",
  //     contract: "34",
  //     logins_per_mo: "7",
  //     sends_per_mo: "27",
  //     open_rate: "7",
  //     // ctr: "0",
  //   },
  //   {
  //     id: 18,
  //     agent: "Alix Prince",
  //     contract: "29",
  //     logins_per_mo: "25",
  //     sends_per_mo: "4",
  //     open_rate: "6",
  //     // ctr: "0",
  //   },
  //   {
  //     id: 19,
  //     agent: "Carola Schonander",
  //     contract: "27",
  //     logins_per_mo: "9",
  //     sends_per_mo: "18",
  //     open_rate: "6",
  //     // ctr: "0",
  //   },
  // ];
  // const columns: any = [
  //   {
  //     name: "#",
  //     slug: "",
  //     sort: false,
  //   },
  //   {
  //     name: "Agent",
  //     slug: "agent",
  //     sort: true,
  //   },
  //   {
  //     name: "Total CMAs",
  //     slug: "total CMAs",
  //     sort: true,
  //   },
  //   {
  //     name: "Live CMAs",
  //     slug: "Live CMAs",
  //     sort: true,
  //   },
  //   {
  //     name: "PDF CMAs",
  //     slug: "PDF CMAs",
  //     sort: true,
  //   },
  // ];
  // const data2: any = [
  //   {
  //     id: 15,
  //     agent: "Sara Reel",
  //     contract: "53",
  //     logins_per_mo: "3",
  //     ctr: "56",
  //   },
  //   {
  //     id: 16,
  //     agent: "Martie Marsala",
  //     contract: "40",
  //     logins_per_mo: "1",

  //     ctr: "41",
  //   },
  //   {
  //     id: 17,
  //     agent: "Terri Prenty",
  //     contract: "34",
  //     logins_per_mo: "0",
  //     ctr: "34",
  //   },
  //   {
  //     id: 18,
  //     agent: "Lori Delaparra",
  //     contract: "25",
  //     logins_per_mo: "2",

  //     ctr: "27",
  //   },
  //   {
  //     id: 19,
  //     agent: "Holly Mellstrom",
  //     contract: "16",
  //     logins_per_mo: "8",
  //     ctr: "24",
  //   },
  // ];
  // const columns2: any = [
  //   {
  //     name: "#",
  //     slug: "",
  //     sort: false,
  //   },
  //   {
  //     name: "Agent",
  //     slug: "agent",
  //     sort: true,
  //   },
  //   {
  //     name: "Contact",
  //     slug: "total CMAs",
  //     sort: true,
  //   },
  //   {
  //     name: "Login per mo",
  //     slug: "Live CMAs",
  //     sort: true,
  //   },
  //   {
  //     name: "CTR",
  //     slug: "PDF CMAs",
  //     sort: true,
  //   },
  // ];

  const columns: any = [
    // {
    //   name: "#",
    //   slug: "",
    //   sort: false,
    // },
    {
      name: "Agent",
      slug: "first_name",
      sort: true,
      classNames: "",
    },
    {
      name: "Total Agents",
      slug: "total_agents",
      sort: true,
      classNames: "justify-content-center",
    },
    {
      name: "Leads",
      slug: "leads",
      sort: true,
      classNames: "justify-content-center",
    },
    {
      name: "Office",
      slug: "office_name",
      sort: true,
      classNames: "",
    },
    {
      name: "Engagement score",
      slug: "engagement_score_percentage",
      sort: true,
      classNames: "justify-content-center",
    },
  ];
  const [agentOportunityData, setAgentOportunityData] = useState([] as any);
  const [agentOportunityApiData, setAgentOportunityApiData] = useState(
    [] as any,
  );
  const [selectedAgent, setSelectedAgent] = useState(null as any);
  const [popOverOpen, setPopOverOpen] = useState({
    id: "",
    isOpen: false,
  });

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [sortColumns, setSortColumns] = useState([] as any);
  const [sortAPIColumns, setSortAPIColumns] = useState("" as any);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0 as any);
  const [perPage, setPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    getAgentOpportunity();
  }, [currentPage, searchText, perPage, sortAPIColumns]);

  function getAgentOpportunity(currentPageData = currentPage) {
    setIsDataLoading(true);
    http
      .get("/tools/neutrino--overall-website-engagement-score-by-agent.json")
      .then((response: any) => {
        const { data }: any = response;
        let theData = data?.website_marketing;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            first_name: faker.person.firstName(),
            last_name: faker.person.lastName(),
          }));
        }
        setAgentOportunityData(theData);
        setAgentOportunityApiData(theData);
        setTotalPage(data?.last_page);
        setIsDataLoading(false);
      }).catch((error) => {
        console.log(error);
        setIsDataLoading(false);
      });
  }

  const handleMouseEnter = (e: any, agent: any, columnName = "") => {
    e.stopPropagation();
    setPopOverOpen({
      id: `${agent?.bw_id}-${columnName}`,
      isOpen: true,
    });
    setSelectedAgent(agent);
  };
  const handleMouseLeave = (e: any, agent: any, columnName = "") => {
    e.stopPropagation();
    setPopOverOpen({
      id: `${agent?.bw_id}-${columnName}`,
      isOpen: false,
    });
    setSelectedAgent(null);
  };

  // Define a function to handle column sorting
  const handleSort = (column: any) => {
    const sortingOutput: any = colSortingV2(
      agentOportunityApiData,
      sortColumns,
      column,
    );
    setSortAPIColumns(sortingOutput?.apiColumn);
    setSortColumns(sortingOutput.columns);
    // setAgents(sortingOutput.data);
  };

  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  function handleSearch(searchValue: any) {
    setCurrentPage(1);
    setSearchText(searchValue);
  }
  // debouncing when searching.
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);
  return (
    <div
      className="custom-modal-inner__padding h-100"
      style={{ background: "#2B3041" }}
    >
      <div className="row row-gap-2 mb-3 ">
        <div className="col-12">
          <div className="d-inline-block">
            <span className="header-title-6 fw-bold text-white">
              Overall Website Engagement Score by Agent
            </span>
            {/* <div className="d-inline-block align-text-top ps-1">
                <HelpIcon
                  tooltip="Overall Website Engagement Score by Agent"
                  width={20}
                  height={20}
                />
              </div> */}
          </div>
        </div>
        <div className="col-12 d-grid justify-lg-content-end">
          <div className="position-relative">
            <input
              id="search"
              placeholder="Type to search for an Agent..."
              type="text"
              className="form-control ps-3"
              onChange={(e) => debouncedHandleSearch(e.target.value)}
            />
            <div className="position-absolute custom-top-2 custom-right-2">
              <SearchIcon />
            </div>
          </div>
          {/* <div className="">
              <SearchSelect
                options={[
                  {
                    id: 1,
                    title: "All Tools",
                  },
                  {
                    id: 2,
                    title: "Tools-1",
                  },
                  {
                    id: 2,
                    title: "Tools-2",
                  },
                ]}
                width="150px"
                isClearable
                getOptionValue={(option: any) => option.id}
                getOptionLabel={(option: any) => `${option.title}`}
                placeholders="Select Office"
              />
            </div> */}
        </div>
      </div>
      <div className="table-responsive scrollable-body-2">
        <table className="align-middle table-nowrap table-check table table-striped table-sm fixed-header fixed-column">
          <thead className="table-light" style={{ background: "#33394C" }}>
            <tr role="row">
              {columns.map((column: any) => (
                <th
                  key={column.slug}
                  onClick={() => {
                    if (column.sort) {
                      handleSort(column.slug);
                    }
                  }}
                  role="button"
                >
                  <div
                    className={`d-flex p-1 align-items-center ${column?.classNames}`}
                  >
                    <TableSortIcon
                      sortColumns={sortColumns}
                      column={column}
                    ></TableSortIcon>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <TablePlaceHolder
              items={agentOportunityData}
              loading={isDataLoading}
              rows={13}
              cols={8}
            />
            {!isDataLoading &&
              agentOportunityData?.map((agent: any, id: any) => (
                <tr key={agent.bw_id}>
                  {/* <td>{agent.agentid}</td> */}
                  <td className="p-2 text-wrap">
                    {agent?.first_name} {agent?.last_name}
                  </td>
                  <td className="text-center">{agent?.total_agents}</td>
                  <td
                    className="text-center"
                    id={`PopoverLegacy-${agent.bw_id}-total-leads`}
                    onMouseEnter={(e: any) => handleMouseEnter(e, agent, "total-leads")}
                    onMouseLeave={(e: any) => handleMouseLeave(e, agent, "total-leads")}
                  >
                    {agent?.leads}
                    {selectedAgent &&
                    popOverOpen?.id === `${agent?.bw_id}-total-leads` &&
                    popOverOpen?.isOpen ? (
                      <Popover
                        // placement="auto"
                        isOpen={popOverOpen?.isOpen}
                        target={`PopoverLegacy-${agent.bw_id}-total-leads`}
                        className="custom-popover"
                      >
                        {/* <PopoverHeader>Sample Popover Title {selectedAgent && selectedAgent?.email_campaigns}</PopoverHeader> */}
                        <PopoverBody>
                          <NeutrinoAgentTableLast12OpenChart
                            agentsInfo={agent}
                            fieldToShow="total_leads"
                            chartTitle="Total Leads"
                          />
                        </PopoverBody>
                      </Popover>
                      ) : (
                        ""
                      )}
                  </td>
                  <td className="">{agent?.office_name}</td>
                  <td className="text-center">
                    {agent?.engagement_score_percentage}%
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="row mt-3">
        <div className="col-12 col-md-4 d-flex gap-2 align-items-center">
          <p className="mb-0 custom-fs-12-to-14">Rows Per Page</p>
          <PerPageOptionSelector
            perPageEmitSet={(value: any) => setPerPage(value)}
            labelUnit=" "
          />
        </div>
        <div className="col-12 col-md-8 d-flex justify-content-center justify-content-md-start align-items-center">
          <Pagination
            pageCount={totalPage}
            perPage={perPage}
            onPageChange={handlePageChange}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default NeutrinoAgentOpportunities;
