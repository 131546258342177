import { SORT_ASC, SORT_DESC } from "@constants/variables";
import { ArrowDownIcon, ArrowUpDownIcon, ArrowUpIcon } from "@utils/icons";
import { useEffect, useState } from "react";

function TableSortIcon({ sortColumns, column }: any) {
  // let sortColumns: any;
  // let column: any;
  const [theStatus, setTheStatus] = useState('.text-warning' as any);
  const [selectedSortColumns, setSelectedSortColumns] = useState({} as any);
  const [fillColor, setFillColor] = useState("#BFC8E2");

  useEffect(() => {
    const index: any = sortColumns.findIndex((item: any) => item.column === column.slug);
    let status:any = 'text-secondary';
    setSelectedSortColumns(sortColumns[index]);
    
    if (index !== -1) {
      const theCol: any = sortColumns[index];
      if (theCol.order === SORT_ASC || theCol.order === SORT_DESC) {
        status = 'text-success';
        setFillColor("#65F3AF");
      } else {
        status = 'text-secondary';
        setFillColor("#BFC8E2");
      }
    } else {
      status = 'text-secondary';
      setFillColor("#BFC8E2");
    }
    setTheStatus(status);
  }, [sortColumns]);

  function getSortIcon() {
    if (!column?.sort) {
      return "";
    } else {
      if (selectedSortColumns?.order === SORT_DESC) {
        return (
          <ArrowUpIcon classNames={`${theStatus}`} fillColor={fillColor}/>
        );
      } else if (selectedSortColumns?.order === SORT_ASC) {
        return (
          <ArrowDownIcon classNames={`${theStatus}`} fillColor={fillColor}/>
        );
      } else {
        return (
          <ArrowUpDownIcon classNames={`${theStatus}`} fillColor={fillColor}/>
        );
      }
    }
  }
  return (
    <>
      <p className={`mb-0 ${theStatus}`}>{column.name}</p>
      {
        getSortIcon()
      }
    </>
  );
}

export default TableSortIcon;
