const defaultChartOptions: any = {
  chart: {
    id: "default-chart",
    height: 350,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  colors: ["#F5822F", "#CF4AFE"],
  xaxis: {
    labels: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    categories: [],
  },
  yaxis: {
    labels: {
      show: false,
      axisTicks: {
        show: false, // Hide ticks on the right side of the y-axis
      },
    },
  },
  // grid: {
  //   padding: {
  //     left: -10,
  //   },
  // },
  legend: {
    horizontalAlign: "center", // Center legend for desktop
  },
};
export default defaultChartOptions;
