import HelpIcon from "@components/common/HelpIcon";
import Placeholder from "@components/common/Placeholder";
import generateColumnChartOptions from "@utils/chart/bar-chart";
import defaultChartOptions from "@utils/chart/default-chart-options";
import { http } from "@utils/http";
import {
  generateListVsSellSideData,
  generateSVsLTicks,
} from "@utils/transform/financialPage";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function BuySideLeftSide() {
  const [sellVsListSide, setSellVsListSide] = useState({} as any);
  const [sellVsListSideApiData, setSellVsListSideApiData] = useState({} as any);
  const [totalActiveListing, setTotalActiveListing] = useState([] as any);
  const [totalpendingUC, setTotalPendingUC] = useState({} as any);
  const [pendingComparison, setPendingComparison] = useState({} as any);
  const [listingComparison, setListingComparison] = useState({} as any);
  const [theOptions, setTheOptions] = useState({} as any);
  const [isDataLoading, setIsDataLoading] = useState({
    sellvslistDataLoading: true,
    tLDataLoading: true,
    tPUCDataLoading: true,
    pendingComparisonDataLoading: true,
    listingComparisonDataLoading: true,
  });

  useEffect(() => {
    getSellVsListSideData();
    getTotalListings();
    getTPendingsUC();
    getPendingComparisonData();
    getListingComparisonData();
  }, []);

  function getSellVsListSideData() {
    setIsDataLoading((prev: any) => ({
      ...prev,
      sellVsListSideData: true,
    }));
    http
      .get("/financial/sell-side-vs-list-side.json")
      .then((response: any) => {
        const apiData = response?.data?.sell_side_vs_list_side;
        setSellVsListSideApiData(apiData);

        const theData: any = generateListVsSellSideData(2023, apiData);
        setSellVsListSide(theData);

        const chartOptions: any = generateColumnChartOptions({
          theId: "line-chart",
          colors: ["#F2CA6B", "#CF4AFE", "#F5822F"],
          categories: theData?.categories,
          ticks: generateSVsLTicks(theData ?? []),
          columnWidthProps: "70%",
          // ticksPreLast: ["$", 'k'],
          // forecast: 3,
        });
        setTheOptions(chartOptions);
        setIsDataLoading((prev: any) => ({
          ...prev,
          sellvslistDataLoading: false,
        }));
      })
      .catch((error: any) => {
        setIsDataLoading((prev: any) => ({
          ...prev,
          sellVsListSideData: false,
        }));
        console.log(error);
      });
  }

  function getTotalListings() {
    http
      .get("financial/total-active-listings.json")
      .then((response: any) => {
        setTotalActiveListing(response?.data?.active_listings);
        setIsDataLoading((prev: any) => ({
          ...prev,
          tLDataLoading: false,
        }));
      })
      .catch((error: any) => {
        setIsDataLoading((prev: any) => ({
          ...prev,
          tLDataLoading: false,
        }));
        console.log(error);
      });
  }

  function getTPendingsUC() {
    http
      .get("financial/total-pending-contracts.json")
      .then((response: any) => {
        setTotalPendingUC(response?.data?.all_pending_contracts);
        setIsDataLoading((prev: any) => ({
          ...prev,
          tPUCDataLoading: false,
        }));
      })
      .catch((error: any) => {
        setIsDataLoading((prev: any) => ({
          ...prev,
          tPUCDataLoading: false,
        }));
        console.log(error);
      });
  }

  function getPendingComparisonData() {
    http
      .get("financial/pending-contracts-office-compare.json")
      .then((response: any) => {
        setPendingComparison(response?.data?.office_compare);
        setIsDataLoading((prev: any) => ({
          ...prev,
          pendingComparisonDataLoading: false,
        }));
      })
      .catch((error: any) => {
        setIsDataLoading((prev: any) => ({
          ...prev,
          pendingComparisonDataLoading: false,
        }));
        console.log(error);
      });
  }

  function getListingComparisonData() {
    http
      .get("financial/active-listings-office-comparison.json")
      .then((response: any) => {
        setListingComparison(response?.data?.office_compare);
        setIsDataLoading((prev: any) => ({
          ...prev,
          listingComparisonDataLoading: false,
        }));
      })
      .catch((error: any) => {
        setIsDataLoading((prev: any) => ({
          ...prev,
          listingComparisonDataLoading: false,
        }));
        console.log(error);
      });
  }
  return (
    <>
      <div className="mb-4" style={{ backgroundColor: "#2A3042" }}>
        <div className="custom-modal-inner__padding">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row row-gap-2 row-gap-md-0">
                <div className="col-md-7">
                  <div className="d-flex align-items-center gap-1">
                    <div className="header-title">List Side Vs. Sell Side</div>
                    <HelpIcon tooltip="Buy Side Vs. Sell Side" />
                  </div>
                </div>
                {/* <div className="col-md-5">
                  <div className="">
                    <SearchSelect
                      options={[
                        {
                          id: 1,
                          title: "2023",
                        },
                        {
                          id: 2,
                          title: "2022",
                        },
                        {
                          id: 2,
                          title: "2021",
                        },
                      ]}
                      isClearable
                      getOptionValue={(option: any) => option.id}
                      getOptionLabel={(option: any) => `${option.title}`}
                      placeholders="Select Office"
                    />
                  </div>
                </div> */}
              </div>
              {isDataLoading?.sellvslistDataLoading && (
                <div className="loader-circle"></div>
              )}
              {isDataLoading?.sellvslistDataLoading ? (
                <div id="bar-column-chart" dir="ltr">
                  <ReactApexChart
                    options={defaultChartOptions}
                    series={[]}
                    type="bar"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              ) : (
                <div id="bar-column-chart" dir="ltr">
                  <ReactApexChart
                    options={theOptions}
                    series={[
                      {
                        name: "List Side",
                        data: sellVsListSide?.total_list_side,
                      },
                      {
                        name: "Sell Side",
                        data: sellVsListSide?.total_sell_side,
                      },
                      {
                        name: "Double Ends",
                        data: sellVsListSide?.total_double_side,
                      },
                    ]}
                    type="bar"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              )}
            </div>
            <div className="col-12 col-md-4 mt-2 mt-md-0">
              <div className="">
                <div className="header-title mb-1">Listings Under Contract</div>
                <p className="mb-1 custom-fs-12">Last months</p>

                <div className="d-flex align-items-center gap-2 ">
                  <Placeholder
                    loading={isDataLoading?.tPUCDataLoading}
                    className="custom-w-2 custom-h-2"
                  ></Placeholder>
                  <p className="number-stats-7 mb-0 text-white">
                    {!isDataLoading?.tPUCDataLoading &&
                      totalpendingUC?.pendingListings}
                  </p>
                  {/* <div className="">
                    <Placeholder
                      loading={isDataLoading?.tPUCDataLoading}
                      className="custom-w-2 custom-h-2"
                    ></Placeholder>
                    {!isDataLoading?.tPUCDataLoading &&
                      (() => {
                        if (pendingComparison?.difference_from_average < 0) {
                          return (
                            <span className="badge badge-soft-danger custom-badge-12-to-14 p-1 me-1">
                              {formatDecimalPlaces(
                                pendingComparison?.difference_from_average,
                              )}
                              %
                            </span>
                          );
                        } else {
                          return (
                            <span className="badge badge-soft-success custom-badge-12-to-14 p-1 me-1">
                              +{" "}
                              {formatDecimalPlaces(
                                pendingComparison?.difference_from_average,
                              )}
                              %
                            </span>
                          );
                        }
                      })()}
                  </div>
                  <span className="custom-fs-12-to-14">
                    {pendingComparison?.difference_from_average < 0
                      ? "Lesser"
                      : "Higher"}{" "}
                    than other offices
                  </span> */}
                </div>
              </div>
              <div className="">
                <hr />
              </div>

              <div className="">
                <div className="header-title mb-1">Total Listings</div>
                <p className="mb-1 custom-fs-12">Last months</p>
                <div className="d-flex align-items-center gap-2 ">
                  <Placeholder
                    loading={isDataLoading?.tLDataLoading}
                    className="custom-w-2 custom-h-2"
                  ></Placeholder>
                  <p className="number-stats-7 mb-0 text-white">
                    {!isDataLoading?.tLDataLoading &&
                      totalActiveListing?.listings}
                  </p>
                  {/* <div className="">
                    <Placeholder
                      loading={isDataLoading?.tLDataLoading}
                      className="custom-w-2 custom-h-2"
                    ></Placeholder>
                    {!isDataLoading?.tLDataLoading &&
                      (() => {
                        if (listingComparison?.difference_from_average < 0) {
                          return (
                            <span className="badge badge-soft-danger custom-badge-12-to-14 p-1 me-1">
                              {formatDecimalPlaces(
                                listingComparison?.difference_from_average,
                              )}
                              %
                            </span>
                          );
                        } else {
                          return (
                            <span className="badge badge-soft-success custom-badge-12-to-14 p-1 me-1">
                              +{" "}
                              {formatDecimalPlaces(
                                listingComparison?.difference_from_average,
                              )}
                              %
                            </span>
                          );
                        }
                      })()}
                  </div>
                  <span className="custom-fs-12-to-14">
                    {listingComparison?.difference_from_average < 0
                      ? "Lesser"
                      : "Higher"}{" "}
                    than other offices
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BuySideLeftSide;
