import HelpIcon from "@components/common/HelpIcon";
import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
import { toolsPageToolTip } from "@constants/tooltip-data";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import generateLineChartOptions from "@utils/chart/line-chart";
import { http } from "@utils/http";
import { generateToolsCmaOverallReportTypeData } from "@utils/transform/toolsDetails";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function CmaOverallReportType() {
  const [chartOptions, setChartOptions] = useState({} as any);

  const [chartData, setChartData] = useState({} as any);

  const [isChartDataLoading, setIsChartDataLoading] = useState(false);

  useEffect(() => {
    getOverallCmaReportData();
  }, []);
  // /tools/cloudcma--overall-report-type-to-calculate-percentage-by-agent-last-12-month/
  function getOverallCmaReportData() {
    setIsChartDataLoading(true);
    http
      .get("/tools/cloudcma--overall-report-type-to-calculate-percentage-by-agent-last-12-month.json")
      .then((response: any) => {
        let apiData = response?.data?.cloudcma_engagement;
        if (apiData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
          apiData = [];
        }
        const theData: any = generateToolsCmaOverallReportTypeData(
          apiData,
        );
        setChartData(theData);
        const chartFormattedOptions: any = generateLineChartOptions({
          theId: "line-chart-cloudcma",
          colors: [
            "#F795A8",
            "#00FF7F",
            "#CCCCFF",
            "#FF7F50",
            "#21C0FC",
            "#A4D124",
            "#FFF0F5",
            "#99FFFF",
            "#DCA2DC",
          ],
          categories: theData?.categories ?? [],
          legendPostion: "top",
          legendPostionOnMobile: "bottom",
          legendHorizontalAlignment: "left",
          dashArray: [0],
          // tickAmount: 6,
          ticksPreLast: ["", ""],
        });
        setChartOptions(chartFormattedOptions);
        setIsChartDataLoading(false);
      })
      .catch((error) => {
        setIsChartDataLoading(false);
        console.log(error);
      });
  }

  return (
    <>
      <div
        className="custom-modal-inner__padding h-100"
        style={{ backgroundColor: "#2A3042" }}
      >
        <div className="row mb-3">
          <div className="d-flex mb-3 align-items-center justify-content-between">
            <div className="d-inline-block">
              <span className="header-title-6 fw-bold text-white mb-0 pe-1">
                CRM Report Types
              </span>
              <div className="d-inline-block align-middle">
                <HelpIcon
                  tooltip={toolsPageToolTip.toolsModalCloudCmaCrmPieChart}
                  width={20}
                  height={20}
                />
              </div>
            </div>
            <p className="custom-fs-12 mb-0">Last 12 months</p>
          </div>
          <div>
            {isChartDataLoading && <div className="loader-circle"></div>}
            {isChartDataLoading ? (
              <DefaultLineChartUI />
            ) : (
              <div id="line-chart-gci" dir="ltr">
                <ReactApexChart
                  series={[
                    {
                      name: "CMA",
                      data: chartData?.cma_last_12_months_count,
                    },
                    {
                      name: "Flyer",
                      data: chartData?.flyer_last_12_months_count,
                    },
                    {
                      name: `Homebeat`,
                      data: chartData?.homebeat_last_12_months_count,
                    },
                    {
                      name: `Property`,
                      data: chartData?.property_last_12_months_count,
                    },
                    {
                      name: `Tour`,
                      data: chartData?.tour_last_12_months_count,
                    },
                  ]}
                  options={chartOptions}
                  type="line"
                  height={400}
                  className="apex-charts custom-legend-bg"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CmaOverallReportType;
