/* eslint-disable camelcase */
import HelpIcon from "@components/common/HelpIcon";
import Placeholder from "@components/common/Placeholder";
import TablePlaceHolder from "@components/common/TablePlaceHolder";
import { agentToolTip } from "@constants/tooltip-data";
import { http } from "@utils/http";
import { useEffect, useState } from "react";

function AgentYearlyTechEngagement({ agentId, officeIdProps }: any) {
  const [ytdEngagmentData, setYtdEngagementData] = useState({} as any);
  const [ytdAvgAgentOfficeData, setYtdAvgAgentOfficeData] = useState({} as any);
  const [isYtdEngagementDataLoading, setIsYtdEngagementDataLoading] =
    useState(true);
  const [isAvgAgentOfficeDataLoading, setIsAvgAgentOfficeDataLoading] =
    useState(true);

  useEffect(() => {
    if (agentId) {
      getYtdEngagementData();
    }
  }, [agentId]);

  useEffect(() => {
    if (officeIdProps) {
      getYtdAvgAgentOfficeData();
    }
  }, [officeIdProps]);

  function getYtdEngagementData() {
    setIsYtdEngagementDataLoading(true);
    http
      .get("/agent/yearly-tech-engagement.json")
      .then((response: any) => {
        const { engagement_score } = response?.data;
        setYtdEngagementData(engagement_score[0]);

        setIsYtdEngagementDataLoading(false);
      })
      .catch((error) => {
        setIsYtdEngagementDataLoading(false);
        console.log(error);
      });
  }

  function getYtdAvgAgentOfficeData() {
    setIsAvgAgentOfficeDataLoading(true);
    http
      .get("/agent/average-office-tech-engagement.json")
      .then((response: any) => {
        const { engagement_score } = response?.data;
        setYtdAvgAgentOfficeData(engagement_score[0]);

        setIsAvgAgentOfficeDataLoading(false);
      })
      .catch((error) => {
        setIsAvgAgentOfficeDataLoading(false);
        console.log(error);
      });
  }

  return (
    <div className="mb-4" style={{ backgroundColor: "#2A3042" }}>
      <div className="custom-modal-inner__padding">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="d-flex gap-1 align-items-center">
            <p className="header-title mb-0">Tech Engagement</p>
            <HelpIcon tooltip={agentToolTip?.agentTechEngmnt} />
          </div>
          <p className="mb-0">Last 12 months</p>
        </div>
        <div className="row mt-2">
          <div className="col-lg-8">
            <table className="w-100">
              <TablePlaceHolder
                items={ytdEngagmentData}
                loading={isYtdEngagementDataLoading}
                rows={6}
                cols={8}
              />
            </table>
            <div>
              {!isYtdEngagementDataLoading && (
                <>
                  <div className="row align-content-start">
                    <div className="col-md-5 fw-medium ">CloudCMA</div>
                    <div className="col-md-7">
                      <div className="d-flex align-items-baseline gap-2">
                        <p>{ytdEngagmentData?.cloudcmascore}%</p>
                        <div className="progress animated-progess progress-md flex-grow-1">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${ytdEngagmentData?.cloudcmascore}%`,
                              backgroundColor: `#F5822F`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div>
              {!isYtdEngagementDataLoading && (
                <>
                  <div className="row align-content-start">
                    <div className="col-md-5 fw-medium ">Email</div>
                    <div className="col-md-7">
                      <div className="d-flex align-items-baseline gap-2">
                        <p>{ytdEngagmentData?.emailmarketingscore}%</p>
                        <div className="progress animated-progess progress-md flex-grow-1">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${ytdEngagmentData?.emailmarketingscore}%`,
                              backgroundColor: `#6BF2F2`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div>
              {!isYtdEngagementDataLoading && (
                <>
                  <div className="row align-content-start">
                    <div className="col-md-5 fw-medium ">Website</div>
                    <div className="col-md-7">
                      <div className="d-flex align-items-baseline gap-2">
                        <p>{ytdEngagmentData?.websitescore}%</p>
                        <div className="progress animated-progess progress-md flex-grow-1">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${ytdEngagmentData?.websitescore}%`,
                              backgroundColor: `#F2CA6B`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <p className="mb-1">Average Engagement - All Offices</p>
            <p className="number-stats-4 text-white mb-0 d-flex align-items-center gap-2">
              <Placeholder
                loading={isAvgAgentOfficeDataLoading}
                className="custom-w-12 custom-h-9"
              ></Placeholder>
              {
                !isAvgAgentOfficeDataLoading && ytdAvgAgentOfficeData?.average_engagement_score
              }%
            </p>
            {/* <p>
              <span className="badge badge-soft-success  me-1">+0.4%</span>
              <span>From Previous Period</span>
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentYearlyTechEngagement;
