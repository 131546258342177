import { api as generatedApi } from './api.generated';
import { setAuth, setLoggedInAuth, setUser } from './appSlice';
import { cookies, CookieType } from './cookies';

export interface ApiError {
  data: unknown
  status: number
}

interface QueryFulfilledResponse {
  data: any
  meta: any
}

enum Tags {
  AGENT = 'AGENT',
  EMERGING_MARKET = 'EMERGING_MARKET',
  LISTING = 'LISTING',
  MLS = 'MLS',
  PLATFORM = 'PLATFORM',
  USER = 'USER',
  NOTIFICATION = 'NOTIFICATION'
}

const storeCredentials = async (arg: any, { dispatch, queryFulfilled }: any) => {
  await queryFulfilled.then((loginData: QueryFulfilledResponse) => {
    cookies.set(CookieType.AUTH.valueOf(), loginData.data, { path: '/' });
    cookies.set(CookieType.LOGGED_IN_AUTH.valueOf(), loginData.data, { path: '/' });
    dispatch(setAuth(loginData.data));
    dispatch(setLoggedInAuth(loginData.data));
  }).catch(() => {
    cookies.remove(CookieType.AUTH.valueOf(), { path: '/' });
    cookies.remove(CookieType.LOGGED_IN_AUTH.valueOf(), { path: '/' });
    dispatch(setAuth(undefined));
    dispatch(setLoggedInAuth(undefined));
  });
};

export * from './api.generated';
const api = generatedApi.enhanceEndpoints({
  addTagTypes: Object.values(Tags),
  endpoints: {
    destroyEmergingMarket: (endpoint) => {
      // @ts-ignore
      endpoint.invalidatesTags = () => [Tags.EMERGING_MARKET.valueOf()];
    },
    listEmergingMarkets: (endpoint) => {
      // @ts-ignore
      endpoint.providesTags = () => [Tags.EMERGING_MARKET.valueOf()];
    },
    listNotifications: (endpoint) => {
      // @ts-ignore
      endpoint.providesTags = () => [Tags.NOTIFICATION.valueOf()];
    },
    updateNotification: (endpoint) => {
      // @ts-ignore
      endpoint.invalidatesTags = () => [Tags.NOTIFICATION.valueOf()];
    },
    partialUpdateNotification: (endpoint) => {
      // @ts-ignore
      endpoint.invalidatesTags = () => [Tags.NOTIFICATION.valueOf()];
    },
    completeAllNotification: (endpoint) => {
      // @ts-ignore
      endpoint.invalidatesTags = () => [Tags.NOTIFICATION.valueOf()];
    },
    ignoreAllNotification: (endpoint) => {
      // @ts-ignore
      endpoint.invalidatesTags = () => [Tags.NOTIFICATION.valueOf()];
    },
    loginAsUser: (endpoint) => {
      endpoint.query = (queryArg) => ({
        url: '/api/v1/auth/login_as_user/',
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${queryArg.tokenLogin.token}`,
        },
        params: { user_id: queryArg.userId },
      });
      endpoint.onQueryStarted = async (args, { dispatch, queryFulfilled }) => {
        await queryFulfilled.then((loginData: QueryFulfilledResponse) => {
          cookies.set(CookieType.AUTH.valueOf(), loginData.data, { path: '/' });
          dispatch(setAuth(loginData.data));
          setTimeout(() => window.location.reload());
        });
      };
    },
    login: (endpoint) => {
      endpoint.query = (queryArg) => ({
        url: '/api/v1/auth/login/',
        method: 'POST',
        headers: {
          'Authorization': `Basic ${btoa(`${queryArg.basicLogin.email}:${queryArg.basicLogin.password}`)}`,
        },
      });
      endpoint.onQueryStarted = storeCredentials;
    },
    logout: (endpoint) => {
      endpoint.onQueryStarted = async (arg, { dispatch, queryFulfilled }) => {
        await queryFulfilled.then(() => {
          cookies.remove(CookieType.AUTH.valueOf(), { path: '/' });
          cookies.remove(CookieType.LOGGED_IN_AUTH.valueOf(), { path: '/' });
          dispatch(setAuth(undefined));
          dispatch(setLoggedInAuth(undefined));
        }).catch(() => {
          cookies.remove(CookieType.AUTH.valueOf(), { path: '/' });
          cookies.remove(CookieType.LOGGED_IN_AUTH.valueOf(), { path: '/' });
          dispatch(setAuth(undefined));
          dispatch(setLoggedInAuth(undefined));
        });
      };
    },
    passwordResetLogin: (endpoint) => {
      endpoint.query = (queryArg) => ({
        url: '/api/v1/auth/password_reset_login/',
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${queryArg.tokenLogin.token}`,
        },
      });
      endpoint.onQueryStarted = storeCredentials;
    },
    retrieveUser: (endpoint) => {
      endpoint.onQueryStarted = async (arg, { dispatch, queryFulfilled }) => {
        await queryFulfilled
          .then((userData: QueryFulfilledResponse) => dispatch(setUser(userData.data)))
          .catch(() => dispatch(setUser(undefined)));
      };
    },
  },
});
export { api };
export const {
  useLazyListListAgentsQuery,
  useLazyListUsersQuery,
  useLazyListListingsHotSpotsQuery,
  useLazyListListingsByMonthsQuery,
  useLazyDataEmergingMarketQuery,
  useLazyRetrieveEmergingMarketQuery,
  useLazyByMlsEmergingMarketQuery,
} = api;

export enum LoginIconType {
  CMA = 'CMA',
  COMMUNICATIONS = 'COMMUNICATIONS',
  CRM = 'CRM',
  EMAIL = 'EMAIL',
  PRINT = 'PRINT',
  SOCIAL = 'SOCIAL',
  TRANSACTION = 'TRANSACTION',
}

export enum NotificationStatus {
  PENDING = 'PENDING',
  IGNORED = 'IGNORED',
  COMPLETED = 'COMPLETED',
  COMPLETED_RESOLVED = 'COMPLETED_RESOLVED',
  COMPLETED_UNRESOLVED = 'COMPLETED_UNRESOLVED',
}
