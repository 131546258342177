import HelpIcon from "@components/common/HelpIcon";
import TableSortIcon from "@components/common/TableSortIcon";
import { EmailIcon } from "@utils/icons";
import { colSorting } from "@utils/table-sort";
import { useEffect, useState } from "react";

function TopUsersTable(props:any) {
  const data1: any = [
    {
      id: 1,
      agent: "Glen Erickson",
      gci: "$115,898",
      engagement_score: "53",
    },
    {
      id: 2,
      agent: "Bonnie Stein",
      gci: "$594,718",
      engagement_score: "50",
    },
    {
      id: 3,
      agent: "Sara Reel",
      gci: "$146,557",
      engagement_score: "20",
    },
    {
      id: 4,
      agent: "Farrah DiMaggio",
      gci: "$92,598",
      engagement_score: "16",
    },
    {
      id: 5,
      agent: "Jennifer Holmes",
      gci: "$1,015,073",
      engagement_score: "10",
    },
  ];
  const columns: any = [
    {
      name: "#",
      slug: "",
      classNames: "",
      sort: false,
    },
    {
      name: "Agent",
      slug: "agent",
      classNames: "",
      sort: true,
    },
    {
      name: "GCI",
      slug: "gci",
      classNames: "",
      sort: true,
    },
    {
      name: "Engagement Score",
      slug: "engagement_score",
      classNames: "",
      sort: true,
    },
    // {
    //   name: "",
    //   classNames: "d-none d-md-inline-block",
    //   sort: false,
    // },
  ];
  const data2: any = [
    {
      id: 1,
      agent: "Cathy Landy",
      gci: "$89,363",
      engagement_score: "40",
    },
    {
      id: 2,
      agent: "Christine McCabe",
      gci: "$151,808",
      engagement_score: "33",
    },
    {
      id: 3,
      agent: "Carola Schonander",
      gci: "$113,574",
      engagement_score: "20",
    },
    {
      id: 4,
      agent: "Ali Greene",
      gci: "$231,869",
      engagement_score: "16",
    },
    {
      id: 5,
      agent: "Alix Prince",
      gci: "$492,250",
      engagement_score: "9",
    },
  ];
  const [offices, setOffices] = useState(data1 && data1 as any);
  const [sortColumns, setSortColumns] = useState([] as any);
  useEffect(() => {
    if (props?.cardId === 1) {
      setOffices(data1);
    } else {
      setOffices(data2);
    }
  }, [props?.cardId]);
  // Define a function to handle column sorting
  const handleSort = (column: any) => {
    const sortingOutput: any = colSorting(offices, sortColumns, column);
    setSortColumns(sortingOutput.columns);
    setOffices(sortingOutput.data);
  };

  const renderTableActionButton = (data: any) => (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm d-flex align-items-center justify-content-center gap-1"
      >
        <EmailIcon/>
        <span className="ps-1 d-none d-md-block">Email</span>
      </button>      
    </>
  );

  return (
    <div className="">
      <div className="custom-modal-inner__padding" style={{ background: "#2B3041" }}>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex align-items-center gap-1">
            <div className="header-title">Top Users</div>
            <HelpIcon
              tooltip="Top Users"
            />
          </div>
          <p className="mb-0">Last Month</p>
        </div>
        <div className="table-responsive">
          <table className="align-middle table-nowrap table-check table table-striped table-sm">
            <thead className="table-light">
              <tr role="row">
                {columns.map((column: any) => (
                  <th
                    key={column.slug}
                    onClick={() => {
                      if (column.sort) {
                        handleSort(column.slug);
                      }
                    }}
                    role="button"
                  >
                    <div className="d-flex">
                      <TableSortIcon
                        sortColumns={sortColumns}
                        column={column}
                      ></TableSortIcon>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {offices?.map((office: any, id: any) => (
                <tr key={office.id}>
                  <td>{office.id}</td>
                  <td>
                    <div className="d-flex justify-content-between gap-1">
                      <div className="d-flex gap-1 align-items-center">
                        {/* <img src={profileImg} alt="profile"/> */}
                        <span>{office.agent}</span>
                      </div>
                      {/* <span className="d-flex d-md-none justify-content-end">
                        {renderTableActionButton(office)}
                      </span> */}
                    </div>
                  </td>
                  <td className="text-start">{office.gci}</td>
                  <td className="text-start">{office.engagement_score}</td>
                  {/* <td className="d-none d-md-block">
                    {renderTableActionButton(office)}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TopUsersTable;
