import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

const apiBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, api) => {
    const appState = (api.getState() as any).app;
    if (appState.auth != null && appState.auth.token != null && !('Authorization' in headers)) {
      headers.set('Authorization', `Bearer ${appState.auth.token}`);
    }
    return headers;
  },
});

export default apiBaseQuery;
