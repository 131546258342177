/* eslint-disable no-multiple-empty-lines */
import { faker } from '@faker-js/faker';
import { formatDate, formatMultiplePhoneNumbers } from '../helpers';

function getFullName(data:any) {
  if (process.env.REACT_APP_IS_DEMO === "yes") {
    return faker.person.fullName();
  } 
  return data;
}

function getOfficeName(data:any) {
  if (process.env.REACT_APP_IS_DEMO === "yes") {
    return faker.company.name();
  } 
  return data;
}

function getFakerPhoneNumber(data:any) {
  if (process.env.REACT_APP_IS_DEMO === "yes") {
    return data;
  } 
  return formatMultiplePhoneNumbers(data) ?? "N/A";
}

function getFakeOrRealPhoneNumber(realData:any, fakeData:any) {
  if (process.env.REACT_APP_IS_DEMO === "yes") {
    return fakeData;
  } 
  return formatMultiplePhoneNumbers(realData) ?? "N/A";
}

function getFakeOrRealData(realData:any, fakeData:any) {
  if (process.env.REACT_APP_IS_DEMO === "yes") {
    return fakeData;
  } 
  return realData;
}
function isDemo() {
  if (process.env.REACT_APP_IS_DEMO === "yes") {
    return true;
  } 
  return false;
}
function getFakerEmail(data:any) {
  if (process.env.REACT_APP_IS_DEMO === "yes") {
    return faker.internet.email();
  } 
  return data ?? "N/A";
}

function getFakerDate(data:any) {
  if (process.env.REACT_APP_IS_DEMO === "yes") {
    const randomDate = faker.date.past(); // Generates a random date in the past
    const formattedDate = formatFakerDate(randomDate);
    return formattedDate;
  } 
  return formatDate(data);
}

function formatFakerDate(date:any) {
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}
function getLoginTitle(data:any) {
  if (process.env.REACT_APP_IS_DEMO === "yes") {
    return "Log In - Kintaro Demo Site";
  }
  return data;
}
function getDemoTitle(data:any) {
  if (process.env.REACT_APP_IS_DEMO === "yes") {
    return "Demo";
  }
  return data;
}

export { getDemoTitle, getFakeOrRealData, getFakeOrRealPhoneNumber, getFakerDate, getFakerEmail, getFakerPhoneNumber, getFullName, getLoginTitle, getOfficeName, isDemo };

