import defaultLineChartOptions from "@utils/chart/default-line-chart-options";
import ReactApexChart from "react-apexcharts";

function DefaultLineChartUI() {
  return (
    <div id="line-chart-sales-price-trends" dir="ltr">
      <ReactApexChart
        options={defaultLineChartOptions}
        series={[]}
        type="line"
        height={320}
        className="apex-charts"
      />
    </div>
  );
}

export default DefaultLineChartUI;
