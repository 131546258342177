import React, { FunctionComponent } from 'react';
import { debounce } from 'lodash';
import { Decimal } from 'decimal.js';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { Card, CardBody } from 'reactstrap';
import { createUseStyles } from 'react-jss';
import {
  EmergingMarket, ListingsByMonth,
} from '../../../../state/api.generated';
import IncreaseDecreaseIndicator from '../../../common/IncreaseDecreaseIndicator/IncreaseDecreaseIndicator';
import { createShortNumber, formatCurrencyString } from '../../../common/formatting';
import UnitsOrDollarsSelector from '../../../common/UnitsOrDollarsSelector/UnitsOrDollarsSelector';
import { useAppSelector } from '../../../../state/hooks';
import { selectTrackingUserId } from '../../../../state/appSlice';
import tracking, { getTrackingData, TrackingActions } from '../../../../state/tracking';

const styles = createUseStyles({
  EmergingMarketAlert: {
    'min-height': '100%',
  },
  EmergingMarketAlert_graphContainer: {
    'display': 'flex',
    'flex-direction': 'column',
  },
  EmergingMarketAlert_graphSelector: {
    'margin-left': 'auto',
    'padding-right': '3em',
    'width': '11em',
  },
  '@media (max-width: 768px)': {
    EmergingMarketAlert_graphContainer: {
      'margin-top': '2em',
    },
  },
});

export interface EmergingMarketAlertProps {
  emergingMarket?: EmergingMarket
  listingsByMonth: ListingsByMonth[]
}

const EmergingMarketAlert: FunctionComponent<EmergingMarketAlertProps> = (
  { emergingMarket, listingsByMonth }: EmergingMarketAlertProps,
) => {
  const classes = styles();
  const [viewInUnits, setViewInUnits] = React.useState(false);
  const userId = useAppSelector(selectTrackingUserId);

  const trackHover = debounce(
    () => tracking.track(
      TrackingActions.MARKET_ALERT_HOVERED.valueOf(),
      getTrackingData(userId!),
    ),
    1000,
  );

  const handleSetViewInUnits = (view: boolean) => {
    setViewInUnits(view);
    tracking.track(
      TrackingActions.MARKET_ALERT_UNITS_CHANGED.valueOf(),
      getTrackingData(userId!, { units: view ? 'units' : 'dollars' }),
    );
  };

  if (listingsByMonth == null) {
    return <div />;
  }

  const today = new Date();
  const listingsData = listingsByMonth
    .map((it) => ({ ...it, date: new Date(it.date!) }))
    // Don't include partial data for this month
    .filter((it) => !(it.date.getMonth() === today.getMonth()
      && it.date.getFullYear() === today.getFullYear()));

  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  const twoYearsAgo = new Date();
  twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);
  const salesLastYear = listingsData.filter((it) => new Date(it.date!) >= oneYearAgo)
    .reduce((previous, current) => previous.add(new Decimal(current.sales)), new Decimal(0));
  const salesTwoYearsAgo = listingsData.filter((it) => new Date(it.date!) >= twoYearsAgo)
    .reduce((previous, current) => previous.add(new Decimal(current.sales)), new Decimal(0))
    .sub(salesLastYear);
  const increasing = salesLastYear > salesTwoYearsAgo;

  const salesDictionary = Object.assign({}, ...listingsData
    .map((it) => ({ [`${it.date.getFullYear()}-${it.date.getMonth()}`]: it })));

  let dateToCheck = new Date();
  dateToCheck.setDate(1);
  dateToCheck.setFullYear(dateToCheck.getFullYear() - 1);
  for (let month = 0; month < 12; month += 1) {
    if (!(`${dateToCheck.getFullYear()}-${dateToCheck.getMonth()}` in salesDictionary)) {
      listingsData.push({
        date: new Date(dateToCheck),
        mls: '',
        sales: '0',
        units: 0,
      });
    }
    dateToCheck = new Date(dateToCheck.setMonth(dateToCheck.getMonth() + 1));
  }

  const sortedData = [...listingsData]
    .sort((a, b) => new Date(a.date!).getTime() - new Date(b.date!).getTime());
  const graphOptions: EChartsOption = {
    series: [
      {
        data: sortedData.map((it) => (viewInUnits ? it.units : it.sales)),
        type: 'line',
        smooth: true,
      },
    ],
    tooltip: {
      confine: true,
      formatter: (tooltip: any) => {
        trackHover();
        return `${tooltip[0].name}: ${viewInUnits ? '' : '$'}${createShortNumber(tooltip[0].value)} ${viewInUnits ? 'units' : ''}`;
      },
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: sortedData.map((it) => new Date(it.date!).toLocaleString('default', { month: 'short', year: 'numeric' })),
      axisLabel: {
        rotate: 60,
      },
    },
    yAxis: {
      axisLabel: {
        formatter: (value: string | number) => `${viewInUnits ? '' : '$'}${createShortNumber(value)}`,
      },
      type: 'value',
    },
    grid: {
      containLabel: true,
    },
  };

  let insights;
  if (emergingMarket!.emerging_market_insights.length > 0) {
    insights = (
      <>
        <div className="mt-4">Insight Summary:</div>
        <div className="mt-2 text-white">
          {emergingMarket!.emerging_market_insights!.map((it) => (
            <div key={it.id} className="mt-1">
              -
              &nbsp;
              {it.text}
            </div>
          ))}
        </div>
      </>
    );
  }

  return (
    <>
      <Card className={classes.EmergingMarketAlert}>
        <CardBody>
          <div className="container-fluid">
            <div className="row">
              <div className="col-4">
                <div className="h4 card-title text-uppercase">Emerging Market Alert!</div>
                <div className="mt-3 font-weight-semibold text-uppercase">{emergingMarket!.name}</div>
                <div className="mt-3 h3 font-weight-semibold">{formatCurrencyString(salesLastYear.toFixed(0))}</div>
                <IncreaseDecreaseIndicator
                  change={salesLastYear.div(salesTwoYearsAgo).sub(1).mul(100).toFixed(1)}
                  increasing={increasing}
                  text="from previous year"
                />
                {insights}
              </div>
              <div className={`col-8 ${classes.EmergingMarketAlert_graphContainer}`}>
                <UnitsOrDollarsSelector
                  className={classes.EmergingMarketAlert_graphSelector}
                  isUnits={viewInUnits}
                  setIsUnits={handleSetViewInUnits}
                />
                <ReactECharts option={graphOptions} />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
EmergingMarketAlert.defaultProps = {
  emergingMarket: {
    name: '',
    emerging_market_insights: [] as any[],
  } as EmergingMarket,
};

export default EmergingMarketAlert;
