import menus from '@constants/sidebar-menu';
import { getDemoTitle } from '@utils/fakers-data';
import { createUseStyles } from 'react-jss';
import { Link, useLocation } from 'react-router-dom';
import { gray700 } from '../../assets/colors';
import logoDark from '../../assets/images/kintaro-dark.png';
import logoLightSvg from '../../assets/images/kintaro_square_black.jpg';

const styles = createUseStyles({
  Sidebar_link: {
    '&:focus': {
      'color': 'inherit',
    },
  },
  Sidebar_link__selected: {
    'color': `${gray700} !important`,
    'backgroundColor': '#1e2330',
  },
});
// #2a3042
const Sidebar = () => {
  const classes = styles();
  const selectedLinkClasses = `${classes.Sidebar_link} ${classes.Sidebar_link__selected} hide-list-background-color`;
  const unselectedLinkClasses = classes.Sidebar_link;
  const location = useLocation();

  return (
    <>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img className="rounded-circle" src={logoLightSvg} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="19" />
              <span className='demo-title__style'>{getDemoTitle("")}</span>
            </span>
          </Link>
        </div>

        <div className="h-100">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled">
              {
                menus?.map((menu: any) => (
                  <li key={menu.path_name} className='mb-2'>
                    <Link to={menu.url} className={`${location.pathname === menu.path_name ? selectedLinkClasses : unselectedLinkClasses} mx-2 rounded`}>
                     <div className='d-flex gap-3 align-items-end'>
                     {menu.icon && <span><menu.icon/></span>}
                      <span>{menu.name}</span>
                     </div>
                    </Link>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
