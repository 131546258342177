import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function EngagementOverTime(props:any) {
  const [chartData, setChartData] = useState([] as any);
  const [otherInfo, setOtherInfo] = useState({} as any);
  
  const desktopOptions: any = {
    chart: {
      id: "line-chart",
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: '#000',
        opacity: 0.35,
      },
    },
    dataLabels: {
      enabled: false,
    },
    
    colors: ["#F5822F"],
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      padding: {
        left: -10,
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      // min: 0,
      // max: 70,
      // tickAmount: 7,
      labels: {
        offsetX: -14,
        align: 'left', // Set the alignment to 'left'
        axisTicks: {
          show: false, // Hide ticks on the right side of the y-axis
        },
      },
    },
  };
  const mobileOptions:any = {
    ...desktopOptions,
    xaxis: {
      ...desktopOptions.xaxis,
      labels: {
        rotate: -45,
        rotateAlways: true,
      },
    },
    legend: {
      horizontalAlign: 'left', // Center legend for desktop
    },
  };

  useEffect(() => {
    if (props?.cardId === 1) {
      setOtherInfo({
        title: 'Total CMAs',
        thisMonth: 47,
        stats: "89%",
        status: "decrease",
      });
      setChartData([220, 436, 47]);
    } else {
      setChartData([12, 33, 4]);
      setOtherInfo({
        title: 'Property Inquiries',
        thisMonth: 4,
        stats: "87%",
        status: "decrease",
      });
    }
  }, [props?.cardId]);
  
  return (
    <>
      <div className="mb-4" >
        <div className="custom-modal-inner__padding" style={{ backgroundColor: "#2A3042" }}>
          <div className="row row-gap-1 row-gap-md-0">
            <div className="col-md-8">
              <div className="row row-gap-2 row-gap-md-0">
                <div className="col-6 col-lg-7">
                  <div className="d-flex align-items-center gap-1">
                    <div className="header-title">{otherInfo?.title}</div>
                  </div>
                </div>
                {/* <div className="col-6 col-lg-5 d-inline-flex justify-content-end">
                  <div className="">
                    <SearchSelect
                      options={[
                        {
                          id: 1,
                          title: "All",
                        },
                        {
                          id: 2,
                          title: "1st Quartile",
                        },
                        {
                          id: 2,
                          title: "2nd Quartile",
                        },
                      ]}
                      width="150px"
                      isClearable
                      getOptionValue={(option: any) => option.id}
                      getOptionLabel={(option: any) => `${option.title}`}
                      placeholders="Select Office"
                    />
                  </div>
                </div> */}
              </div>
              <div id="line-chart" dir="ltr">
                <ReactApexChart
                  series={[
                    {
                      name: "series-1",
                      data: chartData,
                    },
                  ]}
                  options={window.innerWidth <= 768 ? mobileOptions : desktopOptions}
                  type="line"
                  height={320}
                  className="apex-charts"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="">
                <p className="custom-text-fs mb-1">This Month</p>
                <p className="number-stats mb-0">{otherInfo?.thisMonth}</p>
                <div>
                  <span className="badge badge-soft-danger custom-fs-10 p-1 me-1">
                    -{otherInfo?.stats}
                  </span>
                  <span className="custom-fs-10">From Last Month</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EngagementOverTime;
