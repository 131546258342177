/* eslint-disable camelcase */
// List Side vs Sell Side
const generateListVsSellSideData = (year: number, data: any): Record<string, any> => {
  const result: Record<string, any> = {
    year,
    months: [],
    total_sell_side: [],
    total_list_side: [],
    total_double_side: [],
    categories: [],
  };
  const filteredData = data
    .sort((a:any, b:any) => {
      // Compare by year
      if (parseFloat(a.the_year) !== parseFloat(b.the_year)) {
        return (parseFloat(a.the_year) - parseFloat(b.the_year));
      }
      // If years are the same, compare by month
      const monthOrder: any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_short_name] - monthOrder[b.month_short_name];
    });

  filteredData.forEach((entry: any) => {
    const formattedCategories = `${entry.month_short_name ?? ""} ${entry.the_year ?? ""}`;
    result.months.push(entry.month_short_name ?? "");
    result.total_sell_side.push(entry.total_sell_side ?? "");
    result.total_list_side.push(entry.total_list_side ?? "");
    result.total_double_side.push(entry.total_double_side ?? "");
    result.categories.push(formattedCategories);
  });
    
  return result;
};

function generateSVsLTicks(data: any, tickCount:any = 11) {
  const { total_sell_side, total_list_side, total_double_side } = data;
  
  const d1MinValue = Math.min(...total_sell_side);
  const d1MaxValue = Math.max(...total_sell_side);
  const d2MinValue = Math.min(...total_list_side);
  const d2MaxValue = Math.max(...total_list_side);
  const d3MinValue = Math.min(...total_double_side);
  const d3MaxValue = Math.max(...total_double_side);

  const firstValue: any = Math.min(d1MinValue, d2MinValue, d3MinValue);
  const lastValue: any = Math.max(d1MaxValue, d2MaxValue, d3MaxValue);
   
  const tickStep: any = (lastValue - firstValue) / (tickCount - 1);

  return Array.from({ length: tickCount }, (_, index) => firstValue + index * tickStep);
}

const generateSalesPriceTrendsDataByYear = (year: number, data: any): Record<string, any> => {
  const result: Record<string, any> = {
    year,
    months: [],
    last_12_total_listingss: [],
    previous_period_total_listingss: [],
    last_12_active_listings: [],
    previous_period_active_listings: [],
    last_12_average_list_price: [],
    previous_period_average_list_price: [],
    last_12_average_close_price: [],
    previous_period_average_close_price: [],
    last_12_sold_to_list: [],
    pp_sold_to_list: [],
    categories: [],
  };
  
  const filteredData = data
    .sort((a:any, b:any) => {
      // Compare by year
      if (parseFloat(a.the_year) !== parseFloat(b.the_year)) {
        return (parseFloat(a.the_year) - parseFloat(b.the_year));
      }
      // If years are the same, compare by month
      const monthOrder: any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_short_name] - monthOrder[b.month_short_name];
    });

  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry.the_year).slice(-2);
    result.months.push(entry.month_short_name);
    result.categories.push(`${entry?.month_short_name} ${yearSplit}`);
    result.last_12_total_listingss.push(entry.last_12_total_listingss ?? 0);
    result.previous_period_total_listingss.push(entry.previous_period_total_listingss ?? 0);
    
    result.last_12_active_listings.push(entry.last_12_active_listings ?? 0);
    result.previous_period_active_listings.push(entry.previous_period_active_listings ?? 0);
    
    result.last_12_average_list_price.push((entry.last_12_average_list_price ?? 0) / 1000);
    result.previous_period_average_list_price.push((entry.previous_period_average_list_price ?? 0) / 1000);
    
    result.last_12_average_close_price.push((entry.last_12_average_close_price ?? 0) / 1000);
    result.previous_period_average_close_price.push((entry.previous_period_average_close_price ?? 0) / 1000);

    result.last_12_sold_to_list.push((entry.last_12_sold_to_list ?? 0));
    result.pp_sold_to_list.push((entry.pp_sold_to_list ?? 0));
  });

  return result;
};

const generateSPTDataByYearMultipleGraphs = (year: number, data: any): Record<string, any> => {
  const result: Record<string, any> = {
    year,
    months: [],
    last_12_total_listingss: [],
    previous_period_total_listingss: [],
    last_12_active_listings: [],
    previous_period_active_listings: [],
    last_12_average_list_price: [],
    previous_period_average_list_price: [],
    last_12_average_close_price: [],
    previous_period_average_close_price: [],
    last_12_sold_to_list: [],
    pp_sold_to_list: [],
    categories: [],
  };

  const filteredData = data
    .sort((a:any, b:any) => {
      // Compare by year
      if (parseFloat(a.the_year) !== parseFloat(b.the_year)) {
        return (parseFloat(a.the_year) - parseFloat(b.the_year));
      }
      // If years are the same, compare by month
      const monthOrder: any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.month_short_name] - monthOrder[b.month_short_name];
    });

  filteredData.forEach((entry: any) => {
    result.months.push(entry.month_short_name);
    result.categories.push(`${entry?.month_short_name} ${entry.the_year}`);
    result.last_12_total_listingss.push(entry.last_12_total_listingss ?? 0);
    result.previous_period_total_listingss.push(entry.previous_period_total_listingss ?? 0);
    
    result.last_12_active_listings.push(entry.last_12_active_listings ?? 0);
    result.previous_period_active_listings.push(entry.previous_period_active_listings ?? 0);
    
    result.last_12_average_list_price.push((entry.last_12_average_list_price ?? 0));
    result.previous_period_average_list_price.push((entry.previous_period_average_list_price ?? 0));
    
    result.last_12_average_close_price.push((entry.last_12_average_close_price ?? 0));
    result.previous_period_average_close_price.push((entry.previous_period_average_close_price ?? 0));

    result.last_12_sold_to_list.push((entry.last_12_sold_to_list ?? 0));
    result.pp_sold_to_list.push((entry.pp_sold_to_list ?? 0));
  });

  return result;
};

function generateTicks(data: any, tickCount = 11) {
  const firstValue: any = Math.min(...data);
  const lastValue: any = Math.max(...data);
  const tickStep: any = (lastValue - firstValue) / (tickCount - 1);

  return Array.from({ length: tickCount }, (_, index) => firstValue + index * tickStep);
}

export { generateListVsSellSideData, generateSPTDataByYearMultipleGraphs, generateSVsLTicks, generateSalesPriceTrendsDataByYear, generateTicks };
