import { useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

// utils
import { formatNumberWithCommas } from "@utils/helpers";
import { http } from "@utils/http";
import { DeleteIcon, EyeIcon, PencilIcon, SearchIcon } from "@utils/icons";
import { colSortingV2 } from "@utils/table-sort";

// componets
import CreateAgentModal from "@components/agent/CreateAgentModal";
import DeleteAgentModal from "@components/agent/DeleteAgentModal";
import DetailAgentModal from "@components/agent/DetailAgentModal";
import UpdateAgentModal from "@components/agent/UpdateAgentModal";
import Pagination from "@components/common/Pagination/Pagination";
import TablePlaceHolder from "@components/common/TablePlaceHolder";
import TableSortIcon from "@components/common/TableSortIcon";
import debounce from "lodash/debounce";
// css import
import PerPageOptionSelector from "@components/common/PerPageOptionSelector";
import PermissionCheck from "@components/common/PermissionCheck";
import { agentPerms } from "@constants/permission";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { faker } from "@faker-js/faker";
import { actionUserPermissions, selectUser } from "@state/appSlice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import "react-loading-skeleton/dist/skeleton.css";

function TheAgents() {
  const dispatch = useAppDispatch();
  const selectedUser: any = useAppSelector(selectUser);
  const [isPermissionDataLoading, setIsPermissionDataLoading] = useState(true);
  const columns: any = [
    // {
    //   name: "#",
    //   slug: "",
    //   sort: false,
    // },
    {
      name: "Agent",
      slug: "agent_full_name",
      sort: true,
      classNames: "",
    },
    {
      name: "Office",
      slug: "office_name",
      sort: true,
      classNames: "",
    },
    {
      name: "Total Listings",
      slug: "total_listing",
      sort: true,
      classNames: "justify-content-center",
    },
    {
      name: "Active Listings",
      slug: "active_listing",
      sort: true,
      classNames: "justify-content-center",
    },
    {
      name: "Sell Side",
      slug: "sell_side",
      sort: true,
      classNames: "justify-content-center",
    },
    {
      name: "List Side",
      slug: "list_side",
      sort: true,
      classNames: "justify-content-center",
    },
    {
      name: "GCI",
      slug: "gci",
      sort: true,
      classNames: "justify-content-center",
    },
    {
      name: "",
      sort: false,
      classNames: "justify-content-center",
    },
  ];
  const [searchText, setSearchText] = useState("");
  const [agents, setAgents] = useState([] as any);
  const [agentsApiData, setAgentsApiData] = useState([] as any);
  const [modals, setModals] = useState({
    deleteAgentModal: false,
    createAgentModal: false,
    updateAgentModal: false,
    detailAgentModal: false,
  });
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [sortColumns, setSortColumns] = useState([] as any);
  const [sortAPIColumns, setSortAPIColumns] = useState("" as any);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0 as any);
  const [perPage, setPerPage] = useState(10);
  const [agentId, setAgentId] = useState("" as "");
  const [selectedAgentData, setSelectedAgentData] = useState({} as any);

  useEffect(() => {
    if (selectedUser?.id) {
      setIsPermissionDataLoading(true);
      // TODO: here call user permission api
      http
        .get(
          `user-permission-site-data.json`,
        )
        .then((response: any) => {
          const { permission } = response?.data;
          const getUserPerms = response?.data?.permission[0]?.permissions;
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: getUserPerms ?? [] }));
          setIsPermissionDataLoading(false);
        })
        .catch((error) => {
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: [] }));
          setIsPermissionDataLoading(false);
          console.log(error);
        });
    }
  }, [selectedUser]);

  useEffect(() => {
    getAgents();
  }, [currentPage, searchText, perPage, sortAPIColumns]);

  /**
   * Modal close emit
   * Common for all typeof modal.
   *
   * @returns {void}
   */
  function modalCloseEmit(data: any) {
    setModals((prevState) => ({
      ...prevState,
      [data.modalName]: false,
    }));
  }

  function deleteAgentModalOpen(data: any) {
    setModals((prevState) => ({
      ...prevState,
      deleteAgentModal: true,
    }));
  }

  function createAgentModalOpen(data: any) {
    setModals((prevState) => ({
      ...prevState,
      createAgentModal: true,
    }));
  }

  function updateAgentModalOpen(data: any) {
    setSelectedAgentData({
      linkedin: "dodaqe",
      facebook: "symevyq",
      instagram: "cutitowi",
      twitter: "vyrapal",
      currentstatus: "wyziz",
      zip: "hariwedap",
      state: "ladukaqubi",
      street: "jofycoh",
      city: "dubuwy",
      mobilephone: "wefihos",
      homephone: "puruv",
      bio: "maguxutu",
      title: "tujokipyr",
      confirm_password: "Pa$$w0rd!",
      password: "Pa$$w0rd!",
      workphone: 24170,
      userlabel: "suviboneqa",
      branch: {
        id: 1,
        title: "one",
      },
      email: "super@admin.com",
      lastname: "vorykafuko",
      firstname: "wyfybiged",
      username: "qilagex",
    });
    setModals((prevState) => ({
      ...prevState,
      updateAgentModal: true,
    }));
  }

  function detailAgentModalOpen(id: any) {
    setAgentId(id);
    setModals((prevState) => ({
      ...prevState,
      detailAgentModal: true,
    }));
  }

  function getAgents(currentPageData = currentPage) {
    setIsDataLoading(true);
    http
      .get("/agent/agent-stats.json").then((response: any) => {
        setIsDataLoading(false);
        const { data }: any = response;
        let theData = data?.agents;
        
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            agent_full_name: faker.person.fullName(),
            office_name: faker.company.name(),
          }));
        }
        setAgents(theData);
        setAgentsApiData(theData);
        setTotalPage(data?.last_page);
      });
  }

  // Define a function to handle column sorting
  const handleSort = (column: any) => {
    const sortingOutput: any = colSortingV2(agentsApiData, sortColumns, column);
    setSortAPIColumns(sortingOutput?.apiColumn);
    setSortColumns(sortingOutput.columns);
    // setAgents(sortingOutput.data);
  };

  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  function handleSearch(searchValue: any) {
    setCurrentPage(1);
    setSearchText(searchValue);
  }
  // debouncing when searching.
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  function refetchDataEmit(data: any) {
    if (data?.isInitialpage) {
      setCurrentPage(1);
      getAgents(1);
    } else {
      getAgents();
    }
  }

  return (
    <>
      <MetaTags>
        <title>Agents - Kintaro 1</title>
      </MetaTags>

      {modals.deleteAgentModal && (
        <DeleteAgentModal
          modalStatus={modals.deleteAgentModal}
          modalName="deleteAgentModal"
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}

      {modals.createAgentModal && (
        <CreateAgentModal
          modalStatus={modals.createAgentModal}
          modalName="createAgentModal"
          modalClose={(data: any) => modalCloseEmit(data)}
          refetchData={(data: any) => refetchDataEmit(data)}
        />
      )}

      {modals.updateAgentModal && (
        <UpdateAgentModal
          modalStatus={modals.updateAgentModal}
          modalName="updateAgentModal"
          selectedAgentDataProps={selectedAgentData}
          modalClose={(data: any) => modalCloseEmit(data)}
          refetchData={(data: any) => refetchDataEmit(data)}
        />
      )}

      {modals.detailAgentModal && (
        <DetailAgentModal
          modalStatus={modals.detailAgentModal}
          modalName="detailAgentModal"
          agentId={agentId}
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
      <PermissionCheck permission={`${agentPerms.agent_lists}`}>
        <div className="container">
          <div className="row">
            <p className="">
              Search for an agent by their name in order to view their
              performance, engagement, and recommended actions.
            </p>
          </div>
          <div className="row">
            <div className="col-md-5 mb-3">
              <div className="position-relative">
                <input
                  id="search"
                  placeholder="Type to search for an Agent or office..."
                  type="text"
                  className="form-control ps-3"
                  onChange={(e) => debouncedHandleSearch(e.target.value)}
                />
                <div className="position-absolute custom-top-2 custom-right-2">
                  <SearchIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card" style={{ background: "transparent" }}>
              <div className="card-body" style={{ background: "#2B3041" }}>
                <div className="d-flex mb-4 justify-content-between">
                  <div className="header-title">Agents</div>
                  <button
                    type="button"
                    className="btn d-none btn-primary"
                    onClick={createAgentModalOpen}
                  >
                    Create
                  </button>
                </div>
                {/* <div className="mb-2">
                <span className="d-inline-flex align-items-baseline font-size-12 p-1 rounded-md badge" style={{ backgroundColor: "#1e2330" }}>
                  Badge
                  <button
                    type="button"
                    // onClick={modalClose}
                    className="btn p-0"
                  >x</button>
                </span>
              </div> */}
                <div className="table-responsive scrollable-body">
                  <table className="align-middle table-nowrap table-check table table-striped table-sm fixed-header fixed-column">
                    <thead
                      className="table-light"
                      style={{ background: "#33394C" }}
                    >
                      <tr role="row">
                        {columns.map((column: any) => (
                          <th
                            key={column.slug}
                            onClick={() => {
                              if (column.sort) {
                                handleSort(column.slug);
                              }
                            }}
                            role="button"
                          >
                            <div
                              className={`d-flex p-1 align-items-center ${column?.classNames}`}
                            >
                              <TableSortIcon
                                sortColumns={sortColumns}
                                column={column}
                              ></TableSortIcon>
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <TablePlaceHolder
                        items={agents}
                        loading={isDataLoading}
                        rows={13}
                        cols={8}
                      />
                      {!isDataLoading &&
                        agents?.map((agent: any, id: any) => (
                          <tr key={agent.agent_id}>
                            {/* <td>{agent.agentid}</td> */}
                            <td className="p-2">{agent.agent_full_name}</td>
                            <td>{agent.office_name}</td>
                            <td className="text-center">
                              {agent.total_listing}
                            </td>
                            <td className="text-center">
                              {agent.active_listing}
                            </td>
                            <td className="text-center">{agent.sell_side}</td>
                            <td className="text-center">{agent.list_side}</td>
                            <td className="text-center">
                              ${formatNumberWithCommas(agent.gci)}
                            </td>
                            <td>
                              <div className="d-flex gap-2">
                                <button
                                  type="button"
                                  className="btn d-none btn-outline-danger btn-sm d-flex gap-1 justify-content-center align-items-center"
                                  onClick={() => deleteAgentModalOpen("")}
                                >
                                  <DeleteIcon />
                                  <span className="d-none d-lg-inline-flex">
                                    Delete
                                  </span>
                                </button>
                                <button
                                  type="button"
                                  className="btn d-none button-white btn-sm text-black d-flex gap-1 justify-content-center align-items-center"
                                  onClick={() => updateAgentModalOpen(agent)}
                                >
                                  <PencilIcon />
                                  <span className="d-none d-lg-inline-flex">
                                    Edit
                                  </span>
                                </button>
                                <PermissionCheck
                                  permission={`${agentPerms.agent_view_more}`}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm d-flex gap-1 justify-content-center align-items-center"
                                    onClick={() => detailAgentModalOpen(agent.agent_id)}
                                  >
                                    <EyeIcon />
                                    <span className="d-none d-lg-inline-flex">
                                      View More
                                    </span>
                                  </button>
                                </PermissionCheck>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="row mt-3">
                  <div className="col-12 col-md-4 d-flex gap-2 align-items-center">
                    <p className="mb-0 custom-fs-12-to-14">Rows Per Page</p>
                    <PerPageOptionSelector
                      perPageEmitSet={(value: any) => setPerPage(value)}
                      labelUnit=" "
                    />
                  </div>
                  <div className="col-12 col-md-8 d-flex justify-content-center justify-content-md-start align-items-center">
                    <Pagination
                      pageCount={totalPage}
                      perPage={perPage}
                      onPageChange={handlePageChange}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PermissionCheck>
    </>
  );
}
export default TheAgents;
