import HelpIcon from "@components/common/HelpIcon";
import SearchSelect from "@components/common/SearchSelect";
import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
import { officeModalTooltip } from "@constants/tooltip-data";
import generateLineChartOptions from "@utils/chart/line-chart";
import { getTickAmount } from "@utils/helpers";
import { http } from "@utils/http";
import { generateSellSideFinancialDataByYear } from "@utils/transform/officePage";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function OfficeGciVsCompanyChart({ officeIdProps }: any) {
  const [monthOptions, setMonthOptions] = useState([
    {
      id: 1,
      title: "Last 3 Months",
      value: 3,
    },
    {
      id: 2,
      title: "Last 6 Months",
      value: 6,
    },
    {
      id: 3,
      title: "Last 12 Months",
      value: 12,
    },
  ] as any);
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[2] as any);

  const [chartOptions, setChartOptions] = useState({} as any);

  const [sellSideFinancialComparisonData, setSellSideFinancialComparisonData] =
    useState({} as any);

  const [isSellSideDataLoading, setIsSellSideDataLoading] = useState(false);

  useEffect(() => {
    if (officeIdProps !== "") {
      getSellSideFinancialComparisonReport();
    }
  }, [officeIdProps, selectedMonth]);

  function getSellSideFinancialComparisonReport() {
    setIsSellSideDataLoading(true);
    http
      .get("/office/sell-side-financial-comparison-last-12.json")
      .then((response: any) => {
        const theData: any = generateSellSideFinancialDataByYear(
          2023,
          response.data.last_12_sell_sides,
        );
        setSellSideFinancialComparisonData(theData);
        const chartFormattedOptions: any = generateLineChartOptions({
          theId: "line-chart-gci",
          colors: ["#F795A8", "#F795A8", "#F2CA6B", "#F2CA6B"],
          categories: theData?.categories,
          // ticks: generateTicks(theData?.last_12_gci ?? []),
          legendPostion: "top",
          legendPostionOnMobile: 'top',
          dashArray: [0, 5, 0, 5],
          tickAmount: getTickAmount(theData?.last_12_gci),
          ticksPreLast: ["$", " k"],
        });
        setChartOptions(chartFormattedOptions);
        setIsSellSideDataLoading(false);
      })
      .catch((error) => {
        setIsSellSideDataLoading(false);
        console.log(error);
      });
  }
  return (
    <>
      <div className="row mb-3">
        <div className="col-6 col-lg-7 d-flex align-items-center">
          <div className="d-inline-block">
            <span className="header-title-5 text-white mb-0 fw-medium pe-1">
              GCI Vs Company Dollar
            </span>
            <div className="d-inline-block align-middle">
              <HelpIcon
                tooltip={`${officeModalTooltip.gci_vs_company_dollar_chart}`}
                width={20}
                height={20}
              />
            </div>
          </div>
          {/* <div className="d-flex gap-1 align-items-center">
            <p className="header-title mb-0 fw-medium">GCI Vs Company Dollar</p>
            <HelpIcon tooltip={`${agentToolTip.gci_vs_company_dollar_chart}`} />
          </div> */}
        </div>
        <div className="col-6 col-lg-5 d-inline-flex justify-content-end">
          <SearchSelect
            options={monthOptions}
            value={
              JSON.stringify(selectedMonth) !== "{}"
                ? selectedMonth
                : "Select a Month"
            }
            onChange={(option: any) => setSelectedMonth(option)}
            // isClearable
            // width="190px"
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => `${option.title}`}
            placeholder="Select Months"
          />
        </div>
      </div>

      <div>
        {isSellSideDataLoading && <div className="loader-circle"></div>}
        {isSellSideDataLoading ? (
          <DefaultLineChartUI />
        ) : (
          <div id="line-chart-gci" dir="ltr">
            <ReactApexChart
              series={[
                {
                  name: `${selectedMonth?.title} GCI`,
                  data: sellSideFinancialComparisonData?.last_12_gci,
                },
                {
                  name: "Previous Period GCI",
                  data: sellSideFinancialComparisonData?.previous_period_gci,
                },
                {
                  name: `${selectedMonth?.title} Company`,
                  data: sellSideFinancialComparisonData?.last_12_company_dollar,
                },
                {
                  name: "Previous Period Company",
                  data: sellSideFinancialComparisonData?.previous_period_company_dollar,
                },
              ]}
              options={chartOptions}
              type="line"
              height={400}
              className="apex-charts custom-legend-bg"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default OfficeGciVsCompanyChart;
