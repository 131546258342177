import HelpIcon from "@components/common/HelpIcon";
import SearchSelect from "@components/common/SearchSelect";
import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
import { agentToolTip } from "@constants/tooltip-data";
import generateLineChartOptions from "@utils/chart/line-chart";
import { getTickAmount } from "@utils/helpers";
import { http } from "@utils/http";
import {
  generateListingMetricDataByYear,
} from "@utils/transform/financial";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function AgentAvgListVsSoldPriceChart({ agentIdProps }: any) {
  const [monthOptions, setMonthOptions] = useState([
    {
      id: 1,
      title: "Last 3 Months",
      value: 3,
    },
    {
      id: 2,
      title: "Last 6 Months",
      value: 6,
    },
    {
      id: 3,
      title: "Last 12 Months",
      value: 12,
    },
  ] as any);
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[2] as any);

  const [chartOptions, setChartOptions] = useState({} as any);

  const [isListingMetricDataLoading, setIsListingMetricDataLoading] =
    useState(true);

  const [listingMetricData, setListingMetricData] = useState({} as any);

  useEffect(() => {
    if (agentIdProps !== "") {
      getListingMetricReport();
    }
  }, [agentIdProps, selectedMonth]);

  function getListingMetricReport() {
    setIsListingMetricDataLoading(true);
    http
      .get("/agent/listing-metric.json")
      .then((response: any) => {
        const theData: any = generateListingMetricDataByYear(response?.data?.listing_metric);
        setListingMetricData(theData);

        const chartFormattedOptions: any = generateLineChartOptions({
          theId: "line-chart-total-listings",
          colors: ["#21C0FC", "#21C0FC", "#A4D124", "#A4D124"],
          categories: theData?.categories,
          legendPostion: "top",
          dashArray: [0, 5, 0, 5],
          tickAmount: getTickAmount(theData?.last_12_avg_list_price),
          // ticks: generateTicks(theData?.last_12_avg_list_price ?? []),
          gridPL: 1,
          ticksPreLast: ["$", "k"],
        });
        setChartOptions(chartFormattedOptions);
        setIsListingMetricDataLoading(false);
      }).catch((error) => {
        setIsListingMetricDataLoading(false);
        console.log(error);
      });
  }
  return (
    <>
      <div className="row mb-3">
        <div className="col-6 col-lg-7">
          <div className="d-inline-block">
            <span className="header-title-5 text-white mb-0 fw-medium pe-1">
              Average List Price Vs Average Sold Price
            </span>
            <div className="d-inline-block align-middle">
              <HelpIcon
                tooltip={`${agentToolTip.avg_list_vs_sold_price_chart}`}
                width={20}
                height={20}
              />
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-5 d-inline-flex justify-content-end">
          <SearchSelect
            options={monthOptions}
            value={
              JSON.stringify(selectedMonth) !== "{}"
                ? selectedMonth
                : "Select a Month"
            }
            onChange={(option: any) => setSelectedMonth(option)}
            // isClearable
            // width="190px"
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => `${option.title}`}
            placeholder="Select Months"
          />
        </div>
      </div>

      <div>
        {isListingMetricDataLoading && <div className="loader-circle"></div>}
        {isListingMetricDataLoading ? (
          <DefaultLineChartUI />
        ) : (
          <div id="line-chart-total-listings" dir="ltr">
            <ReactApexChart
              series={[
                {
                  name: `${selectedMonth?.title} Avg List Price`,
                  data: listingMetricData?.last_12_avg_list_price,
                },
                {
                  name: "Previous Period Avg List Price",
                  data: listingMetricData?.previous_period_avg_list_price,
                },
                {
                  name: `${selectedMonth?.title} Avg Sold Price`,
                  data: listingMetricData?.last_12_avg_sell_price,
                },
                {
                  name: "Previous Period Avg Sold Price",
                  data: listingMetricData?.previous_period_avg_sell_price,
                },
              ]}
              options={chartOptions}
              type="line"
              height={400}
              className="apex-charts custom-legend-bg"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default AgentAvgListVsSoldPriceChart;
