const generateChartFormatOnTableHover = (data: any): Record<string, any> => {
  const result: Record<string, any> = {
    months: [],
    total_reports: [],
    categories: [],
  };
  const filteredData = data
    .sort((a:any, b:any) => {
      // Compare by year
      if (a.year !== b.year) {
        return a.year - b.year;
      }

      // If years are the same, compare by month
      const monthOrder:any = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12,
      };

      return monthOrder[a.short_month_name] - monthOrder[b.short_month_name];
    });

  filteredData.forEach((entry: any) => {
    const yearSplit = String(entry?.year).slice(-2);
    const formattedCategories = `${entry?.short_month_name} ${yearSplit}`;
    
    result.months.push(entry?.short_month_name ?? "");
    result.total_reports.push((entry?.total_reports ?? 0));

    result.categories.push(formattedCategories);
  });
  return result;
};

export { generateChartFormatOnTableHover };
