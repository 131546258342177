import React from 'react';
import { Label } from 'reactstrap';
import { Control, Controller, FieldPath } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { createUseStyles } from 'react-jss';
import { SelectFieldOption, selectFieldStyles } from './SelectField';
import { error } from '../../assets/colors';

interface AsyncSelectFieldProps {
  className?: string
  control: Control
  label?: string
  loadOptions: (inputValue: string, callback: (options: SelectFieldOption[]) => void) => void
  name: FieldPath<Record<string, any>>
  onChange?: (value: SelectFieldOption | null) => void
  placeholder?: string
  rules: any
}

const overrideStyles = createUseStyles({
  AsyncSelectField__error: {
    '& > [class$="-control"]': {
      'border-color': `${error} !important`,
    },
  },
});

const AsyncSelectField = ({
  className, control, label, loadOptions, name, onChange, placeholder, rules,
}: AsyncSelectFieldProps) => {
  const classes = overrideStyles();
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {
          let onChangeMethod = field.onChange;
          if (onChange != null) {
            onChangeMethod = (value: any) => {
              onChange!(value);
              field.onChange(value);
            };
          }
          return (
            <div {...field} className={fieldState.error != null ? `form-group text-danger ${className}` : `form-group ${className}`}>
              {label !== '' && <Label id={`${name}Label`} for={name}>{label}</Label>}
              <AsyncSelect
                aria-labelledby={`${name}Label`}
                cacheOptions
                className={fieldState.error != null ? classes.AsyncSelectField__error : ''}
                defaultOptions
                id={name}
                loadOptions={loadOptions}
                name={name}
                onChange={onChangeMethod}
                placeholder={placeholder}
                ref={field.ref}
                styles={selectFieldStyles}
              />
              {fieldState.error != null && (
                <div className="invalid-feedback" style={{ display: 'unset' }}>
                  {fieldState.error.message}
                </div>
              )}
            </div>
          );
        }}
      />
    </>
  );
};

AsyncSelectField.defaultProps = {
  className: '',
  label: '',
  onChange: () => {},
  placeholder: 'Select...',
};

export default AsyncSelectField;
