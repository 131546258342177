import SearchSelect from "@components/common/SearchSelect";
import ReactApexChart from "react-apexcharts";

function EmergingMarketShare() {
  const series = [84, 105, 33];
  const options: any = {
    chart: {
      type: "donut",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
            horizontalAlign: 'left',
          },
        },
      },
    ],
    legend: {
      position: "bottom",
      horizontalAlign: 'center',
      itemMargin: {
        horizontal: 10, // Adjust the horizontal margin to increase space between legend items
        // vertical: 10,
      },
    },
    labels: ["Best Realtor Success", "Denver Brokerage", "Other Competitors"],
    colors: ["#CF4AFE", "#F5822F", "#F2CA6B"], // Specify your custom colors here
    // plotOptions: {
    //   stroke: {
    //     color: 'transparent', // Set stroke color to transparent to remove the border
    //   },
    // },
    dataLabels: {
      formatter(val: any) {
        return "";
      },
    },
    stroke: {
      colors: "transparent",
    },
    // tooltip: {
    //   y: {
    //     formatter(val:any) {
    //       return `${val}.00` + ` Rs`;
    //     },
    //     title: {
    //       formatter(seriesName: any) {
    //         return '';
    //       },
    //     },
    //   },
    // },
    tooltip: {
      
      custom: ({ series1, seriesIndex, dataPointIndex, w }: any) => {
        let total: any = 0;
        for (const x of series) {
          total += x;
        }
        const seriesName = w.config.labels[seriesIndex];
        const seriesValue = w.config.series[seriesIndex];
        const percentage = ((seriesValue / total) * 100).toFixed(1);

        return `<div class="apexcharts-tooltip-custom text-break">
          <span>${seriesName}:</span> $${seriesValue} (${percentage}%)</div>`;
      },
    },
  };
  return (
    <>
      <div className="card" style={{ backgroundColor: "#2B3041" }}>
        <div className="card-body">
          <h1 className="header-title  ps-md-0 pb-4">ROUND ROCK Market Share</h1>
          <div className="row">
            <div className="col-lg-6">
              <p>Last month</p>
            </div>
            <div className="col-lg-6">
              <div className="">
                <SearchSelect
                  options={[
                    {
                      id: 1,
                      title: "units",
                    },
                    {
                      id: 2,
                      title: "dollars",
                    },
                  ]}
                  isClearable
                  getOptionValue={(option: any) => option.id}
                  getOptionLabel={(option: any) => `${option.title}`}
                  placeholder="Select Units"
                />
              </div>
            </div>
          </div>
          
          <div className="chart-wrap d-flex align-items-center justify-content-center">
            <div id="chart">
              <ReactApexChart
                options={options}
                series={series}
                type="donut"
                width={380}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmergingMarketShare;
