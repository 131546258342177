import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { setError } from './appSlice';

const ErrorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)
    && action.meta.baseQueryMeta.request.method === 'GET'
    && (action.payload.status >= 500 || action.payload.originalStatus >= 500 || action.payload.status === 'FETCH_ERROR')) {
    api.dispatch(setError(true));
  } else if (isRejectedWithValue(action)
    && action.payload.status === 401) {
    api.dispatch(push('/logout'));
  }
  return next(action);
};

export default ErrorMiddleware;
