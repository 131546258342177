import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { toast } from 'react-toastify';
import { white } from '../../../../assets/colors';
import {
  Notification as NotificationModel, useDraftEmailNotificationMutation,
  usePartialUpdateNotificationMutation,
} from '../../../../state/api.generated';
import ConfirmationDialog from '../../../common/ConfirmationDialog/ConfirmationDialog';
import Tooltip from '../../../common/Tooltip';

interface NotificationProps {
  notification: NotificationModel
}

const styles = createUseStyles({
  Notification: {
    'margin-bottom': '1em',
  },
  Notification_body: {
    'display': 'flex',
    'flex-direction': 'row',
  },
  Notification_text: {
  },
  Notification_textHeader: {
    'text-transform': 'capitalize',
    'flex-direction': 'row',
    'justify-content': 'space-between',
    'color': white,
    'margin-bottom': '1em',
  },
  Notification_actions: {
    'padding-top': '0',
    'margin': 'auto 0',
    'white-space': 'nowrap',
    '& > *': {
      'margin-top': '1em',
      'margin-left': '1em',
    },
  },
  RecItemActions_wrapper: {
    'backgroundColor': '#333849',
    'marginBottom': '5px',
  },
  RecActions_wrapper: {
    'background': '#2A3042',
  },
  '@media (max-width: 767px)': {
    Notification_actions: {
      'padding-top': '0',
      'margin': 'auto 0',
      'white-space': 'nowrap',
      '& > *': {
        'margin-top': '1em',
        'margin-left': '0',
      },
    },
  },
});

const RecomendedAction = ({ notification }: NotificationProps) => {
  const classes = styles();
  const [completeOpen, setCompleteOpen] = useState(false);
  const [ignoreOpen, setIgnoreOpen] = useState(false);
  const [draftOpen, setDraftOpen] = useState(false);
  const [patch, patchData] = usePartialUpdateNotificationMutation();
  const [draft, draftData] = useDraftEmailNotificationMutation();
  const patchNotification = (status: 'IGNORED' | 'COMPLETED') => patch({ id: notification.id!, notification: { status } });
  const submitDraftEmail = () => {
    draft({ id: notification.id!, notification }).unwrap()
      .then((response) => window.open(response, '_blank'))
      .catch(() => toast.dark('Error triggering action.', { type: 'error' }));
    return Promise.resolve();
  };
  return (
    <>
      <div className={`${classes.RecItemActions_wrapper} px-3`}>
        <div className="py-2">
          <div className="row d-flex justify-content-between">
            <div className="col-lg-6">
              <div className="d-flex flex-column gap-2">
                <div>Low Production - Pending</div>
                <div>
                  Sir Geoff has fallen behind on their production.
                  Check up wth them in order to get them back on track.
                  Doing so will increase agent output.
                </div>
              </div>
            </div>
            <ConfirmationDialog
              acceptText="Complete"
              body="Are you sure that you want to mark this notification as complete?"
              open={completeOpen}
              rejectText="Cancel"
              setOpen={setCompleteOpen}
              submitOnClick={() => patchNotification('COMPLETED')}
              title="Complete Notification?"
            />
            <ConfirmationDialog
              acceptText="Ignore"
              body="Are you sure that you want to mark this notification as ignored?"
              open={ignoreOpen}
              rejectText="Cancel"
              setOpen={setIgnoreOpen}
              submitOnClick={() => patchNotification('IGNORED')}
              title="Ignore Notification?"
            />
            <ConfirmationDialog
              acceptText="Draft Email"
              body="Are you sure that you want to draft an email for all this notification? This will not mark the notification as ignored or completed."
              open={draftOpen}
              rejectText="Cancel"
              setOpen={setDraftOpen}
              submitOnClick={submitDraftEmail}
              title="Draft Email?"
            />
            <div className={`${classes.Notification_actions} col-lg-6 d-flex justify-content-between`}>
              <button
                className="btn btn-outline-secondary"
                onClick={() => setIgnoreOpen(true)}
                disabled={false}
                type="button"
              >
                Ignore
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setCompleteOpen(true)}
                disabled={false}
                type="button"
              >
                Complete
              </button>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => setDraftOpen(true)}
                disabled={draftData.isLoading || patchData.isLoading}
              >
                Draft Email
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const RecomendedActions = () => {
  const classes = styles();

  return (
    <>
      <div className={`${classes.RecActions_wrapper} p-3`}>
        <div>
          <h4 className="text-white">Recomended Actions</h4>
          <div className="card mb-3">
            <Tooltip />
          </div>
        </div>
        <div>
          <div className="card mb-3">
            <Tooltip />
            <RecomendedAction notification={{
              id: undefined,
              agent: undefined,
              category: undefined,
              status: undefined,
              text: undefined,
              action: undefined,
            }}
            />
          </div>
        </div>
        <div>
          <div className="card mb-3">
            <Tooltip />
            <RecomendedAction notification={{
              id: undefined,
              agent: undefined,
              category: undefined,
              status: undefined,
              text: undefined,
              action: undefined,
            }}
            />
          </div>
        </div>
        <div>
          <div className="card mb-3">
            <Tooltip />
            <RecomendedAction notification={{
              id: undefined,
              agent: undefined,
              category: undefined,
              status: undefined,
              text: undefined,
              action: undefined,
            }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RecomendedActions;
