import { useState } from "react";
import SearchSelect from "./SearchSelect";

const PerPageOptionSelector = (props: any) => {
  const { labelUnit } = props;
  const label = labelUnit ?? " / page";
  
  const [perPageOptions, setPerPageOptions] = useState([
    { label: `10${label}`, value: 10 },
    { label: `20${label}`, value: 20 },
    { label: `30${label}`, value: 30 },
    { label: `40${label}`, value: 40 },
    { label: `50${label}`, value: 50 },
  ] as any);

  const [selectedPerpage, setSelectedPerpage] = useState(perPageOptions[0] as any);
  const { placeholderText } = props;
  return (
    <SearchSelect
      options={perPageOptions}
      value={
        JSON.stringify(selectedPerpage) !== "{}"
          ? selectedPerpage
          : `${placeholderText ?? "Select Per Page"}`
      }
      onChange={(option: any) => {
        setSelectedPerpage(option);
        props?.perPageEmitSet(option?.value);
      }}
      getOptionValue={(option: any) => option.value}
      getOptionLabel={(option: any) => `${option.label}`}
      placeholder={placeholderText ?? "Select Per Page"}
    />
  );
};

export default PerPageOptionSelector;
