import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

interface PlaceholderProps {
  loading: boolean;
  className?: string;
  count?: number;
}

function Placeholder({ loading, className, count = 1 }: PlaceholderProps) {
  return (
    <>
      {loading && (
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <Skeleton
            count={count}
            className={`${className}`}
            // Add any additional classes if needed
          />
        </SkeletonTheme>
      )}
    </>
  );
}

// Set default props
Placeholder.defaultProps = {
  className: "",
};

export default Placeholder;
