import {
  GoogleMap,
  HeatmapLayer,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { heatmapDummyData } from "@utils/globals";
import { useEffect, useState } from "react";

function EmergingMarketHeatmap() {
  const center = {
    lat: 30.5082551,
    lng: -97.678896,
  };
  
  const googleMapsLibraries:any = ["visualization"] as any;
  const [heatmap, setHeatmap] = useState(heatmapDummyData as any);
  const [map, setMap] = useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY || '',
    libraries: googleMapsLibraries,
  });

  useEffect(() => {
    if (heatmapDummyData) {
      setHeatmap(heatmapDummyData);
    }
  }, []);

  const mapStyles = {
    height: "680px",
    width: "100%",
  };

  return (
    isLoaded ? (
      <GoogleMap
        mapContainerStyle={mapStyles}
        center={center}
        zoom={12}
        onLoad={(mapProps:any) => setMap(mapProps)}
      >
        {map && heatmap && (
        <>
          <HeatmapLayer
            data={heatmap?.map(
              (data: any) => new google.maps.LatLng(data.latitude, data.longitude),
            )}
            options={{
              radius: 50,
            }}
          />
          <Marker position={center} />
        </>
        )}
      </GoogleMap>
    ) : "loading.."
  );
}

export default EmergingMarketHeatmap;
