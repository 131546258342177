import { selectUser, selectUserPermissions } from "@state/appSlice";
import { useAppSelector } from "@state/hooks";
import React from "react";

interface Props {
  permission: string;
  children: React.ReactNode;
}

const PermissionCheck: React.FC<Props> = ({ permission, children }) => {
  // Access Redux state using useSelector hook
  const userPermissions: any = useAppSelector(selectUserPermissions);
  const selectedUser: any = useAppSelector(selectUser);
  const isStaff: boolean = selectedUser?.user_level !== 0; // TODO: later here implement staff logic

  const hasPermission = userPermissions.includes(permission) || isStaff;

  return hasPermission ? <>{children}</> : null;
};

export default PermissionCheck;
