import HelpIcon from "@components/common/HelpIcon";
import TableSortIcon from "@components/common/TableSortIcon";
import { colSorting } from "@utils/table-sort";
import { useState } from "react";

function AgentOpportunities(props: any) {
  const data1: any = [
    {
      id: 15,
      agent: "Bonnie Stein",
      contract: "63",
      logins_per_mo: "55",
      sends_per_mo: "8",
      open_rate: "9",
      // ctr: "0",
    },
    {
      id: 16,
      agent: "Kate Mercado",
      contract: "35",
      logins_per_mo: "35",
      sends_per_mo: "0",
      open_rate: "9",
      // ctr: "0",
    },
    {
      id: 17,
      agent: "Cindy Rafeld",
      contract: "34",
      logins_per_mo: "7",
      sends_per_mo: "27",
      open_rate: "7",
      // ctr: "0",
    },
    {
      id: 18,
      agent: "Alix Prince",
      contract: "29",
      logins_per_mo: "25",
      sends_per_mo: "4",
      open_rate: "6",
      // ctr: "0",
    },
    {
      id: 19,
      agent: "Carola Schonander",
      contract: "27",
      logins_per_mo: "9",
      sends_per_mo: "18",
      open_rate: "6",
      // ctr: "0",
    },
  ];
  const columns: any = [
    {
      name: "#",
      slug: "",
      sort: false,
    },
    {
      name: "Agent",
      slug: "agent",
      sort: true,
    },
    {
      name: "Total CMAs",
      slug: "total CMAs",
      sort: true,
    },
    {
      name: "Live CMAs",
      slug: "Live CMAs",
      sort: true,
    },
    {
      name: "PDF CMAs",
      slug: "PDF CMAs",
      sort: true,
    },
  ];
  const data2: any = [
    {
      id: 15,
      agent: "Sara Reel",
      contract: "53",
      logins_per_mo: "3",
      ctr: "56",
    },
    {
      id: 16,
      agent: "Martie Marsala",
      contract: "40",
      logins_per_mo: "1",

      ctr: "41",
    },
    {
      id: 17,
      agent: "Terri Prenty",
      contract: "34",
      logins_per_mo: "0",
      ctr: "34",
    },
    {
      id: 18,
      agent: "Lori Delaparra",
      contract: "25",
      logins_per_mo: "2",

      ctr: "27",
    },
    {
      id: 19,
      agent: "Holly Mellstrom",
      contract: "16",
      logins_per_mo: "8",
      ctr: "24",
    },
  ];
  const columns2: any = [
    {
      name: "#",
      slug: "",
      sort: false,
    },
    {
      name: "Agent",
      slug: "agent",
      sort: true,
    },
    {
      name: "Contact",
      slug: "total CMAs",
      sort: true,
    },
    {
      name: "Login per mo",
      slug: "Live CMAs",
      sort: true,
    },
    {
      name: "CTR",
      slug: "PDF CMAs",
      sort: true,
    },
  ];
  const [offices, setOffices] = useState(data1 && (data1 as any));
  const [sortColumns, setSortColumns] = useState([] as any);

  // Define a function to handle column sorting
  const handleSort = (column: any) => {
    const sortingOutput: any = colSorting(offices, sortColumns, column);
    setSortColumns(sortingOutput.columns);
    setOffices(sortingOutput.data);
  };

  return (
    <div className="mb-4">
      <div
        className="custom-modal-inner__padding"
        style={{ background: "#2B3041" }}
      >
        <div className="row row-gap-2 row-gap-md-0 mb-3 ">
          <div className="col-7">
            <div className="d-inline-block">
              <span className="header-title">Agent Opportunities</span>
              <div className="d-inline-block align-middle">
                <HelpIcon
                  tooltip="Agent Opportunities"
                  width={20}
                  height={20}
                />
              </div>
            </div>
          </div>
          {/* <div className="col-5 d-inline-flex justify-content-end">
            <div className="">
              <SearchSelect
                options={[
                  {
                    id: 1,
                    title: "All Tools",
                  },
                  {
                    id: 2,
                    title: "Tools-1",
                  },
                  {
                    id: 2,
                    title: "Tools-2",
                  },
                ]}
                width="150px"
                isClearable
                getOptionValue={(option: any) => option.id}
                getOptionLabel={(option: any) => `${option.title}`}
                placeholders="Select Office"
              />
            </div>
          </div> */}
        </div>
        <div className="table-responsive">
          {props?.cardId === 1 ? (
            <table className="align-middle table-nowrap table-check table table-striped table-sm">
              <thead className="table-light">
                <tr role="row">
                  {columns.map((column: any) => (
                    <th
                      key={column.slug}
                      onClick={() => {
                        if (column.sort) {
                          handleSort(column.slug);
                        }
                      }}
                      role="button"
                    >
                      <div className="d-flex">
                        <TableSortIcon
                          sortColumns={sortColumns}
                          column={column}
                        ></TableSortIcon>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {offices?.map((office: any, id: any) => (
                  <tr key={office.id}>
                    <td>{office.id}</td>
                    <td>
                      <div className="d-flex gap-1 align-items-center">
                        {/* <img src={profileImg} alt="profile"/> */}
                        <span>{office.agent}</span>
                      </div>
                    </td>
                    <td>{office.contract}</td>
                    <td>{office.logins_per_mo}</td>
                    <td>{office.sends_per_mo}</td>
                    {/* <td>{office.open_rate}</td> */}
                    {/* <td>{office.ctr}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            ""
          )}
          {props?.cardId === 2 ? (
            <table className="align-middle table-nowrap table-check table table-striped table-sm">
              <thead className="table-light">
                <tr role="row">
                  {columns2.map((column: any) => (
                    <th
                      key={column.slug}
                      onClick={() => {
                        if (column.sort) {
                          handleSort(column.slug);
                        }
                      }}
                      role="button"
                    >
                      <div className="d-flex">
                        <TableSortIcon
                          sortColumns={sortColumns}
                          column={column}
                        ></TableSortIcon>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data2?.map((office: any, id: any) => (
                  <tr key={office.id}>
                    <td>{office.id}</td>
                    <td>
                      <div className="d-flex gap-1 align-items-center">
                        {/* <img src={profileImg} alt="profile"/> */}
                        <span>{office.agent}</span>
                      </div>
                    </td>
                    <td>{office.contract}</td>
                    <td>{office.logins_per_mo}</td>
                    {/* <td>{office.sends_per_mo}</td> */}
                    {/* <td>{office.open_rate}</td> */}
                    <td>{office.ctr}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default AgentOpportunities;
