/* eslint-disable no-multiple-empty-lines */
const STATUS_UP = 'up';
const STATUS_DOWN = 'down';
const STATUS_ACTIVE = 'Active';
const STATUS_INACTIVE = 'Inactive';
const SORT_ASC = 'asc';
const SORT_DESC = 'desc';
const RA_RECOMMEND = 'recommend';
const RA_IGNORE = 'ignore';
const RA_COMPLETED = 'completed';
const RA_DRAFT_EMAIL = 'draft_email';
const RA_BROKERAGE_GCI_LAST_QUARTER = "brokerage-gci-last-quarter";
const RA_BROKERAGE_AVG_SOLD_DOM_LOWER = 'brokerage-avg-sold-dom-lower';
const RA_BROKERAGE_AVG_SOLD_DOM_HIGHER = 'brokerage-avg-sold-dom-higher';
const RT_PROPERTY_INQUIRY = "Property Inquiry";
const RT_AGENT_CONTACT_FORM = "Agent Contact Form";
const RT_PROPERTY_INQUIRY_REFERRAL = "Property Inquiry + referral";
const RT_OFFICE_CONTACT_FORM_REFERRAL = "Office Contact Form + referral";
const RT_REQUEST_CONSULTATION_FORM = "Request Consultation Form";
const RT_SELL_YOUR_HOME_FORM = "Sell Your Home (AVM) Form";
const RT_AREA_INQUIRY = "Area Inquiry";
const RT_REQUEST_CONSULTATION_FORM_REFERRAL = "Request Consultation Form + referral";
const RT_SELL_YOUR_HOME_FORM_REFERRAL = "Sell Your Home (AVM) Form + referral";
const SQL_NOT_EXIST_MESSAGE = "sql file is not exist, please check your sql file!";

const FIRST_BADGE_NAME = "Mentorship Maestro";
const SECOND_BADGE_NAME = "Best Communicator";
const THIRD_BADGE_NAME = "Guru of Dialogue";
const leaderboardList = [
  {
    "user_id": "f21fdcbd-9bd1-4f35-a23c-3b2101667bae",
    "email": "manager_1@kintaro.io.test",
    "first_name": "manager_1",
    "last_name": null,
    "office_name": "Scarsdale",
    "total_score": 1,
    "position": 1,
  },
  {
    "user_id": "0e0ed95f-02c4-4d7c-b05e-565e24326045",
    "email": "manager_3@kintaro.io.test",
    "first_name": "manager_3",
    "last_name": null,
    "office_name": "Larchmont",
    "total_score": -2,
    "position": 2,
  },
  {
    "user_id": "f9ad2e90-1d08-4c01-8b5f-9e09f1ad0c5b",
    "email": "manager_4@kintaro.io.test",
    "first_name": "manager_4",
    "last_name": null,
    "office_name": "Irvington",
    "total_score": -2,
    "position": 3,
  },
  {
    "user_id": "6d54b5a9-fa9a-43ed-8e06-b9f6a5b5ec61",
    "email": "manager_2@kintaro.io.test",
    "first_name": "manager_2",
    "last_name": null,
    "office_name": "Rye",
    "total_score": -3,
    "position": 4,
  },
  {
    "user_id": "1f16a77e-72b8-4bbd-9421-1a92f2431601",
    "email": "manager_5@kintaro.io.test",
    "first_name": "manager_5",
    "last_name": null,
    "office_name": "New Rochelle",
    "total_score": 0,
    "position": 5,
  },
  {
    "user_id": "6a18a765-1c8f-482f-8e91-3d2c7f5b9f26",
    "email": "manager_6@kintaro.io.test",
    "first_name": "manager_6",
    "last_name": null,
    "office_name": "White Plains",
    "total_score": 3,
    "position": 6,
  },
  {
    "user_id": "28f9a836-5e8d-4c97-bda5-f23d7f743541",
    "email": "manager_7@kintaro.io.test",
    "first_name": "manager_7",
    "last_name": null,
    "office_name": "Mamaroneck",
    "total_score": -1,
    "position": 7,
  },
  {
    "user_id": "7a1e06d9-e4f8-4a85-b9d7-d66a57429a61",
    "email": "manager_8@kintaro.io.test",
    "first_name": "manager_8",
    "last_name": null,
    "office_name": "Pelham",
    "total_score": 2,
    "position": 8,
  },
  {
    "user_id": "5a8e34a4-718e-4d67-9443-567b9f652d36",
    "email": "manager_9@kintaro.io.test",
    "first_name": "manager_9",
    "last_name": null,
    "office_name": "Harrison",
    "total_score": -4,
    "position": 9,
  },
  {
    "user_id": "89dfd45c-23c8-4e3e-803f-f8c1b7f4e634",
    "email": "manager_10@kintaro.io.test",
    "first_name": "manager_10",
    "last_name": null,
    "office_name": "Bronxville",
    "total_score": 1,
    "position": 10,
  },
  {
    "user_id": "b239f5e3-84df-4e0d-97b9-c1766e43b34a",
    "email": "manager_11@kintaro.io.test",
    "first_name": "manager_11",
    "last_name": null,
    "office_name": "Yonkers",
    "total_score": -3,
    "position": 11,
  },
  {
    "user_id": "3a91f230-0cf4-4f82-8f1d-9dffba2ff76b",
    "email": "manager_12@kintaro.io.test",
    "first_name": "manager_12",
    "last_name": null,
    "office_name": "Tuckahoe",
    "total_score": 0,
    "position": 12,
  },
  {
    "user_id": "fd57d318-5b2a-4370-9b93-8c2d3a9b7c16",
    "email": "manager_13@kintaro.io.test",
    "first_name": "manager_13",
    "last_name": null,
    "office_name": "Ardsley",
    "total_score": 2,
    "position": 13,
  },
  {
    "user_id": "5d8c4fa4-3b61-4a6e-a4d4-11289d937ad7",
    "email": "manager_14@kintaro.io.test",
    "first_name": "manager_14",
    "last_name": null,
    "office_name": "Eastchester",
    "total_score": -2,
    "position": 14,
  },
];
export { FIRST_BADGE_NAME, RA_BROKERAGE_AVG_SOLD_DOM_HIGHER, RA_BROKERAGE_AVG_SOLD_DOM_LOWER, RA_BROKERAGE_GCI_LAST_QUARTER, RA_COMPLETED, RA_DRAFT_EMAIL, RA_IGNORE, RA_RECOMMEND, RT_AGENT_CONTACT_FORM, RT_AREA_INQUIRY, RT_OFFICE_CONTACT_FORM_REFERRAL, RT_PROPERTY_INQUIRY, RT_PROPERTY_INQUIRY_REFERRAL, RT_REQUEST_CONSULTATION_FORM, RT_REQUEST_CONSULTATION_FORM_REFERRAL, RT_SELL_YOUR_HOME_FORM, RT_SELL_YOUR_HOME_FORM_REFERRAL, SECOND_BADGE_NAME, SORT_ASC, SORT_DESC, SQL_NOT_EXIST_MESSAGE, STATUS_ACTIVE, STATUS_DOWN, STATUS_INACTIVE, STATUS_UP, THIRD_BADGE_NAME, leaderboardList };

