import HelpIcon from "@components/common/HelpIcon";
import PermissionCheck from "@components/common/PermissionCheck";
import Placeholder from "@components/common/Placeholder";
import { financialPerms } from "@constants/permission";
import { financialToolTip } from "@constants/tooltip-data";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { actionUserPermissions, selectUser } from "@state/appSlice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { formatNumberWithCommas } from "@utils/helpers";
import { http } from "@utils/http";
import { generateSPTDataByYearMultipleGraphs } from "@utils/transform/financialPage";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function MultipleGraphStatsCard() {
  const dispatch = useAppDispatch();
  const selectedUser: any = useAppSelector(selectUser);
  const [isPermissionDataLoading, setIsPermissionDataLoading] = useState(true);
  const [graphData, setGraphData] = useState({} as any);
  const [graphApiData, setGraphApiData] = useState({} as any);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const series1: any = [
    {
      data: [5, 20, 15, 35, 15, 25, 44, 26, 38, 25, 35],
    },
  ];

  useEffect(() => {
    if (selectedUser?.id) {
      setIsPermissionDataLoading(true);
      // TODO: here call user permission api
      http
        .get(
          `user-permission-site-data.json`,
        )
        .then((response: any) => {
          const { permission } = response?.data;
          const getUserPerms = response?.data?.permission[0]?.permissions;
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: getUserPerms ?? [] }));
          setIsPermissionDataLoading(false);
        })
        .catch((error) => {
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: [] }));
          setIsPermissionDataLoading(false);
          console.log(error);
        });
    }
  }, [selectedUser]);

  useEffect(() => {
    getGraphData();
  }, []);

  function getGraphData() {
    http
      .get("financial/sales-price-trends-month-histogram.json")
      .then((response: any) => {
        let apiData = response?.data?.sales_price_trends;
        if (apiData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
          apiData = [];
        }
        setGraphApiData(apiData);
        const theData: any = generateSPTDataByYearMultipleGraphs(2023, apiData);
        setGraphData(theData);
        setIsDataLoading(false);
      })
      .catch((error: any) => {
        setIsDataLoading(false);
        console.log(error);
      });
  }

  function getOptions({ color, ticksPreLast = 0 }: any) {
    const options: any = {
      chart: {
        id: "line-chart",
        type: "line",
        width: 100,
        height: 160,
        sparkline: {
          enabled: true,
        },
      },
      colors: [`${color}`],
      stroke: {
        curve: "smooth",
        width: 3,
      },

      tooltip: {
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          formatter(value: any) {
            if (ticksPreLast !== 0) {
              const valueFormatted = parseFloat(value).toFixed(2);
              return `${ticksPreLast[0] ?? ""}${formatNumberWithCommas(
                parseFloat(valueFormatted),
              )}${ticksPreLast[1] ?? ""}`;
            } else {
              return "";
            }
          },
          title: {
            formatter(seriesName: any) {
              return "";
            },
          },
        },
        marker: {
          show: false,
        },
      },
    };
    return options;
  }
  return (
    <div className="row row-gap-3 ">
      {/* Card 1 */}
      <PermissionCheck
        permission={`${financialPerms.financial_total_listings}`}
      >
        <div className="col-md-6 col-lg-3">
          <div className="mb-4 h-100" style={{ backgroundColor: "#2A3042" }}>
            <div className="custom-modal-inner__padding">
              <div className="min-h-70">
                <div className="d-inline-block">
                  <span className="header-title text-white mb-0 pe-1">
                    Total Listings
                  </span>
                  <div className="d-inline-block align-middle">
                    <HelpIcon
                      tooltip={financialToolTip?.totalListingsChart}
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
                {/* <div className="header-title">Total Listings</div> */}
              </div>
              <div className="">
                <p className="custom-fs-12">Last Months</p>
                <div className="row align-items-end">
                  <div className="col-7 d-flex">
                    <Placeholder
                      loading={isDataLoading}
                      className="custom-w-24 custom-h-2"
                    ></Placeholder>
                    <div id="line-chart">
                      <ReactApexChart
                        options={getOptions({
                          color: "#F2CA6B",
                          ticksPreLast: ["", ""],
                        })}
                        series={[
                          {
                            // name: "revenue",
                            data: graphData?.last_12_total_listingss,
                          },
                        ]}
                        type="line"
                        height={35}
                        width={100}
                      />
                    </div>
                    {/* <MultipleGraphLineStatsChart
                      series={series1}
                      colors="#F2CA6B"
                    /> */}
                  </div>
                  <div className="col-5">
                    {/* <p className="number-stats-9 d-flex align-items-center text-white mb-0">
                    <Placeholder
                      loading={isDataLoading}
                      className="custom-w-12 custom-h-2"
                    ></Placeholder>
                      {graphApiData[0]?.delta_total_listings}%
                    </p> */}
                    {/* <p className="number-stats-8 text-white mb-0">30%</p> */}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Placeholder
                    loading={isDataLoading}
                    className="custom-w-12 custom-h-2 me-1"
                  ></Placeholder>
                  {(() => {
                    if (
                      graphApiData[graphApiData?.length - 1]
                        ?.delta_total_listings < 0
                    ) {
                      return (
                        !isDataLoading && (
                          <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                            {
                              graphApiData[graphApiData?.length - 1]
                                ?.delta_total_listings
                            }
                            %
                          </span>
                        )
                      );
                    } else {
                      return (
                        !isDataLoading && (
                          <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                            {
                              graphApiData[graphApiData?.length - 1]
                                ?.delta_total_listings
                            }
                            %
                          </span>
                        )
                      );
                    }
                  })()}
                  <span className="custom-fs-12-to-14">Previous year</span>
                </div>
                {/* <div className="d-flex align-items-center gap-1 pt-3">
                  <span className="badge badge-soft-success font-size-12">
                    +26%
                  </span>
                  <span>Previous year</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </PermissionCheck>
      {/* Card 2 */}
      <PermissionCheck
        permission={`${financialPerms.financial_avg_list_price}`}
      >
        <div className="col-md-6 col-lg-3">
          <div className="mb-4 h-100" style={{ backgroundColor: "#2A3042" }}>
            <div className="custom-modal-inner__padding pr-2">
              <div className="min-h-70">
                <div className="d-inline-block">
                  <span className="header-title text-white mb-0 pe-1">
                    Average List Price
                  </span>
                  <div className="d-inline-block align-middle">
                    <HelpIcon
                      tooltip={financialToolTip?.avgListPriceChart}
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
                {/* <div className="header-title">Average List Price</div> */}
              </div>
              <div className="">
                <p className="custom-fs-12">Last Months</p>
                <div className="row">
                  <div className="col-7 d-flex ">
                    <Placeholder
                      loading={isDataLoading}
                      className="custom-w-24 custom-h-2"
                    ></Placeholder>
                    <div id="line-chart">
                      <ReactApexChart
                        options={getOptions({
                          color: "#4ADEFE",
                          ticksPreLast: ["$", ""],
                        })}
                        series={[
                          {
                            // name: "revenue",
                            data: graphData?.last_12_average_list_price,
                          },
                        ]}
                        type="line"
                        height={35}
                        width={100}
                      />
                    </div>
                    {/* <MultipleGraphLineStatsChart
                    series={series1}
                    colors="#4ADEFE"
                  /> */}
                  </div>
                  <div className="col-5">
                    {/* <p className="number-stats-9 d-flex align-items-center text-white mb-0">
                  <Placeholder
                    loading={isDataLoading}
                    className="custom-w-12 custom-h-2"
                  ></Placeholder>
                    {graphApiData[0]?.delta_average_list_price}%
                  </p> */}
                    {/* <p className="number-stats-8 text-white mb-0">30%</p> */}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Placeholder
                    loading={isDataLoading}
                    className="custom-w-12 custom-h-2 me-1"
                  ></Placeholder>
                  {(() => {
                    if (
                      graphApiData[graphApiData?.length - 1]
                        ?.delta_average_list_price < 0
                    ) {
                      return (
                        !isDataLoading && (
                          <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                            {
                              graphApiData[graphApiData?.length - 1]
                                ?.delta_average_list_price
                            }
                            %
                          </span>
                        )
                      );
                    } else {
                      return (
                        !isDataLoading && (
                          <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                            {
                              graphApiData[graphApiData?.length - 1]
                                ?.delta_average_list_price
                            }
                            %
                          </span>
                        )
                      );
                    }
                  })()}
                  <span className="custom-fs-12-to-14">Previous year</span>
                </div>

                {/* <div className="d-flex align-items-center gap-2 pt-3">
                <span className="badge badge-soft-success font-size-12">
                  +12%
                </span>
                <span>Previous year</span>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </PermissionCheck>
      {/* Card 3 */}
      <PermissionCheck
        permission={`${financialPerms.financial_avg_sold_price}`}
      >
        <div className="col-md-6 col-lg-3">
          <div className="mb-4 h-100" style={{ backgroundColor: "#2A3042" }}>
            <div className="custom-modal-inner__padding pr-2">
              <div className="min-h-70">
                <div className="d-inline-block">
                  <span className="header-title text-white mb-0 pe-1">
                    Average Sold Price
                  </span>
                  <div className="d-inline-block align-middle">
                    <HelpIcon
                      tooltip={financialToolTip?.avgSoldPriceChart}
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
                {/* <div className="header-title">Average Sold Price</div> */}
              </div>
              <div className="">
                <p className="custom-fs-12">Last Months</p>
                <div className="row">
                  <div className="col-7 d-flex ">
                    <Placeholder
                      loading={isDataLoading}
                      className="custom-w-24 custom-h-2"
                    ></Placeholder>
                    <div id="line-chart">
                      <ReactApexChart
                        options={getOptions({
                          color: "#F5822F",
                          ticksPreLast: ["$", ""],
                        })}
                        series={[
                          {
                            // name: "revenue",
                            data: graphData?.last_12_average_close_price,
                          },
                        ]}
                        type="line"
                        height={35}
                        width={100}
                      />
                    </div>
                    {/* <MultipleGraphLineStatsChart
                    series={series1}
                    colors="#F5822F"
                  /> */}
                  </div>
                  <div className="col-5">
                    {/* <p className="number-stats-9 d-flex align-items-center text-white mb-0">
                    <Placeholder
                      loading={isDataLoading}
                      className="custom-w-12 custom-h-2"
                    ></Placeholder>
                    {graphApiData[0]?.delta_average_close_price}%
                  </p> */}
                    {/* <p className="number-stats-8 text-white mb-0">30%</p> */}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Placeholder
                    loading={isDataLoading}
                    className="custom-w-12 custom-h-2 me-1"
                  ></Placeholder>
                  {(() => {
                    if (
                      graphApiData[graphApiData?.length - 1]
                        ?.delta_average_close_price < 0
                    ) {
                      return (
                        !isDataLoading && (
                          <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                            {
                              graphApiData[graphApiData?.length - 1]
                                ?.delta_average_close_price
                            }
                            %
                          </span>
                        )
                      );
                    } else {
                      return (
                        !isDataLoading && (
                          <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                            {
                              graphApiData[graphApiData?.length - 1]
                                ?.delta_average_close_price
                            }
                            %
                          </span>
                        )
                      );
                    }
                  })()}
                  <span className="custom-fs-12-to-14">Previous year</span>
                </div>

                {/* <div className="d-flex align-items-center gap-2 pt-3">
                <span className="badge badge-soft-success font-size-12">
                  +2%
                </span>
                <span>Previous year</span>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </PermissionCheck>
      {/* Card 4 */}
      <PermissionCheck
        permission={`${financialPerms.financial_sold__to_list_ratio}`}
      >
        <div className="col-md-6 col-lg-3">
          <div className="mb-4 h-100" style={{ backgroundColor: "#2A3042" }}>
            <div className="custom-modal-inner__padding pr-2">
              <div className="min-h-70">
                <div className="d-inline-block">
                  <span className="header-title text-white mb-0 pe-1">
                    Sold to List Ratio
                  </span>
                  <div className="d-inline-block align-middle">
                    <HelpIcon
                      tooltip={financialToolTip?.soldToListRatioChart}
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
                {/* <div className="header-title">Sold to List Ratio</div> */}
              </div>
              <div className="">
                <p className="custom-fs-12">Last Months</p>
                <div className="row">
                  <div className="col-7 d-flex ">
                    <Placeholder
                      loading={isDataLoading}
                      className="custom-w-24 custom-h-2"
                    ></Placeholder>
                    <div id="line-chart">
                      <ReactApexChart
                        options={getOptions({
                          color: "#DC48E9",
                          ticksPreLast: ["", "%"],
                        })}
                        series={[
                          {
                            // name: "revenue",
                            data: graphData?.last_12_sold_to_list,
                          },
                        ]}
                        type="line"
                        height={35}
                        width={100}
                      />
                    </div>
                    {/* <MultipleGraphLineStatsChart
                    series={series1}
                    colors="#DC48E9"
                  /> */}
                  </div>
                  <div className="col-5">
                    {/* <p className="number-stats-9 d-flex align-items-center text-white mb-0">
                  <Placeholder
                    loading={isDataLoading}
                    className="custom-w-12 custom-h-2"
                  ></Placeholder>
                    {graphApiData[0]?.delta_sold_to_list}%
                  </p> */}
                    {/* <p className="number-stats-8 text-white mb-0">
                      30%
                  </p> */}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Placeholder
                    loading={isDataLoading}
                    className="custom-w-12 custom-h-2 me-1"
                  ></Placeholder>
                  {(() => {
                    if (
                      graphApiData[graphApiData?.length - 1]
                        ?.delta_sold_to_list < 0
                    ) {
                      return (
                        !isDataLoading && (
                          <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
                            {
                              graphApiData[graphApiData?.length - 1]
                                ?.delta_sold_to_list
                            }
                            %
                          </span>
                        )
                      );
                    } else {
                      return (
                        !isDataLoading && (
                          <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                            {
                              graphApiData[graphApiData?.length - 1]
                                ?.delta_sold_to_list
                            }
                            %
                          </span>
                        )
                      );
                    }
                  })()}{" "}
                  <span className="custom-fs-12-to-14">Previous year</span>
                </div>
                {/* <div className="d-flex align-items-center gap-2 pt-3">
                <span className="badge badge-soft-success font-size-12">
                  +12%
                </span>
                <span>Previous year</span>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </PermissionCheck>
    </div>
  );
}

export default MultipleGraphStatsCard;
