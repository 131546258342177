import React, { ReactNode } from 'react';
import Header from './Header/Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import { VersionedApp } from '@components/VersionedApp';

interface LayoutProps {
  children: ReactNode | ReactNode[],
}

const Layout = ({ children }: LayoutProps) => (
  <VersionedApp>
    <div id="layout-wrapper">
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          {children}
        </div>
      </div>
      <Footer />
    </div>
  </VersionedApp>
);

export default Layout;
