import AdminBrandSelector from '@components/common/AdminBrandSelector';
import MenuIcon from '@mui/icons-material/Menu';
import { selectUser } from "@state/appSlice";
import { useAppSelector } from "@state/hooks";
import { Link } from 'react-router-dom';
import logoLightSvg from '../../../assets/images/kintaro_square_black.jpg';
import BurgerMenu from '../BurgerMenu';
import ProfileMenu from './ProfileMenu';

const Header = () => {
  const selectedUser: any = useAppSelector(selectUser);
  const selectedAdminUserIds = ["f21fdcbd-9bd1-4f35-a23c-3b2101667bae", "f802718f-587a-4fd4-b5d2-a9053b9c890e"]; 
  const isStaff: boolean = selectedUser?.user_level !== 0; // TODO: later here implement staff logic
  const isDevAdmin: boolean = selectedAdminUserIds?.includes(selectedUser?.id); 
  
  const isMobile = window.screen.width <= 998;
  const toggleMenuCallback = () => {
    const { body } = document;
    if (isMobile) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collapsed');
      body.classList.toggle('sidebar-enable');
    }
  };

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex align-items-center">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img className="rounded-circle" src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>
            
            {isMobile ? (
              <BurgerMenu />
            ) : (
              <button
                type="button"
                onClick={toggleMenuCallback}
                className="btn btn-sm px-3 font-size-16 header-item"
                id="vertical-menu-btn"
              >
                <MenuIcon />
              </button>
            )}
            {
              isStaff ? <AdminBrandSelector/> : ""
            }
            
          </div>
          <div className="d-flex align-items-center">
            {/* <ModalOfficesCard /> */}
            {/* {
              isStaff ? <AdminBrandSelector/> : ""
            } */}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
