import CardLoader from "@components/common/CardLoader";
import HelpIcon from "@components/common/HelpIcon";
import { toolsPageToolTip } from "@constants/tooltip-data";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { http } from "@utils/http";
import { generateToolsNeutrinoPieChartData } from "@utils/transform/toolsDetails";
import { Fragment, useEffect, useState } from "react";

function NeutrinoProgressBarReportType() {
  const [chartOptions, setChartOptions] = useState({} as any);
  const [reportTypeData, setReportTypeData] = useState([] as any);
  const [chartReportData, setChartReportData] = useState({} as any);

  const [isChartReportDataLoading, setIsChartReportDataLoading] =
    useState(false);

  useEffect(() => {
    getCmaReport();
  }, []);

  function getCmaReport() {
    setIsChartReportDataLoading(true);
    http
      .get(
        "/tools/neutrino--overall-report-type-to-calculate-percentage-report-type-last-12-month.json",
      )
      .then((response: any) => {
        let apiData = response?.data?.website_engagement;
        if (apiData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
          apiData = [];
        }
        const theData: any = generateToolsNeutrinoPieChartData(
          apiData,
        );
        setChartReportData(theData);
        setReportTypeData(apiData);
        setIsChartReportDataLoading(false);
      })
      .catch((error) => {
        setIsChartReportDataLoading(false);
        console.log(error);
      });
  }

  function getRandomColor(index: any) {
    let color: any = "";

    switch (true) {
      case index === 0:
        color = "#FF7F50";
        break;
      case index === 1:
        color = "#30C1FA";
        break;
      case index === 2:
        color = "#CCCDFE";
        break;
      case index === 3:
        color = "#9DFFFE";
        break;
      case index === 4:
        color = "#CCCDFE";
        break;
      case index === 5:
        color = "#2AFD85";
        break;
      case index === 6:
        color = "#A5CF38";
        break;
      case index === 7:
        color = "#FFF0F5";
        break;
      case index === 8:
        color = "#F596A9";
        break;
      case index === 9:
        color = "#FD7F57";
        break;
      default:
        color = "#FF7F50";
        break;
    }
    return color;
  }

  return (
    <>
      <div className="mb-2">
        <div
          className="custom-modal-inner__padding"
          style={{ backgroundColor: "#2A3042" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="d-inline-block">
                <span className="header-title-6 text-white mb-0 fw-bold pe-1">
                  Report Types
                </span>
                <div className="d-inline-block align-middle">
                  <HelpIcon
                    tooltip={`${toolsPageToolTip.toolsNeutrinoPieChart}`}
                    width={20}
                    height={20}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="text-white">
                <div className="row align-content-start">
                  <CardLoader count={18} loading={isChartReportDataLoading} />
                  {!isChartReportDataLoading &&
                    reportTypeData?.map((item: any, index: any) => (
                      <Fragment key={item?.id}>
                        <div className="col-5 custom-fs-static-13 fw-medium">
                          {item?.modified_type}
                        </div>
                        <div className="col-7">
                          <div className="d-flex align-items-baseline gap-2">
                            <p className="custom-fs-12 text-white fw-normal ">
                              {item?.type_percentage == null
                                ? 0
                                : (item?.type_percentage).toFixed(2)}
                              %
                            </p>
                            <div className="progress animated-progess progress-sm flex-grow-1">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                // title={`${item?.type_count ?? 0}`}
                                style={{
                                  width: `${item?.type_percentage ?? 0}%`,
                                  backgroundColor: `${getRandomColor(
                                    index,
                                  )}`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                </div>
              </div>
            </div>
            {/* <div className="col-12 px-0">
              {isChartReportDataLoading && (
                <div className="loader-circle"></div>
              )}
              {isChartReportDataLoading ? (
                <DefaultLineChartUI />
              ) : (
                <div
                  id="line-chart-gci"
                  dir="ltr"
                  style={{ width: "100%", margin: "auto" }}
                >
                  <ReactApexChart
                    series={chartReportData?.reportCountData ?? []}
                    options={chartOptions}
                    type="donut"
                    height={350}
                    className="apex-charts custom-pie-chart custom-legend-bg"
                  />
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default NeutrinoProgressBarReportType;
