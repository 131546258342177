import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import MetaTags from 'react-meta-tags';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import EmergingMarketSelector from './EmergingMarketSelector/EmergingMarketSelector';
import EmergingMarketAlert from './EmergingMarketAlert/EmergingMarketAlert';
import EmergingMarketShare from './EmergingMarketShare/EmergingMarketShare';
import {
  EmergingMarket,
  EmergingMarketDetailData,
  useRetrieveUserQuery,
  useLazyDataEmergingMarketQuery,
  useLazyListListingsByMonthsQuery,
} from '../../../state/api';
import EmergingMarketHeatmap, { EmergingMarketCenterAndZoom } from './EmergingMarketHeatmap/EmergingMarketHeatmap';
import { useAppSelector } from '../../../state/hooks';
import {
  selectAuth, selectMlsIdList, selectGroupIdList, selectTrackingUserId,
} from '../../../state/appSlice';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';
import tracking, { getTrackingData, TrackingActions } from '../../../state/tracking';

const styles = createUseStyles({
  Markets: {
    'display': 'flex',
    'flex-direction': 'column',
  },
  Markets_bottom: {
    'display': 'flex',
    'flex-direction': 'row',
  },
  Markets_alert: {
    'flex-grow': 1,
    'margin': '1em 1em 0 0',
    'width': '66%',
  },
  Markets_share: {
    'margin-top': '1em',
    'width': '33%',
  },
});

export interface MarketsParams {
  id: string
}

const Markets = () => {
  const classes = styles();
  const [emergingMarket, setEmergingMarket] = useState(null as EmergingMarket | null);
  const [
    emergingMarketInitialState,
    setEmergingMarketInitialState,
  ] = useState({} as EmergingMarketCenterAndZoom);
  const [
    emergingMarketData,
    setEmergingMarketData,
  ] = useState(null as EmergingMarketDetailData | null);
  const auth = useAppSelector(selectAuth);
  const userData = useRetrieveUserQuery({ id: auth!.user_id });
  const [triggerEmergingMarketQuery, emergingMarketQuery] = useLazyDataEmergingMarketQuery();
  const [triggerListingsByMonthQuery, listingsByMonth] = useLazyListListingsByMonthsQuery();
  const mlsList = useAppSelector(selectMlsIdList);
  const brokerageList = useAppSelector(selectGroupIdList);
  const [mapUpdateKey, setMapUpdateKey] = useState(uuidv4());
  const { id } = useParams<MarketsParams>();

  const userId = useAppSelector(selectTrackingUserId);
  useEffect(() => {
    if (id != null) {
      tracking.track(
        TrackingActions.MARKETS_PAGE_OPENED.valueOf(),
        getTrackingData(userId!, { market_insight_id: id }),
      );
    }
  }, [id, userId]);

  // Load emerging market and listings by month if emerging market is null (page load)
  // EmergingMarketSelector sets emerging market to not be null
  useEffect(() => {
    if (userData.isSuccess && emergingMarket != null) {
      triggerEmergingMarketQuery({
        brokerages: brokerageList,
        id: emergingMarket.id!,
      });
      triggerListingsByMonthQuery({
        maximumLongitude: emergingMarket.maximum_longitude!,
        mls: mlsList,
        maximumLatitude: emergingMarket.maximum_latitude!,
        minimumLatitude: emergingMarket.minimum_latitude!,
        minimumLongitude: emergingMarket.minimum_longitude!,
      });
    }
  }, [
    mlsList, brokerageList, triggerEmergingMarketQuery,
    triggerListingsByMonthQuery, emergingMarket, userData,
  ]);
  // handle emerging market query success
  useEffect(() => {
    if (emergingMarketQuery.isSuccess) {
      setEmergingMarketData(emergingMarketQuery.data!);
    }
  }, [emergingMarketQuery, setEmergingMarketData]);
  // handle emerging market query error
  useEffect(() => {
    if (emergingMarketQuery.isError) {
      toast.dark('Unable to load emerging market. Please reach out to customer success.', { type: 'error' });
    }
  }, [emergingMarketQuery]);
  // update emerging market heatmap center and zoom whenever the emerging market changes
  // emerging market is updated by the selector
  useEffect(() => {
    if (emergingMarket != null) {
      setEmergingMarketInitialState({
        centerLatitude: emergingMarket.center_latitude!,
        centerLongitude: emergingMarket.center_longitude!,
        zoom: emergingMarket.zoom!,
      });
    }
  }, [emergingMarket, setEmergingMarketInitialState]);

  const handleEmergingMarketSelection = useCallback((selected: EmergingMarket) => {
    setEmergingMarket(selected);
    setMapUpdateKey(uuidv4());
  }, [setEmergingMarket, setMapUpdateKey]);

  let pageContent;
  if (emergingMarket != null && emergingMarketData != null && listingsByMonth.data != null) {
    pageContent = (
      <>
        <EmergingMarketHeatmap
          initialCenterAndZoom={emergingMarketInitialState}
          mlsIds={mlsList}
          updateKey={mapUpdateKey}
        />
        <div className="row">
          <div className="col-xl mt-3">
            <EmergingMarketAlert
              emergingMarket={emergingMarket}
              listingsByMonth={listingsByMonth.data}
            />
          </div>
          <div className="col-xl mt-3">
            <EmergingMarketShare
              emergingMarket={emergingMarket}
              emergingMarketData={emergingMarketData}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <MetaTags>
        <title>Market Insight - Kintaro</title>
      </MetaTags>
      <div className={classes.Markets}>
        <EmergingMarketSelector setEmergingMarket={handleEmergingMarketSelection} />
        <LoadingIcon loading={emergingMarketQuery.isLoading} />
        {pageContent}
      </div>
    </>
  );
};

export default Markets;
