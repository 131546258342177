import Pagination from "@components/common/Pagination/Pagination";
import PerPageOptionSelector from "@components/common/PerPageOptionSelector";
import PermissionCheck from "@components/common/PermissionCheck";
import TablePlaceHolder from "@components/common/TablePlaceHolder";
import TableSortIcon from "@components/common/TableSortIcon";
import { officePerms } from "@constants/permission";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import { faker } from "@faker-js/faker";
import { actionUserPermissions, selectUser } from "@state/appSlice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { formatNumberWithCommas } from "@utils/helpers";
import { http } from "@utils/http";
import { EyeIcon } from "@utils/icons";
import { colSortingV2 } from "@utils/table-sort";
import { useEffect, useState } from "react";
import DetailsOfficeModal from "./DetailsOfficeModal";

function ListingOverviewTable() {
  const dispatch = useAppDispatch();
  const selectedUser: any = useAppSelector(selectUser);
  const [isPermissionDataLoading, setIsPermissionDataLoading] = useState(true);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [sortColumns, setSortColumns] = useState([] as any);
  const [sortAPIColumns, setSortAPIColumns] = useState("" as any);
  const [offices, setOffices] = useState([] as any);
  const [officeId, setOfficeId] = useState("" as any);
  const [modals, setModals] = useState({
    deleteOfficeModal: false,
    createOfficeModal: false,
    updateOfficeModal: false,
    detailsOfficeModal: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0 as any);
  const [perPage, setPerPage] = useState(10);

  const columns: any = [
    // {
    //   name: "#",
    //   slug: "",
    //   sort: false,
    // },
    {
      name: "Office",
      slug: "office_name",
      headerClassNames: "ps-2",
      subHeaderClassNames: "",
      sort: true,
    },
    {
      name: "Listings",
      slug: "total_listing",
      headerClassNames: "",
      subHeaderClassNames: "justify-content-center",
      sort: true,
    },
    {
      name: "Total Active",
      slug: "active_listing",
      headerClassNames: "",
      subHeaderClassNames: "justify-content-center",
      sort: true,
    },
    {
      name: "Sell Side",
      slug: "sell_side",
      headerClassNames: "",
      subHeaderClassNames: "justify-content-center",
      sort: true,
    },
    {
      name: "List Side",
      slug: "list_side",
      headerClassNames: "",
      subHeaderClassNames: "justify-content-center",
      sort: true,
    },
    {
      name: "Average Listing Price",
      slug: "average_list_price",
      headerClassNames: "",
      subHeaderClassNames: "justify-content-center",
      sort: true,
    },
    {
      name: "GCI",
      slug: "gci",
      headerClassNames: "",
      subHeaderClassNames: "justify-content-center",
      sort: true,
    },
    {
      name: "GCI Under Contract",
      slug: "gci_under_contract",
      headerClassNames: "",
      subHeaderClassNames: "justify-content-center",
      sort: true,
    },
    {
      name: "Setting",
      sort: false,
      headerClassNames: "d-none d-md-block",
      subHeaderClassNames: "",
    },
  ];

  useEffect(() => {
    if (selectedUser?.id) {
      setIsPermissionDataLoading(true);
      // TODO: here call user permission api
      http
        .get(
          `user-permission-site-data.json`,
        )
        .then((response: any) => {
          const { permission } = response?.data;
          const getUserPerms = response?.data?.permission[0]?.permissions;
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: getUserPerms ?? [] }));
          setIsPermissionDataLoading(false);
        })
        .catch((error) => {
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: [] }));
          setIsPermissionDataLoading(false);
          console.log(error);
        });
    }
  }, [selectedUser]);

  useEffect(() => {
    getOffices();
  }, [currentPage, perPage, sortAPIColumns]);

  function getOffices() {
    setIsDataLoading(true);
    http
      .get("/office/office-report.json")
      .then((response: any) => {
        // const { data } = response;
        let theData = response?.data?.office_report;
        if (process.env.REACT_APP_IS_DEMO === 'yes') {
          if (theData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
            theData = [];
          }
          theData = theData?.map((el: any) => ({
            ...el,
            agent_full_name: faker.person.fullName(),
            office_name: faker.company.name(),
          }));
        }
        // const formattedApiData = data?.office_report?.map((el:any) => (
        //   {
        //     ...el,
        //     fakeOfficeName: faker.company.name(),
        //   }));
        // setOffices(data.office_report);
        console.log({ theData });
        setOffices(theData);
        setTotalPage(response?.data?.last_page);
        setIsDataLoading(false);
      })
      .catch((error) => {
        setIsDataLoading(false);
        console.log(error);
      });
  }

  /**
   * Modal close emit
   * Common for all typeof modal.
   *
   * @returns {void}
   */
  function modalCloseEmit(data: any) {
    setModals((prevState) => ({
      ...prevState,
      [data.modalName]: false,
    }));
  }

  function deleteOfficeModalOpen(data: any) {
    setModals((prevState) => ({
      ...prevState,
      deleteOfficeModal: true,
    }));
  }

  function createOfficeModalOpen(data: any) {
    setModals((prevState) => ({
      ...prevState,
      createOfficeModal: true,
    }));
  }

  function updateOfficeModalOpen(data: any) {
    setModals((prevState) => ({
      ...prevState,
      createOfficeModal: true,
    }));
  }

  function detailsOfficeModalOpen(officeid: any) {
    setOfficeId(officeid);
    setModals((prevState) => ({
      ...prevState,
      detailsOfficeModal: true,
    }));
  }
  // Define a function to handle column sorting
  const handleSort = (column: any) => {
    const sortingOutput: any = colSortingV2(offices, sortColumns, column);
    setSortColumns(sortingOutput.columns);
    setSortAPIColumns(sortingOutput?.apiColumn);
    // setOffices(sortingOutput.data);
  };

  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const renderTableActionButton = (office: any) => (
    <>
      <PermissionCheck permission={`${officePerms.office_list_view_more}`}>
        <button
          type="button"
          className="btn btn-primary btn-sm d-flex align-items-center gap-1"
          onClick={() => detailsOfficeModalOpen(office?.office_id)}
        >
          <span>
            <EyeIcon />
          </span>
          <span className="ps-1 d-none d-md-block">View More</span>
        </button>
      </PermissionCheck>
    </>
  );

  return (
    <>
      {/* {modals.deleteOfficeModal && (
        <DeleteAgentModal
          modalStatus={modals.deleteOfficeModal}
          modalName="deleteOfficeModal"
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}

      {modals.createOfficeModal && (
        <CreateAgentModal
          modalStatus={modals.createOfficeModal}
          modalName="createOfficeModal"
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}

      {modals.updateOfficeModal && (
        <UpdateAgentModal
          modalStatus={modals.updateOfficeModal}
          modalName="updateOfficeModal"
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
*/}
      {modals.detailsOfficeModal && (
        <DetailsOfficeModal
          modalStatus={modals.detailsOfficeModal}
          modalName="detailsOfficeModal"
          officeId={officeId}
          modalClose={(data: any) => modalCloseEmit(data)}
        />
      )}
      <div className="row mb-4">
        <div className="">
          <div
            className="custom-modal-inner__padding"
            style={{ backgroundColor: "#2B3041" }}
          >
            <div className="row mb-3">
              <div className="col-7">
                <div className="header-title">Offices Overview</div>
              </div>
              <div className="col-5 d-flex align-items-center justify-content-end">
                {/* <SearchSelect
                  options={[
                    {
                      id: 1,
                      title: "Last 12 Months",
                    },
                    {
                      id: 2,
                      title: "Last 6 Months",
                    },
                  ]}
                  width="170px"
                  isClearable
                  getOptionValue={(option: any) => option.id}
                  getOptionLabel={(option: any) => `${option.title}`}
                  placeholder="Select Month"
                /> */}
                <p className="mb-0">Last 12 Months</p>
              </div>
            </div>
            {/* <div className="d-flex mb-4 justify-content-between">
              <div className="header-title">Listing Overiview</div>
            </div> */}
            <div className="table-responsive scrollable-body-2">
              <table className="align-middle table-nowrap table-check table table-striped table-sm fixed-header fixed-column">
                <thead className="table-light">
                  <tr role="row">
                    {columns.map((column: any) => (
                      <th
                        key={column.slug}
                        onClick={() => {
                          if (column.sort) {
                            handleSort(column.slug);
                          }
                        }}
                        role="button"
                        className={`${column?.headerClassNames}`}
                      >
                        <div
                          className={`d-flex align-items-center ${column?.subHeaderClassNames}`}
                        >
                          <TableSortIcon
                            sortColumns={sortColumns}
                            column={column}
                          ></TableSortIcon>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <TablePlaceHolder
                    items={offices}
                    loading={isDataLoading}
                    rows={13}
                    cols={9}
                  />
                  {!isDataLoading &&
                    offices?.map((office: any, id: any) => (
                      <tr key={office.id}>
                        {/* <td className="ps-2">{office.id}</td> */}
                        <td className="ps-2 first-column">
                          <div className="d-flex justify-content-between gap-1">
                            {office.office_name}
                            {/* { getFakeOrRealData(office.office_name, office.fakeOfficeName)} */}
                            <span className="d-flex d-md-none justify-content-end">
                              {renderTableActionButton(office)}
                            </span>
                          </div>
                        </td>
                        <td className="text-center">
                          {formatNumberWithCommas(office.total_listing)}
                        </td>
                        <td className="text-center">
                          {formatNumberWithCommas(office.active_listing)}
                        </td>
                        <td className="text-center">
                          {formatNumberWithCommas(office.sell_side)}
                        </td>
                        <td className="text-center">
                          {formatNumberWithCommas(office.list_side)}
                        </td>
                        <td className="text-center">
                          ${formatNumberWithCommas(office.average_list_price)}
                        </td>
                        <td className="text-center">
                          ${formatNumberWithCommas(office.gci)}
                        </td>
                        <td className="text-center">
                          ${formatNumberWithCommas(office.gci_under_contract)}
                        </td>
                        <td className="d-none d-md-table-cell">
                          {renderTableActionButton(office)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-3 d-flex gap-2 align-items-center">
                <p className="mb-0 custom-fs-12-to-14">Rows Per Page</p>
                <PerPageOptionSelector
                  perPageEmitSet={(value: any) => setPerPage(value)}
                  labelUnit=" "
                  // placeholderText="select office"
                />
              </div>
              <div className="col-12 col-md-9 d-flex justify-content-center justify-content-md-start align-items-center">
                <Pagination
                  pageCount={totalPage}
                  perPage={perPage}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListingOverviewTable;
