import Placeholder from "@components/common/Placeholder";
import SearchSelect from "@components/common/SearchSelect";
import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
import generatePieChartOptions from "@utils/chart/pie-chart";
import { http } from "@utils/http";
import { generateByOfficeToolsCmaPieChartData, generateByOfficeToolsNeutrinoPieChartData } from "@utils/transform/officePage";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function OfficeToolTechStats({ officeIdProps }: any) {
  const [toolTechOptions, setToolTechOptions] = useState([
    {
      id: 1,
      title: "CMA",
      value: "cma",
      textToShow: "Total Reports",

      apiUrl: "/tools/cloudcma--total-cloudcma-reports-last-12-month-ytd-by-single-office.json",
      responseFieldName: "cloudcma_marketing",
      responseAttributeName: "reports_last_12_months",

      pieChartApiUrl:
        "/office/cloudcma-lead-overall-report-type-to-calculate-percentage-by-office.json",
      pieChartResponseField: "engagement_score",
      hasPieChart: true,

      engagementApiUrl:
        "/office/overall-cloudcma-engagement-score.json",
      engagementResponseField: "cloudcma_engagement",
      engagementAttributeName: "overall_engagement_score",
    },
    {
      id: 2,
      title: "Neutrino",
      value: "neutrino",
      textToShow: "Total Website Leads",

      apiUrl: "/tools/neutrino--total-web-leads-created-last-12-month-ytd-by-single-office.json",
      responseFieldName: "website_engagement",
      responseAttributeName: "leads_last_12_months",

      pieChartApiUrl:
        "/office/website-lead-overall-report-type-to-calculate-percentage-by-office.json",
      pieChartResponseField: "engagement_score",
      hasPieChart: true,

      engagementApiUrl:
        "/tools/neutrino--overall-website-engagement-score-by-office.json",
      engagementResponseField: "website_marketing",
      engagementAttributeName: "engagement_score_percentage",
    },
    {
      id: 3,
      title: "ActivePipe",
      value: "activePipe",
      textToShow: "Total Email Campaigns",

      apiUrl: "/tools/active-pipe--total-email-marketing-send-open-rate-by-single-office.json",
      responseFieldName: "email_marketing",
      responseAttributeName: "last_12_total_sent",

      engagementApiUrl:
        "/office/activepipe-email-marketing-engagement-score.json",
      engagementResponseField: "activepipe_engagement",
      engagementAttributeName: "overall_engagement_score",
    },
  ] as any);
  const [selectedToolTech, setSelectedToolTech] = useState(
    toolTechOptions[0] as any,
  );

  const [chartOptions, setChartOptions] = useState({} as any);
  const [chartData, setChartData] = useState({} as any);

  const [totalReportData, setTotalReportData] = useState(null as any);
  const [engagementData, setEngagementData] = useState(null as any);

  const series = [84, 105, 33, 20, 38, 40, 28, 50];
  const [isDataLoading, setIsDataLoading] = useState({
    isTotalReportDataLoading: true,
    isEngagementScoreDataLoading: true,
    isChartDataLoading: true,
  });
  useEffect(() => {
    if (officeIdProps) {
      getTotalReports();
      getPieReport();
      getEngagementScoreByOffice();
    }
  }, [officeIdProps, selectedToolTech]);

  function setDataLoadingState(fieldName: string, value: boolean) {
    setIsDataLoading((prev: any) => ({
      ...prev,
      [fieldName]: value,
    }));
  }

  function getTotalReports() {
    setDataLoadingState("isTotalReportDataLoading", true);
    http
      .get(`${selectedToolTech?.apiUrl}`, {
        params: {
          office_id: officeIdProps,
        },
      })
      .then((response: any) => {
        const theValue =
          response?.data[selectedToolTech?.responseFieldName][0]?.[
            selectedToolTech?.responseAttributeName
          ];
        setTotalReportData(theValue);
        setDataLoadingState("isTotalReportDataLoading", false);
      })
      .catch((error) => {
        console.log(error);
        setDataLoadingState("isTotalReportDataLoading", false);
      });
  }
  function getEngagementScoreByOffice() {
    setDataLoadingState("isEngagementScoreDataLoading", true);
    http
      .get(`${selectedToolTech?.engagementApiUrl}`, {
        params: {
          office_id: officeIdProps,
        },
      })
      .then((response: any) => {
        let theValue;
        if (selectedToolTech?.value === "neutrino") {
          theValue = response?.data[selectedToolTech?.engagementResponseField][0]?.[
            selectedToolTech?.engagementAttributeName
          ];
        } else {
          theValue = response?.data[selectedToolTech?.engagementResponseField]?.[
            selectedToolTech?.engagementAttributeName
          ];
        }
        setEngagementData(theValue);
        setDataLoadingState("isEngagementScoreDataLoading", false);
      })
      .catch((error) => {
        console.log(error);
        setDataLoadingState("isEngagementScoreDataLoading", false);
      });
  }
  function getPieReport() {
    setDataLoadingState("isChartDataLoading", true);
    if (selectedToolTech?.hasPieChart) {
      http
        .get(`${selectedToolTech?.pieChartApiUrl}`, {
          params: {
            office_id: officeIdProps,
            per_page: 500,
          },
        })
        .then((response: any) => {
          let theData: any = {};
          // eslint-disable-next-line no-unused-expressions
          selectedToolTech?.value === "neutrino"
            ? (theData = generateByOfficeToolsNeutrinoPieChartData(
              response?.data?.[selectedToolTech?.pieChartResponseField],
            ))
            : (theData = generateByOfficeToolsCmaPieChartData(
              response?.data?.[selectedToolTech?.pieChartResponseField],
            ));
          setChartData(theData);
          const chartFormattedOptions: any = generatePieChartOptions({
            theId: "pie-chart",
            colors: [
              "#FF7F50",
              "#21C0FC",
              "#CCCCFF",
              "#99FFFF",
              "#00FF7F",
              "#A4D124",
              "#FFF0F5",
              "#F795A8",
            ],
            categories: theData?.reportLabels,
            legendPositionOnDesktop: "right",
            legendPositionOnMobile: "bottom",
          });
          setChartOptions(chartFormattedOptions);
          setDataLoadingState("isChartDataLoading", false);
        })
        .catch((error) => {
          setDataLoadingState("isChartDataLoading", false);
          console.log(error);
        });
    } else {
      setChartData({});
      setChartOptions({});
      setDataLoadingState("isChartDataLoading", false);
    }
  }
  return (
    <>
      <div className="row mb-3">
        <div className="col-6 col-lg-7">
          <div className="d-inline-block">
            <span className="header-title-5 text-white mb-0 fw-medium pe-1">
              {selectedToolTech?.title} Tech Stats
            </span>
          </div>
          <p className=" mb-0 custom-fs-12">Last 12 Months</p>
        </div>
        <div className="col-6 col-lg-5 d-inline-flex justify-content-end">
          <SearchSelect
            options={toolTechOptions}
            value={
              JSON.stringify(selectedToolTech) !== "{}"
                ? selectedToolTech
                : "Select a tool tech"
            }
            onChange={(option: any) => setSelectedToolTech(option)}
            // isClearable
            width="150px"
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => `${option.title}`}
            placeholder="Select a tool tech"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="d-flex d-lg-block justify-content-between ">
            <p className="header-title-5 text-white">Enagement Score</p>
            <div className="d-flex align-items-center gap-2">
              <Placeholder
                loading={isDataLoading?.isEngagementScoreDataLoading}
                className="custom-w-12 custom-loader-h-6"
              ></Placeholder>
              {/* <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                0.4%
              </span> */}
              <span className="number-stats-11 text-white">
                {!isDataLoading?.isEngagementScoreDataLoading && engagementData}
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="d-flex d-lg-block justify-content-between ">
            <p className="header-title-5 text-white">
              {selectedToolTech?.textToShow}
            </p>
            <div className="d-flex align-items-center gap-2">
              <Placeholder
                loading={isDataLoading?.isTotalReportDataLoading}
                className="custom-w-12 custom-loader-h-6"
              ></Placeholder>
              {/* <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
                0.4%
              </span>
              <span className="number-stats-11 text-white">20%</span> */}
              <span className="number-stats-11 text-white">
                {!isDataLoading?.isTotalReportDataLoading && totalReportData}
              </span>
            </div>
          </div>
        </div>
        {/* <div className="text-center custom-text-fs my-2">
          <span className="badge badge-soft-success custom-badge-12-to-14 me-1">
            %
          </span>{" "}
          <span className="badge badge-soft-danger custom-badge-12-to-14 me-1">
            %
          </span>{" "}
          - difference from previous period
        </div> */}
      </div>
      <div className="row">
        <div className="col-12 px-0">
          {isDataLoading?.isChartDataLoading && (
            <div className="loader-circle"></div>
          )}
          {isDataLoading?.isChartDataLoading ? (
            <DefaultLineChartUI />
          ) : (
            (selectedToolTech?.value !== "activePipe" && (
              <div
                id="line-chart-gci"
                dir="ltr"
                style={{ width: "100%", margin: "auto" }}
              >
                <ReactApexChart
                  series={chartData?.reportCountData ?? []}
                  options={chartOptions}
                  type="donut"
                  height={500}
                  className="apex-charts custom-legend-bg"
                />
              </div>
            )) ||
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default OfficeToolTechStats;
