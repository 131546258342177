const heatmapDummyData = [
  {
    "latitude": 30.527082583650298,
    "longitude": -97.7727997377629,
    "weight": 2,
  },
  {
    "latitude": 30.40928901912321,
    "longitude": -97.73703848362196,
    "weight": 1,
  },
  {
    "latitude": 30.41359213082551,
    "longitude": -97.79552114040746,
    "weight": 1,
  },
  {
    "latitude": 30.4506629814821,
    "longitude": -97.83648051131091,
    "weight": 1,
  },
  {
    "latitude": 30.522818601774702,
    "longitude": -97.60888648005282,
    "weight": 1,
  },
  {
    "latitude": 30.45763091488064,
    "longitude": -97.78028668091866,
    "weight": 1,
  },
  {
    "latitude": 30.44702149999296,
    "longitude": -97.93006796211931,
    "weight": 1,
  },
  {
    "latitude": 30.5112675277089,
    "longitude": -97.3481956800949,
    "weight": 1,
  },
  {
    "latitude": 30.45409132200017,
    "longitude": -97.60841170098362,
    "weight": 1,
  },
  {
    "latitude": 30.62924049632815,
    "longitude": -97.47030069769978,
    "weight": 1,
  },
  {
    "latitude": 30.420500829536408,
    "longitude": -97.65300568452032,
    "weight": 1,
  },
  {
    "latitude": 30.52469006850723,
    "longitude": -97.81345204363961,
    "weight": 1,
  },
  {
    "latitude": 30.498903942030356,
    "longitude": -97.93303669131701,
    "weight": 1,
  },
  {
    "latitude": 30.470296994273937,
    "longitude": -97.62435514068112,
    "weight": 1,
  },
  {
    "latitude": 30.52627016666182,
    "longitude": -97.9499971102128,
    "weight": 1,
  },
  {
    "latitude": 30.41785719786548,
    "longitude": -97.84030388457118,
    "weight": 1,
  },
  {
    "latitude": 30.45485157338155,
    "longitude": -97.58886571399798,
    "weight": 1,
  },
  {
    "latitude": 30.451622378051574,
    "longitude": -97.44750836402793,
    "weight": 1,
  },
  {
    "latitude": 30.461278351465857,
    "longitude": -97.99540044906998,
    "weight": 1,
  },
  {
    "latitude": 30.429315723655833,
    "longitude": -98.01970985851739,
    "weight": 1,
  },
  {
    "latitude": 30.452306012260735,
    "longitude": -97.28884528277989,
    "weight": 1,
  },
  {
    "latitude": 30.495544091166458,
    "longitude": -97.62840932608198,
    "weight": 1,
  },
  {
    "latitude": 30.3993223921037,
    "longitude": -97.71069959035754,
    "weight": 1,
  },
  {
    "latitude": 30.582424996144873,
    "longitude": -97.78027545435461,
    "weight": 1,
  },
  {
    "latitude": 30.50170354627567,
    "longitude": -97.51281765817905,
    "weight": 1,
  },
  {
    "latitude": 30.478624201749184,
    "longitude": -97.92812474628326,
    "weight": 2,
  },
  {
    "latitude": 30.410190144366084,
    "longitude": -97.66483117380139,
    "weight": 1,
  },
  {
    "latitude": 30.520487472930405,
    "longitude": -97.61989973580351,
    "weight": 1,
  },
  {
    "latitude": 30.582652810896228,
    "longitude": -97.66774639568457,
    "weight": 1,
  },
  {
    "latitude": 30.510670392640392,
    "longitude": -97.65445429444316,
    "weight": 1,
  },
  {
    "latitude": 30.441611681453118,
    "longitude": -97.37979201794462,
    "weight": 1,
  },
  {
    "latitude": 30.405462726511843,
    "longitude": -97.8399393194883,
    "weight": 1,
  },
  {
    "latitude": 30.47912470919406,
    "longitude": -97.7942431139782,
    "weight": 1,
  },
  {
    "latitude": 30.438534308786068,
    "longitude": -97.66946024528566,
    "weight": 1,
  },
  {
    "latitude": 30.431702914504896,
    "longitude": -97.71190458957469,
    "weight": 2,
  },
  {
    "latitude": 30.424947444611753,
    "longitude": -97.3680010424784,
    "weight": 1,
  },
  {
    "latitude": 30.480011668070052,
    "longitude": -97.56206913122203,
    "weight": 1,
  },
  {
    "latitude": 30.524528502999765,
    "longitude": -97.61946470676314,
    "weight": 1,
  },
  {
    "latitude": 30.621420004976617,
    "longitude": -97.53047421647923,
    "weight": 1,
  },
  {
    "latitude": 30.477737558551453,
    "longitude": -97.94995241047731,
    "weight": 1,
  },
  {
    "latitude": 30.449373470164453,
    "longitude": -97.7036962000972,
    "weight": 1,
  },
  {
    "latitude": 30.44753304013831,
    "longitude": -97.5050279916496,
    "weight": 1,
  },
  {
    "latitude": 30.46670575429306,
    "longitude": -97.67269518390393,
    "weight": 1,
  },
  {
    "latitude": 30.549324636097143,
    "longitude": -97.50764391054715,
    "weight": 1,
  },
  {
    "latitude": 30.3999530337556,
    "longitude": -97.70272035524258,
    "weight": 1,
  },
  {
    "latitude": 30.419785216702067,
    "longitude": -97.8718524387158,
    "weight": 1,
  },
  {
    "latitude": 30.40611529906062,
    "longitude": -97.96536595885271,
    "weight": 1,
  },
  {
    "latitude": 30.425307086212545,
    "longitude": -97.81895835573934,
    "weight": 1,
  },
  {
    "latitude": 30.450087979498438,
    "longitude": -97.64147305934233,
    "weight": 1,
  },
  {
    "latitude": 30.442798012431595,
    "longitude": -97.65715341378409,
    "weight": 1,
  },
  {
    "latitude": 30.456100577470018,
    "longitude": -97.62509718023219,
    "weight": 1,
  },
  {
    "latitude": 30.42378716525839,
    "longitude": -97.79565046752687,
    "weight": 1,
  },
  {
    "latitude": 30.549698372998254,
    "longitude": -97.78840095294476,
    "weight": 1,
  },
  {
    "latitude": 30.420761663018286,
    "longitude": -97.6251106804696,
    "weight": 1,
  },
  {
    "latitude": 30.548911783395027,
    "longitude": -97.65686048482287,
    "weight": 1,
  },
  {
    "latitude": 30.398268448149636,
    "longitude": -97.70610880184013,
    "weight": 1,
  },
  {
    "latitude": 30.496869968129154,
    "longitude": -97.72878709936091,
    "weight": 1,
  },
  {
    "latitude": 30.503255639136214,
    "longitude": -97.64837718021776,
    "weight": 1,
  },
  {
    "latitude": 30.512106288321053,
    "longitude": -97.6094566450826,
    "weight": 1,
  },
  {
    "latitude": 30.409124089987607,
    "longitude": -97.8582136856897,
    "weight": 1,
  },
  {
    "latitude": 30.564998453613995,
    "longitude": -97.56409126635104,
    "weight": 1,
  },
  {
    "latitude": 30.44292854053905,
    "longitude": -97.86732302042401,
    "weight": 1,
  },
  {
    "latitude": 30.588771464371973,
    "longitude": -97.6231348407183,
    "weight": 1,
  },
  {
    "latitude": 30.599209871415884,
    "longitude": -97.46712722262251,
    "weight": 1,
  },
  {
    "latitude": 30.47756876216595,
    "longitude": -97.55067191733168,
    "weight": 1,
  },
  {
    "latitude": 30.459059616494166,
    "longitude": -97.67122571552065,
    "weight": 2,
  },
  {
    "latitude": 30.45532483356371,
    "longitude": -97.90920944321667,
    "weight": 1,
  },
  {
    "latitude": 30.505950313301533,
    "longitude": -97.71579699519496,
    "weight": 1,
  },
  {
    "latitude": 30.43936120034489,
    "longitude": -97.70921230742975,
    "weight": 1,
  },
];

const currencyFormat = (currencyAmount: number) => currencyAmount.toLocaleString('en-US');
export { currencyFormat, heatmapDummyData };
