import PermissionCheck from "@components/common/PermissionCheck";
import AgentRecruitRetention from "@components/office/AgentRecruitRetention";
import CompletedRecommendation from "@components/office/CompletedRecommendation";
import ListingOverviewTable from "@components/office/ListingOverviewTable";
import NinjaAgentSalesVolumeActivity from "@components/office/NinjaAgentSalesVolumeActivity";
import NinjaCertification from "@components/office/NinjaCertification";
import RecruitmentRetentionTable from "@components/office/RecruitmentRetentionTable";
import RevenueBreakdown from "@components/office/RevenueBreakdown";
import { officePerms } from "@constants/permission";
import { actionUserPermissions, selectUser } from "@state/appSlice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { http } from "@utils/http";
import { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

function Offices(props: any) {
  const dispatch = useAppDispatch();
  const selectedUser: any = useAppSelector(selectUser);
  const [isPermissionDataLoading, setIsPermissionDataLoading] = useState(true);

  useEffect(() => {
    if (selectedUser?.id) {
      setIsPermissionDataLoading(true);
      // TODO: here call user permission api
      http
        .get(
          `user-permission-site-data.json`,
        )
        .then((response: any) => {
          const { permission } = response?.data;
          const getUserPerms = response?.data?.permission[0]?.permissions;
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: getUserPerms ?? [] }));
          setIsPermissionDataLoading(false);
        })
        .catch((error) => {
          // setIsDataLoading(false);
          dispatch(actionUserPermissions({ permissions: [] }));
          setIsPermissionDataLoading(false);
          console.log(error);
        });
    }
  }, [selectedUser]);
  return (
    <>
      <MetaTags>
        <title>Offices - Kintaro 1</title>
      </MetaTags>

      <div className="container">
        <PermissionCheck permission={`${officePerms.office_list_overview}`}>
          {/* Listing Overview */}
          <ListingOverviewTable />
        </PermissionCheck>

        <PermissionCheck
          permission={`${officePerms.office_complete_recommendation}`}
        >
          {/* Completed Recomendations */}
          <CompletedRecommendation />
        </PermissionCheck>

        {/* Agents Recruit and Retention */}
        <AgentRecruitRetention />

        {/* Ninja Certification Status */}
        <NinjaCertification/>
        
        {/* Ninja Agent Sales Volume Activity  */}
        <NinjaAgentSalesVolumeActivity/>
        
        {/* Technology Engagement */}
        {/* <TechnologyEngagement/> */}

        <PermissionCheck permission={`${officePerms.office_gci_breakdown}`}>
          {/* Revenue Breakdown */}
          <RevenueBreakdown />
        </PermissionCheck>
        
        <PermissionCheck
          permission={`${officePerms.office_recruitment_retention}`}
        >
          {/* Recruitement & Retention table */}
          <RecruitmentRetentionTable />
        </PermissionCheck>
      </div>
    </>
  );
}
export default Offices;
