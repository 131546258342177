import HelpIcon from "@components/common/HelpIcon";
import Placeholder from "@components/common/Placeholder";
import DefaultLineChartUI from "@components/common/default-chart-ui/DefaultLineChartUI";
import { toolsPageToolTip } from "@constants/tooltip-data";
import { SQL_NOT_EXIST_MESSAGE } from "@constants/variables";
import generateLineChartOptions from "@utils/chart/line-chart";
import { formatDecimalPlaces } from "@utils/helpers";
import { http } from "@utils/http";
import { generateEmailMarketingEngagementScore } from "@utils/transform/toolsActivePipe";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function NeutrinoEngagementOverTime() {
  const [chartData, setChartData] = useState([] as any);
  const [chartApiData, setChartApiData] = useState([] as any);
  const [theOptions, setTheOptions] = useState({} as any);
  const [isChartDataLoading, setIsChartDataLoading] = useState(true);
  // const [otherInfo, setOtherInfo] = useState({} as any);

  useEffect(() => {
    http
      .get("/tools/neutrino--monthly-website-engagement-score-last-12-month.json")
      .then((response: any) => {
        let apiData = response?.data?.website_engagement;
        if (apiData[0]?.message === SQL_NOT_EXIST_MESSAGE) {
          apiData = [];
        }
        const theData: any = generateEmailMarketingEngagementScore(apiData);
        setChartData(theData);
        setChartApiData(apiData);

        const chartOptions: any = generateLineChartOptions({
          theId: "line-chart-sales-price-trends",
          colors: ["#F5822F"],
          categories: theData?.categories ?? [],
          gridPL: 5,
          // ticks: generateTicks(theData?.last_12_average_close_price ?? []),
          ticksPreLast: ["", ""],
        });
        setTheOptions(chartOptions);
        setIsChartDataLoading(false);
      })
      .catch((error: any) => {
        setIsChartDataLoading(false);
        console.log(error);
      });
  }, []);

  return (
    <>
      <div
        className="custom-modal-inner__padding h-100"
        style={{ backgroundColor: "#2A3042" }}
      >
        <div className="row row-gap-1 row-gap-md-0">
          <div className="col-12">
            <div className="row row-gap-2 row-gap-lg-0">
              <div className="col-12 col-lg-6">
                <div className="d-inline-block">
                  <span className="header-title-6 fw-bold text-white pe-1 ">
                    Engagement Over Time
                  </span>
                  <div className="d-inline-block align-middle">
                    <HelpIcon
                      tooltip={`${toolsPageToolTip.toolsNeutrinoEngmntOverTime}`}
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="">
                  <p className="custom-text-fs mb-1">This Month</p>
                  <p className="number-stats-12 text-white mb-0">
                    <Placeholder
                      loading={isChartDataLoading}
                      className="custom-loader-w-20 custom-loader-h-6"
                    ></Placeholder>
                    {(!isChartDataLoading && chartData?.latestMonthEngagementObj?.current_year_score) ?
                      parseFloat(
                        chartData?.latestMonthEngagementObj?.current_year_score,
                      ).toFixed(2) : ""}
                  </p>
                  <div className="d-flex gap-2 align-items-center">
                    <Placeholder
                      loading={isChartDataLoading}
                      className="custom-loader-w-20 custom-loader-h-6"
                    ></Placeholder>
                    {!isChartDataLoading &&
                      (() => {
                        if (
                          chartData?.latestMonthEngagementObj
                            ?.percentage_difference < 0
                        ) {
                          return (
                            <span className="badge badge-soft-danger custom-badge-12-to-14 p-1 me-1">
                              {formatDecimalPlaces(
                                chartData?.latestMonthEngagementObj
                                  ?.percentage_difference,
                              )}
                              %
                            </span>
                          );
                        } else {
                          return (
                            <span className="badge badge-soft-success custom-badge-12-to-14 p-1 me-1">
                              +{" "}
                              {formatDecimalPlaces(
                                chartData?.latestMonthEngagementObj
                                  ?.percentage_difference,
                              )}
                              %
                            </span>
                          );
                        }
                      })()}
                    <span className="custom-fs-10">From Last Month</span>
                  </div>
                </div>
              </div>
            </div>
            {isChartDataLoading && <div className="loader-circle"></div>}
            {isChartDataLoading ? (
              <DefaultLineChartUI />
            ) : (
              <div id="line-chart-engagement-score" dir="ltr">
                <ReactApexChart
                  series={[
                    {
                      name: "Engagement Score",
                      data: chartData?.current_year_score,
                    },
                  ]}
                  options={theOptions}
                  type="line"
                  height={320}
                  className="apex-charts"
                />
              </div>
            )}
          </div>
          {/* <div className="col-md-4">
            <div className="">
              <p className="custom-text-fs mb-1">This Month</p>
              <p className="number-stats mb-0">
                <Placeholder
                  loading={isChartDataLoading}
                  className="custom-loader-w-20 custom-loader-h-6"
                ></Placeholder>
                {!isChartDataLoading &&
                  parseFloat(
                    chartData?.latestMonthEngagementObj?.current_year_score,
                  ).toFixed(2)}
              </p>
              <div className="d-flex gap-2 align-items-center">
                <Placeholder
                  loading={isChartDataLoading}
                  className="custom-loader-w-20 custom-loader-h-6"
                ></Placeholder>
                {!isChartDataLoading &&
                  (() => {
                    if (
                      chartData?.latestMonthEngagementObj
                        ?.percentage_difference < 0
                    ) {
                      return (
                        <span className="badge badge-soft-danger custom-badge-12-to-14 p-1 me-1">
                          {formatDecimalPlaces(
                            chartData?.latestMonthEngagementObj
                              ?.percentage_difference,
                          )}
                          %
                        </span>
                      );
                    } else {
                      return (
                        <span className="badge badge-soft-success custom-badge-12-to-14 p-1 me-1">
                          +{" "}
                          {formatDecimalPlaces(
                            chartData?.latestMonthEngagementObj
                              ?.percentage_difference,
                          )}
                          %
                        </span>
                      );
                    }
                  })()}
                <span className="custom-fs-10">From Last Month</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default NeutrinoEngagementOverTime;
